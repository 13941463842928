import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./UserProfiles.css";

import { userDataAtom } from "../../../../store/atoms/userDataAtom";
import { useRecoilValue } from "recoil";

// Ant Design Import
import { notification, Radio, Image, message } from "antd";
import TextArea from "antd/es/input/TextArea";

// REACT ICONS
import { IoChevronBack, IoLogoWhatsapp } from "react-icons/io5";
import {
  MdDriveFileRenameOutline,
  MdOutlineDomainVerification,
  MdOutlineEmail,
  MdOutlineNaturePeople,
  MdOutlinePhoneEnabled,
  MdShareLocation,
} from "react-icons/md";
import { TbBuildingEstate, TbCalendarStar, TbInputCheck } from "react-icons/tb";
import { LuTextCursorInput, LuServer, LuTag } from "react-icons/lu";
import { PiBracketsCurlyBold, PiCity, PiShoppingBag } from "react-icons/pi";
import { FaRegUser } from "react-icons/fa";
import { LiaSave, LiaUserTagSolid } from "react-icons/lia";
import { AiOutlineFileImage } from "react-icons/ai";
import { FaRegEyeSlash } from "react-icons/fa6";
import { GrCurrency } from "react-icons/gr";
import { BsTypeStrikethrough } from "react-icons/bs";
import { GiMeepleCircle } from "react-icons/gi";
import { BiParty } from "react-icons/bi";
import { formatPrice } from "../../../../utils/priceFormatter";

const ProfileField = ({
  icon: Icon,
  label,
  value,
  onChange,
  selectedValue,
  disabled = false,
  existingRejectedReason = "",
  onRejectReasonChange = () => {},
}) => {
  const className = `auser-checkboxes-sub ${
    disabled
      ? "disabled"
      : selectedValue === true
      ? "true"
      : selectedValue === false
      ? "false"
      : ""
  }`;
  // Function to determine if the value is a URL
  const isURL = (str) => {
    try {
      new URL(str);
      return true;
    } catch {
      return false;
    }
  };

  const [rejectReason, setRejectReason] = useState("");
  const [rejectReasonSubmitted, setRejectReasonSubmitted] = useState(false);
  const [existingRejectedReasonEdited, setExistingRejectedReasonEdited] =
    useState(true);

  useMemo(() => {
    if (existingRejectedReason && existingRejectedReason !== "") {
      setRejectReason(existingRejectedReason);
    }
    return () => setExistingRejectedReasonEdited(false);
  }, [existingRejectedReason]);

  return (
    <div className={className}>
      <h6>
        <Icon className="auser-checkboxes-sub-icon" />
        {label}
      </h6>
      {isURL(value) ? (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          className="profile-field-url"
        >
          {value}
        </a>
      ) : (
        <p>{value}</p>
      )}
      <Radio.Group
        onChange={onChange}
        className="auser-checkboxex"
        value={selectedValue}
        disabled={disabled}
      >
        <Radio value={true}>Accept</Radio>
        <Radio value={false}>Reject</Radio>
      </Radio.Group>
      {selectedValue === false && (
        <>
          <TextArea
            placeholder="Reject Reason"
            className="auser-sub-textarea"
            value={
              rejectReason === "" && !existingRejectedReasonEdited
                ? existingRejectedReason
                : rejectReason
            }
            onChange={(e) => {
              setRejectReason(e.target?.value);
              setRejectReasonSubmitted(false);
            }}
          />
          {!rejectReasonSubmitted &&
            rejectReason !== existingRejectedReason && (
              <button
                onClick={() => {
                  if (rejectReason === "") {
                    return message.error("Please provide reject reason");
                  }
                  onRejectReasonChange(label, rejectReason);
                  setRejectReasonSubmitted(true);
                }}
                className="auser-sub-reject-btn"
              >
                Save
              </button>
            )}
        </>
      )}
    </div>
  );
};

const ProfileDocumentField = ({
  icon: Icon,
  label,
  imgSrcs,
  imgClassNames,
  onChange,
  selectedValue,
  existingRejectedReason,
  disabled = false,
  onRejectReasonChange = () => {},
}) => {
  const className = `auser-checkboxes-sub ${
    disabled
      ? "disabled"
      : selectedValue === true
      ? "true"
      : selectedValue === false
      ? "false"
      : ""
  }`;
  const renderImage = (src, className, index) => (
    <Image
      width={175}
      height={150}
      src={src}
      alt={`${label} ${index + 1}`}
      className="auser-rv-antdesign-image"
    />
  );

  const [rejectReason, setRejectReason] = useState("");
  const [rejectReasonSubmitted, setRejectReasonSubmitted] = useState(false);
  const [existingRejectedReasonEdited, setExistingRejectedReasonEdited] =
    useState(true);

  useMemo(() => {
    if (existingRejectedReason && existingRejectedReason !== "") {
      setRejectReason(existingRejectedReason);
    }
    return () => setExistingRejectedReasonEdited(false);
  }, [existingRejectedReason]);

  return (
    <div className={className}>
      <h6>
        <Icon className="auser-checkboxes-sub-icon" />
        {label}
      </h6>
      {Array.isArray(imgSrcs)
        ? imgSrcs.map((src, index) =>
            renderImage(
              src,
              Array.isArray(imgClassNames)
                ? imgClassNames[index]
                : imgClassNames,
              index
            )
          )
        : renderImage(imgSrcs, imgClassNames, 0)}
      <Radio.Group
        onChange={onChange}
        value={selectedValue}
        className="auser-checkboxex"
        disabled={disabled}
      >
        <Radio value={true}>Accept</Radio>
        <Radio value={false}>Reject</Radio>
      </Radio.Group>
      {selectedValue === false && (
        <>
          <TextArea
            placeholder="Reject Reason"
            className="auser-sub-textarea"
            value={
              rejectReason === "" && !existingRejectedReasonEdited
                ? existingRejectedReason
                : rejectReason
            }
            onChange={(e) => {
              setRejectReason(e.target?.value);
              setRejectReasonSubmitted(false);
            }}
          />
          {!rejectReasonSubmitted &&
            rejectReason !== existingRejectedReason && (
              <button
                onClick={() => {
                  if (rejectReason === "") {
                    return message.error("Please provide reject reason");
                  }
                  onRejectReasonChange(label, rejectReason);
                  setRejectReasonSubmitted(true);
                }}
                className="auser-sub-reject-btn"
              >
                Save
              </button>
            )}
        </>
      )}
    </div>
  );
};

function ReviewProfiles() {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;

  const { profileId } = useParams();
  const [reviewProfile, setReviewProfile] = useState(null);
  const [employeerData, setEmployeerData] = useState(null);
  const [parsedEmployeerData, setParsedEmployeerData] = useState(null);
  const [reviewProfileState, setReviewProfileState] = useState(null);
  const [existingRejectedReasons, setExistingRejectedReasons] = useState(null);
  const [reviewProfileUserType, setReviewProfileUserType] = useState(null);

  const getAssignedPrfileData = useCallback(async () => {
    try {
      const empdata = "false";
      const alldetails = "true";
      const _userProfileData = await axios.get(
        `${baseURL}/api/profile/getUserDetailsById/${profileId}?empdata=${empdata}&alldetails=${alldetails}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      _userProfileData?.data?.profileData &&
        setReviewProfile(_userProfileData?.data?.profileData);
      setEmployeerData(_userProfileData?.data?.employeer);
      setReviewProfileUserType(_userProfileData?.data?.profileData?.userType);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [baseURL, profileId]);

  useMemo(() => {
    switch (employeerData?.userType) {
      case "Agent":
        setParsedEmployeerData((prev) => ({
          ...prev,
          firmName: employeerData?.agentDetails?.agentFirmName,
          firmLogo: employeerData?.agentDetails?.agentFirmLogo,
          reraNo: employeerData?.agentDetails?.agentReraNo,
          reraNoType: employeerData?.agentDetails?.agentReraNoType,
          firmAddress: employeerData?.agentDetails?.agentOfficeAddress,
          officeName: employeerData?.agentDetails?.agentOfficeName,
          officeCity: employeerData?.agentDetails?.agentOfficeCity,
          officeWebsite: employeerData?.agentDetails?.agentOfficeWebsite,
        }));
        break;

      case "Builder":
        setParsedEmployeerData((prev) => ({
          ...prev,
          firmName: employeerData?.builderDetails?.builderFirmName,
          firmLogo: employeerData?.builderDetails?.builderFirmLogo,
          reraNo: employeerData?.builderDetails?.builderReraNo,
          reraNoType: employeerData?.builderDetails?.builderReraNoType,
          firmAddress: employeerData?.builderDetails?.builderOfficeAddress,
          officeName: employeerData?.builderDetails?.builderOfficeName,
          officeCity: employeerData?.builderDetails?.builderOfficeCity,
          officeWebsite: employeerData?.builderDetails?.builderOfficeWebsite,
        }));
        break;

      default:
        break;
    }
  }, [employeerData]);

  const getProfileVerificationDetails = useCallback(async () => {
    try {
      const verificationProfileDetails = await axios.get(
        `${baseURL}/api/admin/getProfileVerificationDetails/${profileId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (
        Object.keys(verificationProfileDetails?.data?.verificationDetails)
          .length > 0
      ) {
        setReviewProfileState(
          verificationProfileDetails?.data?.verificationDetails
        );
      } else {
        notification.info({
          message: "Profile not found",
          description: "Profile is no longer available for verification",
        });

        return navigate(
          "/admin/user-management/user-profiles/assigned-profiles"
        );
      }
      setExistingRejectedReasons(
        verificationProfileDetails?.data?.verificationDetails?.rejectedReasons
      );
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [baseURL, profileId, navigate]);

  useMemo(() => {
    getAssignedPrfileData();
    getProfileVerificationDetails();
  }, [getAssignedPrfileData, getProfileVerificationDetails]);

  const [profileState, setProfileState] = useState({
    userName: null,
    email: null,
    mobile: null,
    whatsapp: null,
    dealsIn: null,
    designation: null,
    firmName: null,
    reraNo: null,
    reraNoType: null,
    firmAddress: null,
    officeName: null,
    officeCity: null,
    websiteUrl: null,
    idCardType: null,
    idCardNo: null,
    aadhaarCardNo: null,
    panCardNo: null,
    voterCardNo: null,
    passportNo: null,
    drivingLicenseNo: null,
    profilePhoto: null,
    employeementLetter: null,
    firmLogo: null,
    employeeIdCard: null,
    aadhaarCard: null,
    panCard: null,
    voterCard: null,
    passport: null,
    drivingLicense: null,
    rejectedReasons: [],
  });

  useMemo(() => {
    if (existingRejectedReasons) {
      console.log("memo", existingRejectedReasons);
      setProfileState((prev) => ({
        ...prev,
        rejectedReasons: existingRejectedReasons,
      }));
    }

    switch (reviewProfileUserType) {
      case "Buyer":
        setProfileState((prev) => ({
          ...prev,
          userName: reviewProfileState?.userName,
          email: reviewProfileState?.email,
          mobile: reviewProfileState?.mobileNumber,
          whatsapp: reviewProfileState?.whatsappNumber,
          profilePhoto: reviewProfileState?.profilePhoto,
        }));
        break;

      case "Owner":
        setProfileState((prev) => ({
          ...prev,
          userName: reviewProfileState?.userName,
          email: reviewProfileState?.email,
          mobile: reviewProfileState?.mobileNumber,
          whatsapp: reviewProfileState?.whatsappNumber,
          profilePhoto: reviewProfileState?.profilePhoto,
        }));
        break;

      case "Builder":
        setProfileState((prev) => ({
          ...prev,
          userName: reviewProfileState?.userName,
          email: reviewProfileState?.email,
          mobile: reviewProfileState?.mobileNumber,
          whatsapp: reviewProfileState?.whatsappNumber,
          profilePhoto: reviewProfileState?.profilePhoto,
          firmName: reviewProfileState?.firmName,
          firmLogo: reviewProfileState?.firmLogo,
          firmAddress: reviewProfileState?.firmAddress,
          reraNo: reviewProfileState?.reraNo,
          reraNoType: reviewProfileState?.reraNoType,
          officeName: reviewProfileState?.officeName,
          officeCity: reviewProfileState?.officeCity,
          websiteUrl: reviewProfileState?.officeWebsiteUrl,
        }));
        break;

      case "Agent":
        setProfileState((prev) => ({
          ...prev,
          userName: reviewProfileState?.userName,
          email: reviewProfileState?.email,
          mobile: reviewProfileState?.mobileNumber,
          whatsapp: reviewProfileState?.whatsappNumber,
          profilePhoto: reviewProfileState?.profilePhoto,
          dealsIn: reviewProfileState?.dealsIn,
          firmName: reviewProfileState?.firmName,
          firmLogo: reviewProfileState?.firmLogo,
          firmAddress: reviewProfileState?.firmAddress,
          reraNo: reviewProfileState?.reraNo,
          reraNoType: reviewProfileState?.reraNoType,
          officeName: reviewProfileState?.officeName,
          officeCity: reviewProfileState?.officeCity,
          websiteUrl: reviewProfileState?.officeWebsiteUrl,
        }));
        break;

      case "Salesperson":
        setProfileState((prev) => ({
          ...prev,
          userName: reviewProfileState?.userName,
          email: reviewProfileState?.email,
          mobile: reviewProfileState?.mobileNumber,
          whatsapp: reviewProfileState?.whatsappNumber,
          designation: reviewProfileState?.designation,
          profilePhoto: reviewProfileState?.profilePhoto,
          firmName: reviewProfileState?.firmName,
          firmLogo: reviewProfileState?.firmLogo,
          firmAddress: reviewProfileState?.firmAddress,
          reraNo: reviewProfileState?.reraNo,
          reraNoType: reviewProfileState?.reraNoType,
          officeName: reviewProfileState?.officeName,
          officeCity: reviewProfileState?.officeCity,
          websiteUrl: reviewProfileState?.officeWebsiteUrl,
          idCardNo: reviewProfileState?.idCardNo,
          idCardType:
            reviewProfileState?.idCardType ||
            (reviewProfile?.employeeIdType &&
            reviewProfile?.employeeIdType !== ""
              ? true
              : false),
          employeementLetter: reviewProfileState?.employeementLetter,
          employeeIdCard: reviewProfileState?.employeeIdCard,

          aadhaarCardNo: reviewProfileState?.aadhaarCardNo,
          aadhaarCard: reviewProfileState?.aadhaarCard,

          panCardNo: reviewProfileState?.panCardNo,
          panCard: reviewProfileState?.panCard,

          passportNo: reviewProfileState?.passportNo,
          passport: reviewProfileState?.passport,

          drivingLicenseNo: reviewProfileState?.drivingLicenseNo,
          drivingLicense: reviewProfileState?.drivingLicense,
        }));
        break;

      default:
        break;
    }
  }, [
    reviewProfileState,
    reviewProfileUserType,
    reviewProfile,
    existingRejectedReasons,
  ]);
  const handleChange = (field) => (e) => {
    setProfileState((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  const fetchExistingRejectedReason = (label) => {
    const existingRejectedReasons = profileState?.rejectedReasons;

    const index = existingRejectedReasons?.findIndex(
      (reason) => reason.label === label
    );

    if (index !== -1) {
      return profileState?.rejectedReasons[index]?.rejectReason &&
        profileState?.rejectedReasons[index]?.rejectReason !== ""
        ? profileState?.rejectedReasons[index]?.rejectReason
        : "not provided";
    }
  };

  const handleRejectReasonChange = (label, rejectReason = "") => {
    const rejectReasonObj = {
      label: label && label !== "" ? label : "not provided",
      rejectReason: rejectReason,
    };

    const existingRejectedReasons = profileState.rejectedReasons;

    const index = existingRejectedReasons.findIndex(
      (reason) => reason.label === label
    );

    if (index !== -1) {
      existingRejectedReasons[index].rejectReason = rejectReason;
    } else {
      existingRejectedReasons.push(rejectReasonObj);
    }

    setProfileState((prev) => ({
      ...prev,
      rejectedReasons: existingRejectedReasons,
    }));
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();

      formData.append("adminID", userId);
      formData.append("verificationProfileId", profileId);
      formData.append("profileState", JSON.stringify(profileState));

      if (reviewProfileUserType === "Salesperson") {
        formData.append("employeeIdType", reviewProfile.employeeIdType);
        formData.append(
          "isEmployeementLetterPresent",
          reviewProfile.employeementLetter &&
            reviewProfile.employeementLetter !== "" &&
            reviewProfile.employeementLetter !== "undefined"
            ? true
            : false
        );
        formData.append(
          "isFirmNamePresent",
          reviewProfile.firmName &&
            reviewProfile.firmName !== "" &&
            reviewProfile.firmName !== "undefined"
            ? true
            : false
        );
        formData.append(
          "isFirmLogoPresent",
          reviewProfile.firmLogo &&
            reviewProfile.firmLogo !== "" &&
            reviewProfile.firmLogo !== "undefined"
            ? true
            : false
        );
        formData.append(
          "isReraNoPresent",
          reviewProfile.reraNo &&
            reviewProfile.reraNo !== "" &&
            reviewProfile.reraNo !== "undefined"
            ? true
            : false
        );
        formData.append(
          "isReraNoTypePresent",
          reviewProfile.reraNoType &&
            reviewProfile.reraNoType !== "" &&
            reviewProfile.reraNoType !== "undefined"
            ? true
            : false
        );
        formData.append(
          "isFirmAddressPresent",
          reviewProfile.firmAddress &&
            reviewProfile.firmAddress !== "" &&
            reviewProfile.firmAddress !== "undefined"
            ? true
            : false
        );
        formData.append(
          "isOfficeCityPresent",
          reviewProfile.officeCity &&
            reviewProfile.officeCity !== "" &&
            reviewProfile.officeCity !== "undefined"
            ? true
            : false
        );
      }
      formData.append(
        "isOfficeNamePresent",
        reviewProfile.officeName &&
          reviewProfile.officeName !== "" &&
          reviewProfile.officeName !== "undefined"
          ? true
          : false
      );
      formData.append(
        "isOfficeWebsiteUrlPresent",
        reviewProfile.officeWebsite &&
          reviewProfile.officeWebsite !== "" &&
          reviewProfile.officeWebsite !== "undefined"
          ? true
          : false
      );

      const profileVerificationRes = await axios.post(
        `${baseURL}/api/admin/saveProfileVerification`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (
        profileVerificationRes?.data?.message ===
          "Profile verification completed successfully" &&
        profileVerificationRes?.data?.isProfileVerified
      ) {
        notification.success({
          message: "Success",
          description: profileVerificationRes?.data?.message,
        });
      } else if (
        profileVerificationRes?.data?.message ===
        "Profile verification progress saved"
      ) {
        notification.info({
          message: "Progress Saved",
          description: profileVerificationRes?.data?.message,
        });
      }

      // setReviewProfileUserType(_userProfileData?.data?.profileData?.userType);
    } catch (error) {
      console.error("Error fetching user data:", error);
      notification.error({
        message: "Unauthorized",
        description: error?.response?.data?.error,
      });
    } finally {
      return navigate("/admin/user-management/user-profiles/assigned-profiles");
    }
  };

  const annualIncome =
    reviewProfile?.annualIncome && reviewProfile?.annualIncome !== ""
      ? JSON.parse(reviewProfile?.annualIncome)
      : [];

  return (
    <div className="ausers">
      <div className="ausers-sub">
        <div className="ausers-custom-header">
          <div className="ausers-custom-header-sub">
            <button
              onClick={() =>
                navigate(
                  "/admin/user-management/user-profiles/assigned-profiles"
                )
              }
              className="btnCancel"
            >
              <IoChevronBack className="ausers-custom-header-sub-btn-icon" />
              Back
            </button>
            <button onClick={handleSave}>
              <LiaSave className="ausers-custom-header-sub-btn-icon" /> Save
            </button>
          </div>
        </div>
        <div className="auser-container">
          <h1>Profile Verification Procedure</h1>
        </div>
        <div className="auser-container">
          <h4>
            {reviewProfileUserType === "Buyer" ||
            reviewProfileUserType === "Owner"
              ? "Personal"
              : "Business"}{" "}
            Info
          </h4>
          <div className="auser-checkboxes">
            <ProfileField
              icon={MdDriveFileRenameOutline}
              label="Name"
              value={reviewProfile?.userName}
              onChange={handleChange("userName")}
              selectedValue={profileState.userName}
              onRejectReasonChange={handleRejectReasonChange}
              existingRejectedReason={fetchExistingRejectedReason("Name")}
            />
            <ProfileField
              icon={MdOutlineEmail}
              label="Email"
              value={reviewProfile?.email}
              onChange={handleChange("email")}
              selectedValue={profileState.email}
              onRejectReasonChange={handleRejectReasonChange}
              existingRejectedReason={fetchExistingRejectedReason("Email")}
            />
            <ProfileField
              icon={MdOutlinePhoneEnabled}
              label="Mobile"
              value={reviewProfile?.mobileNumber}
              onChange={handleChange("mobile")}
              selectedValue={profileState.mobile}
              onRejectReasonChange={handleRejectReasonChange}
              existingRejectedReason={fetchExistingRejectedReason("Mobile")}
            />
            <ProfileField
              icon={IoLogoWhatsapp}
              label="Whatsapp Mobile"
              value={reviewProfile?.whatsappNumber}
              onChange={handleChange("whatsapp")}
              selectedValue={profileState.whatsapp}
              onRejectReasonChange={handleRejectReasonChange}
              existingRejectedReason={fetchExistingRejectedReason(
                "Whatsapp Mobile"
              )}
            />
            {reviewProfileUserType === "Agent" && (
              <ProfileField
                icon={LuTag}
                label="Deals In"
                value={
                  reviewProfile?.dealsIn &&
                  reviewProfile?.dealsIn !== "" &&
                  reviewProfile?.dealsIn !== "undefined" &&
                  reviewProfile?.dealsIn !== "[]" &&
                  JSON.parse(reviewProfile?.dealsIn)
                    ?.map((item) => item)
                    .join(", ")
                }
                onChange={handleChange("dealsIn")}
                selectedValue={profileState.dealsIn}
                onRejectReasonChange={handleRejectReasonChange}
                existingRejectedReason={fetchExistingRejectedReason("Deals In")}
              />
            )}
            {reviewProfileUserType === "Salesperson" && (
              <ProfileField
                icon={LiaUserTagSolid}
                label="Designation"
                value={reviewProfile?.designation}
                onChange={handleChange("designation")}
                selectedValue={profileState.designation}
                onRejectReasonChange={handleRejectReasonChange}
                existingRejectedReason={fetchExistingRejectedReason(
                  "Designation"
                )}
              />
            )}
          </div>
        </div>
        {reviewProfileUserType !== "Buyer" &&
          reviewProfileUserType !== "Owner" && (
            <>
              <div className="auser-container">
                <h4>Firm Details</h4>
                <div className="auser-checkboxes">
                  <ProfileField
                    icon={TbBuildingEstate}
                    label="Firm Name"
                    value={
                      !reviewProfile?.userAccessId
                        ? reviewProfile?.firmName &&
                          reviewProfile?.firmName !== ""
                          ? reviewProfile?.firmName
                          : "not provided"
                        : parsedEmployeerData?.firmName &&
                          parsedEmployeerData?.firmName !== ""
                        ? parsedEmployeerData?.firmName
                        : "not provided"
                    }
                    onChange={handleChange("firmName")}
                    selectedValue={profileState.firmName}
                    disabled={reviewProfile?.firmName === ""}
                    onRejectReasonChange={handleRejectReasonChange}
                    existingRejectedReason={fetchExistingRejectedReason(
                      "Firm Name"
                    )}
                  />
                  <ProfileField
                    icon={TbInputCheck}
                    label="RERA No."
                    value={
                      !reviewProfile?.userAccessId
                        ? reviewProfile?.reraNo && reviewProfile?.reraNo !== ""
                          ? reviewProfile?.reraNo
                          : "not provided"
                        : parsedEmployeerData?.reraNo &&
                          parsedEmployeerData?.reraNo !== ""
                        ? parsedEmployeerData?.reraNo
                        : "not provided"
                    }
                    onChange={handleChange("reraNo")}
                    selectedValue={profileState.reraNo}
                    disabled={reviewProfile?.reraNo === ""}
                    onRejectReasonChange={handleRejectReasonChange}
                    existingRejectedReason={fetchExistingRejectedReason(
                      "RERA No."
                    )}
                  />
                  <ProfileField
                    icon={LuTextCursorInput}
                    label="RERA No. Type"
                    value={
                      !reviewProfile?.userAccessId
                        ? reviewProfile?.reraNoType &&
                          reviewProfile?.reraNoType !== ""
                          ? reviewProfile?.reraNoType
                          : "not provided"
                        : parsedEmployeerData?.reraNoType &&
                          parsedEmployeerData?.reraNotype !== ""
                        ? parsedEmployeerData?.reraNoType
                        : "not provided"
                    }
                    onChange={handleChange("reraNoType")}
                    selectedValue={profileState.reraNoType}
                    disabled={reviewProfile?.reraNoType === ""}
                    onRejectReasonChange={handleRejectReasonChange}
                    existingRejectedReason={fetchExistingRejectedReason(
                      "RERA No. Type"
                    )}
                  />
                  <ProfileField
                    icon={MdShareLocation}
                    label="Firm Address"
                    value={
                      !reviewProfile?.userAccessId
                        ? reviewProfile?.firmAddress &&
                          reviewProfile?.firmAddress !== ""
                          ? reviewProfile?.firmAddress
                          : "not provided"
                        : parsedEmployeerData?.firmAddress &&
                          parsedEmployeerData?.firmAddress !== ""
                        ? parsedEmployeerData?.firmAddress
                        : "not provided"
                    }
                    onChange={handleChange("firmAddress")}
                    selectedValue={profileState.firmAddress}
                    disabled={reviewProfile?.firmAddress === ""}
                    onRejectReasonChange={handleRejectReasonChange}
                    existingRejectedReason={fetchExistingRejectedReason(
                      "Firm Address"
                    )}
                  />
                </div>
              </div>
              <div className="auser-container">
                <h4>Office Details</h4>
                <div className="auser-checkboxes">
                  <ProfileField
                    icon={TbBuildingEstate}
                    label="Office Name"
                    value={
                      !reviewProfile?.userAccessId
                        ? reviewProfile?.officeName &&
                          reviewProfile?.officeName !== ""
                          ? reviewProfile?.officeName
                          : "not provided"
                        : parsedEmployeerData?.officeName &&
                          parsedEmployeerData?.officeName !== ""
                        ? parsedEmployeerData?.officeName
                        : "not provided"
                    }
                    onChange={handleChange("officeName")}
                    selectedValue={profileState.officeName}
                    disabled={reviewProfile?.officeName === ""}
                    onRejectReasonChange={handleRejectReasonChange}
                    existingRejectedReason={fetchExistingRejectedReason(
                      "Office Name"
                    )}
                  />
                  <ProfileField
                    icon={PiCity}
                    label="Office City"
                    value={
                      !reviewProfile?.userAccessId
                        ? reviewProfile?.officeCity &&
                          reviewProfile?.officeCity !== ""
                          ? reviewProfile?.officeCity
                          : "not provided"
                        : parsedEmployeerData?.officeCity &&
                          parsedEmployeerData?.officeCity !== ""
                        ? parsedEmployeerData?.officeCity
                        : "not provided"
                    }
                    onChange={handleChange("officeCity")}
                    selectedValue={profileState.officeCity}
                    disabled={reviewProfile?.officeCity === ""}
                    onRejectReasonChange={handleRejectReasonChange}
                    existingRejectedReason={fetchExistingRejectedReason(
                      "Office City"
                    )}
                  />
                  <ProfileField
                    icon={PiBracketsCurlyBold}
                    label="Website URL"
                    value={
                      !reviewProfile?.userAccessId
                        ? reviewProfile?.officeWebsite &&
                          reviewProfile?.officeWebsite !== ""
                          ? reviewProfile?.officeWebsite
                          : "not provided"
                        : parsedEmployeerData?.officeWebsite &&
                          parsedEmployeerData?.officeWebsite !== "" &&
                          parsedEmployeerData?.officeWebsite !== "undefined"
                        ? parsedEmployeerData?.officeWebsite
                        : "not provided"
                    }
                    onChange={handleChange("websiteUrl")}
                    selectedValue={profileState.websiteUrl}
                    disabled={
                      reviewProfile?.officeWebsite === "" ||
                      reviewProfile?.officeWebsite === "undefined"
                    }
                    onRejectReasonChange={handleRejectReasonChange}
                    existingRejectedReason={fetchExistingRejectedReason(
                      "Website URL"
                    )}
                  />
                </div>
              </div>
            </>
          )}
        <div className="auser-container">
          <h4>Verification Documents</h4>
          {reviewProfileUserType === "Salesperson" && (
            <div className="auser-checkboxes">
              <ProfileField
                icon={MdOutlineDomainVerification}
                label="ID Card Type"
                value={reviewProfile?.employeeIdType}
                onChange={handleChange("idCardType")}
                selectedValue={profileState.idCardType}
                disabled={true}
                onRejectReasonChange={handleRejectReasonChange}
                existingRejectedReason={fetchExistingRejectedReason(
                  "ID Card Type"
                )}
              />
              {reviewProfile?.employeeIdType === "Employee ID Card" && (
                <ProfileField
                  icon={MdOutlineDomainVerification}
                  label="Employee ID Card No."
                  value="NREOIHIOJ"
                  onChange={handleChange("idCardNo")}
                  selectedValue={profileState.idCardNo}
                  onRejectReasonChange={handleRejectReasonChange}
                  existingRejectedReason={fetchExistingRejectedReason(
                    "Employee ID Card No."
                  )}
                />
              )}
              {reviewProfile?.employeeIdType === "Aadhaar Card" && (
                <ProfileField
                  icon={MdOutlineDomainVerification}
                  label="Aadhaar Card No."
                  value="NREOIHIOJ"
                  onChange={handleChange("aadhaarCardNo")}
                  selectedValue={profileState.aadhaarCardNo}
                  onRejectReasonChange={handleRejectReasonChange}
                  existingRejectedReason={fetchExistingRejectedReason(
                    "Aadhaar Card No."
                  )}
                />
              )}
              {reviewProfile?.employeeIdType === "Pan Card" && (
                <ProfileField
                  icon={MdOutlineDomainVerification}
                  label="PAN Card No."
                  value="NREOIHIOJ"
                  onChange={handleChange("panCardNo")}
                  selectedValue={profileState.panCardNo}
                  onRejectReasonChange={handleRejectReasonChange}
                  existingRejectedReason={fetchExistingRejectedReason(
                    "PAN Card No."
                  )}
                />
              )}
              {reviewProfile?.employeeIdType === "Voter Card" && (
                <ProfileField
                  icon={MdOutlineDomainVerification}
                  label="Voter Card No."
                  value="NREOIHIOJ"
                  onChange={handleChange("voterCardNo")}
                  selectedValue={profileState.voterCardNo}
                  onRejectReasonChange={handleRejectReasonChange}
                  existingRejectedReason={fetchExistingRejectedReason(
                    "Voter Card No."
                  )}
                />
              )}
              {reviewProfile?.employeeIdType === "Passport" && (
                <ProfileField
                  icon={MdOutlineDomainVerification}
                  label="Passport No."
                  value="NREOIHIOJ"
                  onChange={handleChange("passportNo")}
                  selectedValue={profileState.passportNo}
                  onRejectReasonChange={handleRejectReasonChange}
                  existingRejectedReason={fetchExistingRejectedReason(
                    "Passport No."
                  )}
                />
              )}
              {reviewProfile?.employeeIdType === "Driving License" && (
                <ProfileField
                  icon={MdOutlineDomainVerification}
                  label="Driving License No."
                  value="NREOIHIOJ"
                  onChange={handleChange("drivingLicenseNo")}
                  selectedValue={profileState.drivingLicenseNo}
                  onRejectReasonChange={handleRejectReasonChange}
                  existingRejectedReason={fetchExistingRejectedReason(
                    "Driving License No."
                  )}
                />
              )}
            </div>
          )}
          <div className="auser-checkboxes">
            <ProfileDocumentField
              icon={FaRegUser}
              label="Profile Photo"
              imgSrcs={
                reviewProfile?.profilePhoto ||
                "https://placehold.co/100x100?text=Profile+Photo"
              }
              onChange={handleChange("profilePhoto")}
              selectedValue={profileState.profilePhoto}
              onRejectReasonChange={handleRejectReasonChange}
              existingRejectedReason={fetchExistingRejectedReason(
                "Profile Photo"
              )}
            />
            {reviewProfileUserType !== "Buyer" &&
              reviewProfileUserType !== "Owner" && (
                <>
                  <ProfileDocumentField
                    icon={LuServer}
                    label="Firm Logo"
                    imgSrcs={
                      reviewProfile?.firmLogo && reviewProfile?.firmLogo !== ""
                        ? reviewProfile?.firmLogo
                        : parsedEmployeerData?.firmLogo &&
                          parsedEmployeerData?.firmLogo !== ""
                        ? parsedEmployeerData?.firmLogo
                        : "https://placehold.co/100x100?text=Firm+Logo"
                    }
                    onChange={handleChange("firmLogo")}
                    selectedValue={profileState.firmLogo}
                    disabled={reviewProfile?.firmLogo === null}
                    onRejectReasonChange={handleRejectReasonChange}
                    existingRejectedReason={fetchExistingRejectedReason(
                      "Firm Logo"
                    )}
                  />
                  {reviewProfileUserType === "Salesperson" && (
                    <>
                      {reviewProfile.employeementLetter && (
                        <ProfileDocumentField
                          icon={AiOutlineFileImage}
                          label="Employeement letter"
                          imgSrcs={
                            reviewProfile?.employeementLetter &&
                            reviewProfile?.employeementLetter !== ""
                              ? reviewProfile?.employeementLetter
                              : "https://placehold.co/100x100?text=EL"
                          }
                          imgClassNames="auser-checkboxes-sub-img-lg"
                          onChange={handleChange("employeementLetter")}
                          selectedValue={profileState.employeementLetter}
                          onRejectReasonChange={handleRejectReasonChange}
                          existingRejectedReason={fetchExistingRejectedReason(
                            "Employeement letter"
                          )}
                        />
                      )}
                      {reviewProfile?.employeeIdType === "Employee ID Card" && (
                        <ProfileDocumentField
                          icon={AiOutlineFileImage}
                          label="Employee ID Card"
                          imgSrcs={
                            reviewProfile?.employeeIdCardImage &&
                            reviewProfile?.employeeIdCardImage !== ""
                              ? reviewProfile?.employeeIdCardImage
                              : "https://placehold.co/100x100?text=ID+Card"
                          }
                          imgClassNames="auser-checkboxes-sub-img-lg"
                          onChange={handleChange("employeeIdCard")}
                          selectedValue={profileState.employeeIdCard}
                          onRejectReasonChange={handleRejectReasonChange}
                          existingRejectedReason={fetchExistingRejectedReason(
                            "Employee ID Card"
                          )}
                        />
                      )}
                      {reviewProfile?.employeeIdType === "Aadhaar Card" && (
                        <ProfileDocumentField
                          icon={AiOutlineFileImage}
                          label="Aadhaar Card"
                          imgSrcs={[
                            reviewProfile?.employeeAadhaarcardFrontImage &&
                            reviewProfile?.employeeAadhaarcardFrontImage !== ""
                              ? reviewProfile?.employeeAadhaarcardFrontImage
                              : "https://placehold.co/100x100?text=Aadhaar+Front",
                            reviewProfile?.employeeAadhaarcardBackImage &&
                            reviewProfile?.employeeAadhaarcardBackImage !== ""
                              ? reviewProfile?.employeeAadhaarcardBackImage
                              : "https://placehold.co/100x100?text=Aadhaar+Back",
                          ]}
                          imgClassNames="auser-checkboxes-sub-img-lg"
                          onChange={handleChange("aadhaarCard")}
                          selectedValue={profileState.aadhaarCard}
                          onRejectReasonChange={handleRejectReasonChange}
                          existingRejectedReason={fetchExistingRejectedReason(
                            "Aadhaar Card"
                          )}
                        />
                      )}
                      {reviewProfile?.employeeIdType === "Pan Card" && (
                        <ProfileDocumentField
                          icon={AiOutlineFileImage}
                          label="PAN Card"
                          imgSrcs={
                            reviewProfile?.employeePancardImage &&
                            reviewProfile?.employeePancardImage !== ""
                              ? reviewProfile?.employeePancardImage
                              : "https://placehold.co/100x100?text=PAN+Card"
                          }
                          imgClassNames="auser-checkboxes-sub-img-lg"
                          onChange={handleChange("panCard")}
                          selectedValue={profileState.panCard}
                          onRejectReasonChange={handleRejectReasonChange}
                          existingRejectedReason={fetchExistingRejectedReason(
                            "PAN Card"
                          )}
                        />
                      )}
                      {reviewProfile?.employeeIdType === "Voter Card" && (
                        <ProfileDocumentField
                          icon={AiOutlineFileImage}
                          label="Voter Card"
                          imgSrcs={[
                            reviewProfile?.employeeVoterIdFrontImage &&
                            reviewProfile?.employeeVoterIdFrontImage !== ""
                              ? reviewProfile?.employeeVoterIdFrontImage
                              : "https://placehold.co/100x100?text=Voter+Front",
                            reviewProfile?.employeeVoterIdBackImage &&
                            reviewProfile?.employeeVoterIdBackImage !== ""
                              ? reviewProfile?.employeeVoterIdBackImage
                              : "https://placehold.co/100x100?text=Voter+Back",
                          ]}
                          imgClassNames={[
                            "auser-checkboxes-sub-img-lg",
                            "auser-checkboxes-sub-img-lg",
                          ]}
                          onChange={handleChange("voterCard")}
                          selectedValue={profileState.voterCard}
                          onRejectReasonChange={handleRejectReasonChange}
                          existingRejectedReason={fetchExistingRejectedReason(
                            "Voter Card"
                          )}
                        />
                      )}
                      {reviewProfile?.employeeIdType === "Passport" && (
                        <ProfileDocumentField
                          icon={AiOutlineFileImage}
                          label="Passport"
                          imgSrcs={
                            reviewProfile?.employeePassportImage &&
                            reviewProfile?.employeePassportImage !== ""
                              ? reviewProfile?.employeePassportImage
                              : "https://placehold.co/100x100?text=Passport"
                          }
                          imgClassNames="auser-checkboxes-sub-img-lg"
                          onChange={handleChange("passport")}
                          selectedValue={profileState.passport}
                          onRejectReasonChange={handleRejectReasonChange}
                          existingRejectedReason={fetchExistingRejectedReason(
                            "Passport"
                          )}
                        />
                      )}
                      {reviewProfile?.employeeIdType === "Driving License" && (
                        <ProfileDocumentField
                          icon={AiOutlineFileImage}
                          label="Driving License"
                          imgSrcs={[
                            reviewProfile?.employeeDrivinglicenseFrontImage &&
                            reviewProfile?.employeeDrivinglicenseFrontImage !==
                              ""
                              ? reviewProfile?.employeeDrivinglicenseFrontImage
                              : "https://placehold.co/100x100?text=DL+Front",
                            reviewProfile?.employeeDrivinglicenseBackImage &&
                            reviewProfile?.employeeDrivinglicenseBackImage !==
                              ""
                              ? reviewProfile?.employeeDrivinglicenseBackImage
                              : "https://placehold.co/100x100?text=DL+Back",
                          ]}
                          imgClassNames={[
                            "auser-checkboxes-sub-img-lg",
                            "auser-checkboxes-sub-img-lg",
                          ]}
                          onChange={handleChange("drivingLicense")}
                          selectedValue={profileState.drivingLicense}
                          onRejectReasonChange={handleRejectReasonChange}
                          existingRejectedReason={fetchExistingRejectedReason(
                            "Driving License"
                          )}
                        />
                      )}
                    </>
                  )}
                </>
              )}
          </div>
        </div>
        <div className="auser-container">
          <h4>Personal Details</h4>
          <div className="auser-checkboxes">
            <ProfileField
              icon={FaRegEyeSlash}
              label="Are Details Public?"
              value={
                reviewProfile?.showPersonalDetails === true
                  ? "true"
                  : reviewProfile?.showPersonalDetails === false
                  ? "false"
                  : "not provided"
              }
              onChange={handleChange("showPersonalDetails")}
              selectedValue={profileState.showPersonalDetails}
              disabled={true}
              onRejectReasonChange={handleRejectReasonChange}
              existingRejectedReason={fetchExistingRejectedReason(
                "Are Details Public?"
              )}
            />
            {(reviewProfileUserType === "Buyer" ||
              reviewProfileUserType === "Owner") && (
              <>
                <ProfileField
                  icon={GrCurrency}
                  label="Annual Income"
                  value={
                    reviewProfile?.annualIncomeCurrencyType &&
                    annualIncome?.length !== 0
                      ? `${formatPrice(
                          annualIncome[0],
                          reviewProfile?.annualIncomeCurrencyType
                        )} - ${formatPrice(
                          annualIncome[1],
                          reviewProfile?.annualIncomeCurrencyType
                        )}`
                      : "not provided"
                  }
                  onChange={handleChange("annualIncome")}
                  selectedValue={profileState.annualIncome}
                  disabled={true}
                  onRejectReasonChange={handleRejectReasonChange}
                  existingRejectedReason={fetchExistingRejectedReason(
                    "Annual Income"
                  )}
                />
                <ProfileField
                  icon={BsTypeStrikethrough}
                  label="Income Currency Type"
                  value={
                    reviewProfile?.annualIncomeCurrencyType &&
                    reviewProfile?.annualIncomeCurrencyType !== ""
                      ? reviewProfile?.annualIncomeCurrencyType
                      : "not provided"
                  }
                  onChange={handleChange("annualIncomeCurrencyType")}
                  selectedValue={profileState?.annualIncomeCurrencyType}
                  disabled={true}
                  onRejectReasonChange={handleRejectReasonChange}
                  existingRejectedReason={fetchExistingRejectedReason(
                    "Income Currency Type"
                  )}
                />
                <ProfileField
                  icon={PiShoppingBag}
                  label="Profession"
                  value={
                    reviewProfile?.profession &&
                    reviewProfile?.profession !== ""
                      ? reviewProfile?.profession
                      : "not provided"
                  }
                  onChange={handleChange("profession")}
                  selectedValue={profileState.profession}
                  disabled={true}
                  onRejectReasonChange={handleRejectReasonChange}
                  existingRejectedReason={fetchExistingRejectedReason(
                    "Profession"
                  )}
                />
              </>
            )}
            <ProfileField
              icon={TbCalendarStar}
              label="DOB"
              value={
                reviewProfile?.dob && reviewProfile?.dob !== ""
                  ? new Date(reviewProfile?.dob).toLocaleDateString()
                  : "not provided"
              }
              onChange={handleChange("dob")}
              selectedValue={profileState.dob}
              disabled={true}
              onRejectReasonChange={handleRejectReasonChange}
              existingRejectedReason={fetchExistingRejectedReason("DOB")}
            />
            <ProfileField
              icon={GiMeepleCircle}
              label="Marital Status"
              value={
                reviewProfile?.maritalStatus &&
                reviewProfile?.maritalStatus !== ""
                  ? reviewProfile?.maritalStatus
                  : "not provided"
              }
              onChange={handleChange("maritalStatus")}
              selectedValue={profileState.maritalStatus}
              disabled={true}
              onRejectReasonChange={handleRejectReasonChange}
              existingRejectedReason={fetchExistingRejectedReason(
                "Marital Status"
              )}
            />

            {reviewProfile?.maritalStatus === "Married" && (
              <ProfileField
                icon={BiParty}
                label="Marriage Anniversary Date"
                value={
                  reviewProfile?.marriageAnniversary &&
                  reviewProfile?.marriageAnniversary !== ""
                    ? new Date(
                        reviewProfile?.marriageAnniversary
                      ).toLocaleDateString()
                    : "not provided"
                }
                onChange={handleChange("marriageAnniversary")}
                selectedValue={profileState.marriageAnniversary}
                disabled={true}
                onRejectReasonChange={handleRejectReasonChange}
                existingRejectedReason={fetchExistingRejectedReason(
                  "Marriage Anniversary Date"
                )}
              />
            )}
            <ProfileField
              icon={MdOutlineNaturePeople}
              label="Gender"
              value={
                reviewProfile?.gender && reviewProfile?.gender !== ""
                  ? reviewProfile?.gender
                  : "not provided"
              }
              onChange={handleChange("gender")}
              selectedValue={profileState.gender}
              disabled={true}
              onRejectReasonChange={handleRejectReasonChange}
              existingRejectedReason={fetchExistingRejectedReason("Gender")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewProfiles;
