import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../UserManagement.css";

// Loader
import AdminLoader from "../../../components/Loader/AdminLoader";

// Ant Design Imports
import { Table, Modal, Button, Input, Select, notification } from "antd";

// React Icons
import { CgShapeCircle } from "react-icons/cg";
import { userDataAtom } from "../../../../store/atoms/userDataAtom";
import { useRecoilValue } from "recoil";

function AssignedProfiles() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const showModal = (selectedProfileData) => {
    setSelectedProfile(selectedProfileData.id);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    navigate(
      `/admin/user-management/user-profiles/assigned-profiles/${selectedProfile}`
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const formatMobileNumber = (mobile) => {
    if (typeof mobile === "string" && mobile.length >= 12) {
      const countryCode = mobile.slice(0, 2);
      const number = mobile.slice(2);
      return `+${countryCode} ${number}`;
    }
    return mobile;
  };

  const [data, setData] = useState([]);
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/api/admin/getAllAssignedUnverifiedProfiles`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: {
            userId: userId,
          },
        }
      );
      if (
        !response.data ||
        (response.data.unverifiedProfiles &&
          !Array.isArray(response.data.unverifiedProfiles))
      ) {
        throw new Error("Unexpected data format");
      }
      const formattedData = response.data.unverifiedProfiles.map(
        (user, index) => {
          return {
            key: index.toString(),
            userType: `${user.userType?.toUpperCase()}`,
            name: <h5 className="auser-table-data">{user?.fullName}</h5>,
            photo: (
              <img
                src={
                  user?.profilePhoto ||
                  `https://placehold.co/40x40?text=${user?.fullName
                    ?.charAt(0)
                    ?.toUpperCase()}`
                }
                alt=""
                className="auser-table-data-profileImg"
              />
            ),
            email: <h5 className="auser-table-data">{user.email || "N/A"}</h5>,
            mobile: (
              <h5 className="auser-table-data">
                {formatMobileNumber(user.mobileNumber) || "N/A"}
              </h5>
            ),
            status: (
              <div className={`auser-table-data-role reviewer`}>
                <CgShapeCircle className="auser-table-data-role-icon" /> PENDING
              </div>
            ),
            exists: "true",
            updatedAt: (
              <h5 className="auser-table-data">
                {new Date(user.updatedAt).toLocaleString()}
              </h5>
            ),
            createdAt: (
              <h5 className="auser-table-data">
                {new Date(user.createdAt).toLocaleString()}
              </h5>
            ),
            action: (
              <div className="auser-table-data-action">
                {user.profileStatus === "pending" ? (
                  <span onClick={() => showModal(user)}>
                    Start Verification
                  </span>
                ) : (
                  <span
                    onClick={() =>
                      navigate(
                        `/admin/user-management/user-profiles/assigned-profiles/${user.id}`
                      )
                    }
                  >
                    Continue Verification
                  </span>
                )}
              </div>
            ),
          };
        }
      );
      setData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      notification.error({
        message: "Error",
        description: "Failed to load user data",
      });
    } finally {
      setLoading(false);
    }
  }, [baseURL, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: "PHOTO",
      dataIndex: "photo",
      align: "center",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "NAME",
      dataIndex: "name",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "MOBILE NO.",
      dataIndex: "mobile",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "USER TYPE",
      dataIndex: "userType",
      responsive: ["xs", "sm"],
      ellipsis: true,
      filters: [
        {
          text: "BUYER",
          value: "BUYER",
        },
        {
          text: "OWNER",
          value: "OWNER",
        },
        {
          text: "BUILDER",
          value: "BUILDER",
        },
        {
          text: "AGENT",
          value: "AGENT",
        },
        {
          text: "SALESPERSON",
          value: "SALESPERSON",
        },
      ],
      onFilter: (value, record) => record.userType.indexOf(value) === 0,
      render: (userType) => (
        <div className={`auser-table-data-role userType`}>
          <CgShapeCircle className="auser-table-data-role-icon" /> {userType}
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "EXISTS",
      dataIndex: "exists",
      responsive: ["xs", "sm"],
      ellipsis: true,
      filters: [
        {
          text: "TRUE",
          value: "true",
        },
        {
          text: "FALSE",
          value: "false",
        },
      ],
      onFilter: (value, record) => record.exists.indexOf(value) === 0,
      render: (exists) => {
        let existsClass = "";
        switch (exists) {
          case "true":
            existsClass = "superAdmin";
            break;
          case "false":
            existsClass = "danger";
            break;
          default:
            existsClass = "default";
        }
        const displayText = exists ? exists.toUpperCase() : "UNKNOWN";
        return (
          <div className={`auser-table-data-role ${existsClass}`}>
            <CgShapeCircle className="auser-table-data-role-icon" />{" "}
            {displayText}
          </div>
        );
      },
    },
    {
      title: "LAST LOGIN AT",
      dataIndex: "updatedAt",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "ACTION",
      dataIndex: "action",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
  ];

  return (
    <div className="ausers">
      {loading && <AdminLoader />}
      <div className="ausers-sub">
        <div className="auser-container">
          <h1>Assigned Profile Verifications</h1>
        </div>
        <div className="auser-container">
          <h4>
            <span>{data && data?.length > 0 ? data?.length : "0"}</span>{" "}
            Assigned Profiles (Pending Verification)
          </h4>
          <div className="auser-table">
            <Table
              loading={loading}
              className="auser-table-ant-design"
              bordered
              columns={columns}
              dataSource={data}
              showSorterTooltip={{
                target: "sorter-icon",
              }}
              pagination={{ position: ["bottomLeft"] }}
              scroll={{ x: true }}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Are you sure to want to proceed?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Proceed
          </Button>,
        ]}
        centered
        className="auser-modal"
      >
        <div className="auser-modal-sub">
          <p>
            (Note: Once the verification process has started, the user will see
            that the review work has begun on your end and cannot be reverted.)
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default AssignedProfiles;
