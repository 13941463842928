import React from "react";
import "./AdminBlog.css";

function BlogAdd() {
  return (
    <div className="ausers">
      <div className="ausers-sub">
        <div className="auser-container">
          <h1>Post New Blog</h1>
        </div>
        <div className="auser-container"></div>
      </div>
    </div>
  );
}

export default BlogAdd;
