import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Map.css";

// ASSETS IMPORT
import mapMarker from "../../assets/svgs/locpin.svg";

// ANT DESIGN IMPORTS
import { FloatButton, Popconfirm, message } from "antd";

// REACT ICONS
import { IoMdLocate } from "react-icons/io";

// PRICE FORMATTER
import PriceFormatter from "../PriceFormatter/PriceFormatter";

// LEAFLET MAP IMPORTS
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L, { divIcon, point } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../store/atoms/userDataAtom";

function Map() {
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const navigate = useNavigate();

  const [userSavedLocation, setUserSavedLocation] = useState(null);

  useMemo(() => {
    const userData = userDetails?.user;
    if (userData && userData.latitude && userData.longitude) {
      return setUserSavedLocation([userData?.latitude, userData?.longitude]);
    }
  }, [userDetails]);

  const [messageApi, contextHolder] = message.useMessage();

  const [fetchedPosts, setFetchedPosts] = useState(null);
  const [fetchedProjects, setFetchedProjects] = useState(null);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/post/publicposts`);
        setFetchedPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/project/publicprojects`
        );
        setFetchedProjects(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchPosts();
    fetchProjects();
  }, [baseURL]);

  const createCustomClusterIcon = (cluster) => {
    return new divIcon({
      html: `<div class = "cluster-icon">
  ${cluster.getChildCount()}
  </div>`,
      iconSize: point(33, 33, true),
      className: "custom-marker-cluster",
    });
  };

  const isBase64Image = (str) => {
    // Regular expression to match base64 image format
    const base64Regex = /^([A-Za-z0-9+/])+={0,2}$/;
    return base64Regex.test(str);
  };

  const CustomMarker = ({
    position,
    propertyPrice,
    propertyCurrencyType,
    title,
    image,
    postId,
  }) => {
    const formattedPrice = PriceFormatter({
      amount: propertyPrice,
      currencyType: propertyCurrencyType,
    });

    const icon = L.divIcon({
      className: "custom-marker",
      html: `<div class = "custom-marker-sub">${formattedPrice.props.children[0]} ${formattedPrice.props.children[1]} ${formattedPrice.props.children[2]}
        <div class = "arrow-down"></div>
        </div>`,
    });

    const location = position;
    const latitude = location.latitude;
    const longitude = location.longitude;

    if (latitude && longitude) {
      return (
        <Marker position={[latitude, longitude]} icon={icon}>
          <Popup position={[latitude, longitude]}>
            <div className="custom-marker-popup">
              <div className="custom-popup-image">
                {isBase64Image(image) ? (
                  <img
                    src={`data:image/jpeg;base64,${image}`}
                    alt="propertyimage"
                  />
                ) : (
                  <img src={image} alt="propertyimage" />
                )}
              </div>
              <div className="custom-popup-content">
                <h5>{title}</h5>
                <h4>
                  <PriceFormatter
                    amount={propertyPrice}
                    currencyType={propertyCurrencyType}
                  />
                </h4>
                <Link
                  to={`/viewproperty/${postId}`}
                  className="custom-popup-content-link"
                >
                  View Deal
                </Link>
              </div>
            </div>
          </Popup>
        </Marker>
      );
    }
  };
  const CustomMarkerForProject = ({
    position,
    projectName,
    projectCurrencyType,
    projectPriceRange,
    image,
    projectId,
  }) => {
    const formattedPriceFrom = PriceFormatter({
      amount: projectPriceRange.priceFrom,
      currencyType: projectCurrencyType,
    });
    const formattedPriceTo = PriceFormatter({
      amount: projectPriceRange.priceTo,
      currencyType: projectCurrencyType,
    });

    const generateInitials = (name) => {
      return name
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");
    };
    const projectInitials = generateInitials(projectName);
    const projectIcon = L.divIcon({
      className: "custom-marker-project",
      html: `<div class="project-details-container-map">
          <img src="data:image/jpeg;base64,${image}" onerror="this.src='https://placehold.co/31x31?text=${projectInitials}'" class="project-logo-map" />
        </div>`,
    });

    const location = position;
    const latitude = location.latitude;
    const longitude = location.longitude;

    if (latitude && longitude) {
      return (
        <Marker position={[latitude, longitude]} icon={projectIcon}>
          <Popup position={[latitude, longitude]}>
            <div className="custom-marker-popup">
              <div className="custom-popup-image">
                <img
                  src={`data:image/jpeg;base64,${image}`}
                  onerror="this.src='https://placehold.co/301x301?text=proptzo'"
                  alt={projectName}
                />
              </div>
              <div className="custom-popup-content">
                <h5>{projectName}</h5>
                <h4>
                  <PriceFormatter
                    amount={projectPriceRange.priceFrom}
                    currencyType={projectCurrencyType}
                  />{" "}
                  -{" "}
                  <PriceFormatter
                    amount={projectPriceRange.priceTo}
                    currencyType={projectCurrencyType}
                  />
                </h4>
                <Link
                  to={`/viewprojects/${projectId}`}
                  className="custom-popup-content-link"
                >
                  View Deal
                </Link>
              </div>
            </div>
          </Popup>
        </Marker>
      );
    }
  };

  const customIcon = L.icon({
    iconUrl: mapMarker, // Replace with the path to your image
    iconSize: [38, 38], // Size of the icon
    iconAnchor: [22, 38], // Point of the icon which will correspond to marker's location
    popupAnchor: [-3, -38], // Point from which the popup should open relative to the iconAnchor
  });

  const [userCurrentLocation, setUserCurrentLocation] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = () => {
    getLocation();
    setConfirmLoading(true);
    messageApi.open({
      type: "success",
      content: "Location permission granted",
    });
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
      messageApi.open({
        type: "success",
        content: "Location fetched successfully",
      });
    }, 2000);
  };

  const handleCancel = () => {
    messageApi.open({
      type: "error",
      content: "Location permission denied",
    });
    setOpen(false);
  };

  function getLocation() {
    if (navigator.geolocation) {
      // Check for requestPermission support
      if (typeof navigator.geolocation.requestPermission === "function") {
        navigator.geolocation
          .requestPermission()
          .then((permission) => {
            if (permission === "granted") {
              messageApi.open({
                type: "success",
                content: "Location permission granted",
              });
              getUserLocation();
            } else {
              messageApi.open({
                type: "error",
                content: "Location permission denied",
              });
            }
          })
          .catch((error) => {
            console.error("Error requesting geolocation permission:", error);
            messageApi.open({
              type: "error",
              content:
                "Error requesting location permission, please try again!",
            });
          });
      } else {
        getUserLocation();
      }
    } else {
      messageApi.open({
        type: "error",
        content: "Geolocation is not supported by this browser.",
      });
    }
  }

  function getUserLocation() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(
      successCallback,
      errorCallback,
      options
    );
  }

  function successCallback(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    setUserCurrentLocation([latitude, longitude]);
  }

  function errorCallback(error) {
    console.error("Error getting geolocation:", error.message);
  }

  const handleClickGetLocation = () => {
    if (userCurrentLocation) {
      getLocation();
      messageApi.open({
        type: "success",
        content: "Location fetched successfully",
      });
    } else {
      setOpen(true);
    }
  };

  const mapRef = useRef(null);

  useEffect(() => {
    const centerOnUserLocation = () => {
      if (mapRef.current) {
        if (userCurrentLocation) {
          mapRef.current.setView(
            [userCurrentLocation[0], userCurrentLocation[1]],
            13
          );
        }
      } else {
        console.error("Map instance not available yet");
      }
    };
    centerOnUserLocation();
  }, [userCurrentLocation, mapRef]);

  return (
    <div className="searches searches-map last">
      <Popconfirm
        title="Grant Location Permission"
        description="Allow location access for better results?"
        open={open}
        onConfirm={handleOk}
        okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
        cancelButtonProps={{ danger: true }}
      ></Popconfirm>
      {contextHolder}
      <div className="searches-sub">
        <div className="searches-header">
          <h5>PROPERTIES & PROJECTS</h5>
          <div className="searches-header-sub">
            <h1>View On Map</h1>
            <button
              className="map-header-button"
              onClick={() => navigate("/properties")}
            >
              View All
            </button>
          </div>
        </div>
        {userSavedLocation ? (
          <div className="map-main">
            <div className="map">
              <FloatButton
                className="map-controls"
                icon={<IoMdLocate style={{ paddingTop: "4px" }} />}
                type={userCurrentLocation ? "primary" : "default"}
                onClick={handleClickGetLocation}
              />
              <MapContainer
                center={
                  userSavedLocation && userSavedLocation.length
                    ? [userSavedLocation[0], userSavedLocation[1]]
                    : userCurrentLocation
                    ? [userCurrentLocation[0], userCurrentLocation[1]]
                    : [20.5937, 78.9629]
                }
                zoom={userSavedLocation || userCurrentLocation ? 10 : 5}
                className="properties-map-sub"
                ref={mapRef}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {userSavedLocation && (
                  <Marker
                    position={
                      userCurrentLocation
                        ? [userCurrentLocation[0], userCurrentLocation[1]]
                        : userSavedLocation && [
                            userSavedLocation[0],
                            userSavedLocation[1],
                          ]
                    }
                    icon={customIcon}
                  >
                    <Popup
                      position={
                        userCurrentLocation
                          ? [userCurrentLocation[0], userCurrentLocation[1]]
                          : userSavedLocation && [
                              userSavedLocation[0],
                              userSavedLocation[1],
                            ]
                      }
                    >
                      Your location
                    </Popup>
                  </Marker>
                )}

                <MarkerClusterGroup
                  chunkedLoading
                  iconCreateFunction={createCustomClusterIcon}
                >
                  {fetchedPosts &&
                    fetchedPosts.map((post, index) => (
                      <CustomMarker
                        key={index}
                        postId={post._id}
                        position={post.propertyLocation}
                        title={post.propertyTitle}
                        image={
                          post.propertyImages.length > 0
                            ? post.propertyImages[0]
                            : "https://placehold.co/250x250?text=p"
                        }
                        propertyCurrencyType={post.propertyCurrencyType}
                        propertyPrice={post.propertyPrice}
                      ></CustomMarker>
                    ))}
                  {fetchedProjects &&
                    fetchedProjects.map((project, index) => (
                      <CustomMarkerForProject
                        key={index}
                        projectId={project._id}
                        position={project.projectLocation}
                        projectName={project.projectName}
                        image={
                          project.projectLogo
                            ? project.projectLogo
                            : "https://placehold.co/250x250?text=p"
                        }
                        projectCurrencyType={project.projectCurrencyType}
                        projectPriceRange={project.projectPriceRange}
                      ></CustomMarkerForProject>
                    ))}
                </MarkerClusterGroup>
              </MapContainer>
            </div>
          </div>
        ) : (
          <div className="map-main">
            <div className="map">
              <FloatButton
                className="map-controls"
                icon={<IoMdLocate style={{ paddingTop: "4px" }} />}
                type={userCurrentLocation ? "primary" : "default"}
                onClick={handleClickGetLocation}
              />
              <MapContainer
                center={
                  userCurrentLocation
                    ? [userCurrentLocation[0], userCurrentLocation[1]]
                    : [20.5937, 78.9629]
                }
                zoom={userCurrentLocation ? 10 : 5}
                className="properties-map-sub"
                ref={mapRef}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {userCurrentLocation && (
                  <Marker
                    position={
                      userCurrentLocation && [
                        userCurrentLocation[0],
                        userCurrentLocation[1],
                      ]
                    }
                    icon={customIcon}
                  >
                    <Popup
                      position={
                        userCurrentLocation && [
                          userCurrentLocation[0],
                          userCurrentLocation[1],
                        ]
                      }
                    >
                      Your location
                    </Popup>
                  </Marker>
                )}

                <MarkerClusterGroup
                  chunkedLoading
                  iconCreateFunction={createCustomClusterIcon}
                >
                  {fetchedPosts &&
                    fetchedPosts.map((post, index) => (
                      <CustomMarker
                        key={index}
                        postId={post._id}
                        position={post.propertyLocation}
                        title={post.propertyTitle}
                        image={
                          post.propertyImages.length > 0
                            ? post.propertyImages[0]
                            : "https://placehold.co/250x250?text=p"
                        }
                        propertyCurrencyType={post.propertyCurrencyType}
                        propertyPrice={post.propertyPrice}
                      ></CustomMarker>
                    ))}
                  {fetchedProjects &&
                    fetchedProjects.map((project, index) => (
                      <CustomMarkerForProject
                        key={index}
                        projectId={project._id}
                        position={project.projectLocation}
                        projectName={project.projectName}
                        image={
                          project.projectLogo
                            ? project.projectLogo
                            : "https://placehold.co/250x250?text=p"
                        }
                        projectCurrencyType={project.projectCurrencyType}
                        projectPriceRange={project.projectPriceRange}
                      ></CustomMarkerForProject>
                    ))}
                </MarkerClusterGroup>
              </MapContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Map;
