import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import "./ListingsMobile.css";

// Ant Design Imports
import { Select } from "antd";
import {
  SafetyCertificateOutlined,
  HeartOutlined,
  EyeOutlined,
  ShareAltOutlined,
  EnvironmentOutlined,
  ExpandAltOutlined,
} from "@ant-design/icons";

// React Icons
import { TbFilter } from "react-icons/tb";
import {
  PiBathtubDuotone,
  PiBedDuotone,
  PiListMagnifyingGlass,
} from "react-icons/pi";
import { GrMapLocation } from "react-icons/gr";
import { CgList } from "react-icons/cg";

// Leaflet Map
import { MapContainer, TileLayer } from "react-leaflet";

function ListingsMobile() {
  const [sortOption, setSortOption] = useState("Relevance");
  const handleSortChange = (value) => {
    setSortOption(value);
  };

  return (
    <div className="lm">
      <div className="lm-sub">
        <div className="lm-flters">
          <div className="lm-filters-sub">
            <Select
              placeholder="Sort By"
              optionFilterProp="label"
              defaultValue={"Relevance"}
              className="lm-filters-search"
              onChange={handleSortChange}
              options={[
                {
                  value: "Relevance",
                  label: "Sort By (Relevance)",
                },
                {
                  value: "PriceLowToHigh",
                  label: "Sort By (Price: Low to High)",
                },
                {
                  value: "PriceHighToLow",
                  label: "Sort By (Price: High to Low)",
                },
                {
                  value: "Newest",
                  label: "Sort By (Newest)",
                },
                {
                  value: "Oldest",
                  label: "Sort By (Oldest)",
                },
                {
                  value: "AreaHighToLow",
                  label: "Sort By (Area: High to Low)",
                },
                {
                  value: "AreaLowToHigh",
                  label: "Sort By (Area: Low to High)",
                },
              ]}
            />
            <div className="lm-filters-options">
              <GrMapLocation className="lm-filters-sub-icon" />
              <CgList className="lm-filters-sub-icon" />
              <TbFilter className="lm-filters-sub-icon" />
            </div>
          </div>
        </div>
        <div className="lm-listings">
          <div className="lm-listings-sub">
            {[...Array(8)].map((_, index) => (
              <div key={index} className="trending-prop-sub">
                <div className="trending-prop-img">
                  <img
                    src="https://placehold.co/800x450?text=proptzo"
                    alt="property"
                  />
                  <div className="trending-for">
                    <h5>FOR SALE</h5>
                  </div>
                  <div className="trending-type">
                    <h5>APARTMENT</h5>
                  </div>
                  <div className="trending-rera">
                    <SafetyCertificateOutlined className="trending-rera-icon" />
                    <h5>RERA CERTIFIED</h5>
                  </div>
                  <div className="trending-prop-img-icons">
                    <ShareAltOutlined className="trending-prop-img-icons-icon" />
                    <HeartOutlined className="trending-prop-img-icons-icon" />
                    <EyeOutlined className="trending-prop-img-icons-icon" />
                  </div>
                </div>
                <h5>Casa Loma De Machali Casa Loma De Machali</h5>
                <div className="trending-prop-loc">
                  <EnvironmentOutlined className="trending-prop-loc-icon" />
                  <p>
                    15 Willow Street, Seattle, WA 15 Willow Street, Seattle, WA
                  </p>
                </div>
                <div className="trending-prop-data">
                  <PiBedDuotone className="trending-prop-data-icon" />
                  <h5>2</h5>
                  <PiBathtubDuotone className="trending-prop-data-icon" />
                  <h5>6</h5>
                  <ExpandAltOutlined className="trending-prop-data-icon" />
                  <h5>2250 sqft</h5>
                </div>
                <div className="trending-prop-footer">
                  <h5 className="trending-prop-footer-name">Anil Kumar</h5>
                  <h5 className="trending-prop-footer-price">₹ 2.5Cr</h5>
                </div>
              </div>
            ))}
            <button>Load More</button>
          </div>
          <div className="lm-listings-map">
            <MapContainer
              center={[20.5937, 78.9629]}
              zoom={4}
              className="ps-map-sub"
              // ref={propertyMapRef}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingsMobile;
