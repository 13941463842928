import React, { useState, useEffect, Suspense, lazy, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";
import { userDataAtom } from "../store/atoms/userDataAtom";
import { useRecoilValue } from "recoil";

const SignUp = lazy(() => import("../pages/SignUp/SignUp"));

function UserTypeRedirectGaurd() {
  const navigate = useNavigate();
  const [userPresent, setUserPresent] = useState(null);
  const [userPresentCheckComplete, setUserPresentCheckComplete] =
    useState(false);

  const userDetails = useRecoilValue(userDataAtom);

  useMemo(() => {
    if (userDetails) {
      setUserPresent(userDetails?.hasOwnProperty("user"));
      setUserPresentCheckComplete(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (userPresent === false && userPresentCheckComplete) {
      navigate("/signup");
    }
  }, [userPresent, userPresentCheckComplete, navigate]);

  if (userPresent === null && !userPresentCheckComplete) {
    return <LoadingOverlay />;
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      {userPresent ? <Outlet /> : <SignUp />}
    </Suspense>
  );
}

export default UserTypeRedirectGaurd;
