import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

function PropertyNotFound() {
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          navigate("/");
          clearInterval(timer);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div className="pnf">
      <div className="pnf-sub">
        <h1>Property Not Found</h1>
        <p>
          We're sorry, but the property you are looking for has been removed by
          the owner or is no longer available.
        </p>
        <p>
          Please check other listings or contact support for further assistance.
        </p>
        <p>You will be redirected to the home page in {countdown} seconds.</p>
      </div>
    </div>
  );
}

export default PropertyNotFound;
