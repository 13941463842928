import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./FooterMobile.css";

// React Icons
import {
  TbSquareRoundedPlus,
  TbHome2,
  TbTrendingUp,
  TbHeart,
  TbUser,
} from "react-icons/tb";

function FooterMobile() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path) => {
    navigate(path);
  };
  const isSelected = (path) =>
    location.pathname === path ? "footerm-option selected" : "footerm-option";

  return (
    <div className="footerm">
      <div className="footerm-sub-left">
        <div className={isSelected("/")} onClick={() => handleNavigation("/")}>
          <TbHome2 className="footerm-sub-icon" />
          <h6>Home</h6>
        </div>
        <div
          className={isSelected("/trending")}
          onClick={() => handleNavigation("/trending")}
        >
          <TbTrendingUp className="footerm-sub-icon" />
          <h6>Trending</h6>
        </div>
      </div>
      <div
        className="footerm-sub"
        onClick={() => handleNavigation("/postproperty")}
      >
        <TbSquareRoundedPlus className="footerm-sub-icon" />
        <h6>Sell</h6>
      </div>
      <div className="footerm-center"></div>
      <div className="footerm-sub-right">
        <div
          className={isSelected("/wishlist")}
          onClick={() => handleNavigation("/wishlist")}
        >
          <TbHeart className="footerm-sub-icon" />
          <h6>Wishlist</h6>
        </div>
        <div
          className={isSelected("/profile")}
          onClick={() => handleNavigation("/profile")}
        >
          <TbUser className="footerm-sub-icon" />
          <h6>Profile</h6>
        </div>
      </div>
    </div>
  );
}

export default FooterMobile;
