import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HeroMobile.css";

import citiesData from "../../../utils/CitiesData";

// Ant Design Import
import { Input, Select, notification } from "antd";
import { TbCurrentLocation } from "react-icons/tb";

function HeroMobile() {
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState("BUY");
  const [options, setOptions] = useState([
    { value: "allResidential", label: "All Residential" },
    { value: "flatApartment", label: "Flat/Apartment" },
    { value: "independentHouse", label: "Independent House" },
    { value: "pentHouse", label: "Pent House" },
    { value: "villa", label: "Villa" },
    { value: "plot", label: "Plot" },
    { value: "builderFloor", label: "Builder Floor" },
  ]);
  const [selectedValue, setSelectedValue] = useState("allResidential");

  const categories = [
    {
      label: "NEW LAUNCH",
      options: [
        { value: "allProperties", label: "All Properties" },
        { value: "allProjects", label: "All Projects" },
      ],
    },
    {
      label: "BUY",
      options: [
        { value: "allResidential", label: "All Residential" },
        { value: "flatApartment", label: "Flat/Apartment" },
        { value: "independentHouse", label: "Independent House" },
        { value: "pentHouse", label: "Pent House" },
        { value: "villa", label: "Villa" },
        { value: "plot", label: "Plot" },
        { value: "builderFloor", label: "Builder Floor" },
      ],
    },
    {
      label: "RENT",
      options: [
        { value: "allResidential", label: "All Residential" },
        { value: "flatApartment", label: "Flat/Apartment" },
        { value: "independentHouse", label: "Independent House" },
      ],
    },
    {
      label: "PG",
      options: [
        { value: "pg", label: "PG" },
        { value: "flatApartment", label: "Flat/Apartment" },
        { value: "independentHouse", label: "Independent House" },
      ],
    },
    {
      label: "COMMERCIAL",
      options: [
        { value: "allCommercial", label: "All Commercial" },
        { value: "shop", label: "Shop" },
        { value: "showroom", label: "Showroom" },
        { value: "booth", label: "Booth" },
        { value: "soho", label: "SOHO" },
        { value: "officeSpace", label: "Office Space" },
        { value: "coWorkingSpace", label: "Co-Working Space" },
      ],
    },
    {
      label: "INDUSTRIAL",
      options: [
        { value: "allIndustrial", label: "All Industrial" },
        { value: "industrialPlot", label: "Industrial Plot" },
        { value: "industrialSpace", label: "Industrial Space" },
        { value: "industrialShed", label: "Industrial Shed" },
        { value: "godown", label: "Godown" },
      ],
    },
    {
      label: "PROJECTS",
      options: [
        { value: "allProjects", label: "All Projects" },
        { value: "readyToMove", label: "Ready To Move" },
        { value: "underConstruction", label: "Under Construction" },
      ],
    },
  ];

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const [selectedCity, setSelectedCity] = useState(null);
  const [locationFetched, setLocationFetched] = useState(false);
  const handleCityChange = (value) => {
    setSelectedCity(value);
  };
  const cityOptions = citiesData.map((city) => ({
    value: city.city,
    label: city.city,
  }));
  // Function to calculate distance between two coordinates using Haversine formula
  const getDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Radius of Earth in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };
  const getNearestCity = (latitude, longitude) => {
    let nearestCity = null;
    let minDistance = Infinity;
    citiesData.forEach((city) => {
      const distance = getDistance(
        latitude,
        longitude,
        city.geoCode[0],
        city.geoCode[1]
      );
      if (distance < minDistance) {
        minDistance = distance;
        nearestCity = city.city;
      }
    });
    return nearestCity;
  };
  const openNotification = (city) => {
    notification.success({
      message: "Location Fetched Successfully!",
      description: `We’ve detected that you are in ${city}.`,
      placement: "topLeft",
      duration: 3,
    });
  };
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const nearestCity = getNearestCity(latitude, longitude);
          setSelectedCity(nearestCity);
          setLocationFetched(true);
          openNotification(nearestCity);
        },
        (error) => {
          console.error("Error fetching location: ", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="herom">
      <div className="herom-sub"></div>
      <div className="herom-search">
        <div className="herom-search-sub">
          <h6>Keyword</h6>
          <Input
            bordered={false}
            placeholder="Search here ..."
            className="hero-search-sub-input"
          />
        </div>
        <div className="herom-search-sub">
          <h6>Keyword</h6>
          <div className="hero-search-flex">
            <Select
              className="hero-search-sub-flex-select"
              variant="light"
              placeholder="Select City"
              options={cityOptions}
              value={selectedCity}
              onChange={handleCityChange}
              showSearch
              filterOption={(input, option) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
            />
            <TbCurrentLocation
              className={`hero-search-flex-icon ${
                locationFetched ? "selected" : ""
              }`}
              onClick={getCurrentLocation}
            />
          </div>
        </div>
        <div className="herom-search-sub">
          <h6>Keyword</h6>
          <Select
            className="hero-search-sub-select"
            variant="light"
            options={options}
            value={selectedValue}
            onChange={handleSelectChange}
          />
        </div>
        <div className="herom-search-sub">
          <button>2k+ Results</button>
        </div>
      </div>
    </div>
  );
}

export default HeroMobile;
