import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./Dashboard.css";

// Price Formatter
import PriceFormatter from "../../components/PriceFormatter/PriceFormatter";

// Recoil Integration
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../store/atoms/userDataAtom";

// Loader
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

// Ant Design Imports
import { Table, Drawer, Timeline, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

// React Icons
import {
  AiOutlineDashboard,
  AiOutlineProject,
  AiOutlineHeart,
} from "react-icons/ai";
import { BiBuildingHouse } from "react-icons/bi";
import { TbMessage2Check } from "react-icons/tb";
import {
  MdOutlineModeEdit,
  MdDeleteOutline,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { RxCircleBackslash } from "react-icons/rx";
import { RiCircleLine } from "react-icons/ri";
import {
  HiOutlineCheckCircle,
  HiOutlineInformationCircle,
  HiOutlineSearchCircle,
  HiOutlineXCircle,
} from "react-icons/hi";
import { HiOutlineEye } from "react-icons/hi2";
import { IoRemoveCircleOutline } from "react-icons/io5";
import { AiOutlineDownload } from "react-icons/ai";
import { BiMessageSquareDots } from "react-icons/bi";

function Dashboard() {
  // Render Status Component
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const renderStatus = (status, label, IconComponent, additionalClass = "") => (
    <div className="dash-status-status">
      <div
        className={`dash-table-status-sub ${status} ${additionalClass}`}
        onClick={showDrawer}
      >
        <IconComponent className="dash-table-status-sub-icon" />
        <h5>{label}</h5>
      </div>
    </div>
  );

  const { confirm } = Modal;

  const navigate = useNavigate();
  const location = useLocation();

  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;
  const userType = userDetails?.userProfile?.userType;

  useEffect(() => {
    if (userType) {
      document.title = `Proptzo - ${userType} Dashboard`;
    } else {
      document.title = "Proptzo - Dashboard";
    }
  }, [userType]);

  const [loading, setLoading] = useState(false);
  const [selectedSection, setSelectedSection] = useState("dashboards");
  useEffect(() => {
    if (location.state?.from === "reviews") {
      setSelectedSection("reviews");
    }
  }, [location.state]);
  
  const handleSectionChange = (section) => {
    setLoading(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      setSelectedSection(section);
      setLoading(false);
    }, 300);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const retrivedHistory = localStorage.getItem("history");
  const StoredHistory = retrivedHistory ? JSON.parse(retrivedHistory) : [];

  const [fetchedProjects, setFetchedProjects] = useState([]);
  const [fetchedPosts, setFetchedPosts] = useState([]);

  useEffect(() => {
    const fetchUserPosts = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/post/getposts/${userId}`
        );
        setFetchedProjects(response.data.projects);
        setFetchedPosts(response.data.posts);
      } catch (error) {
        console.error("Error fetching user posts:", error);
      }
    };
    if (userId) {
      fetchUserPosts();
    }
  }, [userId, baseURL]);

  const showProjectDeleteConfirm = (projectId) => {
    confirm({
      title: "Are you sure you want to delete this project?",
      icon: <ExclamationCircleFilled />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleProjectDelete(projectId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const showPostDeleteConfirm = (postId) => {
    confirm({
      title: "Are you sure you want to delete this property?",
      icon: <ExclamationCircleFilled />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handlePostDelete(postId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleProjectDelete = async (projectId) => {
    if (!projectId) {
      console.error("Project ID to delete is not set.");
      return;
    }
    try {
      await axios.delete(`${baseURL}/api/project/delete/${projectId}`);
      setFetchedProjects(
        fetchedProjects.filter((project) => project._id !== projectId)
      );
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };
  const handlePostDelete = async (postId) => {
    if (!postId) {
      console.error("Post ID to delete is not set.");
      return;
    }
    try {
      await axios.delete(`${baseURL}/api/post/delete/${postId}`);
      setFetchedPosts(fetchedPosts.filter((post) => post._id !== postId));
      if (StoredHistory && StoredHistory.length) {
        const filteredHistory = StoredHistory.filter(
          (historyId) => historyId !== postId
        );
        localStorage.setItem("history", JSON.stringify(filteredHistory));
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  // DASHBOARDS

  // MY PROJECTS
  const projectsColumns = [
    {
      title: "PROJECT",
      dataIndex: "project",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      align: "center",
      filters: [
        {
          text: "Approved",
          value: "approved",
        },
        {
          text: "Pending Verification",
          value: "pending",
        },
        {
          text: "Processing",
          value: "processing",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        switch (status) {
          case "approved":
            return renderStatus("approved", "Approved", HiOutlineCheckCircle);
          case "pending":
            return renderStatus(
              "pending",
              "Pending",
              HiOutlineInformationCircle
            );
          case "processing":
            return renderStatus(
              "processing",
              "Processing",
              HiOutlineSearchCircle
            );
          case "rejected":
            return renderStatus("rejected", "Rejected", HiOutlineXCircle);
          default:
            return renderStatus("default", "Default", RiCircleLine);
        }
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
    },
  ];
  const allProjects = fetchedProjects?.map((project, index) => ({
    key: index.toString(),
    project: (
      <div className="dash-table-property">
        <img
          src={
            project?.projectlogo && project?.projectlogo !==""
              ? project?.projectlogo
              : "https://placehold.co/175x125?text=proptzo"
          }
          alt="Project"
        />
        <div className="dash-table-property-sub">
          <h3>
            {project?.projectTitle?.length > 50
              ? `${project?.projectTitle.substring(0, 50)} ...`
              : project?.projectTitle || "No Project Name"}
          </h3>

          <p>Posting date: {new Date(project?.projectCreatedAt).toLocaleString()}</p>
          {project?.projectPriceRange && (
            <h2>
              <PriceFormatter
                amount={project?.projectPriceRange.priceFrom}
                currencyType={project?.projectCurrencyType}
              />
              {" - "}
              <PriceFormatter
                amount={project?.projectPriceRange.priceTo}
                currencyType={project?.projectCurrencyType}
              />
            </h2>
          )}
        </div>
      </div>
    ),
    status: project?.status || "default",
    action: (
      <div className="dash-table-actions">
        <span onClick={() => navigate(`/viewprojects/${project.projectId}`)}>
          <HiOutlineEye className="dash-table-actions-span-icon" /> View
        </span>
        <span onClick={() => navigate(`/projectupdate/${project.projectId}`)}>
          <MdOutlineModeEdit className="dash-table-actions-span-icon" /> Edit
        </span>
        <span>
          <RxCircleBackslash className="dash-table-actions-span-icon" /> Mark as
          sold
        </span>
        <span onClick={() => showProjectDeleteConfirm(project.projectId)}>
          <MdDeleteOutline className="dash-table-actions-span-icon" /> Delete
        </span>
      </div>
    ),
  }));
  const soldProjectsColumns = [
    {
      title: "PROJECT",
      dataIndex: "project",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      align: "center",
    },
    {
      title: "ACTION",
      dataIndex: "action",
    },
  ];
  const soldProjects = [
    {
      key: "1",
      project: (
        <div className="dash-table-property">
          <img src="https://placehold.co/175x125?text=proptzo" alt="" />
          <div className="dash-table-property-sub sold">
            <h3>Here the title will come here</h3>
            <p>Posting date: Jun 07, 2025</p>
            <h2>₹ 2.5 Cr - ₹ 5 Cr</h2>
          </div>
        </div>
      ),
      status: (
        <div className="dash-status-status">
          <div className="dash-table-status-sub sold">
            <RxCircleBackslash className="dash-table-status-sub-icon" />
            <h5>Sold</h5>
          </div>
        </div>
      ),
      action: (
        <div className="dash-table-actions">
          <span>
            <HiOutlineEye className="dash-table-actions-span-icon" /> View
          </span>
          <span>
            <MdDeleteOutline className="dash-table-actions-span-icon" /> Delete
          </span>
        </div>
      ),
    },
  ];

  // MY PROPERTIES
  const propertiesColumns = [
    {
      title: "PROPERTY",
      dataIndex: "property",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      align: "center",
      filters: [
        {
          text: "Approved",
          value: "approved",
        },
        {
          text: "Pending Verification",
          value: "pending",
        },
        {
          text: "Processing",
          value: "processing",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        switch (status) {
          case "approved":
            return renderStatus("approved", "Approved", HiOutlineCheckCircle);
          case "pending":
            return renderStatus(
              "pending",
              "Pending",
              HiOutlineInformationCircle
            );
          case "processing":
            return renderStatus(
              "processing",
              "Processing",
              HiOutlineSearchCircle
            );
          case "rejected":
            return renderStatus("rejected", "Rejected", HiOutlineXCircle);
          default:
            return renderStatus("default", "Not Started", RiCircleLine);
        }
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
    },
  ];
  const allProperties = fetchedPosts.map((post, index) => ({
    key: index.toString(),
    property: (
      <div className="dash-table-property">
        <img
          src={
            post.propertyImage && post.propertyImage !== ""
              ? post.propertyImage
              : "https://placehold.co/175x125?text=proptzo"
          }
          alt="Property"
        />
        <div className="dash-table-property-sub">
          <h3>
            {post.propertyTitle?.length > 50
              ? `${post.propertyTitle.substring(0, 50)} ...`
              : post.propertyTitle || "No Title"}
          </h3>

          <p>
            Posting date: {new Date(post.propertyCreatedAt).toLocaleString()}
          </p>
          <h2>
            <PriceFormatter
              amount={post.propertyPrice}
              currencyType={post.propertyCurrencyType}
            />
          </h2>
        </div>
      </div>
    ),
    status: post.status || "default",
    action: (
      <div className="dash-table-actions">
        <span onClick={() => navigate(`/viewproperty/${post.propertyId}`)}>
          <HiOutlineEye className="dash-table-actions-span-icon" /> View
        </span>
        <span onClick={() => navigate(`/postupdate/${post.propertyId}`)}>
          <MdOutlineModeEdit className="dash-table-actions-span-icon" /> Edit
        </span>
        <span>
          <RxCircleBackslash className="dash-table-actions-span-icon" /> Mark as
          sold
        </span>
        <span onClick={() => showPostDeleteConfirm(post.propertyId)}>
          <MdDeleteOutline className="dash-table-actions-span-icon" /> Delete
        </span>
      </div>
    ),
  }));
  const soldPropertiesColumns = [
    {
      title: "PROPERTY",
      dataIndex: "property",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      align: "center",
    },
    {
      title: "ACTION",
      dataIndex: "action",
    },
  ];
  const soldProperties = [
    {
      key: "1",
      property: (
        <div className="dash-table-property">
          <img src="https://placehold.co/175x125?text=proptzo" alt="" />
          <div className="dash-table-property-sub sold">
            <h3>Here the title will come here</h3>
            <p>Posting date: Jun 07, 2025</p>
            <h2>₹ 2.5 Cr</h2>
          </div>
        </div>
      ),
      status: (
        <div className="dash-status-status">
          <div className="dash-table-status-sub sold">
            <RxCircleBackslash className="dash-table-status-sub-icon" />
            <h5>Sold</h5>
          </div>
        </div>
      ),
      action: (
        <div className="dash-table-actions">
          <span>
            <HiOutlineEye className="dash-table-actions-span-icon" /> View
          </span>
          <span>
            <MdDeleteOutline className="dash-table-actions-span-icon" /> Delete
          </span>
        </div>
      ),
    },
    {
      key: "2",
      property: (
        <div className="dash-table-property">
          <img src="https://placehold.co/175x125?text=proptzo" alt="" />
          <div className="dash-table-property-sub sold">
            <h3>Here the title will come here</h3>
            <p>Posting date: Jun 07, 2025</p>
            <h2>₹ 2.5 Cr</h2>
          </div>
        </div>
      ),
      status: (
        <div className="dash-status-status">
          <div className="dash-table-status-sub sold">
            <RxCircleBackslash className="dash-table-status-sub-icon" />
            <h5>Sold</h5>
          </div>
        </div>
      ),
      action: (
        <div className="dash-table-actions">
          <span>
            <HiOutlineEye className="dash-table-actions-span-icon" /> View
          </span>
          <span>
            <MdDeleteOutline className="dash-table-actions-span-icon" /> Delete
          </span>
        </div>
      ),
    },
    {
      key: "3",
      property: (
        <div className="dash-table-property">
          <img src="https://placehold.co/175x125?text=proptzo" alt="" />
          <div className="dash-table-property-sub sold">
            <h3>Here the title will come here</h3>
            <p>Posting date: Jun 07, 2025</p>
            <h2>₹ 2.5 Cr</h2>
          </div>
        </div>
      ),
      status: (
        <div className="dash-status-status">
          <div className="dash-table-status-sub sold">
            <RxCircleBackslash className="dash-table-status-sub-icon" />
            <h5>Sold</h5>
          </div>
        </div>
      ),
      action: (
        <div className="dash-table-actions">
          <span>
            <HiOutlineEye className="dash-table-actions-span-icon" /> View
          </span>
          <span>
            <MdDeleteOutline className="dash-table-actions-span-icon" /> Delete
          </span>
        </div>
      ),
    },
  ];

  // MY WISHLISTS
  const wishlistsColumns = [
    {
      title: "PROPERTY",
      dataIndex: "property",
    },
    {
      title: "PUBLISHED DATE",
      dataIndex: "published",
    },
    {
      title: "ACTION",
      dataIndex: "action",
    },
  ];
  const allWishlists = [
    {
      key: "1",
      property: (
        <div className="dash-table-property">
          <img src="https://placehold.co/175x125?text=proptzo" alt="" />
          <div className="dash-table-property-sub">
            <h3>Here the title will come here</h3>
            <p>12 Street, Washington</p>
            <h2>₹ 2.5 Cr</h2>
          </div>
        </div>
      ),
      published: (
        <div className="dash-table-actions">
          <span>April 09, 2024</span>
        </div>
      ),
      action: (
        <div className="dash-table-actions">
          <span>
            <HiOutlineEye className="dash-table-actions-span-icon" /> View
          </span>
          <span>
            <AiOutlineDownload className="dash-table-actions-span-icon" />{" "}
            Download brochure
          </span>
          <span>
            <BiMessageSquareDots className="dash-table-actions-span-icon" />{" "}
            Contact Owner
          </span>
          <span>
            <IoRemoveCircleOutline className="dash-table-actions-span-icon" />{" "}
            Remove from wishlist
          </span>
        </div>
      ),
    },
    {
      key: "2",
      property: (
        <div className="dash-table-property">
          <img src="https://placehold.co/175x125?text=proptzo" alt="" />
          <div className="dash-table-property-sub">
            <h3>Here the title will come here</h3>
            <p>12 Street, Washington</p>
            <h2>₹ 2.5 Cr</h2>
          </div>
        </div>
      ),
      published: (
        <div className="dash-table-actions">
          <span>April 09, 2024</span>
        </div>
      ),
      action: (
        <div className="dash-table-actions">
          <span>
            <HiOutlineEye className="dash-table-actions-span-icon" /> View
          </span>
          <span>
            <AiOutlineDownload className="dash-table-actions-span-icon" />{" "}
            Download brochure
          </span>
          <span>
            <BiMessageSquareDots className="dash-table-actions-span-icon" />{" "}
            Contact Owner
          </span>
          <span>
            <IoRemoveCircleOutline className="dash-table-actions-span-icon" />{" "}
            Remove from wishlist
          </span>
        </div>
      ),
    },
  ];
  const soldWishlistsColumns = [
    {
      title: "PROPERTY",
      dataIndex: "property",
    },
    {
      title: "PUBLISHED DATE",
      dataIndex: "published",
    },
    {
      title: "SOLD DATE",
      dataIndex: "sold",
    },
    {
      title: "ACTION",
      dataIndex: "action",
    },
  ];
  const soldWishlists = [
    {
      key: "1",
      property: (
        <div className="dash-table-property">
          <img src="https://placehold.co/175x125?text=proptzo" alt="" />
          <div className="dash-table-property-sub">
            <h3>Here the title will come here</h3>
            <p>12 Street, Washington</p>
            <h2>₹ 2.5 Cr</h2>
          </div>
        </div>
      ),
      published: (
        <div className="dash-table-actions">
          <span>April 09, 2024</span>
        </div>
      ),
      sold: (
        <div className="dash-table-actions">
          <span>April 25, 2024</span>
        </div>
      ),
      action: (
        <div className="dash-table-actions">
          <span>
            <HiOutlineEye className="dash-table-actions-span-icon" /> View
          </span>
          <span>
            <AiOutlineDownload className="dash-table-actions-span-icon" />{" "}
            Download brochure
          </span>
          <span>
            <BiMessageSquareDots className="dash-table-actions-span-icon" />{" "}
            Contact Owner
          </span>
          <span>
            <IoRemoveCircleOutline className="dash-table-actions-span-icon" />{" "}
            Remove from wishlist
          </span>
        </div>
      ),
    },
    {
      key: "2",
      property: (
        <div className="dash-table-property">
          <img src="https://placehold.co/175x125?text=proptzo" alt="" />
          <div className="dash-table-property-sub">
            <h3>Here the title will come here</h3>
            <p>12 Street, Washington</p>
            <h2>₹ 2.5 Cr</h2>
          </div>
        </div>
      ),
      published: (
        <div className="dash-table-actions">
          <span>April 09, 2024</span>
        </div>
      ),
      sold: (
        <div className="dash-table-actions">
          <span>April 25, 2024</span>
        </div>
      ),
      action: (
        <div className="dash-table-actions">
          <span>
            <HiOutlineEye className="dash-table-actions-span-icon" /> View
          </span>
          <span>
            <AiOutlineDownload className="dash-table-actions-span-icon" />{" "}
            Download brochure
          </span>
          <span>
            <BiMessageSquareDots className="dash-table-actions-span-icon" />{" "}
            Contact Owner
          </span>
          <span>
            <IoRemoveCircleOutline className="dash-table-actions-span-icon" />{" "}
            Remove from wishlist
          </span>
        </div>
      ),
    },
  ];
  const deletedWishlistsColumns = [
    {
      title: "PROPERTY",
      dataIndex: "property",
    },
    {
      title: "ACTION",
      dataIndex: "action",
    },
  ];
  const deletedWishlists = [
    {
      key: "1",
      property: (
        <div className="dash-table-property">
          <img src="https://placehold.co/175x125?text=proptzo" alt="" />
          <div className="dash-table-property-sub sold">
            <h2>Property no longer available</h2>
            <h3>Property Removed by Owner</h3>
            <p>This property has been removed from our servers.</p>
          </div>
        </div>
      ),
      action: (
        <div className="dash-table-actions">
          <span>
            <IoRemoveCircleOutline className="dash-table-actions-span-icon" />{" "}
            Remove from wishlist
          </span>
        </div>
      ),
    },
  ];

  // REVIEWS

  return (
    <div className="dash">
      {loading && <LoadingOverlay />}
      <div className="dash-sub">
        <div className="dash-sidebar">
          <div
            className={`dash-sidebar-sub ${
              selectedSection === "dashboards" ? "selected" : ""
            }`}
            onClick={() => handleSectionChange("dashboards")}
          >
            <AiOutlineDashboard
              className="dash-sidebar-sub-icon"
              aria-label="Dashboard"
            />
            <h5>Dashboards</h5>
          </div>
          <div
            className={`dash-sidebar-sub ${
              selectedSection === "projects" ? "selected" : ""
            }`}
            onClick={() => handleSectionChange("projects")}
          >
            <AiOutlineProject className="dash-sidebar-sub-icon" />
            <h5>My Projects</h5>
          </div>
          <div
            className={`dash-sidebar-sub ${
              selectedSection === "properties" ? "selected" : ""
            }`}
            onClick={() => handleSectionChange("properties")}
          >
            <BiBuildingHouse className="dash-sidebar-sub-icon" />
            <h5>My Properties</h5>
          </div>
          <div
            className={`dash-sidebar-sub ${
              selectedSection === "wishlists" ? "selected" : ""
            }`}
            onClick={() => handleSectionChange("wishlists")}
          >
            <AiOutlineHeart className="dash-sidebar-sub-icon" />
            <h5>My Wishlists</h5>
          </div>
          <div
            className={`dash-sidebar-sub ${
              selectedSection === "reviews" ? "selected" : ""
            }`}
            onClick={() => handleSectionChange("reviews")}
          >
            <TbMessage2Check className="dash-sidebar-sub-icon" />
            <h5>Reviews</h5>
          </div>
        </div>

        {/* Render content conditionally based on the selected section */}
        {selectedSection === "dashboards" && (
          <div className="dash-container">
            <div className="dash-container-sub">
              <h3>Dashboards</h3>
            </div>
          </div>
        )}
        {selectedSection === "projects" && (
          <div className="dash-container">
            <div className="dash-container-sub">
              <h3>My Projects</h3>
              <h5>
                <span>{allProjects?.length}</span> Results found (All Projects)
              </h5>
              <div className="dash-table-main">
                <Table
                  className="dash-table"
                  columns={projectsColumns}
                  dataSource={allProjects}
                  showSorterTooltip={{
                    target: "sorter-icon",
                  }}
                  onChange={handleTableChange}
                  bordered
                  pagination={{ position: ["bottomLeft"] }}
                />
              </div>
              <h5>
                <span>{soldProjects.length}</span> Results found (Sold)
              </h5>
              <div className="dash-table-main">
                <Table
                  className="dash-table"
                  columns={soldProjectsColumns}
                  dataSource={soldProjects}
                  showSorterTooltip={{
                    target: "sorter-icon",
                  }}
                  bordered
                  pagination={{ position: ["bottomLeft"] }}
                />
              </div>
              <p>
                (Note: Projects marked as sold will automatically be removed
                from our system after 45 days. During this period, the sold
                projects will still be visible to users with a "sold" tag, but
                they will be permanently deleted after 45 days.)
              </p>
            </div>
          </div>
        )}
        {selectedSection === "properties" && (
          <div className="dash-container">
            <div className="dash-container-sub">
              <h3>My Properties</h3>
              <h5>
                <span>{allProperties.length}</span> Results found (All
                Properties)
              </h5>
              <div className="dash-table-main">
                <Table
                  className="dash-table"
                  columns={propertiesColumns}
                  dataSource={allProperties}
                  showSorterTooltip={{
                    target: "sorter-icon",
                  }}
                  onChange={handleTableChange}
                  bordered
                  pagination={{ position: ["bottomLeft"] }}
                />
              </div>
              <h5>
                <span>{soldProperties.length}</span> Results found (Sold)
              </h5>
              <div className="dash-table-main">
                <Table
                  className="dash-table"
                  columns={soldPropertiesColumns}
                  dataSource={soldProperties}
                  showSorterTooltip={{
                    target: "sorter-icon",
                  }}
                  bordered
                  pagination={{ position: ["bottomLeft"] }}
                />
              </div>
              <p>
                (Note: Properties marked as sold will automatically be removed
                from our system after 45 days. During this period, the sold
                properties will still be visible to users with a "sold" tag, but
                they will be permanently deleted after 45 days.)
              </p>
            </div>
          </div>
        )}
        {selectedSection === "wishlists" && (
          <div className="dash-container">
            <div className="dash-container-sub">
              <h3>My Wishlists</h3>
              <h5>
                <span>{allWishlists.length}</span> Results found (All Wishlisted
                Properties)
              </h5>
              <div className="dash-table-main">
                <Table
                  className="dash-table"
                  columns={wishlistsColumns}
                  dataSource={allWishlists}
                  showSorterTooltip={{
                    target: "sorter-icon",
                  }}
                  onChange={handleTableChange}
                  bordered
                  pagination={{ position: ["bottomLeft"] }}
                />
              </div>
              <h5>
                <span>{soldWishlists.length}</span> Results found (Sold
                Properties)
              </h5>
              <div className="dash-table-main">
                <Table
                  className="dash-table"
                  columns={soldWishlistsColumns}
                  dataSource={soldWishlists}
                  showSorterTooltip={{
                    target: "sorter-icon",
                  }}
                  onChange={handleTableChange}
                  bordered
                  pagination={{ position: ["bottomLeft"] }}
                />
              </div>
              <h5>
                <span>{deletedWishlists.length}</span> Results found (Deleted
                Properties)
              </h5>
              <div className="dash-table-main">
                <Table
                  className="dash-table"
                  columns={deletedWishlistsColumns}
                  dataSource={deletedWishlists}
                  showSorterTooltip={{
                    target: "sorter-icon",
                  }}
                  onChange={handleTableChange}
                  bordered
                  pagination={{ position: ["bottomLeft"] }}
                />
              </div>
            </div>
          </div>
        )}
        {selectedSection === "reviews" && (
          <div className="dash-container">
            <div className="dash-container-sub">
              <h3>Reviews</h3>
            </div>
          </div>
        )}
      </div>
      <div className="dash-sidebar-opener">
        <MdKeyboardArrowRight className="dash-sidebar-opener-icon" />
      </div>
      <Drawer title="Basic Drawer" onClose={onClose} open={open}>
        <Timeline
          items={[
            {
              children: "Create a services site 2015-09-01",
            },
            {
              children: "Solve initial network problems 2015-09-01",
            },
            {
              dot: <AiOutlineDashboard className="timeline-clock-icon" />,
              color: "red",
              children: "Technical testing 2015-09-01",
            },
            {
              children: "Network problems being solved 2015-09-01",
            },
          ]}
        />
      </Drawer>
    </div>
  );
}

export default Dashboard;
