import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// STYLING IMPORT
import "./UserProfile.css";

// LOADER IMPORT
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

// REACT ICONS
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { MdOutlineNaturePeople } from "react-icons/md";
import { GiMeepleCircle } from "react-icons/gi";
import { PiShoppingBag } from "react-icons/pi";
import { AiOutlineCloseCircle, AiOutlineDelete } from "react-icons/ai";
import { GoArrowLeft } from "react-icons/go";
import { RiArrowLeftSLine, RiHomeOfficeLine } from "react-icons/ri";
import { GrPowerReset } from "react-icons/gr";
import { IoLogoWhatsapp } from "react-icons/io5";
import {
  MdDriveFileRenameOutline,
  MdOutlineEdit,
  MdOutlineEmail,
  MdOutlinePhoneEnabled,
} from "react-icons/md";
import {
  TbBuildingEstate,
  TbCalendarStar,
  TbEyeCode,
  TbInputCheck,
  TbSettings2,
} from "react-icons/tb";
import { LuUser2, LuUsers2, LuTextCursorInput } from "react-icons/lu";
import { PiBracketsCurlyBold, PiCity } from "react-icons/pi";
import { BsCurrencyDollar, BsCurrencyRupee } from "react-icons/bs";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { CgTag } from "react-icons/cg";
import { GrCurrency } from "react-icons/gr";
import { LiaAddressCardSolid, LiaUserTagSolid } from "react-icons/lia";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { BiParty } from "react-icons/bi";

// ANT DESIGN IMPORT
import {
  Slider,
  message,
  Popconfirm,
  Select,
  Progress,
  Switch,
  Collapse,
  List,
  DatePicker,
  Button,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { LoadingOutlined } from "@ant-design/icons";

// RECOIL INTEGRATION
import { useRecoilState, useRecoilValue } from "recoil";
import { userDataAtom } from "../../store/atoms/userDataAtom";
import { fetchLatestUserDetails } from "../../store/atoms/fetchLatestUserDetails";

// ZOD
import {
  agentEmployeeSchema,
  builderEmployeeSchema,
} from "../../schemas/userTypeRedirectSchema";
import {
  updateProfileSchema,
  updateSalespersonSchema,
} from "../../schemas/updateProfileSchema";

// CITIES IMPORT
import citiesData from "../../utils/CitiesData";

// REUSABLE FORM INPUT COMPONENT
import FormInput from "../../components/Reusable/FormInput/FormInput";
// REUSABLE IMAGE UPLOAD COMPONENT
import ImageUpload from "../../components/Reusable/ImageUpload/ImageUpload";

function UserProfile() {
  dayjs.extend(customParseFormat);
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  const [isProfileVerified, setIsProfileVerified] = useState(null);

  useMemo(() => {
    if (userDetails) {
      setUserId(userDetails?.user?._id);
      setUserType(userDetails?.userProfile?.userType);
      setIsProfileVerified(
        userDetails?.userProfile?.verification?.isProfileVerified
      );
    }
  }, [userDetails]);

  useEffect(() => {
    document.title = "Proptzo - User Profile";
  }, []);

  const [selectedSection, setSelectedSection] = useState("Business Info");
  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  // Cities with their GeoCodes
  const cityOptions = citiesData.map((city) => ({
    cityName: city.city,
    geocode: city.geoCode,
  }));
  const filterCityData = (selectedCity) =>
    citiesData.filter((city) => city.city === selectedCity)[0];

  // Business Info
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsappMobile, setWhatsappMobile] = useState("");

  const [showSaveUserDetailsBtn, setShowSaveUserDetailsBtn] = useState(false);
  const [fetchLatestUserData, setFetchLatestUserData] = useRecoilState(
    fetchLatestUserDetails
  );
  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setMobile(value);
    }
  };
  const formatMobileDisplay = (mobile) => {
    const formatted = mobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const handleWhatsappMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setWhatsappMobile(value);
    }
  };
  const formatWhatsappMobileDisplay = (whatsappMobile) => {
    const formatted = whatsappMobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const [checked, setChecked] = useState(false);
  const onSameWhatsappMobileAsMobileChange = useCallback(
    (isChecked) => {
      setChecked(isChecked);
      if (isChecked) {
        setWhatsappMobile(mobile);
      } else {
        setWhatsappMobile("");
      }
    },
    [setChecked, setWhatsappMobile, mobile]
  );
  const switchBackgroundColor = checked ? "var(--primary-color)" : "#ccc";

  const [profileImage, setProfileImage] = useState(null);

  // Builder Firm Details
  const [builderDetails, setBuilderDetails] = useState({
    builderFirmName: "",
    builderReraNo: "",
    builderReraNoType: "",
    builderOfficeCity: "",
    builderOfficeWebsite: "",
    builderAddress: "",
    builderProfilePhoto: null,
    builderFirmLogo: null,
    builderOfficeName: "",
  });
  // Agent Firm Details
  const [agentDetails, setAgentDetails] = useState({
    agentFirmName: "",
    agentReraNo: "",
    agentReraNoType: "",
    agentOfficeCity: "",
    agentOfficeWebsite: "",
    agentAddress: "",
    agentProfilePhoto: null,
    agentFirmLogo: null,
    agentOfficeName: "",
    dealsIn: [],
  });
  // Agent Deals In
  const handleAgentDealsInChange = (deal) => {
    setAgentDetails((prev) => {
      const isSelected = prev.dealsIn.includes(deal);
      const newDealsIn = isSelected
        ? prev.dealsIn.filter((d) => d !== deal)
        : [...prev.dealsIn, deal];
      return {
        ...prev,
        dealsIn: newDealsIn,
      };
    });
  };
  const handleClearAgentDealsInSelection = () => {
    setAgentDetails((prev) => ({
      ...prev,
      dealsIn: [],
    }));
  };
  const isAgentDealSelected = (deal) => agentDetails.dealsIn.includes(deal);

  // Solo salesperson Details
  const [salespersonDetails, setSalespersonDetails] = useState({
    employeeIdType: "",
    employeeDesignation: "",
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeementLetterImage: null,
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });

  // Reset Salesperson Id Type (Related Fields) OnChnage - Id Proofs Reset
  const resetSalespersonIdProofDetails = () => ({
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });
  const handleSalespersonIdTypeChange = (value) => {
    setSalespersonDetails((prev) => ({
      ...prev,
      employeeIdType: value,
      ...resetSalespersonIdProofDetails(),
    }));
  };
  // Salesperson Employee Aadhaar Card
  const handleSalespersonAadhaarCardNo = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 4);
    setSalespersonDetails((prev) => ({
      ...prev,
      employeeAadhaarCardNo: numericValue,
    }));
  };

  // Builder Address
  const [builderAddressCharacterCount, setBuilderAddressCharacterCount] =
    useState(0);
  const handleBuilderAddressChange = (e) => {
    const newBuilderAddress = e.target.value;
    if (newBuilderAddress.length <= 100) {
      setBuilderDetails({
        ...builderDetails,
        builderAddress: newBuilderAddress,
      });
      setBuilderAddressCharacterCount(newBuilderAddress?.length);
    } else {
      setBuilderDetails({
        ...builderDetails,
        builderAddress: newBuilderAddress.slice(0, 100),
      });
      setBuilderAddressCharacterCount(100);
    }
  };

  const builderAddressSpanContent =
    builderAddressCharacterCount ||
    builderDetails?.builderAddress?.length === 100
      ? "Max character limit reached"
      : "Provide complete address";

  // Agent Address
  const [agentAddressCharacterCount, setAgentAddressCharacterCount] =
    useState(0);
  const [
    salespersonCompanyAddressCharacterCount,
    setSalespersonCompanyAddressCharacterCount,
  ] = useState(0);
  const handleagentAddressChange = (e) => {
    const newAgentAddress = e.target.value;
    if (newAgentAddress.length <= 100) {
      setAgentDetails({
        ...agentDetails,
        agentAddress: newAgentAddress,
      });
      setAgentAddressCharacterCount(newAgentAddress?.length);
    } else {
      setAgentDetails({
        ...agentDetails,
        agentAddress: newAgentAddress.slice(0, 100),
      });
      setAgentAddressCharacterCount(100);
    }
  };

  const handleSalespersonCompanyAddressChange = (e) => {
    const newSalespersonCompanyAddress = e.target.value;
    if (newSalespersonCompanyAddress?.length <= 100) {
      setSalespersonDetails({
        ...salespersonDetails,
        employeeCompanyAddress: newSalespersonCompanyAddress,
      });
      setSalespersonCompanyAddressCharacterCount(
        newSalespersonCompanyAddress?.length
      );
    } else {
      setSalespersonDetails({
        ...salespersonDetails,
        employeeCompanyAddress: newSalespersonCompanyAddress.slice(0, 100),
      });
      setSalespersonCompanyAddressCharacterCount(100);
    }
  };

  const agentAddressSpanContent =
    agentAddressCharacterCount || agentDetails?.agentAddress?.length === 100
      ? "Max character limit reached"
      : "Provide complete address";

  const salespersonCompanyAddressSpanContent =
    salespersonCompanyAddressCharacterCount ||
    salespersonDetails?.employeeCompanyAddress?.length === 100
      ? "Max character limit reached"
      : "Provide complete address";

  // Builder Update Employees
  const [showBuilderUpdateEmployeeBtn, setShowBuilderUpdateEmployeeBtn] =
    useState(false);
  const [isBuilderEditing, setIsBuilderEditing] = useState(false);
  const [builderEditingEmployeeIndex, setBuilderEditingEmployeeIndex] =
    useState(null);
  const [builderEmployeeUpdateDetails, setBuilderEmployeeUpdateDetails] =
    useState({
      employeeUserId: "",
      employeeName: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeDesignation: "",
      employeeID: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
    });
  const handleBuilderEmployeeUpdateMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setBuilderEmployeeUpdateDetails((prev) => ({
        ...prev,
        employeeMobileNo: value,
      }));
    }
  };
  const formatBuilderEmployeeUpdateMobileDisplay = (employeeMobileNo) => {
    const formatted = employeeMobileNo.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };

  const handleEditBuilderEmployee = (index) => {
    const selectedEmployee = builderAddedEmployeesList[index];

    // Set the employee details for the form
    setBuilderEmployeeUpdateDetails({
      employeeUserId: selectedEmployee.userId,
      employeeName: selectedEmployee.employeeName,
      employeeEmail: selectedEmployee.employeeEmail,
      employeeMobileNo: selectedEmployee.employeeMobileNo,
      employeeDesignation: selectedEmployee.employeeDesignation,
      employeeID: selectedEmployee.employeeID,
      employeeImage: selectedEmployee.employeeImage,
      employeementLetterImage: selectedEmployee.employeementLetterImage,
      employeeIdCardImage: selectedEmployee.employeeIdCardImage,
    });

    // Set editing index
    setBuilderEditingEmployeeIndex(index);

    // Show update button and mark as editing
    setIsBuilderEditing(true);
    setShowBuilderUpdateEmployeeBtn(true);
  };

  const handleCancelBuilderUpdate = () => {
    // Reset all states related to editing
    setIsBuilderEditing(false);
    setShowBuilderUpdateEmployeeBtn(false);
    setBuilderEmployeeUpdateDetails({
      employeeUserId: "",
      employeeName: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeDesignation: "",
      employeeID: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
    });
    setBuilderEditingEmployeeIndex(null);
  };

  const handleUpdateBuilderEmployee = async () => {
    const formData = new FormData();
    formData.append("userAccessId", userId);
    formData.append("userId", builderEmployeeUpdateDetails.employeeUserId);
    formData.append("employeeName", builderEmployeeUpdateDetails.employeeName);
    formData.append(
      "employeeEmail",
      builderEmployeeUpdateDetails.employeeEmail
    );
    formData.append(
      "employeeMobileNo",
      builderEmployeeUpdateDetails.employeeMobileNo
    );
    formData.append("employeeID", builderEmployeeUpdateDetails.employeeID);
    formData.append(
      "employeeDesignation",
      builderEmployeeUpdateDetails.employeeDesignation
    );
    formData.append("selectedEmployeeIdType", "Employee ID Card");

    let employeeDocuments = {};

    if (
      builderEmployeeUpdateDetails.employeeImage &&
      typeof builderEmployeeUpdateDetails.employeeImage !== "string"
    ) {
      employeeDocuments.employeeImage = true;
    }

    if (
      builderEmployeeUpdateDetails.employeementLetterImage &&
      typeof builderEmployeeUpdateDetails.employeementLetterImage !== "string"
    ) {
      employeeDocuments.employeementLetterImage = true;
    }

    if (
      builderEmployeeUpdateDetails.employeeIdCardImage &&
      typeof builderEmployeeUpdateDetails.employeeIdCardImage !== "string"
    ) {
      employeeDocuments.employeeIdCardImage = true;
    }

    formData.append("employeeDocuments", JSON.stringify(employeeDocuments));

    try {
      const checkDuplicateCredentials = await axios.get(
        `${baseURL}/api/profile/checkDuplicateEmpCredentials`,
        {
          params: {
            userId: builderEmployeeUpdateDetails.employeeUserId,
            email: builderEmployeeUpdateDetails.employeeEmail,
            mobileNo: builderEmployeeUpdateDetails.employeeMobileNo,
          },
        }
      );
      if (
        checkDuplicateCredentials.status === 200 ||
        checkDuplicateCredentials.statusText === "OK"
      ) {
        try {
          const updatedEmployeeRes = await axios.patch(
            `${baseURL}/api/profile/updateEmployeeProfile`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
              },
            }
          );
          message.success(updatedEmployeeRes?.data?.message);

          const employeeMediaUploadInfo = updatedEmployeeRes?.data
            ?.employeeMediaUploadInfo
            ? updatedEmployeeRes?.data?.employeeMediaUploadInfo
            : {};

          let employeeMediaUploadResponse = {};

          try {
            employeeMediaUploadResponse.employeeID =
              employeeMediaUploadInfo.employeeID;

            if (employeeMediaUploadInfo.employeementLetterUploadUrl) {
              const employeementLetterUploadResponse = await axios.put(
                employeeMediaUploadInfo.employeementLetterUploadUrl,
                builderEmployeeUpdateDetails.employeementLetterImage
              );

              if (
                employeementLetterUploadResponse.status === 200 ||
                employeementLetterUploadResponse.statusText === "OK"
              ) {
                const employeementLetterUrl =
                  employeeMediaUploadInfo.employeementLetterUploadUrl;

                const parsedEmployeementLetterUrl = new URL(
                  employeementLetterUrl
                );

                const employeementLetterkey =
                  parsedEmployeementLetterUrl.pathname.slice(1);

                employeeMediaUploadResponse.employeementLetter =
                  employeementLetterkey;
              }
            }

            if (employeeMediaUploadInfo.employeeImageUploadUrl) {
              const employeeImageUploadResponse = await axios.put(
                employeeMediaUploadInfo.employeeImageUploadUrl,
                builderEmployeeUpdateDetails.employeeImage
              );

              if (
                employeeImageUploadResponse.status === 200 ||
                employeeImageUploadResponse.statusText === "OK"
              ) {
                const employeeImageUrl =
                  employeeMediaUploadInfo.employeeImageUploadUrl;

                const parsedEmployeeImageUrl = new URL(employeeImageUrl);

                const employeeImagekey =
                  parsedEmployeeImageUrl.pathname.slice(1);

                employeeMediaUploadResponse.employeeImage = employeeImagekey;
              }
            }

            if (employeeMediaUploadInfo.employeeIdCardImageUploadUrl) {
              const employeeIdCardImageUploadResponse = await axios.put(
                employeeMediaUploadInfo.employeeIdCardImageUploadUrl,
                builderEmployeeUpdateDetails.employeeIdCardImage
              );

              if (
                employeeIdCardImageUploadResponse.status === 200 ||
                employeeIdCardImageUploadResponse.statusText === "OK"
              ) {
                const employeeIdCardImageUrl =
                  employeeMediaUploadInfo.employeeIdCardImageUploadUrl;

                const parsedEmployeeIdCardImageUrl = new URL(
                  employeeIdCardImageUrl
                );

                const employeeIdCardImagekey =
                  parsedEmployeeIdCardImageUrl.pathname.slice(1);

                employeeMediaUploadResponse.employeeIdCardImage =
                  employeeIdCardImagekey;
              }
            }
          } catch (error) {
            console.error(`Error uploading employee media:`, error);
            throw error;
          }

          try {
            const savedEmployeeMediaKeys =
              Object.keys(employeeMediaUploadInfo).length > 1 &&
              (await axios.patch(
                `${baseURL}/api/profile/saveProfileFileKeys`,
                {
                  userType: "Salesperson",
                  employeeMediaUploadResponse: JSON.stringify(
                    employeeMediaUploadResponse
                  ),
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "access_token"
                    )}`,
                    "Content-Type": "application/json",
                  },
                }
              ));

            if (savedEmployeeMediaKeys) {
              message.success("Employee documents uploaded successfully");
            }
          } catch (err) {
            console.log("checking the issue", err);

            message.error("Error occured while saving images");
          }
        } catch (err) {
          message.error("Error occured while updating employee profile");
        } finally {
          setFetchLatestUserData(true);
          setIsBuilderEditing(false);
          setShowBuilderUpdateEmployeeBtn(false);
          setBuilderEditingEmployeeIndex(null);
          setBuilderEmployeeUpdateDetails({
            employeeUserId: "",
            employeeName: "",
            employeeEmail: "",
            employeeMobileNo: "",
            employeeDesignation: "",
            employeeID: "",
            employeeImage: null,
            employeementLetterImage: null,
            employeeIdCardImage: null,
          });
        }
      }
    } catch (err) {
      console.log("error occured while checking duplicate credentials", err);
      message.error(err.response.data.message);
    }
  };

  // Builder Employees
  const [builderEmployeeDetails, setBuilderEmployeeDetails] = useState({
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeDesignation: "",
    employeeID: "",
    employeeImage: null,
    employeementLetterImage: null,
    employeeIdCardImage: null,
  });
  // Builder Employee Mobile
  const handleBuilderEmployeeMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setBuilderEmployeeDetails((prev) => ({
        ...prev,
        employeeMobileNo: value,
      }));
    }
  };
  const formatBuilderEmployeeMobileDisplay = (employeeMobileNo) => {
    const formatted = employeeMobileNo.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };

  // Builder Added Employees List
  const [builderAddedEmployeesList, setBuilderAddedEmployeesList] = useState(
    []
  );
  const [builderTempAddedEmployeesList, setBuilderTempAddedEmployeesList] =
    useState([]);
  const addBuilderEmployee = async () => {
    // validate here in zod the builder employee data object ha no missing required fields

    if (
      builderEmployeeSchema.safeParse({
        employeeFullName: builderEmployeeDetails.employeeName,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeFullName: builderEmployeeDetails.employeeName,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee name");
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeDesignation: builderEmployeeDetails.employeeDesignation,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeDesignation: builderEmployeeDetails.employeeDesignation,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee designation"
      );
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeEmail: builderEmployeeDetails.employeeEmail,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeEmail: builderEmployeeDetails.employeeEmail,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee email");
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeMobileNo: builderEmployeeDetails.employeeMobileNo,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeMobileNo: builderEmployeeDetails.employeeMobileNo,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee mobile no"
      );
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeId: builderEmployeeDetails.employeeID,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeId: builderEmployeeDetails.employeeID,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee ID card");
      message.error(customMessage);
      return;
    }

    if (!builderEmployeeDetails.employeeImage) {
      message.error("Employee image required");
      return;
    }

    if (!builderEmployeeDetails.employeeIdCardImage) {
      message.error("Employee ID card image required");
      return;
    }

    if (
      builderAddedEmployeesList.length > 0 &&
      builderAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === builderEmployeeDetails.employeeEmail ||
          employee.employeeMobileNo ===
            builderEmployeeDetails.employeeMobileNo.replace(
              /(\d{2})(\d{0,10})/,
              "+$1 $2"
            )
      )
    ) {
      const identifier = builderAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === builderEmployeeDetails.employeeEmail
      )
        ? "email"
        : builderAddedEmployeesList.some(
            (employee) =>
              employee.employeeMobileNo ===
              builderEmployeeDetails.employeeMobileNo.replace(
                /(\d{2})(\d{0,10})/,
                "+$1 $2"
              )
          )
        ? "mobile no"
        : "credentials";
      message.error(
        `Employee with this ${identifier} already exists in the employee list`
      );
      return;
    }

    const formData = new FormData();

    formData.append("userAccessId", userId);

    if (userType === "Builder") {
      formData.append("employeeName", builderEmployeeDetails.employeeName);
      formData.append("employeeEmail", builderEmployeeDetails.employeeEmail);
      formData.append(
        "employeeMobileNo",
        builderEmployeeDetails.employeeMobileNo
      );
      formData.append(
        "employeeDesignation",
        builderEmployeeDetails.employeeDesignation
      );
      formData.append("selectedEmployeeIdType", "Employee ID Card");
      formData.append("employeeID", builderEmployeeDetails.employeeID);
      let employeeDocuments;

      if (builderEmployeeDetails.employeementLetterImage) {
        employeeDocuments = {
          employeementLetterImage: true,
          employeeImage: true,
          employeeIdCardImage: true,
        };
      } else {
        employeeDocuments = {
          employeeImage: true,
          employeeIdCardImage: true,
        };
      }
      formData.append(`employeeDocuments`, JSON.stringify(employeeDocuments));
    }
    try {
      message.info("Sending invitation request");
      setLoading(true);

      const invitationResponse = await axios.post(
        `${baseURL}/api/mail/sendMailUsingS3`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (invitationResponse.data.recruitmentStatus === "Invitation sent") {
        message.open({
          type: "success",
          content: invitationResponse.data.message,
        });

        if (
          userType === "Builder" &&
          invitationResponse?.data?.employeeDocumentUploadUrls
        ) {
          const employeeDocumentUploadUrls =
            invitationResponse?.data?.employeeDocumentUploadUrls &&
            JSON.parse(invitationResponse?.data?.employeeDocumentUploadUrls);
          const employeeMediaUploadResponse = {};

          try {
            employeeMediaUploadResponse.employeeID =
              employeeDocumentUploadUrls.employeeID;

            if (employeeDocumentUploadUrls.employeementLetterUploadUrl) {
              const employeementLetterUploadResponse = await axios.put(
                employeeDocumentUploadUrls.employeementLetterUploadUrl,
                builderEmployeeDetails.employeementLetterImage
              );

              if (
                employeementLetterUploadResponse.status === 200 ||
                employeementLetterUploadResponse.statusText === "OK"
              ) {
                const employeementLetterUrl =
                  employeeDocumentUploadUrls.employeementLetterUploadUrl;

                const parsedEmployeementLetterUrl = new URL(
                  employeementLetterUrl
                );

                const employeementLetterkey =
                  parsedEmployeementLetterUrl.pathname.slice(1);

                employeeMediaUploadResponse.employeementLetter =
                  employeementLetterkey;
              }
            }

            if (employeeDocumentUploadUrls.employeeImageUploadUrl) {
              const employeeImageUploadResponse = await axios.put(
                employeeDocumentUploadUrls.employeeImageUploadUrl,
                builderEmployeeDetails.employeeImage
              );

              if (
                employeeImageUploadResponse.status === 200 ||
                employeeImageUploadResponse.statusText === "OK"
              ) {
                const employeeImageUrl =
                  employeeDocumentUploadUrls.employeeImageUploadUrl;

                const parsedEmployeeImageUrl = new URL(employeeImageUrl);

                const employeeImagekey =
                  parsedEmployeeImageUrl.pathname.slice(1);

                employeeMediaUploadResponse.employeeImage = employeeImagekey;
              }
            }

            if (employeeDocumentUploadUrls.employeeIdCardImageUploadUrl) {
              const employeeIdCardImageUploadResponse = await axios.put(
                employeeDocumentUploadUrls.employeeIdCardImageUploadUrl,
                builderEmployeeDetails.employeeIdCardImage
              );

              if (
                employeeIdCardImageUploadResponse.status === 200 ||
                employeeIdCardImageUploadResponse.statusText === "OK"
              ) {
                const employeeIdCardImageUrl =
                  employeeDocumentUploadUrls.employeeIdCardImageUploadUrl;

                const parsedEmployeeIdCardImageUrl = new URL(
                  employeeIdCardImageUrl
                );

                const employeeIdCardImagekey =
                  parsedEmployeeIdCardImageUrl.pathname.slice(1);

                employeeMediaUploadResponse.employeeIdCardImage =
                  employeeIdCardImagekey;
              }
            }
          } catch (error) {
            console.error(`Error uploading employee media:`, error);
            throw error;
          }

          try {
            const savedEmployeeMediaKeys = await axios.patch(
              `${baseURL}/api/mail/saveEmployeeProfileFileKeys`,
              {
                userId,
                employeeMediaUploadResponse: JSON.stringify(
                  employeeMediaUploadResponse
                ),
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (savedEmployeeMediaKeys) {
              message.success(savedEmployeeMediaKeys.data.message);
            }
          } catch (err) {
            console.log("checking the issue", err);

            message.error("Error occured while saving images");
          }
        }
      } else if (
        invitationResponse.data.recruitmentStatus === "Already recruited" ||
        invitationResponse.data.recruitmentStatus === "Already invited" ||
        invitationResponse.data.recruitmentStatus === "Employee Profile Exists"
      ) {
        message.open({
          type: "info",
          content: invitationResponse.data.message,
        });
        setLoading(false);
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setBuilderEmployeeDetails({
        employeeName: "",
        employeeEmail: "",
        employeeMobileNo: "",
        employeeDesignation: "",
        employeeID: "",
        employeeImage: null,
        employeementLetterImage: null,
        employeeIdCardImage: null,
      });
    } catch (err) {
      console.log(err);
      message.error(
        err.response?.data?.message || "Error occured while uploading images"
      );
      return;
    } finally {
      setFetchLatestUserData(true);
      setLoading(false);
    }
  };

  const removeBuilderEmployee = (index) => {
    setBuilderAddedEmployeesList((prev) => prev.filter((_, i) => i !== index));
  };
  const renderBuilderEmployeeImage = (image, placeholderText) => {
    if (image && typeof image !== "string") {
      return URL.createObjectURL(image);
    } else if (image && typeof image === "string") {
      return image;
    } else {
      return `https://placehold.co/50x50?text=${placeholderText}`;
    }
  };
  const builderEmployeesForm = useMemo(
    () => (
      <>
        <div className="utr-form-category">
          <h4>Employee Details</h4>
          <div className="utr-form-inputs">
            <FormInput
              icon={MdDriveFileRenameOutline}
              placeholder="Employee Full Name"
              value={builderEmployeeDetails.employeeName}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeName: e.target.value,
                }))
              }
            />
            <FormInput
              icon={LiaUserTagSolid}
              placeholder="Employee Designation"
              value={builderEmployeeDetails.employeeDesignation}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeDesignation: e.target.value,
                }))
              }
            />
            <FormInput
              icon={MdOutlineEmail}
              placeholder="Employee Email"
              value={builderEmployeeDetails.employeeEmail}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeEmail: e.target.value,
                }))
              }
            />
            <FormInput
              icon={MdOutlinePhoneEnabled}
              placeholder="Employee Mobile No."
              value={formatBuilderEmployeeMobileDisplay(
                builderEmployeeDetails.employeeMobileNo
              )}
              onChange={handleBuilderEmployeeMobileChange}
              maxLength="14"
            />
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Employee ID card"
              value={builderEmployeeDetails.employeeID}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeID: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="utr-form-category">
          <h4>Employee Photos</h4>
          <div className="utr-form-images">
            <ImageUpload
              image={builderEmployeeDetails.employeeImage}
              onImageChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeImage: null,
                }))
              }
              label="Profile"
            />
            <ImageUpload
              required={false}
              image={builderEmployeeDetails.employeementLetterImage}
              onImageChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeementLetterImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeementLetterImage: null,
                }))
              }
              label="Employment Letter"
            />
            <ImageUpload
              image={builderEmployeeDetails.employeeIdCardImage}
              onImageChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: null,
                }))
              }
              label="ID Card"
            />
          </div>
          <button
            className="utr-add-project-employee-add"
            onClick={addBuilderEmployee}
          >
            Add Employee
          </button>
        </div>
      </>
    ),
    [builderEmployeeDetails]
  );
  // Builder Employees Reset
  const confirmBuilderEmployeesReset = (e) => {
    message.success("Employees Data Reset success!");
    setBuilderEmployeeDetails({
      employeeName: "",
      employeeDesignation: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeID: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
    });
  };
  const cancelBuilderEmployeesReset = (e) => {
    message.error("Employess reset cancelled!");
  };
  const builderEmployeesCollapse = useMemo(
    () => [
      {
        key: "1",
        label: `Add Employee`,
        children: builderEmployeesForm,
        extra: (
          <Popconfirm
            title="Reset Employee Data"
            description="Are you sure to reset all employee data?"
            onConfirm={confirmBuilderEmployeesReset}
            onCancel={cancelBuilderEmployeesReset}
            okText="Reset"
            cancelText="Cancel"
          >
            <GrPowerReset className="utr-collapse-icon" />
          </Popconfirm>
        ),
      },
    ],
    [builderEmployeesForm]
  );

  // Agent Employees Update Details
  const [showUpdateAgentEmployeeBtn, setShowUpdateAgentEmployeeBtn] =
    useState(false);
  const [isAgentEditing, setIsAgentEditing] = useState(false);
  const [editingAgentEmployeeIndex, setEditingAgentEmployeeIndex] =
    useState(null);
  const [agentEmployeeUpdateDetails, setAgentEmployeeUpdateDetails] = useState({
    employeeUserId: "",
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeDesignation: "",
    employeeIdType: "",
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeeImage: null,
    employeementLetterImage: null,
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });
  const handleEditAgentEmployee = (index) => {
    const selectedEmployee = agentAddedEmployeesList[index]; // Assuming you have a list similar to builder
    console.log(selectedEmployee);
    setAgentEmployeeUpdateDetails({
      employeeUserId: selectedEmployee.userId,
      employeeName: selectedEmployee.employeeName,
      employeeEmail: selectedEmployee.employeeEmail,
      employeeMobileNo: selectedEmployee.employeeMobileNo,
      employeeDesignation: selectedEmployee.employeeDesignation,
      employeeIdType: selectedEmployee.selectedEmployeeIdType,
      employeeID: selectedEmployee.employeeID,
      employeeAadhaarCardNo: selectedEmployee.employeeAadhaarcardNo,
      employeePanCardNo: selectedEmployee.employeePancardNo,
      employeeVoterCardNo: selectedEmployee.employeeVoterIdNo,
      employeePassportNo: selectedEmployee.employeePassportNo,
      employeeDrivingLicenseNo: selectedEmployee.employeeDrivinglicenseNo,
      employeeImage: selectedEmployee.employeeImage,
      employeementLetterImage: selectedEmployee.employeeEmployeementLetter,
      employeeIdCardImage: selectedEmployee.employeeIdCardImage,
      employeeAadhaarcardFrontImage:
        selectedEmployee.employeeAadhaarcardFrontImage,
      employeeAadhaarcardBackImage:
        selectedEmployee.employeeAadhaarcardBackImage,
      employeePancardImage: selectedEmployee.employeePancardImage,
      employeeVoterIdFrontImage: selectedEmployee.employeeVoterIdFrontImage,
      employeeVoterIdBackImage: selectedEmployee.employeeVoterIdBackImage,
      employeePassportImage: selectedEmployee.employeePassportImage,
      employeeDrivinglicenseFrontImage:
        selectedEmployee.employeeDrivinglicenseFrontImage,
      employeeDrivinglicenseBackImage:
        selectedEmployee.employeeDrivinglicenseBackImage,
    });

    setEditingAgentEmployeeIndex(index);
    setIsAgentEditing(true);
    setShowUpdateAgentEmployeeBtn(true);
  };
  const handleCancelAgentUpdate = () => {
    setIsAgentEditing(false);
    setShowUpdateAgentEmployeeBtn(false);
    setAgentEmployeeUpdateDetails({
      employeeUserId: "",
      employeeName: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeDesignation: "",
      employeeIdType: "",
      employeeID: "",
      employeeAadhaarCardNo: "",
      employeePanCardNo: "",
      employeeVoterCardNo: "",
      employeePassportNo: "",
      employeeDrivingLicenseNo: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
      employeeAadhaarcardFrontImage: null,
      employeeAadhaarcardBackImage: null,
      employeePancardImage: null,
      employeeVoterIdFrontImage: null,
      employeeVoterIdBackImage: null,
      employeePassportImage: null,
      employeeDrivinglicenseFrontImage: null,
      employeeDrivinglicenseBackImage: null,
    });
    setEditingAgentEmployeeIndex(null);
  };
  const handleUpdateAgentEmployee = async () => {
    const formData = new FormData();

    formData.append("userAccessId", userId);
    formData.append("userId", agentEmployeeUpdateDetails.employeeUserId);
    formData.append("employeeName", agentEmployeeUpdateDetails.employeeName);
    formData.append("employeeEmail", agentEmployeeUpdateDetails.employeeEmail);
    formData.append(
      "employeeMobileNo",
      agentEmployeeUpdateDetails.employeeMobileNo
    );
    formData.append(
      "employeeDesignation",
      agentEmployeeUpdateDetails.employeeDesignation
    );
    formData.append(
      "selectedEmployeeIdType",
      agentEmployeeUpdateDetails.employeeIdType
    );

    let employeeDocuments = {};

    if (
      agentEmployeeUpdateDetails.employeementLetterImage &&
      typeof agentEmployeeUpdateDetails.employeementLetterImage !== "string"
    ) {
      employeeDocuments.employeementLetterImage = true;
    }
    if (
      agentEmployeeUpdateDetails.employeeImage &&
      typeof agentEmployeeUpdateDetails.employeeImage !== "string"
    ) {
      employeeDocuments.employeeImage = true;
    }

    switch (agentEmployeeUpdateDetails.employeeIdType) {
      case "Employee ID Card":
        if (
          agentEmployeeUpdateDetails.employeeIdCardImage &&
          typeof agentEmployeeUpdateDetails.employeeIdCardImage !== "string"
        ) {
          employeeDocuments.employeeIdCardImage = true;
        }

        formData.append("employeeID", agentEmployeeUpdateDetails.employeeID);
        break;

      case "Aadhaar Card":
        if (
          agentEmployeeUpdateDetails.employeeAadhaarcardFrontImage &&
          typeof agentEmployeeUpdateDetails.employeeAadhaarcardFrontImage !==
            "string"
        ) {
          employeeDocuments.employeeAadhaarcardFrontImage = true;
        }

        if (
          agentEmployeeUpdateDetails.employeeAadhaarcardBackImage &&
          typeof agentEmployeeUpdateDetails.employeeAadhaarcardBackImage !==
            "string"
        ) {
          employeeDocuments.employeeAadhaarcardBackImage = true;
        }

        formData.append(
          "employeeAadhaarcardNo",
          agentEmployeeUpdateDetails.employeeAadhaarCardNo
        );
        break;

      case "Pan Card":
        if (
          agentEmployeeUpdateDetails.employeePancardImage &&
          typeof agentEmployeeUpdateDetails.employeePancardImage !== "string"
        ) {
          employeeDocuments.employeePancardImage = true;
        }

        formData.append(
          "employeePancardNo",
          agentEmployeeUpdateDetails.employeePanCardNo
        );
        break;

      case "Voter Card":
        if (
          agentEmployeeUpdateDetails.employeeVoterIdFrontImage &&
          typeof agentEmployeeUpdateDetails.employeeVoterIdFrontImage !==
            "string"
        ) {
          employeeDocuments.employeeVoterIdFrontImage = true;
        }
        if (
          agentEmployeeUpdateDetails.employeeVoterIdBackImage &&
          typeof agentEmployeeUpdateDetails.employeeVoterIdBackImage !==
            "string"
        ) {
          employeeDocuments.employeeVoterIdBackImage = true;
        }

        formData.append(
          "employeeVoterIdNo",
          agentEmployeeUpdateDetails.employeeVoterCardNo
        );
        break;

      case "Passport":
        if (
          agentEmployeeUpdateDetails.employeePassportImage &&
          typeof agentEmployeeUpdateDetails.employeePassportImage !== "string"
        ) {
          employeeDocuments.employeePassportImage = true;
        }

        formData.append(
          "employeePassportNo",
          agentEmployeeUpdateDetails.employeePassportNo
        );
        break;

      case "Driving License":
        if (
          agentEmployeeUpdateDetails.employeeDrivinglicenseFrontImage &&
          typeof agentEmployeeUpdateDetails.employeeDrivinglicenseFrontImage !==
            "string"
        ) {
          employeeDocuments.employeeDrivinglicenseFrontImage = true;
        }

        if (
          agentEmployeeUpdateDetails.employeeDrivinglicenseBackImage &&
          typeof agentEmployeeUpdateDetails.employeeDrivinglicenseBackImage !==
            "string"
        ) {
          employeeDocuments.employeeDrivinglicenseBackImage = true;
        }
        formData.append(
          "employeeDrivinglicenseNo",
          agentEmployeeUpdateDetails.employeeDrivingLicenseNo
        );
        break;

      default:
        break;
    }

    formData.append("employeeDocuments", JSON.stringify(employeeDocuments));

    try {
      const checkDuplicateCredentials = await axios.get(
        `${baseURL}/api/profile/checkDuplicateEmpCredentials`,
        {
          params: {
            userId: agentEmployeeUpdateDetails.employeeUserId,
            email: agentEmployeeUpdateDetails.employeeEmail,
            mobileNo: agentEmployeeUpdateDetails.employeeMobileNo,
          },
        }
      );
      if (
        checkDuplicateCredentials.status === 200 ||
        checkDuplicateCredentials.statusText === "OK"
      ) {
        try {
          const updatedEmployeeRes = await axios.patch(
            `${baseURL}/api/profile/updateEmployeeProfile`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Content-Type": "application/json",
              },
            }
          );
          message.success(updatedEmployeeRes?.data?.message);

          const employeeMediaUploadInfo = updatedEmployeeRes?.data
            ?.employeeMediaUploadInfo
            ? updatedEmployeeRes?.data?.employeeMediaUploadInfo
            : {};

          let employeeMediaUploadResponse = {};

          try {
            employeeMediaUploadResponse.employeeID =
              employeeMediaUploadInfo.employeeID;

            if (employeeMediaUploadInfo.employeementLetterUploadUrl) {
              const employeementLetterUploadResponse = await axios.put(
                employeeMediaUploadInfo.employeementLetterUploadUrl,
                agentEmployeeUpdateDetails.employeementLetterImage
              );

              if (
                employeementLetterUploadResponse.status === 200 ||
                employeementLetterUploadResponse.statusText === "OK"
              ) {
                const employeementLetterUrl =
                  employeeMediaUploadInfo.employeementLetterUploadUrl;

                const parsedEmployeementLetterUrl = new URL(
                  employeementLetterUrl
                );

                const employeementLetterkey =
                  parsedEmployeementLetterUrl.pathname.slice(1);

                employeeMediaUploadResponse.employeementLetter =
                  employeementLetterkey;
              }
            }

            if (employeeMediaUploadInfo.employeeImageUploadUrl) {
              const employeeImageUploadResponse = await axios.put(
                employeeMediaUploadInfo.employeeImageUploadUrl,
                agentEmployeeUpdateDetails.employeeImage
              );

              if (
                employeeImageUploadResponse.status === 200 ||
                employeeImageUploadResponse.statusText === "OK"
              ) {
                const employeeImageUrl =
                  employeeMediaUploadInfo.employeeImageUploadUrl;

                const parsedEmployeeImageUrl = new URL(employeeImageUrl);

                const employeeImagekey =
                  parsedEmployeeImageUrl.pathname.slice(1);

                employeeMediaUploadResponse.employeeImage = employeeImagekey;
              }
            }

            switch (agentEmployeeUpdateDetails.employeeIdType) {
              case "Employee ID Card":
                if (employeeMediaUploadInfo.employeeIdCardImageUploadUrl) {
                  const employeeIdCardImageUploadResponse = await axios.put(
                    employeeMediaUploadInfo.employeeIdCardImageUploadUrl,
                    agentEmployeeUpdateDetails.employeeIdCardImage
                  );

                  if (
                    employeeIdCardImageUploadResponse.status === 200 ||
                    employeeIdCardImageUploadResponse.statusText === "OK"
                  ) {
                    const employeeIdCardImageUrl =
                      employeeMediaUploadInfo.employeeIdCardImageUploadUrl;

                    const parsedEmployeeIdCardImageUrl = new URL(
                      employeeIdCardImageUrl
                    );

                    const employeeIdCardImagekey =
                      parsedEmployeeIdCardImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeeIdCardImage =
                      employeeIdCardImagekey;
                  }
                }
                break;

              case "Aadhaar Card":
                if (
                  employeeMediaUploadInfo.employeeAadhaarCardFrontImageUploadUrl
                ) {
                  const employeeAadhaarCardFrontImageUploadResponse =
                    await axios.put(
                      employeeMediaUploadInfo.employeeAadhaarCardFrontImageUploadUrl,
                      agentEmployeeUpdateDetails.employeeAadhaarcardFrontImage
                    );

                  if (
                    employeeAadhaarCardFrontImageUploadResponse.status ===
                      200 ||
                    employeeAadhaarCardFrontImageUploadResponse.statusText ===
                      "OK"
                  ) {
                    const employeeAadhaarCardFrontImageUrl =
                      employeeMediaUploadInfo.employeeAadhaarCardFrontImageUploadUrl;

                    const ParsedEmployeeAadhaarCardFrontImageUrl = new URL(
                      employeeAadhaarCardFrontImageUrl
                    );

                    const employeeAadhaarCardFrontImagekey =
                      ParsedEmployeeAadhaarCardFrontImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeeAadhaarCardFrontImage =
                      employeeAadhaarCardFrontImagekey;
                  }
                }
                if (
                  employeeMediaUploadInfo.employeeAadhaarCardBackImageUploadUrl
                ) {
                  const employeeAadhaarCardBackImageUploadResponse =
                    await axios.put(
                      employeeMediaUploadInfo.employeeAadhaarCardBackImageUploadUrl,
                      agentEmployeeUpdateDetails.employeeAadhaarcardBackImage
                    );

                  if (
                    employeeAadhaarCardBackImageUploadResponse.status === 200 ||
                    employeeAadhaarCardBackImageUploadResponse.statusText ===
                      "OK"
                  ) {
                    const employeeAadhaarCardBackImageUrl =
                      employeeMediaUploadInfo.employeeAadhaarCardBackImageUploadUrl;

                    const ParsedEmployeeAadhaarCardBackImageUrl = new URL(
                      employeeAadhaarCardBackImageUrl
                    );

                    const employeeAadhaarCardBackImagekey =
                      ParsedEmployeeAadhaarCardBackImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeeAadhaarCardBackImage =
                      employeeAadhaarCardBackImagekey;
                  }
                }
                break;

              case "Pan Card":
                if (employeeMediaUploadInfo.employeePanCardUploadUrl) {
                  const employeePanCardImageUploadResponse = await axios.put(
                    employeeMediaUploadInfo.employeePanCardUploadUrl,
                    agentEmployeeUpdateDetails.employeePancardImage
                  );

                  if (
                    employeePanCardImageUploadResponse.status === 200 ||
                    employeePanCardImageUploadResponse.statusText === "OK"
                  ) {
                    const employeePanCardImageUrl =
                      employeeMediaUploadInfo.employeePanCardUploadUrl;

                    const ParsedEmployeePanCardImageUrl = new URL(
                      employeePanCardImageUrl
                    );

                    const employeePanCardImagekey =
                      ParsedEmployeePanCardImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeePanCardImage =
                      employeePanCardImagekey;
                  }
                }
                break;

              case "Voter Card":
                if (
                  employeeMediaUploadInfo.employeeVoterCardFrontImageUploadUrl
                ) {
                  const employeeVoterCardFrontImageUploadResponse =
                    await axios.put(
                      employeeMediaUploadInfo.employeeVoterCardFrontImageUploadUrl,
                      agentEmployeeUpdateDetails.employeeVoterIdFrontImage
                    );

                  if (
                    employeeVoterCardFrontImageUploadResponse.status === 200 ||
                    employeeVoterCardFrontImageUploadResponse.statusText ===
                      "OK"
                  ) {
                    const employeeVoterCardFrontImageUrl =
                      employeeMediaUploadInfo.employeeVoterCardFrontImageUploadUrl;

                    const ParsedEmployeeVoterCardFrontImageUrl = new URL(
                      employeeVoterCardFrontImageUrl
                    );

                    const employeeVoterCardFrontImagekey =
                      ParsedEmployeeVoterCardFrontImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeeVoterCardFrontImage =
                      employeeVoterCardFrontImagekey;
                  }
                }
                if (
                  employeeMediaUploadInfo.employeeVoterCardBackImageUploadUrl
                ) {
                  const employeeVoterCardBackImageUploadResponse =
                    await axios.put(
                      employeeMediaUploadInfo.employeeVoterCardBackImageUploadUrl,
                      agentEmployeeUpdateDetails.employeeVoterIdBackImage
                    );

                  if (
                    employeeVoterCardBackImageUploadResponse.status === 200 ||
                    employeeVoterCardBackImageUploadResponse.statusText === "OK"
                  ) {
                    const employeeVoterCardBackImageUrl =
                      employeeMediaUploadInfo.employeeVoterCardBackImageUploadUrl;

                    const ParsedEmployeeVoterCardBackImageUrl = new URL(
                      employeeVoterCardBackImageUrl
                    );

                    const employeeVoterCardBackImagekey =
                      ParsedEmployeeVoterCardBackImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeeVoterCardBackImage =
                      employeeVoterCardBackImagekey;
                  }
                }
                break;

              case "Passport":
                if (employeeMediaUploadInfo.employeePassportImageUploadUrl) {
                  const employeePassportImageUploadResponse = await axios.put(
                    employeeMediaUploadInfo.employeePassportImageUploadUrl,
                    agentEmployeeUpdateDetails.employeePassportImage
                  );

                  if (
                    employeePassportImageUploadResponse.status === 200 ||
                    employeePassportImageUploadResponse.statusText === "OK"
                  ) {
                    const employeePassportImageUrl =
                      employeeMediaUploadInfo.employeePassportImageUploadUrl;

                    const ParsedEmployeePassportImageUrl = new URL(
                      employeePassportImageUrl
                    );

                    const employeePassportImagekey =
                      ParsedEmployeePassportImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeePassportImage =
                      employeePassportImagekey;
                  }
                }

                break;

              case "Driving License":
                if (
                  employeeMediaUploadInfo.employeeDrivingLicenseFrontImageUploadUrl
                ) {
                  const employeeDrivingLicenseFrontImageUploadResponse =
                    await axios.put(
                      employeeMediaUploadInfo.employeeDrivingLicenseFrontImageUploadUrl,
                      agentEmployeeUpdateDetails.employeeDrivinglicenseFrontImage
                    );

                  if (
                    employeeDrivingLicenseFrontImageUploadResponse.status ===
                      200 ||
                    employeeDrivingLicenseFrontImageUploadResponse.statusText ===
                      "OK"
                  ) {
                    const employeeDrivingLicenseFrontImageUrl =
                      employeeMediaUploadInfo.employeeDrivingLicenseFrontImageUploadUrl;

                    const ParsedEmployeeDrivingLicenseFrontImageUrl = new URL(
                      employeeDrivingLicenseFrontImageUrl
                    );

                    const employeeDrivingLicenseFrontImagekey =
                      ParsedEmployeeDrivingLicenseFrontImageUrl.pathname.slice(
                        1
                      );

                    employeeMediaUploadResponse.employeeDrivingLicenseFrontImage =
                      employeeDrivingLicenseFrontImagekey;
                  }
                }
                if (
                  employeeMediaUploadInfo.employeeDrivingLicenseBackImageUploadUrl
                ) {
                  const employeeDrivingLicenseBackImageUploadResponse =
                    await axios.put(
                      employeeMediaUploadInfo.employeeDrivingLicenseBackImageUploadUrl,
                      agentEmployeeUpdateDetails.employeeDrivinglicenseBackImage
                    );

                  if (
                    employeeDrivingLicenseBackImageUploadResponse.status ===
                      200 ||
                    employeeDrivingLicenseBackImageUploadResponse.statusText ===
                      "OK"
                  ) {
                    const employeeDrivingLicenseBackImageUrl =
                      employeeMediaUploadInfo.employeeDrivingLicenseBackImageUploadUrl;

                    const ParsedEmployeeDrivingLicenseBackImageUrl = new URL(
                      employeeDrivingLicenseBackImageUrl
                    );

                    const employeeDrivingLicenseBackImagekey =
                      ParsedEmployeeDrivingLicenseBackImageUrl.pathname.slice(
                        1
                      );

                    employeeMediaUploadResponse.employeeDrivingLicenseBackImage =
                      employeeDrivingLicenseBackImagekey;
                  }
                }
                break;

              default:
                break;
            }
          } catch (error) {
            console.error(`Error uploading employee media:`, error);
            throw error;
          }

          try {
            const savedEmployeeMediaKeys =
              Object.keys(employeeMediaUploadInfo).length > 1 &&
              (await axios.patch(
                `${baseURL}/api/profile/saveProfileFileKeys`,
                {
                  userType: "Salesperson",
                  employeeMediaUploadResponse: JSON.stringify(
                    employeeMediaUploadResponse
                  ),
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "access_token"
                    )}`,
                    "Content-Type": "application/json",
                  },
                }
              ));

            if (savedEmployeeMediaKeys) {
              message.success("Employee documents uploaded successfully");
            }
          } catch (err) {
            console.log("checking the issue", err);

            message.error("Error occured while saving images");
          }
        } catch (err) {
          message.error("Error occured while updating employee profile");
        } finally {
          setFetchLatestUserData(true);
          setIsAgentEditing(false);
          setShowUpdateAgentEmployeeBtn(false);
          setEditingAgentEmployeeIndex(null);
          setAgentEmployeeUpdateDetails({
            employeeUserId: "",
            employeeName: "",
            employeeEmail: "",
            employeeMobileNo: "",
            employeeDesignation: "",
            employeeIdType: "",
            employeeID: "",
            employeeAadhaarCardNo: "",
            employeePanCardNo: "",
            employeeVoterCardNo: "",
            employeePassportNo: "",
            employeeDrivingLicenseNo: "",
            employeeImage: null,
            employeementLetterImage: null,
            employeeIdCardImage: null,
            employeeAadhaarcardFrontImage: null,
            employeeAadhaarcardBackImage: null,
            employeePancardImage: null,
            employeeVoterIdFrontImage: null,
            employeeVoterIdBackImage: null,
            employeePassportImage: null,
            employeeDrivinglicenseFrontImage: null,
            employeeDrivinglicenseBackImage: null,
          });
        }
      }
    } catch (err) {
      console.log("error occured while checking duplicate credentials", err);
      message.error(err.response.data.message);
    }
  };

  const handleAgentEmployeeUpdateMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setAgentEmployeeUpdateDetails((prev) => ({
        ...prev,
        employeeMobileNo: value,
      }));
    }
  };
  const formatAgentEmployeeUpdateMobileDisplay = (employeeMobileNo) => {
    const formatted = employeeMobileNo.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const handleAgentEmployeeUpdateIdTypeChange = (value) => {
    setAgentEmployeeUpdateDetails((prev) => ({
      ...prev,
      employeeIdType: value,
      ...resetEmployeeIdProofDetails(),
    }));
  };
  const handleAgentEmployeeUpdateAadhaarCardNo = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 4);
    setAgentEmployeeUpdateDetails((prev) => ({
      ...prev,
      employeeAadhaarCardNo: numericValue,
    }));
  };

  useMemo(() => {
    if (selectedSection !== "Employees") {
      switch (userType) {
        case "Agent":
          setIsAgentEditing(false);
          setShowUpdateAgentEmployeeBtn(false);
          break;
        case "Builder":
          setIsBuilderEditing(false);
          setShowBuilderUpdateEmployeeBtn(false);
          break;
        default:
          break;
      }
    }
  }, [selectedSection, userType]);

  // Agent Employees
  const [agentEmployeeDetails, setAgentEmployeeDetails] = useState({
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeDesignation: "",
    employeeIdType: "",
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeeImage: null,
    employeementLetterImage: null,
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });

  // Agent Employee Mobile
  const handleAgentEmployeeMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setAgentEmployeeDetails((prev) => ({
        ...prev,
        employeeMobileNo: value,
      }));
    }
  };
  const formatAgentEmployeeMobileDisplay = (employeeMobileNo) => {
    const formatted = employeeMobileNo.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };

  const resetEmployeeIdProofDetails = () => ({
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });
  const handleAgentEmployeeIdTypeChange = (value) => {
    setAgentEmployeeDetails((prev) => ({
      ...prev,
      employeeIdType: value,
      ...resetEmployeeIdProofDetails(),
    }));
  };
  // Agent Employee Aadhaar Card
  const handleAgentEmployeeAadhaarCardNo = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 4);
    setAgentEmployeeDetails((prev) => ({
      ...prev,
      employeeAadhaarCardNo: numericValue,
    }));
  };
  // Agent Added Employees List
  const [agentAddedEmployeesList, setAgentAddedEmployeesList] = useState([]);
  const [agentTempAddedEmployeesList, setAgentTempAddedEmployeesList] =
    useState([]);
  const addAgentEmployee = async () => {
    if (
      agentEmployeeSchema.safeParse({
        employeeFullName: agentEmployeeDetails.employeeName,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeFullName: agentEmployeeDetails.employeeName,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee name");
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeDesignation: agentEmployeeDetails.employeeDesignation,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeDesignation: agentEmployeeDetails.employeeDesignation,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee designation"
      );
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeEmail: agentEmployeeDetails.employeeEmail,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeEmail: agentEmployeeDetails.employeeEmail,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee email");
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeMobileNo: agentEmployeeDetails.employeeMobileNo,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeMobileNo: agentEmployeeDetails.employeeMobileNo,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee mobile no"
      );
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeIdType: agentEmployeeDetails.employeeIdType,
      }).success === false
    ) {
      message.error("Select atleast one employee id type");
      return;
    }

    if (agentEmployeeDetails.employeeIdType === "Employee ID Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeeID: agentEmployeeDetails.employeeID,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeID: agentEmployeeDetails.employeeID,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Employee ID");
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeIdCardImage) {
        message.error("Employee id Image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Aadhaar Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeeAadhaarCardNo: agentEmployeeDetails.employeeAadhaarCardNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeAadhaarCardNo: agentEmployeeDetails.employeeAadhaarCardNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(
          /^String/,
          "Aadhaar card no."
        );
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeAadhaarcardFrontImage) {
        message.error("Employee aadhaar card front image required");
        return;
      }

      if (!agentEmployeeDetails.employeeAadhaarcardBackImage) {
        message.error("Employee aadhaar card back image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Pan Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeePanCardNo: agentEmployeeDetails.employeePanCardNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeePanCardNo: agentEmployeeDetails.employeePanCardNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Pan card no.");
        message.error(customMessage);
        return;
      }

      if (!agentEmployeeDetails.employeePancardImage) {
        message.error("Employee pancard image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Voter Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeeVoterCardNo: agentEmployeeDetails.employeeVoterCardNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeVoterCardNo: agentEmployeeDetails.employeeVoterCardNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Voter card no.");
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeVoterIdFrontImage) {
        message.error("Employee voter card front image required");
        return;
      }

      if (!agentEmployeeDetails.employeeVoterIdBackImage) {
        message.error("Employee voter card back image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Passport") {
      if (
        agentEmployeeSchema.safeParse({
          employeePassportNo: agentEmployeeDetails.employeePassportNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeePassportNo: agentEmployeeDetails.employeePassportNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Passport no.");
        message.error(customMessage);
        return;
      }

      if (!agentEmployeeDetails.employeePassportImage) {
        message.error("Employee passport image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Driving License") {
      if (
        agentEmployeeSchema.safeParse({
          employeeDrivingLicenseNo:
            agentEmployeeDetails.employeeDrivingLicenseNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeDrivingLicenseNo:
            agentEmployeeDetails.employeeDrivingLicenseNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(
          /^String/,
          "Driving license no."
        );
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeDrivinglicenseFrontImage) {
        message.error("Employee driving license front image required");
        return;
      }

      if (!agentEmployeeDetails.employeeDrivinglicenseBackImage) {
        message.error("Employee driving license back image required");
        return;
      }
    }

    if (!agentEmployeeDetails.employeeImage) {
      message.error("Employee image required");
      return;
    }

    if (
      agentAddedEmployeesList.length > 0 &&
      agentAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === agentEmployeeDetails.employeeEmail ||
          employee.employeeMobileNo ===
            agentEmployeeDetails.employeeMobileNo.replace(
              /(\d{2})(\d{0,10})/,
              "+$1 $2"
            )
      )
    ) {
      const identifier = agentAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === agentEmployeeDetails.employeeEmail
      )
        ? "email"
        : agentAddedEmployeesList.some(
            (employee) =>
              employee.employeeMobileNo ===
              agentEmployeeDetails.employeeMobileNo.replace(
                /(\d{2})(\d{0,10})/,
                "+$1 $2"
              )
          )
        ? "mobile no"
        : "credentials";
      message.error(
        `Employee with this ${identifier} already exists in the employee list`
      );
      return;
    }

    const formData = new FormData();
    formData.append("userAccessId", userId);
    if (userType === "Agent") {
      formData.append("employeeName", agentEmployeeDetails.employeeName);
      formData.append("employeeEmail", agentEmployeeDetails.employeeEmail);
      formData.append(
        "employeeMobileNo",
        agentEmployeeDetails.employeeMobileNo
      );
      formData.append(
        "selectedEmployeeIdType",
        agentEmployeeDetails.employeeIdType
      );
      formData.append(
        "employeeDesignation",
        agentEmployeeDetails.employeeDesignation
      );
      let employeeDocuments;
      if (agentEmployeeDetails.employeeIdType === "Employee ID Card") {
        formData.append("employeeID", agentEmployeeDetails.employeeID);

        if (agentEmployeeDetails.employeementLetterImage) {
          employeeDocuments = {
            employeeImage: true,
            employeeIdCardImage: true,
            employeementLetterImage: true,
          };
        } else {
          employeeDocuments = {
            employeeImage: true,
            employeeIdCardImage: true,
          };
        }
      }

      if (agentEmployeeDetails.employeeIdType === "Aadhaar Card") {
        formData.append(
          "employeeAadhaarcardNo",
          agentEmployeeDetails.employeeAadhaarCardNo
        );
        if (agentEmployeeDetails.employeementLetterImage) {
          employeeDocuments = {
            employeeImage: true,
            employeementLetterImage: true,
            employeeAadhaarCardFrontImage: true,
            employeeAadhaarCardBackImage: true,
          };
        } else {
          employeeDocuments = {
            employeeImage: true,
            employeeAadhaarCardFrontImage: true,
            employeeAadhaarCardBackImage: true,
          };
        }
      }

      if (agentEmployeeDetails.employeeIdType === "Pan Card") {
        formData.append(
          "employeePancardNo",
          agentEmployeeDetails.employeePanCardNo
        );
        if (agentEmployeeDetails.employeementLetterImage) {
          employeeDocuments = {
            employeeImage: true,
            employeementLetterImage: true,
            employeePanCardImage: true,
          };
        } else {
          employeeDocuments = {
            employeeImage: true,
            employeePanCardImage: true,
          };
        }
      }

      if (agentEmployeeDetails.employeeIdType === "Voter Card") {
        formData.append(
          "employeeVoterIdNo",
          agentEmployeeDetails.employeeVoterCardNo
        );
        if (agentEmployeeDetails.employeementLetterImage) {
          employeeDocuments = {
            employeeImage: true,
            employeementLetterImage: true,
            employeeVoterCardFrontImage: true,
            employeeVoterCardBackImage: true,
          };
        } else {
          employeeDocuments = {
            employeeImage: true,
            employeeVoterCardFrontImage: true,
            employeeVoterCardBackImage: true,
          };
        }
      }

      if (agentEmployeeDetails.employeeIdType === "Passport") {
        formData.append(
          "employeePassportNo",
          agentEmployeeDetails.employeePassportNo
        );
        if (agentEmployeeDetails.employeementLetterImage) {
          employeeDocuments = {
            employeeImage: true,
            employeementLetterImage: true,
            employeePassportImage: true,
          };
        } else {
          employeeDocuments = {
            employeeImage: true,
            employeePassportImage: true,
          };
        }
      }

      if (agentEmployeeDetails.employeeIdType === "Driving License") {
        formData.append(
          "employeeDrivinglicenseNo",
          agentEmployeeDetails.employeeDrivingLicenseNo
        );
        if (agentEmployeeDetails.employeementLetterImage) {
          employeeDocuments = {
            employeeImage: true,
            employeementLetterImage: true,
            employeeDrivingLicenseFrontImage: true,
            employeeDrivingLicenseBackImage: true,
          };
        } else {
          employeeDocuments = {
            employeeImage: true,
            employeeDrivingLicenseFrontImage: true,
            employeeDrivingLicenseBackImage: true,
          };
        }
      }

      formData.append(`employeeDocuments`, JSON.stringify(employeeDocuments));
    }

    try {
      message.info("Sending invitation request");
      setLoading(true);

      const invitationResponse = await axios.post(
        `${baseURL}/api/mail/sendMailUsingS3`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (invitationResponse.data.recruitmentStatus === "Invitation sent") {
        message.open({
          type: "success",
          content: invitationResponse.data.message,
        });

        if (
          userType === "Agent" &&
          invitationResponse?.data?.employeeDocumentUploadUrls
        ) {
          const employeeDocumentUploadUrls = JSON.parse(
            invitationResponse?.data?.employeeDocumentUploadUrls
          );
          const employeeMediaUploadResponse = {};

          try {
            employeeMediaUploadResponse.employeeID =
              employeeDocumentUploadUrls.employeeID;

            if (employeeDocumentUploadUrls.employeementLetterUploadUrl) {
              const employeementLetterUploadResponse = await axios.put(
                employeeDocumentUploadUrls.employeementLetterUploadUrl,
                agentEmployeeDetails.employeementLetterImage
              );

              if (
                employeementLetterUploadResponse.status === 200 ||
                employeementLetterUploadResponse.statusText === "OK"
              ) {
                const employeementLetterUrl =
                  employeeDocumentUploadUrls.employeementLetterUploadUrl;

                const parsedEmployeementLetterUrl = new URL(
                  employeementLetterUrl
                );

                const employeementLetterkey =
                  parsedEmployeementLetterUrl.pathname.slice(1);

                employeeMediaUploadResponse.employeementLetter =
                  employeementLetterkey;
              }
            }

            if (employeeDocumentUploadUrls.employeeImageUploadUrl) {
              const employeeImageUploadResponse = await axios.put(
                employeeDocumentUploadUrls.employeeImageUploadUrl,
                agentEmployeeDetails.employeeImage
              );

              if (
                employeeImageUploadResponse.status === 200 ||
                employeeImageUploadResponse.statusText === "OK"
              ) {
                const employeeImageUrl =
                  employeeDocumentUploadUrls.employeeImageUploadUrl;

                const parsedEmployeeImageUrl = new URL(employeeImageUrl);

                const employeeImagekey =
                  parsedEmployeeImageUrl.pathname.slice(1);

                employeeMediaUploadResponse.employeeImage = employeeImagekey;
              }
            }

            if (agentEmployeeDetails.employeeIdType === "Employee ID Card") {
              if (employeeDocumentUploadUrls.employeeIdCardImageUrl) {
                const employeeIdCardImageUploadResponse = await axios.put(
                  employeeDocumentUploadUrls.employeeIdCardImageUrl,
                  agentEmployeeDetails.employeeIdCardImage
                );

                if (
                  employeeIdCardImageUploadResponse.status === 200 ||
                  employeeIdCardImageUploadResponse.statusText === "OK"
                ) {
                  const employeeIdCardImageUrl =
                    employeeDocumentUploadUrls.employeeIdCardImageUrl;

                  const parsedEmployeeIdCardImageUrl = new URL(
                    employeeIdCardImageUrl
                  );

                  const employeeIdCardImagekey =
                    parsedEmployeeIdCardImageUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeeIdCardImage =
                    employeeIdCardImagekey;
                }
              }
            }
            if (agentEmployeeDetails.employeeIdType === "Aadhaar Card") {
              if (employeeDocumentUploadUrls.employeeAadhaarCardFrontImageUrl) {
                const employeeAadhaarCardFrontImageUploadResponse =
                  await axios.put(
                    employeeDocumentUploadUrls.employeeAadhaarCardFrontImageUrl,
                    agentEmployeeDetails.employeeAadhaarcardFrontImage
                  );

                if (
                  employeeAadhaarCardFrontImageUploadResponse.status === 200 ||
                  employeeAadhaarCardFrontImageUploadResponse.statusText ===
                    "OK"
                ) {
                  const employeeAadhaarCardFrontImageUrl =
                    employeeDocumentUploadUrls.employeeAadhaarCardFrontImageUrl;

                  const ParsedEmployeeAadhaarCardFrontImageUrl = new URL(
                    employeeAadhaarCardFrontImageUrl
                  );

                  const employeeAadhaarCardFrontImagekey =
                    ParsedEmployeeAadhaarCardFrontImageUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeeAadhaarCardFrontImage =
                    employeeAadhaarCardFrontImagekey;
                }
              }
              if (employeeDocumentUploadUrls.employeeAadhaarCardBackImageUrl) {
                const employeeAadhaarCardBackImageUploadResponse =
                  await axios.put(
                    employeeDocumentUploadUrls.employeeAadhaarCardBackImageUrl,
                    agentEmployeeDetails.employeeAadhaarcardBackImage
                  );

                if (
                  employeeAadhaarCardBackImageUploadResponse.status === 200 ||
                  employeeAadhaarCardBackImageUploadResponse.statusText === "OK"
                ) {
                  const employeeAadhaarCardBackImageUrl =
                    employeeDocumentUploadUrls.employeeAadhaarCardBackImageUrl;

                  const ParsedEmployeeAadhaarCardBackImageUrl = new URL(
                    employeeAadhaarCardBackImageUrl
                  );

                  const employeeAadhaarCardBackImagekey =
                    ParsedEmployeeAadhaarCardBackImageUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeeAadhaarCardBackImage =
                    employeeAadhaarCardBackImagekey;
                }
              }
            }
            if (agentEmployeeDetails.employeeIdType === "Pan Card") {
              if (employeeDocumentUploadUrls.employeePanCardImageUrl) {
                const employeePanCardImageUploadResponse = await axios.put(
                  employeeDocumentUploadUrls.employeePanCardImageUrl,
                  agentEmployeeDetails.employeePancardImage
                );

                if (
                  employeePanCardImageUploadResponse.status === 200 ||
                  employeePanCardImageUploadResponse.statusText === "OK"
                ) {
                  const employeePanCardImageUrl =
                    employeeDocumentUploadUrls.employeePanCardImageUrl;

                  const ParsedEmployeePanCardImageUrl = new URL(
                    employeePanCardImageUrl
                  );

                  const employeePanCardImagekey =
                    ParsedEmployeePanCardImageUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeePanCardImage =
                    employeePanCardImagekey;
                }
              }
            }

            if (agentEmployeeDetails.employeeIdType === "Voter Card") {
              if (employeeDocumentUploadUrls.employeeVoterCardFrontImageUrl) {
                const employeeVoterCardFrontImageUploadResponse =
                  await axios.put(
                    employeeDocumentUploadUrls.employeeVoterCardFrontImageUrl,
                    agentEmployeeDetails.employeeVoterIdFrontImage
                  );

                if (
                  employeeVoterCardFrontImageUploadResponse.status === 200 ||
                  employeeVoterCardFrontImageUploadResponse.statusText === "OK"
                ) {
                  const employeeVoterCardFrontImageUrl =
                    employeeDocumentUploadUrls.employeeVoterCardFrontImageUrl;

                  const ParsedEmployeeVoterCardFrontImageUrl = new URL(
                    employeeVoterCardFrontImageUrl
                  );

                  const employeeVoterCardFrontImagekey =
                    ParsedEmployeeVoterCardFrontImageUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeeVoterCardFrontImage =
                    employeeVoterCardFrontImagekey;
                }
              }
              if (employeeDocumentUploadUrls.employeeVoterCardBackImageUrl) {
                const employeeVoterCardBackImageUploadResponse =
                  await axios.put(
                    employeeDocumentUploadUrls.employeeVoterCardBackImageUrl,
                    agentEmployeeDetails.employeeVoterIdBackImage
                  );

                if (
                  employeeVoterCardBackImageUploadResponse.status === 200 ||
                  employeeVoterCardBackImageUploadResponse.statusText === "OK"
                ) {
                  const employeeVoterCardBackImageUrl =
                    employeeDocumentUploadUrls.employeeVoterCardBackImageUrl;

                  const ParsedEmployeeVoterCardBackImageUrl = new URL(
                    employeeVoterCardBackImageUrl
                  );

                  const employeeVoterCardBackImagekey =
                    ParsedEmployeeVoterCardBackImageUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeeVoterCardBackImage =
                    employeeVoterCardBackImagekey;
                }
              }
            }
            if (agentEmployeeDetails.employeeIdType === "Passport") {
              if (employeeDocumentUploadUrls.employeePassportImageUrl) {
                const employeePassportImageUploadResponse = await axios.put(
                  employeeDocumentUploadUrls.employeePassportImageUrl,
                  agentEmployeeDetails.employeePassportImage
                );

                if (
                  employeePassportImageUploadResponse.status === 200 ||
                  employeePassportImageUploadResponse.statusText === "OK"
                ) {
                  const employeePassportImageUrl =
                    employeeDocumentUploadUrls.employeePassportImageUrl;

                  const ParsedEmployeePassportImageUrl = new URL(
                    employeePassportImageUrl
                  );

                  const employeePassportImagekey =
                    ParsedEmployeePassportImageUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeePassportImage =
                    employeePassportImagekey;
                }
              }
            }
            if (agentEmployeeDetails.employeeIdType === "Driving License") {
              if (
                employeeDocumentUploadUrls.employeeDrivingLicenseFrontImageUrl
              ) {
                const employeeDrivingLicenseFrontImageUploadResponse =
                  await axios.put(
                    employeeDocumentUploadUrls.employeeDrivingLicenseFrontImageUrl,
                    agentEmployeeDetails.employeeDrivinglicenseFrontImage
                  );

                if (
                  employeeDrivingLicenseFrontImageUploadResponse.status ===
                    200 ||
                  employeeDrivingLicenseFrontImageUploadResponse.statusText ===
                    "OK"
                ) {
                  const employeeDrivingLicenseFrontImageUrl =
                    employeeDocumentUploadUrls.employeeDrivingLicenseFrontImageUrl;

                  const ParsedEmployeeDrivingLicenseFrontImageUrl = new URL(
                    employeeDrivingLicenseFrontImageUrl
                  );

                  const employeeDrivingLicenseFrontImagekey =
                    ParsedEmployeeDrivingLicenseFrontImageUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeeDrivingLicenseFrontImage =
                    employeeDrivingLicenseFrontImagekey;
                }
              }
              if (
                employeeDocumentUploadUrls.employeeDrivingLicenseBackImageUrl
              ) {
                const employeeDrivingLicenseBackImageUploadResponse =
                  await axios.put(
                    employeeDocumentUploadUrls.employeeDrivingLicenseBackImageUrl,
                    agentEmployeeDetails.employeeDrivinglicenseBackImage
                  );

                if (
                  employeeDrivingLicenseBackImageUploadResponse.status ===
                    200 ||
                  employeeDrivingLicenseBackImageUploadResponse.statusText ===
                    "OK"
                ) {
                  const employeeDrivingLicenseBackImageUrl =
                    employeeDocumentUploadUrls.employeeDrivingLicenseBackImageUrl;

                  const ParsedEmployeeDrivingLicenseBackImageUrl = new URL(
                    employeeDrivingLicenseBackImageUrl
                  );

                  const employeeDrivingLicenseBackImagekey =
                    ParsedEmployeeDrivingLicenseBackImageUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeeDrivingLicenseBackImage =
                    employeeDrivingLicenseBackImagekey;
                }
              }
            }
          } catch (error) {
            console.error(`Error uploading employee media:`, error);
            throw error;
          }

          try {
            const savedEmployeeMediaKeys = await axios.patch(
              `${baseURL}/api/mail/saveEmployeeProfileFileKeys`,
              {
                userId,
                employeeMediaUploadResponse: JSON.stringify(
                  employeeMediaUploadResponse
                ),
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (savedEmployeeMediaKeys) {
              message.success(savedEmployeeMediaKeys.data.message);
            }
          } catch (err) {
            console.log("checking the issue", err);

            message.error("Error occured while saving images");
          }
        }
      } else if (
        invitationResponse.data.recruitmentStatus === "Already recruited" ||
        invitationResponse.data.recruitmentStatus === "Already invited" ||
        invitationResponse.data.recruitmentStatus === "Employee Profile Exists"
      ) {
        message.open({
          type: "info",
          content: invitationResponse.data.message,
        });
        setLoading(false);
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);

      setAgentEmployeeDetails({
        employeeName: "",
        employeeEmail: "",
        employeeMobileNo: "",
        employeeDesignation: "",
        employeeIdType: "",
        employeeID: "",
        employeeAadhaarCardNo: "",
        employeePanCardNo: "",
        employeeVoterCardNo: "",
        employeePassportNo: "",
        employeeDrivingLicenseNo: "",
        employeeImage: null,
        employeementLetterImage: null,
        employeeIdCardImage: null,
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,
        employeePancardImage: null,
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
        employeePassportImage: null,
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,
      });
    } catch (err) {
      message.error(err.response?.data?.message);
      return;
    } finally {
      setFetchLatestUserData(true);
      setLoading(false);
    }
  };
  const removeAgentEmployee = (index) => {
    setAgentAddedEmployeesList((prev) => prev.filter((_, i) => i !== index));
  };
  const renderAgentEmployeeImage = (image, placeholderText) => {
    if (image && typeof image !== "string") {
      return URL.createObjectURL(image);
    } else if (image && typeof image === "string") {
      return image;
    } else {
      return `https://placehold.co/50x50?text=${placeholderText}`;
    }
  };
  const agentEmployeesForm = (
    <>
      <div className="utr-form-category">
        <h4> Employee Details</h4>
        <div className="utr-form-inputs">
          <FormInput
            icon={MdDriveFileRenameOutline}
            placeholder="Employee Full Name"
            value={agentEmployeeDetails.employeeName}
            onChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeName: e.target.value,
              }))
            }
          />
          <FormInput
            icon={LiaUserTagSolid}
            placeholder="Employee Designation"
            value={agentEmployeeDetails.employeeDesignation}
            onChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeDesignation: e.target.value,
              }))
            }
          />
          <FormInput
            icon={MdOutlineEmail}
            placeholder="Employee Email"
            value={agentEmployeeDetails.employeeEmail}
            onChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeEmail: e.target.value,
              }))
            }
          />
          <FormInput
            icon={MdOutlinePhoneEnabled}
            placeholder="Employee Mobile No."
            value={formatAgentEmployeeMobileDisplay(
              agentEmployeeDetails.employeeMobileNo
            )}
            onChange={handleAgentEmployeeMobileChange}
            maxLength="14"
          />
          <div className="utr-form-input-cover">
            {agentEmployeeDetails.employeeIdType ? (
              <h6 className="utr-form-input-cover-h6-select">Select ID Type</h6>
            ) : (
              <h6 className="utr-form-input-cover-h6-select red">* Required</h6>
            )}
            <div className="utr-form-input">
              <HiOutlineDocumentReport
                className={`utr-form-input-icon ${
                  agentEmployeeDetails.employeeIdType ? "selected" : ""
                }`}
              />
              <Select
                showSearch
                name="agentEmployeeIdType"
                className="utr-form-input-select"
                placeholder="Select ID Type"
                value={agentEmployeeDetails.employeeIdType || undefined}
                optionFilterProp="label"
                onChange={handleAgentEmployeeIdTypeChange}
                options={[
                  {
                    value: "Employee ID Card",
                    label: "Employee ID Card",
                  },
                  {
                    value: "Aadhaar Card",
                    label: "Employee Aadhaar Card",
                  },
                  {
                    value: "Pan Card",
                    label: "Employee Pan Card",
                  },
                  {
                    value: "Voter Card",
                    label: "Employee Voter Card",
                  },
                  {
                    value: "Passport",
                    label: "Employee Passport",
                  },
                  {
                    value: "Driving License",
                    label: "Employee Driving License",
                  },
                ]}
              />
            </div>
          </div>
          {agentEmployeeDetails.employeeIdType === "Employee ID Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Employee ID"
              value={agentEmployeeDetails.employeeID}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeID: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Aadhaar Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Aadhaar Card No. (last 4 digits)"
              value={agentEmployeeDetails.employeeAadhaarCardNo}
              onChange={handleAgentEmployeeAadhaarCardNo}
              maxLength="4"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Pan Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Pan Card No."
              value={agentEmployeeDetails.employeePanCardNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePanCardNo: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Voter Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Voter Card No."
              value={agentEmployeeDetails.employeeVoterCardNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeVoterCardNo: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Passport" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Passport No."
              value={agentEmployeeDetails.employeePassportNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePassportNo: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Driving License" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Driving License No."
              value={agentEmployeeDetails.employeeDrivingLicenseNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeDrivingLicenseNo: e.target.value,
                }))
              }
            />
          )}
        </div>
      </div>
      <div className="utr-form-category">
        <h4>Employee Photos</h4>
        <div className="utr-form-images">
          <ImageUpload
            image={agentEmployeeDetails.employeeImage}
            onImageChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeImage: e.target.files[0],
              }))
            }
            onRemove={() =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeImage: null,
              }))
            }
            label="Employee Photo"
          />
          <ImageUpload
            required={false}
            image={agentEmployeeDetails.employeementLetterImage}
            onImageChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeementLetterImage: e.target.files[0],
              }))
            }
            onRemove={() =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeementLetterImage: null,
              }))
            }
            label="Employeement Letter"
          />
          {agentEmployeeDetails.employeeIdType === "Employee ID Card" && (
            <ImageUpload
              image={agentEmployeeDetails.employeeIdCardImage}
              onImageChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: null,
                }))
              }
              label="ID Card"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Aadhaar Card" && (
            <>
              <ImageUpload
                image={agentEmployeeDetails.employeeAadhaarcardFrontImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardFrontImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardFrontImage: null,
                  }))
                }
                label="Aadhaar Front"
              />
              <ImageUpload
                image={agentEmployeeDetails.employeeAadhaarcardBackImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardBackImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardBackImage: null,
                  }))
                }
                label="Aadhaar Back"
              />
            </>
          )}
          {agentEmployeeDetails.employeeIdType === "Pan Card" && (
            <ImageUpload
              image={agentEmployeeDetails.employeePancardImage}
              onImageChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePancardImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePancardImage: null,
                }))
              }
              label="Pan Card"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Voter Card" && (
            <>
              <ImageUpload
                image={agentEmployeeDetails.employeeVoterIdFrontImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdFrontImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdFrontImage: null,
                  }))
                }
                label="Voter Front"
              />
              <ImageUpload
                image={agentEmployeeDetails.employeeVoterIdBackImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdBackImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdBackImage: null,
                  }))
                }
                label="Voter Back"
              />
            </>
          )}
          {agentEmployeeDetails.employeeIdType === "Passport" && (
            <ImageUpload
              image={agentEmployeeDetails.employeePassportImage}
              onImageChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePassportImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePassportImage: null,
                }))
              }
              label="Passport"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Driving License" && (
            <>
              <ImageUpload
                image={agentEmployeeDetails.employeeDrivinglicenseFrontImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseFrontImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseFrontImage: null,
                  }))
                }
                label="Driving License Front"
              />
              <ImageUpload
                image={agentEmployeeDetails.employeeDrivinglicenseBackImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseBackImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseBackImage: null,
                  }))
                }
                label="Driving License Back"
              />
            </>
          )}
        </div>
        <button
          className="utr-add-project-employee-add"
          onClick={addAgentEmployee}
        >
          Add Employee
        </button>
      </div>
    </>
  );

  // Agent Employees Reset
  const confirmAgentEmployeesReset = (e) => {
    message.success("Employees Data Reset success!");
    setAgentEmployeeDetails({
      employeeName: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeDesignation: "",
      employeeIdType: "",
      employeeID: "",
      employeeAadhaarCardNo: "",
      employeePanCardNo: "",
      employeeVoterCardNo: "",
      employeePassportNo: "",
      employeeDrivingLicenseNo: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
      employeeAadhaarcardFrontImage: null,
      employeeAadhaarcardBackImage: null,
      employeePancardImage: null,
      employeeVoterIdFrontImage: null,
      employeeVoterIdBackImage: null,
      employeePassportImage: null,
      employeeDrivinglicenseFrontImage: null,
      employeeDrivinglicenseBackImage: null,
    });
  };
  const cancelAgentEmployeesReset = (e) => {
    message.error("Employees reset cancelled!");
  };

  const agentEmployeesCollapse = [
    {
      key: "1",
      label: `${agentAddedEmployeesList.length} ${
        agentAddedEmployeesList.length === 1 ? "Employee" : "Employees"
      } added`,
      children: agentEmployeesForm,
      extra: (
        <Popconfirm
          title="Reset Employee Data"
          description="Are you sure to reset all employee data?"
          onConfirm={confirmAgentEmployeesReset}
          onCancel={cancelAgentEmployeesReset}
          okText="Reset"
          cancelText="Cancel"
        >
          <GrPowerReset className="utr-collapse-icon" />
        </Popconfirm>
      ),
    },
  ];

  const [userData, setUserData] = useState(null);
  const [userProfileData, setUserProfileData] = useState(null);
  const [allUserData, setAllUserData] = useState(null);

  const [employeerDetails, setEmployeerDetails] = useState(null);
  const [rejectedReasons, setRejectedReasons] = useState(null);
  // const [userProfileDataWithEmployeeList, setUserProfileDataWithEmployeeList] =
  //   useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      const rrdata = true;
      const alldetails = true;
      const _userProfileData = await axios.get(
        `${baseURL}/api/profile/getUserDetailsById/${userId}?rrdata=${rrdata}&alldetails=${alldetails}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setUserProfileData(_userProfileData.data?.profileData);
      setAllUserData(_userProfileData.data);
      setEmployeerDetails(_userProfileData?.data?.employeer);
      setRejectedReasons(_userProfileData?.data?.rejectedReasons);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [baseURL, userId]);
  useMemo(() => {
    if (userId && baseURL && !fetchLatestUserData) {
      fetchUserData();
    }
    return () => setFetchLatestUserData(false);
  }, [userId, baseURL, fetchUserData, fetchLatestUserData]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useMemo(async () => {
    setUserData(userDetails?.user);
  }, [userDetails]);

  useMemo(() => {
    if (userData && userType === "Buyer") {
      if (userData.name) {
        setFullName(userData?.name);
      }
      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setEmail(userData?.email);
      }
      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setMobile(userData.mobileNumber);
      }
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setWhatsappMobile(userData.whatsappNumber);
      }
      if (userProfileData) {
        setProfileImage(userProfileData?.profilePhoto);
      }
    }
    if (userData && userType === "Owner") {
      if (userData.name) {
        setFullName(userData?.name);
      }
      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setEmail(userData?.email);
      }
      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setMobile(userData.mobileNumber);
      }
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setWhatsappMobile(userData.whatsappNumber);
      }
      if (userProfileData) {
        setProfileImage(userProfileData?.profilePhoto);
      }
    }
    if (userData && userType === "Builder") {
      if (userData.name) {
        setFullName(userData?.name);
      }
      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setEmail(userData?.email);
      }
      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setMobile(userData.mobileNumber);
      }
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setWhatsappMobile(userData.whatsappNumber);
      }

      // -----------------------------------------------------------------------
      if (userProfileData) {
        setProfileImage(userProfileData?.profilePhoto);
      }
      if (userProfileData) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderFirmLogo: userProfileData?.firmLogo,
        }));
      }
      if (userProfileData) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderFirmName: userProfileData?.firmName,
        }));
      }

      if (userProfileData) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderReraNo: userProfileData?.reraNo,
        }));
      }

      if (userProfileData) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderReraNoType: userProfileData?.reraNoType,
        }));
      }

      if (userProfileData) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderAddress: userProfileData?.firmAddress,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.hasOwnProperty("officeName") &&
        userProfileData?.officeName !== "undefined"
      ) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderOfficeName: userProfileData?.officeName,
        }));
      }

      if (userProfileData) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderOfficeCity: userProfileData?.officeCity,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.hasOwnProperty("officeWebsite") &&
        userProfileData?.officeWebsite !== "undefined"
      ) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderOfficeWebsite: userProfileData?.officeWebsite,
        }));
      }

      if (userProfileData) {
        if (
          userProfileData?.userType === "Builder" &&
          allUserData?.builderEmployeesList
        ) {
          setBuilderAddedEmployeesList(
            JSON.parse(allUserData?.builderEmployeesList)
          );
        }
        if (
          userProfileData?.userType === "Builder" &&
          allUserData?.builderTempEmployeesList
        ) {
          setBuilderTempAddedEmployeesList(
            JSON.parse(allUserData?.builderTempEmployeesList)
          );
        }
      }
    }
    if (userData && userType === "Agent") {
      if (userData.name) {
        setFullName(userData?.name);
      }
      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setEmail(userData?.email);
      }
      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setMobile(userData.mobileNumber);
      }
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setWhatsappMobile(userData.whatsappNumber);
      }
      if (userProfileData) {
        setProfileImage(userProfileData?.profilePhoto);
      }
      if (userProfileData) {
        setAgentDetails((prev) => ({
          ...prev,
          agentFirmLogo: userProfileData?.firmLogo,
        }));
      }
      if (userProfileData) {
        setAgentDetails((prev) => ({
          ...prev,
          agentFirmName: userProfileData?.firmName,
        }));
      }

      if (userProfileData) {
        setAgentDetails((prev) => ({
          ...prev,
          agentReraNo: userProfileData?.reraNo,
        }));
      }

      if (userProfileData) {
        setAgentDetails((prev) => ({
          ...prev,
          agentReraNoType: userProfileData?.reraNoType,
        }));
      }

      if (userProfileData) {
        setAgentDetails((prev) => ({
          ...prev,
          agentAddress: userProfileData?.firmAddress,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.hasOwnProperty("officeName") &&
        userProfileData?.profileData?.officeName !== "undefined"
      ) {
        setAgentDetails((prev) => ({
          ...prev,
          agentOfficeName: userProfileData?.officeName,
        }));
      }

      if (userProfileData) {
        setAgentDetails((prev) => ({
          ...prev,
          dealsIn: JSON.parse(userProfileData?.dealsIn),
        }));
      }
      if (userProfileData) {
        setAgentDetails((prev) => ({
          ...prev,
          agentOfficeCity: userProfileData?.officeCity,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.hasOwnProperty("officeWebsite") &&
        userProfileData?.officeWebsite !== "undefined"
      ) {
        setAgentDetails((prev) => ({
          ...prev,
          agentOfficeWebsite: userProfileData?.officeWebsite,
        }));
      }

      if (userProfileData) {
        if (
          userProfileData?.userType === "Agent" &&
          allUserData?.agentEmployeesList
        ) {
          setAgentAddedEmployeesList(
            JSON.parse(allUserData?.agentEmployeesList)
          );
        }
        if (
          userProfileData?.userType === "Agent" &&
          allUserData?.agentTempEmployeesList
        ) {
          setAgentTempAddedEmployeesList(
            JSON.parse(allUserData?.agentTempEmployeesList)
          );
        }
      }
    }
    if (userData && userType === "Salesperson") {
      if (userData.name) {
        setFullName(userData?.name);
      }
      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setEmail(userData?.email);
      }
      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setMobile(userData.mobileNumber);
      }
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setWhatsappMobile(userData.whatsappNumber);
      }
      if (userProfileData) {
        setProfileImage(userProfileData?.profilePhoto);
      }

      if (userProfileData) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeCompanyFirmLogo: userProfileData?.firmLogo,
        }));
      }
      if (userProfileData) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeementLetterImage: userProfileData?.employeementLetter,
        }));
      }
      if (userProfileData) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeDesignation: userProfileData?.designation,
        }));
      }
      if (userProfileData) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeCompanyFirmName: userProfileData?.firmName,
        }));
      }

      if (userProfileData) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeCompanyReraNo: userProfileData?.reraNo,
        }));
      }

      if (userProfileData) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeCompanyReraNoType: userProfileData?.reraNoType,
        }));
      }

      if (userProfileData) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeCompanyAddress: userProfileData?.firmAddress,
        }));
      }

      if (userProfileData && userProfileData?.hasOwnProperty("officeName")) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeCompanyOfficeName: userProfileData?.officeName,
        }));
      }

      if (userProfileData) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeCompanyOfficeCity: userProfileData?.officeCity,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.hasOwnProperty("officeWebsite") &&
        userProfileData?.officeWebsite !== "undefined"
      ) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeCompanyOfficeWebsite: userProfileData?.officeWebsite,
        }));
      }

      if (userProfileData) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeIdType: userProfileData?.employeeIdType,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.employeeIdType === "Employee ID Card"
      ) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeID: userProfileData?.employeeId,
          employeeIdCardImage: userProfileData?.employeeIdCardImage,
        }));
      }
      if (
        userProfileData &&
        userProfileData?.employeeIdType === "Aadhaar Card"
      ) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeAadhaarCardNo: userProfileData?.employeeAadhaarcardNo,
          employeeAadhaarcardFrontImage:
            userProfileData?.employeeAadhaarcardFrontImage,
          employeeAadhaarcardBackImage:
            userProfileData?.employeeAadhaarcardBackImage,
        }));
      }
      if (userProfileData && userProfileData?.employeeIdType === "Pan Card") {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeePanCardNo: userProfileData?.employeePancardNo,
          employeePancardImage: userProfileData?.employeePancardImage,
        }));
      }
      if (userProfileData && userProfileData?.employeeIdType === "Voter Card") {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeVoterCardNo: userProfileData?.employeeVoterIdNo,
          employeeVoterIdFrontImage: userProfileData?.employeeVoterIdFrontImage,
          employeeVoterIdBackImage: userProfileData?.employeeVoterIdBackImage,
        }));
      }
      if (userProfileData && userProfileData?.employeeIdType === "Passport") {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeePassportNo: userProfileData?.employeePassportNo,
          employeePassportImage: userProfileData?.employeePassportImage,
        }));
      }
      if (
        userProfileData &&
        userProfileData?.employeeIdType === "Driving License"
      ) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeDrivingLicenseNo: userProfileData?.employeeDrivinglicenseNo,
          employeeDrivinglicenseFrontImage:
            userProfileData?.employeeDrivinglicenseFrontImage,
          employeeDrivinglicenseBackImage:
            userProfileData?.employeeDrivinglicenseBackImage,
        }));
      }
    }
  }, [
    userData,
    userProfileData,
    userType,
    setFullName,
    setEmail,
    setMobile,
    setWhatsappMobile,
    allUserData,
  ]);

  const [profileProgress, setProfileProgress] = useState(0);
  const [pendingProfileInfo, setPendingProfileInfo] = useState([]);

  const [personalDetails, setPersonalDetails] = useState({
    showPersonalDetails: null,
    annualIncome: [0, 0],
    annualIncomeCurrencyType: "₹",
    profession: "",
    dob: "",
    maritalStatus: "",
    marriageAnniversary: "",
    gender: "",
  });

  useMemo(() => {
    switch (userType) {
      case "Buyer":
        let buyerNonEmptyValuesCount = 0;
        let buyerTotalRelevantFields = 0;

        let buyerEmptyFields = [];

        const buyerInfo = {
          fullname,
          email,
          mobile,
          whatsappMobile,
          profileImage,
          annualIncome: personalDetails.annualIncome,
          profession: personalDetails.profession,
          dob: personalDetails.dob,
          gender: personalDetails.gender,
          maritalStatus: personalDetails.maritalStatus,
          marriageAnniversary: personalDetails.marriageAnniversary,
        };

        Object.keys(buyerInfo).forEach((key) => {
          if (
            key === "fullname" ||
            key === "email" ||
            key === "mobile" ||
            key === "whatsappMobile" ||
            key === "profileImage" ||
            key === "annualIncome" ||
            key === "profession" ||
            key === "dob" ||
            key === "gender" ||
            key === "maritalStatus" ||
            (personalDetails.maritalStatus === "Married" &&
              key === "marriageAnniversary")
          ) {
            const userValue = buyerInfo[key];
            buyerTotalRelevantFields++;

            if (key === "annualIncome") {
              if (userValue.some((value) => value !== 0)) {
                buyerNonEmptyValuesCount++;
              } else {
                buyerEmptyFields.push(key);
              }
            } else if (
              key === "marriageAnniversary" &&
              personalDetails.maritalStatus === "Married"
            ) {
              if (userValue && userValue !== "") {
                buyerNonEmptyValuesCount++;
              } else {
                buyerEmptyFields.push(key);
              }
            } else if (userValue && userValue !== "") {
              buyerNonEmptyValuesCount++;
            } else {
              buyerEmptyFields.push(key);
            }
          }
        });

        const buyerProgressPercentage =
          (buyerNonEmptyValuesCount / buyerTotalRelevantFields) * 100;
        setProfileProgress(buyerProgressPercentage.toFixed(1));
        setPendingProfileInfo(buyerEmptyFields);
        break;

      case "Owner":
        let ownerNonEmptyValuesCount = 0;
        let ownerTotalRelevantFields = 0;

        let ownerEmptyFields = [];

        const ownerInfo = {
          fullname,
          email,
          mobile,
          whatsappMobile,
          profileImage,
          annualIncome: personalDetails.annualIncome,
          profession: personalDetails.profession,
          dob: personalDetails.dob,
          gender: personalDetails.gender,
          maritalStatus: personalDetails.maritalStatus,
          marriageAnniversary: personalDetails.marriageAnniversary,
        };

        Object.keys(ownerInfo).forEach((key) => {
          if (
            key === "fullname" ||
            key === "email" ||
            key === "mobile" ||
            key === "whatsappMobile" ||
            key === "profileImage" ||
            key === "annualIncome" ||
            key === "profession" ||
            key === "dob" ||
            key === "gender" ||
            key === "maritalStatus" ||
            (personalDetails.maritalStatus === "Married" &&
              key === "marriageAnniversary")
          ) {
            const userValue = ownerInfo[key];
            ownerTotalRelevantFields++;
            if (key === "annualIncome") {
              if (userValue.some((value) => value !== 0)) {
                ownerNonEmptyValuesCount++;
              } else {
                ownerEmptyFields.push(key);
              }
            } else if (
              key === "marriageAnniversary" &&
              personalDetails.maritalStatus === "Married"
            ) {
              if (userValue && userValue !== "") {
                ownerNonEmptyValuesCount++;
              } else {
                ownerEmptyFields.push(key);
              }
            } else if (userValue && userValue !== "") {
              ownerNonEmptyValuesCount++;
            } else {
              ownerEmptyFields.push(key);
            }
          }
        });

        const ownerProgressPercentage =
          (ownerNonEmptyValuesCount / ownerTotalRelevantFields) * 100;
        setProfileProgress(ownerProgressPercentage.toFixed(1));
        setPendingProfileInfo(ownerEmptyFields);
        break;

      case "Builder":
        let builderNonEmptyValuesCount = 0;
        let builderTotalRelevantFields = 0;

        let builderEmptyFields = [];

        const leanBuilderInfo = {
          firmAddress: builderDetails?.builderAddress,
          firmName: builderDetails?.builderFirmName,
          firmLogo: builderDetails?.builderFirmLogo,
          officeCity: builderDetails?.builderOfficeCity,
          officeName: builderDetails?.builderOfficeName,
          officeWebsite: builderDetails?.builderOfficeWebsite,
          profilePhoto: profileImage,
          reraNo: builderDetails?.builderReraNo,
          reraNoType: builderDetails?.builderReraNoType,
          employeesList: builderAddedEmployeesList.length > 0 && true,
        };

        Object.keys(leanBuilderInfo).forEach((key) => {
          const value = leanBuilderInfo[key];
          if (
            key === "firmName" ||
            key === "firmAddress" ||
            key === "firmLogo" ||
            key === "officeCity" ||
            key === "officeName" ||
            key === "officeWebsite" ||
            key === "profilePhoto" ||
            key === "reraNo" ||
            key === "reraNoType" ||
            key === "employeesList"
          ) {
            builderTotalRelevantFields++;

            if (value && value !== "") {
              builderNonEmptyValuesCount++;
            } else {
              builderEmptyFields.push(key);
            }
          }
        });

        const builderInfo = {
          fullname,
          email,
          mobile,
          whatsappMobile,
          dob: personalDetails.dob,
          gender: personalDetails.gender,
          maritalStatus: personalDetails.maritalStatus,
          marriageAnniversary: personalDetails.marriageAnniversary,
        };

        Object.keys(builderInfo).forEach((key) => {
          if (
            key === "fullname" ||
            key === "email" ||
            key === "mobile" ||
            key === "whatsappMobile" ||
            key === "dob" ||
            key === "gender" ||
            key === "maritalStatus" ||
            (personalDetails.maritalStatus === "Married" &&
              key === "marriageAnniversary")
          ) {
            const userValue = builderInfo[key];
            builderTotalRelevantFields++;
            if (
              key === "marriageAnniversary" &&
              personalDetails.maritalStatus === "Married"
            ) {
              if (userValue && userValue !== "") {
                builderNonEmptyValuesCount++;
              } else {
                builderEmptyFields.push(key);
              }
            } else if (userValue && userValue !== "") {
              builderNonEmptyValuesCount++;
            } else {
              builderEmptyFields.push(key);
            }
          }
        });

        const builderProgressPercentage =
          (builderNonEmptyValuesCount / builderTotalRelevantFields) * 100;
        setProfileProgress(builderProgressPercentage.toFixed(1));
        setPendingProfileInfo(builderEmptyFields);
        break;

      case "Agent":
        let agentNonEmptyValuesCount = 0;
        let agentTotalRelevantFields = 0;

        let agentEmptyFields = [];

        const leanAgentInfo = {
          dealsIn: agentDetails?.dealsIn?.length > 0 && agentDetails?.dealsIn,
          firmAddress: agentDetails?.agentAddress,
          firmName: agentDetails?.agentFirmName,
          firmLogo: agentDetails?.agentFirmLogo,
          officeCity: agentDetails?.agentOfficeCity,
          officeName: agentDetails?.agentOfficeName,
          officeWebsite: agentDetails?.agentOfficeWebsite,
          profilePhoto: profileImage,
          reraNo: agentDetails?.agentReraNo,
          reraNoType: agentDetails?.agentReraNoType,
          employeesList: agentAddedEmployeesList.length < 0 && true,
        };

        Object.keys(leanAgentInfo).forEach((key) => {
          const value = leanAgentInfo[key];
          if (
            key === "dealsIn" ||
            key === "firmName" ||
            key === "firmAddress" ||
            key === "firmLogo" ||
            key === "officeCity" ||
            key === "officeName" ||
            key === "officeWebsite" ||
            key === "profilePhoto" ||
            key === "reraNo" ||
            key === "reraNoType" ||
            key === "employeesList"
          ) {
            agentTotalRelevantFields++;

            if (value && value !== "") {
              agentNonEmptyValuesCount++;
            } else {
              agentEmptyFields.push(key);
            }
          }
        });

        const agentInfo = {
          fullname,
          email,
          mobile,
          whatsappMobile,
          dob: personalDetails.dob,
          gender: personalDetails.gender,
          maritalStatus: personalDetails.maritalStatus,
          marriageAnniversary: personalDetails.marriageAnniversary,
        };

        Object.keys(agentInfo).forEach((key) => {
          if (
            key === "fullname" ||
            key === "email" ||
            key === "mobile" ||
            key === "whatsappMobile" ||
            key === "dob" ||
            key === "gender" ||
            key === "maritalStatus" ||
            (personalDetails.maritalStatus === "Married" &&
              key === "marriageAnniversary")
          ) {
            const userValue = agentInfo[key];
            agentTotalRelevantFields++;
            if (
              key === "marriageAnniversary" &&
              personalDetails.maritalStatus === "Married"
            ) {
              if (userValue && userValue !== "") {
                agentNonEmptyValuesCount++;
              } else {
                agentEmptyFields.push(key);
              }
            } else if (userValue && userValue !== "") {
              agentNonEmptyValuesCount++;
            } else {
              agentEmptyFields.push(key);
            }
          }
        });

        const agentProgressPercentage =
          (agentNonEmptyValuesCount / agentTotalRelevantFields) * 100;
        setProfileProgress(agentProgressPercentage.toFixed(1));
        setPendingProfileInfo(agentEmptyFields);
        break;

      case "Salesperson":
        let nonEmptyValuesCount = 0;
        let totalRelevantFields = 0; // To track the total number of relevant fields to check

        const idTypeFields = {
          "Employee ID Card": ["employeeID", "employeeIdCardImage"],
          "Aadhaar Card": [
            "employeeAadhaarCardNo",
            "employeeAadhaarcardFrontImage",
            "employeeAadhaarcardBackImage",
          ],
          "Pan Card": ["employeePanCardNo", "employeePancardImage"],
          "Voter Card": [
            "employeeVoterCardNo",
            "employeeVoterIdFrontImage",
            "employeeVoterIdBackImage",
          ],
          Passport: ["employeePassportNo", "employeePassportImage"],
          "Driving License": [
            "employeeDrivingLicenseNo",
            "employeeDrivinglicenseFrontImage",
            "employeeDrivinglicenseBackImage",
          ],
        };

        const selectedIdType = salespersonDetails.employeeIdType;
        const relevantFields = idTypeFields[selectedIdType] || [];

        let emptyFields = [];

        if (userProfileData?.userAccessId) {
          Object.keys(salespersonDetails).forEach((key) => {
            const value = salespersonDetails[key];

            if (key === "employeeIdType" || relevantFields.includes(key)) {
              totalRelevantFields++;

              if (value && value !== "") {
                nonEmptyValuesCount++;
              } else {
                emptyFields.push(key); // Log empty fields
              }
            }
          });

          const leanEmployeerInfo = {
            firmName:
              employeerDetails.userType === "Builder"
                ? employeerDetails.builderDetails.builderFirmName
                : employeerDetails.userType === "Agent"
                ? employeerDetails.agentDetails.agentFirmName
                : null,
            reraNo:
              employeerDetails.userType === "Builder"
                ? employeerDetails.builderDetails.builderReraNo
                : employeerDetails.userType === "Agent"
                ? employeerDetails.agentDetails.agentReraNo
                : null,
            reraNoType:
              employeerDetails.userType === "Builder"
                ? employeerDetails.builderDetails.builderReraNoType
                : employeerDetails.userType === "Agent"
                ? employeerDetails.agentDetails.agentReraNoType
                : null,
            firmAddress:
              employeerDetails.userType === "Builder"
                ? employeerDetails.builderDetails.builderOfficeAddress
                : employeerDetails.userType === "Agent"
                ? employeerDetails.agentDetails.agentOfficeAddress
                : null,
            officeName:
              employeerDetails.userType === "Builder"
                ? employeerDetails.builderDetails.builderOfficeName
                : employeerDetails.userType === "Agent"
                ? employeerDetails.agentDetails.agentOfficeName
                : null,
            officeCity:
              employeerDetails.userType === "Builder"
                ? employeerDetails.builderDetails.builderOfficeCity
                : employeerDetails.userType === "Agent"
                ? employeerDetails.agentDetails.agentOfficeCity
                : null,
            officeWebsite:
              employeerDetails.userType === "Builder"
                ? employeerDetails.builderDetails.builderOfficeWebsite
                : employeerDetails.userType === "Agent"
                ? employeerDetails.agentDetails.agentOfficeWebsite
                : null,
          };

          Object.keys(leanEmployeerInfo).forEach((key) => {
            const value = leanEmployeerInfo[key];
            if (
              key === "firmName" ||
              key === "reraNo" ||
              key === "reraNoType" ||
              key === "firmAddress" ||
              key === "officeName" ||
              key === "officeCity" ||
              key === "officeWebsite"
            ) {
              totalRelevantFields++;
              // Check if the field matches the selected employeeIdType or is not related to any ID type
              if (value && value !== "") {
                nonEmptyValuesCount++;
              } else {
                emptyFields.push(key); // Log empty fields
              }
            }
          });

          const salespersonAdditionalDetails = {
            employeeDesignation: salespersonDetails.employeeDesignation,
            profileImage,
            employeementLetter: salespersonDetails.employeementLetterImage,
          };

          Object.keys(salespersonAdditionalDetails).forEach((key) => {
            if (
              key === "employeeDesignation" ||
              key === "profileImage" ||
              key === "employeementLetter"
            ) {
              const salespersonValue = salespersonAdditionalDetails[key];
              totalRelevantFields++;
              if (salespersonValue && salespersonValue !== "") {
                nonEmptyValuesCount++;
              } else {
                emptyFields.push(key);
              }
            }
          });
        } else {
          Object.keys(salespersonDetails).forEach((key) => {
            const value = salespersonDetails[key];

            // Check if the field matches the selected employeeIdType or is not related to any ID type
            if (key === "employeeIdType" || relevantFields.includes(key)) {
              // Count only the relevant fields for the selected ID type
              totalRelevantFields++;

              if (value && value !== "") {
                nonEmptyValuesCount++;
              } else {
                emptyFields.push(key); // Log empty fields
              }
            } else if (
              !Object.keys(idTypeFields).some((idType) =>
                idTypeFields[idType].includes(key)
              )
            ) {
              // Count unrelated fields (e.g., name, address)
              totalRelevantFields++;

              if (value && value !== "") {
                nonEmptyValuesCount++;
              } else {
                emptyFields.push(key); // Log empty fields
              }
            }
          });
        }

        const userInfo = {
          fullname,
          email,
          mobile,
          whatsappMobile,
          dob: personalDetails.dob,
          gender: personalDetails.gender,
          maritalStatus: personalDetails.maritalStatus,
          marriageAnniversary: personalDetails.marriageAnniversary,
        };

        Object.keys(userInfo).forEach((key) => {
          if (
            key === "fullname" ||
            key === "email" ||
            key === "mobile" ||
            key === "whatsappMobile" ||
            key === "dob" ||
            key === "gender" ||
            key === "maritalStatus" ||
            (personalDetails.maritalStatus === "Married" &&
              key === "marriageAnniversary")
          ) {
            const userValue = userInfo[key];
            totalRelevantFields++;
            if (
              key === "marriageAnniversary" &&
              personalDetails.maritalStatus === "Married"
            ) {
              if (userValue && userValue !== "") {
                nonEmptyValuesCount++;
              } else {
                emptyFields.push(key);
              }
            } else if (userValue && userValue !== "") {
              nonEmptyValuesCount++;
            } else {
              emptyFields.push(key);
            }
          }
        });

        const progressPercentage =
          (nonEmptyValuesCount / totalRelevantFields) * 100;
        setProfileProgress(progressPercentage.toFixed(1));
        setPendingProfileInfo(emptyFields);
        break;

      default:
        break;
    }
  }, [
    userType,
    profileImage,
    fullname,
    email,
    mobile,
    whatsappMobile,
    userProfileData,
    salespersonDetails,
    employeerDetails,
    agentDetails,
    agentAddedEmployeesList,
    builderDetails,
    builderAddedEmployeesList,
    personalDetails,
  ]);

  const arraysAreDifferent = (arr1, arr2) => {
    if (!arr1 || !arr2 || arr1.length <= 0) return false;
    const parsedArr2 = JSON.parse(arr2 || "[]");
    return (
      arr1.length !== parsedArr2.length ||
      arr1.some((item, i) => item !== parsedArr2[i])
    );
  };
  useMemo(() => {
    if (
      fullname &&
      mobile &&
      email &&
      userData &&
      (fullname !== userData?.name ||
        email !== userData?.email ||
        mobile !== userData?.mobileNumber ||
        whatsappMobile !== userData?.whatsappNumber)
    ) {
      setShowSaveUserDetailsBtn(true);
    } else {
      setShowSaveUserDetailsBtn(false);
    }

    if (
      userType === "Builder" &&
      ((builderDetails?.builderFirmName &&
        builderDetails?.builderFirmName !== userProfileData?.firmName) ||
        (builderDetails?.builderReraNo &&
          builderDetails?.builderReraNo !== userProfileData?.reraNo) ||
        (builderDetails?.builderReraNoType &&
          builderDetails?.builderReraNoType !== userProfileData?.reraNoType) ||
        (builderDetails?.builderAddress &&
          builderDetails?.builderAddress !== userProfileData?.firmAddress) ||
        (builderDetails?.builderFirmLogo &&
          typeof builderDetails.builderFirmLogo !== "string") ||
        builderDetails?.builderOfficeName !==
          (userProfileData?.officeName === "undefined"
            ? ""
            : userProfileData?.officeName) ||
        builderDetails?.builderOfficeCity !== userProfileData?.officeCity ||
        builderDetails?.builderOfficeWebsite !==
          (userProfileData?.officeWebsite === "undefined"
            ? ""
            : userProfileData?.officeWebsite))
    ) {
      setShowSaveUserDetailsBtn(true);
    }

    if (
      userType === "Agent" &&
      ((agentDetails?.agentFirmName &&
        agentDetails?.agentFirmName !== userProfileData?.firmName) ||
        (agentDetails?.dealsIn &&
          arraysAreDifferent(
            agentDetails?.dealsIn,
            userProfileData?.dealsIn
          )) ||
        (agentDetails?.agentReraNo &&
          agentDetails?.agentReraNo !== userProfileData?.reraNo) ||
        (agentDetails?.agentReraNoType &&
          agentDetails?.agentReraNoType !== userProfileData?.reraNoType) ||
        (agentDetails?.agentAddress &&
          agentDetails?.agentAddress !== userProfileData?.firmAddress) ||
        (agentDetails?.agentFirmLogo &&
          typeof agentDetails?.agentFirmLogo !== "string") ||
        agentDetails?.agentOfficeName !==
          (userProfileData?.officeName === "undefined"
            ? ""
            : userProfileData?.officeName) ||
        agentDetails?.agentOfficeCity !== userProfileData?.officeCity ||
        agentDetails?.agentOfficeWebsite !==
          (userProfileData?.officeWebsite === "undefined"
            ? ""
            : userProfileData?.officeWebsite))
    ) {
      setShowSaveUserDetailsBtn(true);
    }

    if (
      userType === "Salesperson" &&
      ((salespersonDetails?.employeeDesignation &&
        salespersonDetails?.employeeDesignation !==
          userProfileData?.designation) ||
        (salespersonDetails?.employeeCompanyFirmName &&
          salespersonDetails?.employeeCompanyFirmName !==
            userProfileData?.firmName) ||
        (salespersonDetails?.employeeCompanyReraNo &&
          salespersonDetails?.employeeCompanyReraNo !==
            userProfileData?.reraNo) ||
        (salespersonDetails?.employeeCompanyReraNoType &&
          salespersonDetails?.employeeCompanyReraNoType !==
            userProfileData?.reraNoType) ||
        (salespersonDetails?.employeeCompanyAddress &&
          salespersonDetails?.employeeCompanyAddress !==
            userProfileData?.firmAddress) ||
        (salespersonDetails?.employeeCompanyFirmLogo &&
          typeof salespersonDetails?.employeeCompanyFirmLogo !== "string") ||
        (salespersonDetails?.employeementLetterImage &&
          typeof salespersonDetails?.employeementLetterImage !== "string") ||
        salespersonDetails?.employeeCompanyOfficeName !==
          (userProfileData?.officeName === undefined
            ? ""
            : userProfileData?.officeName) ||
        salespersonDetails?.employeeCompanyOfficeCity !==
          userProfileData?.officeCity ||
        salespersonDetails?.employeeCompanyOfficeWebsite !==
          (userProfileData?.officeWebsite === undefined
            ? ""
            : userProfileData?.officeWebsite) ||
        (salespersonDetails.employeeIdType &&
          salespersonDetails.employeeIdType !==
            userProfileData?.employeeIdType))
    ) {
      setShowSaveUserDetailsBtn(true);
    }

    if (userType === "Salesperson") {
      switch (salespersonDetails.employeeIdType) {
        case "Employee ID Card":
          if (
            (salespersonDetails.employeeID &&
              salespersonDetails.employeeID !== userProfileData?.employeeId) ||
            (salespersonDetails.employeeIdCardImage &&
              typeof salespersonDetails.employeeIdCardImage !== "string")
          ) {
            setShowSaveUserDetailsBtn(true);
          }
          break;
        case "Aadhaar Card":
          if (
            (salespersonDetails.employeeAadhaarCardNo &&
              salespersonDetails.employeeAadhaarCardNo !==
                userProfileData?.employeeAadhaarcardNo) ||
            (salespersonDetails.employeeAadhaarcardFrontImage &&
              typeof salespersonDetails.employeeAadhaarcardFrontImage !==
                "string") ||
            (salespersonDetails.employeeAadhaarcardBackImage &&
              typeof salespersonDetails.employeeAadhaarcardBackImage !==
                "string")
          ) {
            setShowSaveUserDetailsBtn(true);
          }
          break;
        case "Pan Card":
          if (
            (salespersonDetails.employeePanCardNo &&
              salespersonDetails.employeePanCardNo !==
                userProfileData?.employeePancardNo) ||
            (salespersonDetails.employeePancardImage &&
              typeof salespersonDetails.employeePancardImage !== "string")
          ) {
            setShowSaveUserDetailsBtn(true);
          }
          break;
        case "Voter Card":
          if (
            (salespersonDetails.employeeVoterCardNo &&
              salespersonDetails.employeeVoterCardNo !==
                userProfileData?.employeeVoterIdNo) ||
            (salespersonDetails.employeeVoterIdFrontImage &&
              typeof salespersonDetails.employeeVoterIdFrontImage !==
                "string") ||
            (salespersonDetails.employeeVoterIdBackImage &&
              typeof salespersonDetails.employeeVoterIdBackImage !== "string")
          ) {
            setShowSaveUserDetailsBtn(true);
          }
          break;
        case "Passport":
          if (
            (salespersonDetails.employeePassportNo &&
              salespersonDetails.employeePassportNo !==
                userProfileData?.employeePassportNo) ||
            (salespersonDetails.employeePassportImage &&
              typeof salespersonDetails.employeePassportImage !== "string")
          ) {
            setShowSaveUserDetailsBtn(true);
          }
          break;
        case "Driving License":
          if (
            (salespersonDetails.employeeDrivingLicenseNo &&
              salespersonDetails.employeeDrivingLicenseNo !==
                userProfileData?.employeeDrivinglicenseNo) ||
            (salespersonDetails.employeeDrivinglicenseFrontImage &&
              typeof salespersonDetails.employeeDrivinglicenseFrontImage !==
                "string") ||
            (salespersonDetails.employeeDrivinglicenseBackImage &&
              typeof salespersonDetails.employeeDrivinglicenseBackImage !==
                "string")
          ) {
            setShowSaveUserDetailsBtn(true);
          }
          break;
        default:
          break; // If no matching ID type, set employeeDocuments to null
      }
    }

    if (whatsappMobile && mobile && mobile === whatsappMobile) {
      onSameWhatsappMobileAsMobileChange(true);
    }
  }, [
    fullname,
    mobile,
    email,
    whatsappMobile,
    userData,
    userType,
    builderDetails,
    agentDetails,
    salespersonDetails,
    userProfileData,
    onSameWhatsappMobileAsMobileChange,
  ]);

  useEffect(() => {
    setLoading(true);
    if (userData) {
      setLoading(false);
    }
  }, [userData]);

  const updateProfile = async () => {
    try {
      if (
        email &&
        updateProfileSchema.safeParse({
          email: email,
        }).success === false
      ) {
        const errorMessage = updateProfileSchema.safeParse({
          email: email,
        }).error.issues[0].message;
        message.error(errorMessage);
        return;
      }

      if (
        mobile &&
        updateProfileSchema.safeParse({
          mobile: mobile,
        }).success === false
      ) {
        const errorMessage = updateProfileSchema.safeParse({
          mobile: mobile,
        }).error.issues[0].message;
        message.error(errorMessage);
        return;
      }

      if (
        whatsappMobile &&
        updateProfileSchema.safeParse({
          whatsappMobile: whatsappMobile,
        }).success === false
      ) {
        const errorMessage = updateProfileSchema.safeParse({
          whatsappMobile: whatsappMobile,
        }).error.issues[0].message;
        message.error(errorMessage);
        return;
      }

      if (
        userType === "Builder" &&
        builderDetails.builderOfficeWebsite &&
        updateProfileSchema.safeParse({
          officeWebsite: builderDetails.builderOfficeWebsite,
        }).success === false
      ) {
        const errorMessage = updateProfileSchema.safeParse({
          officeWebsite: builderDetails.builderOfficeWebsite,
        }).error.issues[0].message;
        message.error(errorMessage);
        return;
      } else if (
        userType === "Agent" &&
        agentDetails.agentOfficeWebsite &&
        updateProfileSchema.safeParse({
          officeWebsite: agentDetails.agentOfficeWebsite,
        }).success === false
      ) {
        const errorMessage = updateProfileSchema.safeParse({
          officeWebsite: agentDetails.agentOfficeWebsite,
        }).error.issues[0].message;
        message.error(errorMessage);
        return;
      } else if (
        userType === "Salesperson" &&
        salespersonDetails.employeeCompanyOfficeWebsite &&
        updateProfileSchema.safeParse({
          officeWebsite: salespersonDetails.employeeCompanyOfficeWebsite,
        }).success === false
      ) {
        const errorMessage = updateProfileSchema.safeParse({
          officeWebsite: salespersonDetails.employeeCompanyOfficeWebsite,
        }).error.issues[0].message;
        message.error(errorMessage);
        return;
      }

      if (userType === "Salesperson") {
        if (salespersonDetails.employeeIdType === "Employee ID Card") {
          if (
            updateSalespersonSchema.safeParse({
              employeeID: salespersonDetails.employeeID,
            }).success === false
          ) {
            const errorMessage = updateSalespersonSchema.safeParse({
              employeeID: salespersonDetails.employeeID,
            }).error.issues[0].message;
            message.error(errorMessage);
            return;
          }

          if (!salespersonDetails.employeeIdCardImage) {
            message.error("Please provide your employee ID card image");
            return;
          }
        }
        if (salespersonDetails.employeeIdType === "Aadhaar Card") {
          if (
            updateSalespersonSchema.safeParse({
              employeeAadhaarcard: salespersonDetails.employeeAadhaarCardNo,
            }).success === false
          ) {
            const errorMessage = updateSalespersonSchema.safeParse({
              employeeAadhaarcard: salespersonDetails.employeeAadhaarCardNo,
            }).error.issues[0].message;
            message.error(errorMessage);
            return;
          }

          if (!salespersonDetails.employeeAadhaarcardFrontImage) {
            message.error("Please provide your aadhaar card (front) image");
            return;
          }
          if (!salespersonDetails.employeeAadhaarcardBackImage) {
            message.error("Please provide your aadhaar card (back) image");
            return;
          }
        }

        if (salespersonDetails.employeeIdType === "Pan Card") {
          if (
            updateSalespersonSchema.safeParse({
              employeePancard: salespersonDetails.employeePanCardNo,
            }).success === false
          ) {
            const errorMessage = updateSalespersonSchema.safeParse({
              employeePancard: salespersonDetails.employeePanCardNo,
            }).error.issues[0].message;
            message.error(errorMessage);
            return;
          }

          if (!salespersonDetails.employeePancardImage) {
            message.error("Please provide your pancard image");
            return;
          }
        }

        if (salespersonDetails.employeeIdType === "Voter Card") {
          if (
            updateSalespersonSchema.safeParse({
              employeeVotercard: salespersonDetails.employeeVoterCardNo,
            }).success === false
          ) {
            const errorMessage = updateSalespersonSchema.safeParse({
              employeeVotercard: salespersonDetails.employeeVoterCardNo,
            }).error.issues[0].message;
            message.error(errorMessage);
            return;
          }

          if (!salespersonDetails.employeeVoterIdFrontImage) {
            message.error("Please provide your voter card (front) image");
            return;
          }
          if (!salespersonDetails.employeeVoterIdBackImage) {
            message.error("Please provide your voter card (back) image");
            return;
          }
        }

        if (salespersonDetails.employeeIdType === "Passport") {
          if (
            updateSalespersonSchema.safeParse({
              employeePassport: salespersonDetails.employeePassportNo,
            }).success === false
          ) {
            const errorMessage = updateSalespersonSchema.safeParse({
              employeePassport: salespersonDetails.employeePassportNo,
            }).error.issues[0].message;
            message.error(errorMessage);
            return;
          }

          if (!salespersonDetails.employeePassportImage) {
            message.error("Please provide your passport image");
            return;
          }
        }

        if (salespersonDetails.employeeIdType === "Driving License") {
          if (
            updateSalespersonSchema.safeParse({
              employeeDrivinglicense:
                salespersonDetails.employeeDrivingLicenseNo,
            }).success === false
          ) {
            const errorMessage = updateSalespersonSchema.safeParse({
              employeeDrivinglicense:
                salespersonDetails.employeeDrivingLicenseNo,
            }).error.issues[0].message;
            message.error(errorMessage);
            return;
          }

          if (!salespersonDetails.employeeDrivinglicenseFrontImage) {
            message.error("Please provide your driving license (front) image");
            return;
          }
          if (!salespersonDetails.employeeDrivinglicenseBackImage) {
            message.error("Please provide your driving license (back) image");
            return;
          }
        }
      }

      const formdata = new FormData();

      formdata.append("userId", userId);
      formdata.append("userType", userType);

      formdata.append("name", fullname);
      formdata.append("email", email);
      formdata.append("mobileNumber", mobile);
      formdata.append("whatsappNumber", whatsappMobile);

      formdata.append(
        "showPersonalDetails",
        personalDetails.showPersonalDetails === null
          ? false
          : personalDetails.showPersonalDetails
      );

      if (userType === "Owner" || userType === "Buyer") {
        formdata.append(
          "annualIncome",
          JSON.stringify(personalDetails.annualIncome)
        );
        formdata.append(
          "annualIncomeCurrencyType",
          personalDetails.annualIncomeCurrencyType
        );
        formdata.append("profession", personalDetails.profession);
      }

      formdata.append("dob", personalDetails.dob);
      formdata.append("maritalStatus", personalDetails.maritalStatus);
      formdata.append(
        "marriageAnniversary",
        personalDetails.marriageAnniversary
      );
      formdata.append("gender", personalDetails.gender);

      if (userType === "Buyer") {
        let buyerDocuments = {};
        if (typeof profileImage !== "string") {
          buyerDocuments.profileImage = true;
          formdata.append("buyerDocuments", JSON.stringify(buyerDocuments));
        }
      }
      if (userType === "Owner") {
        let ownerDocuments = {};
        if (typeof profileImage !== "string") {
          ownerDocuments.profileImage = true;
          formdata.append("ownerDocuments", JSON.stringify(ownerDocuments));
        }
      }

      if (userType === "Builder") {
        let builderDocuments = {};
        if (typeof profileImage !== "string" && profileImage) {
          builderDocuments.profileImage = true;
        }
        if (
          typeof builderDetails.builderFirmLogo !== "string" &&
          builderDetails.builderFirmLogo
        ) {
          builderDocuments.firmLogo = true;
        }
        formdata.append("builderDocuments", JSON.stringify(builderDocuments));
        formdata.append("builderFirmName", builderDetails.builderFirmName);
        formdata.append("builderReraNo", builderDetails.builderReraNo);
        formdata.append("builderReraNoType", builderDetails.builderReraNoType);
        formdata.append("builderOfficeAddress", builderDetails.builderAddress);
        formdata.append("builderOfficeName", builderDetails.builderOfficeName);
        formdata.append("builderOfficeCity", builderDetails.builderOfficeCity);
        formdata.append(
          "builderOfficeWebsite",
          builderDetails.builderOfficeWebsite
        );
      }

      if (userType === "Agent") {
        let agentDocuments = {};
        if (typeof profileImage !== "string" && profileImage) {
          agentDocuments.profileImage = true;
        }
        if (
          typeof agentDetails.agentFirmLogo !== "string" &&
          agentDetails.agentFirmLogo
        ) {
          agentDocuments.firmLogo = true;
        }
        formdata.append("agentDocuments", JSON.stringify(agentDocuments));
        formdata.append("agentFirmName", agentDetails.agentFirmName);
        formdata.append("agentReraNo", agentDetails.agentReraNo);
        formdata.append("agentDealsIn", JSON.stringify(agentDetails.dealsIn));
        formdata.append("agentReraNoType", agentDetails.agentReraNoType);
        formdata.append("agentOfficeAddress", agentDetails.agentAddress);
        formdata.append("agentOfficeName", agentDetails.agentOfficeName);
        formdata.append("agentOfficeCity", agentDetails.agentOfficeCity);
        formdata.append("agentOfficeWebsite", agentDetails.agentOfficeWebsite);
      }

      if (userType === "Salesperson") {
        formdata.append(
          "salespersonDesignation",
          salespersonDetails.employeeDesignation
        );
        formdata.append(
          "selectedSalespersonEmployeeIdType",
          salespersonDetails.employeeIdType
        );
        formdata.append("salespersonEmployeeID", salespersonDetails.employeeID);
        formdata.append(
          "salespersonAadhaarcardNo",
          salespersonDetails.employeeAadhaarCardNo
        );
        formdata.append(
          "salespersonPancardNo",
          salespersonDetails.employeePanCardNo
        );
        formdata.append(
          "salespersonVoterIdNo",
          salespersonDetails.employeeVoterCardNo
        );
        formdata.append(
          "salespersonPassportNo",
          salespersonDetails.employeePassportNo
        );
        formdata.append(
          "salespersonDrivinglicenseNo",
          salespersonDetails.employeeDrivingLicenseNo
        );
        formdata.append(
          "salespersonCompanyFirmName",
          salespersonDetails.employeeCompanyFirmName
        );
        formdata.append(
          "salespersonCompanyReraNo",
          salespersonDetails.employeeCompanyReraNo
        );
        formdata.append(
          "salespersonCompanyReraNoType",
          salespersonDetails.employeeCompanyReraNoType
        );
        formdata.append(
          "salespersonCompanyAddress",
          salespersonDetails.employeeCompanyAddress
        );
        formdata.append(
          "salespersonCompanyOfficeName",
          salespersonDetails.employeeCompanyOfficeName
        );
        formdata.append(
          "salespersonCompanyOfficeCity",
          salespersonDetails.employeeCompanyOfficeCity
        );
        formdata.append(
          "salespersonCompanyOfficeWebsite",
          salespersonDetails.employeeCompanyOfficeWebsite
        );

        let employeeDocuments = {};

        const addIfNotNull = (field, key) => {
          if (field && typeof field !== "string") {
            employeeDocuments[key] = true;
          }
        };
        addIfNotNull(
          salespersonDetails.employeementLetterImage,
          "employeementLetterImage"
        );
        addIfNotNull(profileImage, "employeeImage");
        addIfNotNull(
          salespersonDetails.employeeCompanyFirmLogo,
          "employeeCompanyFirmLogo"
        );

        // Checking the employeeIdType and adding respective documents if not null
        switch (salespersonDetails.employeeIdType) {
          case "Employee ID Card":
            addIfNotNull(
              salespersonDetails.employeeIdCardImage,
              "employeeIdCardImage"
            );
            break;
          case "Aadhaar Card":
            addIfNotNull(
              salespersonDetails.employeeAadhaarcardFrontImage,
              "employeeAadhaarCardFrontImage"
            );
            addIfNotNull(
              salespersonDetails.employeeAadhaarcardBackImage,
              "employeeAadhaarCardBackImage"
            );
            break;
          case "Pan Card":
            addIfNotNull(
              salespersonDetails.employeePancardImage,
              "employeePanCardImage"
            );
            break;
          case "Voter Card":
            addIfNotNull(
              salespersonDetails.employeeVoterIdFrontImage,
              "employeeVoterIdFrontImage"
            );
            addIfNotNull(
              salespersonDetails.employeeVoterIdBackImage,
              "employeeVoterIdBackImage"
            );
            break;
          case "Passport":
            addIfNotNull(
              salespersonDetails.employeePassportImage,
              "employeePassportImage"
            );
            break;
          case "Driving License":
            addIfNotNull(
              salespersonDetails.employeeDrivinglicenseFrontImage,
              "employeeDrivingLicenseFrontImage"
            );
            addIfNotNull(
              salespersonDetails.employeeDrivinglicenseBackImage,
              "employeeDrivingLicenseBackImage"
            );
            break;
          default:
            break; // If no matching ID type, set employeeDocuments to null
        }

        if (employeeDocuments) {
          formdata.append(
            `salespersonDocuments`,
            JSON.stringify(employeeDocuments)
          );
        }
      }

      setButtonLoading(true);

      const response = await axios.patch(
        `${baseURL}/api/profile/updateProfile`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.data.message
      ) {
        message.success(response.data.message);
        if (
          userType === "Buyer" &&
          profileImage &&
          response.data.buyerProfilePhotoUploadUrl
        ) {
          const buyerProfilePhotoUploadUrl =
            response.data.buyerProfilePhotoUploadUrl;

          const profileImageUploadResponse = await axios.put(
            buyerProfilePhotoUploadUrl,
            profileImage
          );

          if (
            profileImageUploadResponse.status === 200 ||
            profileImageUploadResponse.statusText === "OK"
          ) {
            const ParsedProfileImageUrl = new URL(buyerProfilePhotoUploadUrl);

            const buyerProfilePhotokey =
              ParsedProfileImageUrl.pathname.slice(1);

            if (buyerProfilePhotokey) {
              message.success("Profile image uploaded successfully");

              try {
                const savedBuyerProfilephotoKey = await axios.patch(
                  `${baseURL}/api/profile/saveProfileFileKeys`,
                  {
                    userType,
                    buyerProfileID: userId,
                    buyerProfilePhotokey,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                      )}`,
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (savedBuyerProfilephotoKey) {
                  message.success(savedBuyerProfilephotoKey.data.message);
                }
              } catch (err) {
                message.error("Error occured while saving profile image");
              }
            }
          }
          setShowSaveUserDetailsBtn(false);
          setFetchLatestUserData(true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }

        if (
          userType === "Owner" &&
          profileImage &&
          response.data.ownerProfilePhotoUploadUrl
        ) {
          const ownerProfilePhotoUploadUrl =
            response.data.ownerProfilePhotoUploadUrl;

          const profileImageUploadResponse = await axios.put(
            ownerProfilePhotoUploadUrl,
            profileImage
          );

          if (
            profileImageUploadResponse.status === 200 ||
            profileImageUploadResponse.statusText === "OK"
          ) {
            const ParsedProfileImageUrl = new URL(ownerProfilePhotoUploadUrl);

            const ownerProfilePhotokey =
              ParsedProfileImageUrl.pathname.slice(1);

            if (ownerProfilePhotokey) {
              message.success("Profile image uploaded successfully");

              try {
                const savedOwnerProfilephotoKey = await axios.patch(
                  `${baseURL}/api/profile/saveProfileFileKeys`,
                  {
                    userType,
                    ownerProfileID: userId,
                    ownerProfilePhotokey,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                      )}`,
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (savedOwnerProfilephotoKey) {
                  message.success(savedOwnerProfilephotoKey.data.message);
                }
              } catch (err) {
                message.error("Error occured while saving profile image");
              }
            }
          }
        }

        if (
          userType === "Builder" &&
          profileImage &&
          response.data.builderProfilePhotoUploadUrl
        ) {
          const builderProfilePhotoUploadUrl =
            response.data.builderProfilePhotoUploadUrl;

          const profileImageUploadResponse = await axios.put(
            builderProfilePhotoUploadUrl,
            profileImage
          );

          if (
            profileImageUploadResponse.status === 200 ||
            profileImageUploadResponse.statusText === "OK"
          ) {
            const ParsedProfileImageUrl = new URL(builderProfilePhotoUploadUrl);

            const builderProfilePhotokey =
              ParsedProfileImageUrl.pathname.slice(1);

            if (builderProfilePhotokey) {
              message.success("Profile image uploaded successfully");

              try {
                const savedBuilderProfilephotoKey = await axios.patch(
                  `${baseURL}/api/profile/saveProfileFileKeys`,
                  {
                    userType,
                    builderProfileID: userId,
                    builderProfilePhotokey,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                      )}`,
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (savedBuilderProfilephotoKey) {
                  message.success(savedBuilderProfilephotoKey.data.message);
                }
              } catch (err) {
                message.error("Error occured while saving profile image");
              }
            }
          }
        }
        if (
          userType === "Builder" &&
          builderDetails.builderFirmLogo &&
          response.data.builderFirmLogoUploadUrl
        ) {
          const builderFirmLogoUploadUrl =
            response.data.builderFirmLogoUploadUrl;

          const firmLogoUploadResponse = await axios.put(
            builderFirmLogoUploadUrl,
            builderDetails.builderFirmLogo
          );

          if (
            firmLogoUploadResponse.status === 200 ||
            firmLogoUploadResponse.statusText === "OK"
          ) {
            const ParsedFirmLogoUrl = new URL(builderFirmLogoUploadUrl);

            const builderFirmLogokey = ParsedFirmLogoUrl.pathname.slice(1);

            if (builderFirmLogokey) {
              message.success("Firm logo uploaded successfully");

              try {
                const savedBuilderFirmLogoKey = await axios.patch(
                  `${baseURL}/api/profile/saveProfileFileKeys`,
                  {
                    userType,
                    builderProfileID: userId,
                    builderFirmLogokey,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                      )}`,
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (savedBuilderFirmLogoKey) {
                  message.success(savedBuilderFirmLogoKey.data.message);
                }
              } catch (err) {
                message.error("Error occured while saving firm logo");
              }
            }
          }
        }
        if (
          userType === "Agent" &&
          profileImage &&
          response.data.agentProfilePhotoUploadUrl
        ) {
          const agentProfilePhotoUploadUrl =
            response.data.agentProfilePhotoUploadUrl;

          const profileImageUploadResponse = await axios.put(
            agentProfilePhotoUploadUrl,
            profileImage
          );

          if (
            profileImageUploadResponse.status === 200 ||
            profileImageUploadResponse.statusText === "OK"
          ) {
            const ParsedProfileImageUrl = new URL(agentProfilePhotoUploadUrl);

            const agentProfilePhotokey =
              ParsedProfileImageUrl.pathname.slice(1);

            if (agentProfilePhotokey) {
              message.success("Profile image uploaded successfully");

              try {
                const savedAgentProfilephotoKey = await axios.patch(
                  `${baseURL}/api/profile/saveProfileFileKeys`,
                  {
                    userType,
                    agentProfileID: userId,
                    agentProfilePhotokey,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                      )}`,
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (savedAgentProfilephotoKey) {
                  message.success(savedAgentProfilephotoKey.data.message);
                }
              } catch (err) {
                message.error("Error occured while saving profile image");
              }
            }
          }
        }
        if (
          userType === "Agent" &&
          agentDetails.agentFirmLogo &&
          response.data.agentFirmLogoUploadUrl
        ) {
          const agentFirmLogoUploadUrl = response.data.agentFirmLogoUploadUrl;

          const firmLogoUploadResponse = await axios.put(
            agentFirmLogoUploadUrl,
            agentDetails.agentFirmLogo
          );

          if (
            firmLogoUploadResponse.status === 200 ||
            firmLogoUploadResponse.statusText === "OK"
          ) {
            const ParsedFirmLogoUrl = new URL(agentFirmLogoUploadUrl);

            const agentFirmLogokey = ParsedFirmLogoUrl.pathname.slice(1);

            if (agentFirmLogokey) {
              message.success("Firm logo uploaded successfully");

              try {
                const savedAgentFirmLogoKey = await axios.patch(
                  `${baseURL}/api/profile/saveProfileFileKeys`,
                  {
                    userType,
                    agentProfileID: userId,
                    agentFirmLogokey,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                      )}`,
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (savedAgentFirmLogoKey) {
                  message.success(savedAgentFirmLogoKey.data.message);
                }
              } catch (err) {
                message.error("Error occured while saving firm logo");
              }
            }
          }
        }
        if (
          userType === "Salesperson" &&
          response.data?.salespersonMediaUploadInfo
        ) {
          try {
            let employeeMediaUploadResponse = {};
            const salespersonMediaUploadInfo =
              response.data?.salespersonMediaUploadInfo;

            try {
              employeeMediaUploadResponse.employeeID =
                salespersonMediaUploadInfo.employeeID;

              const salespersonEmployeementLetterUploadUrl =
                response.data?.salespersonEmployeementLetterUploadUrl;

              if (salespersonEmployeementLetterUploadUrl) {
                const employeementLetterUploadResponse = await axios.put(
                  salespersonEmployeementLetterUploadUrl,
                  salespersonDetails.employeementLetterImage
                );

                if (
                  employeementLetterUploadResponse.status === 200 ||
                  employeementLetterUploadResponse.statusText === "OK"
                ) {
                  const employeementLetterUrl =
                    salespersonEmployeementLetterUploadUrl;

                  const parsedEmployeementLetterUrl = new URL(
                    employeementLetterUrl
                  );

                  const employeementLetterkey =
                    parsedEmployeementLetterUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeementLetter =
                    employeementLetterkey;
                }
              }

              const salespersonProfilePhotoUploadUrl =
                response.data?.salespersonProfilePhotoUploadUrl;

              if (salespersonProfilePhotoUploadUrl) {
                const employeeImageUploadResponse = await axios.put(
                  salespersonProfilePhotoUploadUrl,
                  profileImage
                );

                if (
                  employeeImageUploadResponse.status === 200 ||
                  employeeImageUploadResponse.statusText === "OK"
                ) {
                  const employeeImageUrl = salespersonProfilePhotoUploadUrl;

                  const parsedEmployeeImageUrl = new URL(employeeImageUrl);

                  const employeeImagekey =
                    parsedEmployeeImageUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeeImage = employeeImagekey;
                }
              }

              const salespersonFirmLogoUploadUrl =
                response.data?.salespersonFirmLogoUploadUrl;

              if (salespersonFirmLogoUploadUrl) {
                const employeeFirmlogoUploadResponse = await axios.put(
                  salespersonFirmLogoUploadUrl,
                  salespersonDetails.employeeCompanyFirmLogo
                );

                if (
                  employeeFirmlogoUploadResponse.status === 200 ||
                  employeeFirmlogoUploadResponse.statusText === "OK"
                ) {
                  const employeeFirmLogoUrl = salespersonFirmLogoUploadUrl;

                  const parsedEmployeeFirmLogoUrl = new URL(
                    employeeFirmLogoUrl
                  );

                  const employeeCompanyFirmLogokey =
                    parsedEmployeeFirmLogoUrl.pathname.slice(1);

                  employeeMediaUploadResponse.employeeCompanyFirmLogo =
                    employeeCompanyFirmLogokey;
                }
              }

              if (salespersonDetails.employeeIdType === "Employee ID Card") {
                if (salespersonMediaUploadInfo.employeeIdCardImageUrl) {
                  const employeeIdCardImageUploadResponse = await axios.put(
                    salespersonMediaUploadInfo.employeeIdCardImageUrl,
                    salespersonDetails.employeeIdCardImage
                  );

                  if (
                    employeeIdCardImageUploadResponse.status === 200 ||
                    employeeIdCardImageUploadResponse.statusText === "OK"
                  ) {
                    const employeeIdCardImageUrl =
                      salespersonMediaUploadInfo.employeeIdCardImageUrl;

                    const parsedEmployeeIdCardImageUrl = new URL(
                      employeeIdCardImageUrl
                    );

                    const employeeIdCardImagekey =
                      parsedEmployeeIdCardImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeeIdCardImage =
                      employeeIdCardImagekey;
                  }
                }
              }
              if (salespersonDetails.employeeIdType === "Aadhaar Card") {
                if (
                  salespersonMediaUploadInfo.employeeAadhaarCardFrontImageUrl
                ) {
                  const employeeAadhaarCardFrontImageUploadResponse =
                    await axios.put(
                      salespersonMediaUploadInfo.employeeAadhaarCardFrontImageUrl,
                      salespersonDetails.employeeAadhaarcardFrontImage
                    );

                  if (
                    employeeAadhaarCardFrontImageUploadResponse.status ===
                      200 ||
                    employeeAadhaarCardFrontImageUploadResponse.statusText ===
                      "OK"
                  ) {
                    const employeeAadhaarCardFrontImageUrl =
                      salespersonMediaUploadInfo.employeeAadhaarCardFrontImageUrl;

                    const ParsedEmployeeAadhaarCardFrontImageUrl = new URL(
                      employeeAadhaarCardFrontImageUrl
                    );

                    const employeeAadhaarCardFrontImagekey =
                      ParsedEmployeeAadhaarCardFrontImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeeAadhaarCardFrontImage =
                      employeeAadhaarCardFrontImagekey;
                  }
                }
                if (
                  salespersonMediaUploadInfo.employeeAadhaarCardBackImageUrl
                ) {
                  const employeeAadhaarCardBackImageUploadResponse =
                    await axios.put(
                      salespersonMediaUploadInfo.employeeAadhaarCardBackImageUrl,
                      salespersonDetails.employeeAadhaarcardBackImage
                    );

                  if (
                    employeeAadhaarCardBackImageUploadResponse.status === 200 ||
                    employeeAadhaarCardBackImageUploadResponse.statusText ===
                      "OK"
                  ) {
                    const employeeAadhaarCardBackImageUrl =
                      salespersonMediaUploadInfo.employeeAadhaarCardBackImageUrl;

                    const ParsedEmployeeAadhaarCardBackImageUrl = new URL(
                      employeeAadhaarCardBackImageUrl
                    );

                    const employeeAadhaarCardBackImagekey =
                      ParsedEmployeeAadhaarCardBackImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeeAadhaarCardBackImage =
                      employeeAadhaarCardBackImagekey;
                  }
                }
              }
              if (salespersonDetails.employeeIdType === "Pan Card") {
                if (salespersonMediaUploadInfo.employeePanCardImageUrl) {
                  const employeePanCardImageUploadResponse = await axios.put(
                    salespersonMediaUploadInfo.employeePanCardImageUrl,
                    salespersonDetails.employeePancardImage
                  );

                  if (
                    employeePanCardImageUploadResponse.status === 200 ||
                    employeePanCardImageUploadResponse.statusText === "OK"
                  ) {
                    const employeePanCardImageUrl =
                      salespersonMediaUploadInfo.employeePanCardImageUrl;

                    const ParsedEmployeePanCardImageUrl = new URL(
                      employeePanCardImageUrl
                    );

                    const employeePanCardImagekey =
                      ParsedEmployeePanCardImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeePanCardImage =
                      employeePanCardImagekey;
                  }
                }
              }

              if (salespersonDetails.employeeIdType === "Voter Card") {
                if (salespersonMediaUploadInfo.employeeVoterCardFrontImageUrl) {
                  const employeeVoterCardFrontImageUploadResponse =
                    await axios.put(
                      salespersonMediaUploadInfo.employeeVoterCardFrontImageUrl,
                      salespersonDetails.employeeVoterIdFrontImage
                    );

                  if (
                    employeeVoterCardFrontImageUploadResponse.status === 200 ||
                    employeeVoterCardFrontImageUploadResponse.statusText ===
                      "OK"
                  ) {
                    const employeeVoterCardFrontImageUrl =
                      salespersonMediaUploadInfo.employeeVoterCardFrontImageUrl;

                    const ParsedEmployeeVoterCardFrontImageUrl = new URL(
                      employeeVoterCardFrontImageUrl
                    );

                    const employeeVoterCardFrontImagekey =
                      ParsedEmployeeVoterCardFrontImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeeVoterCardFrontImage =
                      employeeVoterCardFrontImagekey;
                  }
                }
                if (salespersonMediaUploadInfo.employeeVoterCardBackImageUrl) {
                  const employeeVoterCardBackImageUploadResponse =
                    await axios.put(
                      salespersonMediaUploadInfo.employeeVoterCardBackImageUrl,
                      salespersonDetails.employeeVoterIdBackImage
                    );

                  if (
                    employeeVoterCardBackImageUploadResponse.status === 200 ||
                    employeeVoterCardBackImageUploadResponse.statusText === "OK"
                  ) {
                    const employeeVoterCardBackImageUrl =
                      salespersonMediaUploadInfo.employeeVoterCardBackImageUrl;

                    const ParsedEmployeeVoterCardBackImageUrl = new URL(
                      employeeVoterCardBackImageUrl
                    );

                    const employeeVoterCardBackImagekey =
                      ParsedEmployeeVoterCardBackImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeeVoterCardBackImage =
                      employeeVoterCardBackImagekey;
                  }
                }
              }

              if (salespersonDetails.employeeIdType === "Passport") {
                if (salespersonMediaUploadInfo.employeePassportImageUrl) {
                  const employeePassportImageUploadResponse = await axios.put(
                    salespersonMediaUploadInfo.employeePassportImageUrl,
                    salespersonDetails.employeePassportImage
                  );

                  if (
                    employeePassportImageUploadResponse.status === 200 ||
                    employeePassportImageUploadResponse.statusText === "OK"
                  ) {
                    const employeePassportImageUrl =
                      salespersonMediaUploadInfo.employeePassportImageUrl;

                    const ParsedEmployeePassportImageUrl = new URL(
                      employeePassportImageUrl
                    );

                    const employeePassportImagekey =
                      ParsedEmployeePassportImageUrl.pathname.slice(1);

                    employeeMediaUploadResponse.employeePassportImage =
                      employeePassportImagekey;
                  }
                }
              }
              if (salespersonDetails.employeeIdType === "Driving License") {
                if (
                  salespersonMediaUploadInfo.employeeDrivingLicenseFrontImageUrl
                ) {
                  const employeeDrivingLicenseFrontImageUploadResponse =
                    await axios.put(
                      salespersonMediaUploadInfo.employeeDrivingLicenseFrontImageUrl,
                      salespersonDetails.employeeDrivinglicenseFrontImage
                    );

                  if (
                    employeeDrivingLicenseFrontImageUploadResponse.status ===
                      200 ||
                    employeeDrivingLicenseFrontImageUploadResponse.statusText ===
                      "OK"
                  ) {
                    const employeeDrivingLicenseFrontImageUrl =
                      salespersonMediaUploadInfo.employeeDrivingLicenseFrontImageUrl;

                    const ParsedEmployeeDrivingLicenseFrontImageUrl = new URL(
                      employeeDrivingLicenseFrontImageUrl
                    );

                    const employeeDrivingLicenseFrontImagekey =
                      ParsedEmployeeDrivingLicenseFrontImageUrl.pathname.slice(
                        1
                      );

                    employeeMediaUploadResponse.employeeDrivingLicenseFrontImage =
                      employeeDrivingLicenseFrontImagekey;
                  }
                }
                if (
                  salespersonMediaUploadInfo.employeeDrivingLicenseBackImageUrl
                ) {
                  const employeeDrivingLicenseBackImageUploadResponse =
                    await axios.put(
                      salespersonMediaUploadInfo.employeeDrivingLicenseBackImageUrl,
                      salespersonDetails.employeeDrivinglicenseBackImage
                    );

                  if (
                    employeeDrivingLicenseBackImageUploadResponse.status ===
                      200 ||
                    employeeDrivingLicenseBackImageUploadResponse.statusText ===
                      "OK"
                  ) {
                    const employeeDrivingLicenseBackImageUrl =
                      salespersonMediaUploadInfo.employeeDrivingLicenseBackImageUrl;

                    const ParsedEmployeeDrivingLicenseBackImageUrl = new URL(
                      employeeDrivingLicenseBackImageUrl
                    );

                    const employeeDrivingLicenseBackImagekey =
                      ParsedEmployeeDrivingLicenseBackImageUrl.pathname.slice(
                        1
                      );

                    employeeMediaUploadResponse.employeeDrivingLicenseBackImage =
                      employeeDrivingLicenseBackImagekey;
                  }
                }
              }
            } catch (error) {
              console.error(`Error uploading media for salesperson`, error);
              throw error;
            }

            if (employeeMediaUploadResponse) {
              message.success("files uploaded successfully");

              try {
                const savedSalespersonMediaKeys = await axios.patch(
                  `${baseURL}/api/profile/saveProfileFileKeys`,
                  {
                    userType,
                    employeeMediaUploadResponse: JSON.stringify(
                      employeeMediaUploadResponse
                    ),
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                      )}`,
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (savedSalespersonMediaKeys) {
                  message.success(savedSalespersonMediaKeys.data.message);
                }
              } catch (err) {
                message.error("Error occured while saving images");
              }
            }
          } catch (err) {
            message.error("Error occured while uploading images");
          }
        }
      }
    } catch (error) {
      if (
        error.response?.data.message ||
        error.response?.status === 409 ||
        error.response?.statusText === "Conflict"
      ) {
        return message.error(error.response?.data.message);
      }
      alert(error);
      message.error("Error updating profile, try again!");
    } finally {
      setButtonLoading(false);
      setShowSaveUserDetailsBtn(false);
      setFetchLatestUserData(true);
    }
  };

  useEffect(() => {
    if (personalDetails.maritalStatus !== "Married") {
      setPersonalDetails((prev) => ({
        ...prev,
        marriageAnniversary: "",
      }));
    }
  }, [personalDetails.maritalStatus]);

  useMemo(() => {
    if (userProfileData && Object.keys(userProfileData).length > 0) {
      const parsedAnnualIncome =
        userProfileData?.annualIncome && userProfileData?.annualIncome?.length
          ? JSON.parse(userProfileData?.annualIncome)
          : [0, 0];

      setPersonalDetails((prev) => ({
        ...prev,
        showPersonalDetails: userProfileData?.showPersonalDetails,
        annualIncome:
          parsedAnnualIncome?.length > 0 ? parsedAnnualIncome : [0, 0],
        annualIncomeCurrencyType:
          userProfileData?.annualIncomeCurrencyType &&
          userProfileData?.annualIncomeCurrencyType !== ""
            ? userProfileData?.annualIncomeCurrencyType
            : "₹",
        profession: userProfileData?.profession,
        dob: userProfileData?.dob,
        maritalStatus: userProfileData?.maritalStatus,
        marriageAnniversary: userProfileData?.marriageAnniversary
          ? userProfileData?.marriageAnniversary
          : "",
        gender: userProfileData?.gender,
      }));
    }
  }, [userProfileData]);

  useEffect(() => {
    const parsedAnnualIncome =
      userProfileData?.annualIncome && userProfileData?.annualIncome.length
        ? JSON.parse(userProfileData?.annualIncome)
        : [0, 0];

    switch (userType) {
      case "Owner":
        if (
          !parsedAnnualIncome?.every(
            (income, i) => income === personalDetails.annualIncome[i]
          ) ||
          parsedAnnualIncome?.length !== personalDetails.annualIncome.length ||
          personalDetails.profession !== userProfileData?.profession ||
          personalDetails.dob !== userProfileData?.dob ||
          personalDetails.gender !== userProfileData?.gender ||
          personalDetails.maritalStatus !== userProfileData?.maritalStatus ||
          personalDetails.marriageAnniversary !==
            (userProfileData?.marriageAnniversary &&
            userProfileData?.marriageAnniversary !== "undefined"
              ? userProfileData?.marriageAnniversary
              : "") ||
          personalDetails.showPersonalDetails !==
            userProfileData?.showPersonalDetails
        ) {
          setShowSaveUserDetailsBtn(true);
        }
        break;

      case "Buyer":
        if (
          !parsedAnnualIncome?.every(
            (income, i) => income === personalDetails.annualIncome[i]
          ) ||
          parsedAnnualIncome?.length !== personalDetails.annualIncome.length ||
          personalDetails.profession !== userProfileData?.profession ||
          personalDetails.dob !== userProfileData?.dob ||
          personalDetails.gender !== userProfileData?.gender ||
          personalDetails.maritalStatus !== userProfileData?.maritalStatus ||
          personalDetails.marriageAnniversary !==
            (userProfileData?.marriageAnniversary &&
            userProfileData?.marriageAnniversary !== "undefined"
              ? userProfileData?.marriageAnniversary
              : "") ||
          personalDetails.showPersonalDetails !==
            userProfileData?.showPersonalDetails
        ) {
          setShowSaveUserDetailsBtn(true);
        }
        break;

      case "Builder":
        if (
          personalDetails.dob !== userProfileData?.dob ||
          personalDetails.gender !== userProfileData?.gender ||
          personalDetails.maritalStatus !== userProfileData?.maritalStatus ||
          personalDetails.marriageAnniversary !==
            (userProfileData?.marriageAnniversary &&
            userProfileData?.marriageAnniversary !== "undefined"
              ? userProfileData?.marriageAnniversary
              : "") ||
          personalDetails.showPersonalDetails !==
            userProfileData?.showPersonalDetails
        ) {
          setShowSaveUserDetailsBtn(true);
        }
        break;

      case "Agent":
        if (
          personalDetails.dob !== userProfileData?.dob ||
          personalDetails.gender !== userProfileData?.gender ||
          personalDetails.maritalStatus !== userProfileData?.maritalStatus ||
          personalDetails.marriageAnniversary !==
            (userProfileData?.marriageAnniversary &&
            userProfileData?.marriageAnniversary !== "undefined"
              ? userProfileData?.marriageAnniversary
              : "") ||
          personalDetails.showPersonalDetails !==
            userProfileData?.showPersonalDetails
        ) {
          setShowSaveUserDetailsBtn(true);
        }
        break;

      case "Salesperson":
        if (
          personalDetails.dob !== userProfileData?.dob ||
          personalDetails.gender !== userProfileData?.gender ||
          personalDetails.maritalStatus !== userProfileData?.maritalStatus ||
          personalDetails.marriageAnniversary !==
            (userProfileData?.marriageAnniversary &&
            userProfileData?.marriageAnniversary !== "undefined"
              ? userProfileData?.marriageAnniversary
              : "") ||
          personalDetails.showPersonalDetails !==
            userProfileData?.showPersonalDetails
        ) {
          setShowSaveUserDetailsBtn(true);
        }
        break;

      default:
        setShowSaveUserDetailsBtn(false);
        break;
    }
  }, [personalDetails, userType, userProfileData]);

  // Show Public Details
  const handleSwitchChange = (checked) => {
    setPersonalDetails((prev) => ({
      ...prev,
      showPersonalDetails: checked,
    }));
  };
  const switchBackgroundColorPublicDetails = personalDetails.showPersonalDetails
    ? "var(--primary-color)"
    : "#ccc";

  // Annual Income Slider
  const formatPrice = (price, currencyType) => {
    const numericPrice = parseFloat(price);
    if (!isNaN(numericPrice)) {
      let formattedPrice = "";
      if (currencyType === "₹") {
        if (numericPrice >= 10000000) {
          formattedPrice = `₹ ${numericPrice / 10000000} Cr`;
        } else if (numericPrice >= 100000) {
          formattedPrice = `₹ ${numericPrice / 100000} Lakh`;
        } else if (numericPrice >= 1000) {
          formattedPrice = `₹ ${numericPrice / 1000} K`;
        } else {
          formattedPrice = `₹ ${numericPrice}`;
        }
      } else if (currencyType === "$") {
        if (numericPrice >= 1000000) {
          formattedPrice = `$ ${numericPrice / 1000000} M`;
        } else if (numericPrice >= 1000) {
          formattedPrice = `$ ${numericPrice / 1000} K`;
        } else {
          formattedPrice = `$ ${numericPrice}`;
        }
      }
      return formattedPrice;
    } else {
      return "";
    }
  };

  const handleSliderChange = (value) => {
    setPersonalDetails((prev) => ({
      ...prev,
      annualIncome: value,
    }));
  };
  const currencySymbol = personalDetails.annualIncomeCurrencyType;
  const marks =
    currencySymbol === "₹"
      ? {
          0: <h5 className="utr-form-range-slider-marks-h5">₹0</h5>,
          500000: <h5 className="utr-form-range-slider-marks-h5">₹5 Lakh</h5>,
          1000000: <h5 className="utr-form-range-slider-marks-h5">₹10 Lakh</h5>,
          1500000: <h5 className="utr-form-range-slider-marks-h5">₹15 Lakh</h5>,
          2000000: (
            <h5 className="utr-form-range-slider-marks-h5">₹20 Lakh+</h5>
          ),
        }
      : {
          0: <h5 className="utr-form-range-slider-marks-h5">$0</h5>,
          500000: <h5 className="utr-form-range-slider-marks-h5">$500K</h5>,
          1000000: <h5 className="utr-form-range-slider-marks-h5">$1M</h5>,
          1500000: <h5 className="utr-form-range-slider-marks-h5">₹15M</h5>,
          2000000: <h5 className="utr-form-range-slider-marks-h5">$2M+</h5>,
        };
  const formattedMinIncome = formatPrice(
    personalDetails.annualIncome[0],
    currencySymbol
  );
  const formattedMaxIncome = formatPrice(
    personalDetails.annualIncome[1],
    currencySymbol
  );
  const annualIncomeValue =
    personalDetails.annualIncome[0] === 0 &&
    personalDetails.annualIncome[1] === 0
      ? ""
      : `${formattedMinIncome} - ${formattedMaxIncome}`;

  return (
    <div className="utr">
      {loading && <LoadingOverlay />}
      <div className="utr-sub">
        <div className="utr-container">
          {rejectedReasons && rejectedReasons?.length > 0 && (
            <div className="utr-verify-status rejected">
              <div className="utr-verify-status-sub">
                <marquee scrollamount="3">
                  {rejectedReasons?.map((reason) => {
                    return (
                      <span>{`${reason.label}: ${reason.rejectReason}, `}</span>
                    );
                  })}
                  <AiOutlineCloseCircle className="utr-verify-status-sub-icon" />
                </marquee>
              </div>
            </div>
          )}
          {isProfileVerified &&
          (!rejectedReasons || rejectedReasons?.length === 0) ? (
            <div className="utr-verify-status success">
              <div className="utr-verify-status-sub">
                <marquee scrollamount="3">
                  Woohoo! Your profile is now verified—you're officially fancy!{" "}
                  <RiVerifiedBadgeFill className="utr-verify-status-sub-icon" />
                </marquee>
              </div>
            </div>
          ) : (!rejectedReasons || rejectedReasons?.length === 0) &&
            !isProfileVerified ? (
            <div className="utr-verify-status">
              <div className="utr-verify-status-sub">
                <marquee scrollamount="3">
                  Your profile is currently under verification. Once verified,
                  you will be able to proceed with listing{" "}
                  {userType !== "Owner" &&
                  userType !== "Buyer" &&
                  userType !== "Salesperson"
                    ? "projects and "
                    : ""}
                  properties. If any updates are required, we will inform you
                  via email or our team will contact you if necessary.
                </marquee>
              </div>
            </div>
          ) : null}
          <div className="utr-header">
            <div className="utr-header-sub">
              <GoArrowLeft
                className="utr-header-sub-icon"
                onClick={() => {
                  if (userType === "Agent") {
                    if (isAgentEditing) {
                      handleCancelAgentUpdate();
                    } else {
                      navigate("/");
                    }
                  } else if (userType === "Builder") {
                    if (isBuilderEditing) {
                      handleCancelBuilderUpdate();
                    } else {
                      navigate("/");
                    }
                  } else {
                    navigate("/");
                  }
                }}
              />
              <h3>{userType} - Profile</h3>
              <Progress
                type="circle"
                percent={profileProgress}
                size={45}
                strokeColor="#ff2d2d"
                trailColor="#cccccc"
                strokeWidth={7}
              />
            </div>
          </div>
          <div className="utr-footer-profile">
            <div className="utr-footer-profile-sub">
              <div
                className={`utr-footer-profile-option ${
                  selectedSection === "Business Info" ? "selected" : ""
                }`}
                onClick={() => handleSectionClick("Business Info")}
              >
                <LuUser2 className="utr-footer-profile-option-icon" />
                {userType === "Owner" || userType === "Buyer" ? (
                  <>
                    <h5>Personal Info</h5>
                  </>
                ) : (
                  <h5>Business Info</h5>
                )}
              </div>
              {userType !== "Owner" && userType !== "Buyer" && (
                <>
                  <div
                    className={`utr-footer-profile-option ${
                      selectedSection === "Firm Details" ? "selected" : ""
                    }`}
                    onClick={() => handleSectionClick("Firm Details")}
                  >
                    <AiOutlineSafetyCertificate className="utr-footer-profile-option-icon" />
                    <h5>Firm Details</h5>
                  </div>
                  <div
                    className={`utr-footer-profile-option ${
                      selectedSection === "Office Details" ? "selected" : ""
                    }`}
                    onClick={() => handleSectionClick("Office Details")}
                  >
                    <RiHomeOfficeLine className="utr-footer-profile-option-icon" />
                    <h5>Office Details</h5>
                  </div>
                  {userType !== "Salesperson" && (
                    <div
                      className={`utr-footer-profile-option ${
                        selectedSection === "Employees" ? "selected" : ""
                      }`}
                      onClick={() => handleSectionClick("Employees")}
                    >
                      {userType === "Builder" && isBuilderEditing ? (
                        <>
                          <MdOutlineEdit className="utr-footer-profile-option-icon" />
                          <h5>Edit Employee</h5>
                        </>
                      ) : userType === "Agent" && isAgentEditing ? (
                        <>
                          <MdOutlineEdit className="utr-footer-profile-option-icon" />
                          <h5>Edit Employee</h5>
                        </>
                      ) : (
                        <>
                          <LuUsers2 className="utr-footer-profile-option-icon" />
                          <h5>Employees</h5>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
              {userType === "Salesperson" && (
                <div
                  className={`utr-footer-profile-option ${
                    selectedSection === "Documents" ? "selected" : ""
                  }`}
                  onClick={() => handleSectionClick("Documents")}
                >
                  <HiOutlineDocumentDuplicate className="utr-footer-profile-option-icon" />
                  <h5>Documents</h5>
                </div>
              )}
              <div
                className={`utr-footer-profile-option ${
                  selectedSection === "Preferences" ? "selected" : ""
                }`}
                onClick={() => handleSectionClick("Preferences")}
              >
                <TbSettings2 className="utr-footer-profile-option-icon" />
                <h5>Personal Details</h5>
              </div>
            </div>
          </div>
          <div
            className={`utr-form utr-form-profiles ${
              showSaveUserDetailsBtn ? "ufpbmq" : ""
            }`}
          >
            {selectedSection === "Business Info" && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <Button
                    filled
                    icon={<TbEyeCode />}
                    onClick={() => navigate(`/viewagentprofile/${userId}`)}
                  >
                    See Public Profile
                  </Button>
                </div>
                <div className="utr-form-category">
                  <h4>Profile Details</h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      icon={MdDriveFileRenameOutline}
                      placeholder="Full Name"
                      value={fullname}
                      onChange={(e) => setFullName(e.target.value)}
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                    />
                    <FormInput
                      icon={MdOutlineEmail}
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        if (!userData?.email?.includes("@tempmail.com")) {
                          setEmail(e.target.value);
                        }
                      }}
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                    />
                    <FormInput
                      icon={MdOutlinePhoneEnabled}
                      placeholder="Mobile No."
                      value={formatMobileDisplay(mobile)}
                      onChange={!userData?.mobile && handleMobileChange}
                      maxLength="14"
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                    />
                    <FormInput
                      icon={IoLogoWhatsapp}
                      placeholder="Whatsapp No."
                      value={
                        checked
                          ? formatMobileDisplay(mobile)
                          : formatWhatsappMobileDisplay(whatsappMobile)
                      }
                      onChange={
                        !userData?.whatsappMobile && handleWhatsappMobileChange
                      }
                      readOnly={checked}
                      maxLength="14"
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                    />
                  </div>
                  <h6>
                    Whatsapp Mobile number same as Mobile number?
                    <Switch
                      checked={checked}
                      onChange={onSameWhatsappMobileAsMobileChange}
                      style={{ backgroundColor: switchBackgroundColor }}
                      className="utr-form-category-h6-switch"
                    />
                  </h6>
                </div>
                {userType === "Agent" && (
                  <div className="utr-form-category">
                    <h4>Deals In</h4>
                    <div className="utr-form-types">
                      {["Sale", "Rent / Lease", "Rent / Lease"].map((deal) => (
                        <div
                          key={deal}
                          className={`utr-form-types-sub ${
                            isAgentDealSelected(deal) ? "selected" : ""
                          }`}
                          onClick={() => handleAgentDealsInChange(deal)}
                        >
                          <CgTag className="utr-form-types-sub-icon" />
                          <h5>{deal}</h5>
                        </div>
                      ))}
                      {/* {agentDetails.dealsIn.length > 0 && (
                        <div className="utr-form-types-clearselection">
                          <span onClick={handleClearAgentDealsInSelection}>
                            - Clear Selection
                          </span>
                        </div>
                      )} */}
                    </div>
                  </div>
                )}
                {userType === "Salesperson" && (
                  <div className="utr-form-category">
                    <h4>Career Details</h4>
                    <div className="utr-form-inputs">
                      <FormInput
                        icon={LiaUserTagSolid}
                        placeholder="Employee Designation"
                        disabled={
                          userType === "Salesperson" &&
                          userProfileData?.userAccessId
                        }
                        value={salespersonDetails.employeeDesignation}
                        onChange={(e) => {
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeeDesignation: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="utr-form-category utr-form-category-last">
                  <h4>Images</h4>
                  <div className="utr-form-images">
                    <ImageUpload
                      required={!(userType === "Owner" || userType === "Buyer")}
                      image={profileImage}
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                      onImageChange={(e) => {
                        setProfileImage(e.target.files[0]);
                        if (typeof profileImage !== "string") {
                          setShowSaveUserDetailsBtn(true);
                        }
                      }}
                      onRemove={() => {
                        setProfileImage(null);
                        setShowSaveUserDetailsBtn(false);
                      }}
                      label="Profile Photo"
                    />
                  </div>
                </div>
              </div>
            )}
            {selectedSection === "Firm Details" && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>{userType} Firm Details</h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      icon={TbBuildingEstate}
                      placeholder="Firm Name"
                      value={
                        userType === "Builder"
                          ? builderDetails.builderFirmName
                          : userType === "Agent"
                          ? agentDetails.agentFirmName
                          : userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          ? employeerDetails?.userType === "Agent"
                            ? employeerDetails?.agentDetails?.agentFirmName
                            : employeerDetails?.userType === "Builder"
                            ? employeerDetails?.builderDetails?.builderFirmName
                            : null
                          : salespersonDetails.employeeCompanyFirmName
                      }
                      onChange={(e) =>
                        userType === "Builder"
                          ? setBuilderDetails((prev) => ({
                              ...prev,
                              builderFirmName: e.target.value,
                            }))
                          : userType === "Agent"
                          ? setAgentDetails((prev) => ({
                              ...prev,
                              agentFirmName: e.target.value,
                            }))
                          : userType === "Salesperson" &&
                            !userProfileData?.userAccessId
                          ? setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeCompanyFirmName: e.target.value,
                            }))
                          : null
                      }
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                    />
                    <FormInput
                      icon={TbInputCheck}
                      placeholder="RERA No."
                      value={
                        userType === "Builder"
                          ? builderDetails.builderReraNo.toUpperCase()
                          : userType === "Agent"
                          ? agentDetails.agentReraNo.toUpperCase()
                          : userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          ? employeerDetails.userType === "Agent"
                            ? employeerDetails.agentDetails.agentReraNo.toUpperCase()
                            : employeerDetails.userType === "Builder"
                            ? employeerDetails.builderDetails.builderReraNo.toUpperCase()
                            : null
                          : salespersonDetails?.employeeCompanyReraNo.toUpperCase()
                      }
                      onChange={(e) =>
                        userType === "Builder"
                          ? setBuilderDetails((prev) => ({
                              ...prev,
                              builderReraNo: e.target.value,
                            }))
                          : userType === "Agent"
                          ? setAgentDetails((prev) => ({
                              ...prev,
                              agentReraNo: e.target.value,
                            }))
                          : userType === "Salesperson" &&
                            !userProfileData?.userAccessId
                          ? setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeCompanyReraNo: e.target.value,
                            }))
                          : null
                      }
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                    />
                    <div className="utr-form-input-cover">
                      {(
                        userType === "Builder"
                          ? builderDetails.builderReraNoType
                          : userType === "Agent"
                          ? agentDetails.agentReraNoType
                          : userType === "Salesperson"
                          ? salespersonDetails?.employeeCompanyReraNoType
                          : userType === "Employer"
                          ? employeerDetails.userType === "Agent"
                            ? employeerDetails.agentDetails.agentReraNoType
                            : employeerDetails.userType === "Builder"
                            ? employeerDetails.builderDetails.builderReraNoType
                            : undefined
                          : undefined
                      ) ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          Rera No. Type
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select red">
                          * Required
                        </h6>
                      )}
                      <div className="utr-form-input">
                        <LuTextCursorInput
                          className={`utr-form-input-icon ${
                            (
                              userType === "Builder"
                                ? builderDetails.builderReraNoType
                                : userType === "Agent"
                                ? agentDetails.agentReraNoType
                                : userType === "Salesperson" &&
                                  userProfileData?.userAccessId
                                ? employeerDetails.userType === "Agent"
                                  ? employeerDetails.agentDetails
                                      .agentReraNoType
                                  : employeerDetails.userType === "Builder"
                                  ? employeerDetails.builderDetails
                                      .builderReraNoType
                                  : ""
                                : salespersonDetails?.employeeCompanyReraNoType
                            )
                              ? "selected"
                              : ""
                          }`}
                        />
                        <Select
                          showSearch
                          name="builderReraNoType"
                          className="utr-form-input-select"
                          placeholder="Select Rera Number Type"
                          value={
                            userType === "Builder"
                              ? builderDetails.builderReraNoType || undefined
                              : userType === "Agent"
                              ? agentDetails.agentReraNoType || undefined
                              : userType === "Salesperson" &&
                                userProfileData?.userAccessId
                              ? employeerDetails.userType === "Agent"
                                ? employeerDetails.agentDetails
                                    .agentReraNoType || undefined
                                : employeerDetails.userType === "Builder"
                                ? employeerDetails.builderDetails
                                    .builderReraNoType || undefined
                                : ""
                              : salespersonDetails?.employeeCompanyReraNoType ||
                                undefined
                          }
                          optionFilterProp="label"
                          onChange={(value) =>
                            userType === "Salesperson" &&
                            !userProfileData?.userAccessId
                              ? setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeCompanyReraNoType: value,
                                }))
                              : setBuilderDetails((prev) => ({
                                  ...prev,
                                  builderReraNoType: value,
                                }))
                          }
                          options={[
                            {
                              value:
                                "(ANRA) - Andaman and Nicobar Islands Real Estate Regulatory Authority",
                              label:
                                "(ANRA) - Andaman and Nicobar Islands Real Estate Regulatory Authority",
                            },
                            {
                              value:
                                "(APRERA) - Arunachal Pradesh Real Estate Regulatory Authority",
                              label:
                                "(APRERA) - Arunachal Pradesh Real Estate Regulatory Authority",
                            },
                            {
                              value:
                                "(APTIDCO) - Andhra Pradesh Township and Infrastructure Development Corporation",
                              label:
                                "(APTIDCO) - Andhra Pradesh Township and Infrastructure Development Corporation",
                            },
                            {
                              value:
                                "(AUIIP) - Assam Urban Infrastructure Investment Program",
                              label:
                                "(AUIIP) - Assam Urban Infrastructure Investment Program",
                            },
                            {
                              value:
                                "(BUIDCO) - Bihar Urban Infrastructure Development Corporation",
                              label:
                                "(BUIDCO) - Bihar Urban Infrastructure Development Corporation",
                            },
                            {
                              value:
                                "(Bihar RERA) - Bihar Real Estate Regulatory Authority",
                              label:
                                "(Bihar RERA) - Bihar Real Estate Regulatory Authority",
                            },
                            {
                              value: "(CGHB) - Chhattisgarh Housing Board",
                              label: "(CGHB) - Chhattisgarh Housing Board",
                            },
                            {
                              value: "(CHB) - Chandigarh Housing Board",
                              label: "(CHB) - Chandigarh Housing Board",
                            },
                            {
                              value: "(DDA) - Delhi Development Authority",
                              label: "(DDA) - Delhi Development Authority",
                            },
                            {
                              value:
                                "(DNHDD RERA) - Dadra and Nagar Haveli and Daman and Diu Real Estate Regulatory Authority",
                              label:
                                "(DNHDD RERA) - Dadra and Nagar Haveli and Daman and Diu Real Estate Regulatory Authority",
                            },
                            {
                              value:
                                "(HUDA) - Haryana Urban Development Authority",
                              label:
                                "(HUDA) - Haryana Urban Development Authority",
                            },
                            {
                              value:
                                "(HIMUDA) - Himachal Pradesh Housing and Urban Development Authority",
                              label:
                                "(HIMUDA) - Himachal Pradesh Housing and Urban Development Authority",
                            },
                            {
                              value:
                                "(MHADA) - Maharashtra Housing and Area Development Authority",
                              label:
                                "(MHADA) - Maharashtra Housing and Area Development Authority",
                            },
                            {
                              value:
                                "(MUDA) - Meghalaya Urban Development Authority",
                              label:
                                "(MUDA) - Meghalaya Urban Development Authority",
                            },
                            {
                              value:
                                "(PUDA) - Punjab Urban Planning and Development Authority",
                              label:
                                "(PUDA) - Punjab Urban Planning and Development Authority",
                            },
                            {
                              value:
                                "(UHUDA) - Uttarakhand Housing and Urban Development Authority",
                              label:
                                "(UHUDA) - Uttarakhand Housing and Urban Development Authority",
                            },
                            {
                              value:
                                "(WBHIDCO) - West Bengal Housing Infrastructure Development Corporation",
                              label:
                                "(WBHIDCO) - West Bengal Housing Infrastructure Development Corporation",
                            },
                          ]}
                          disabled={
                            userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Where your firm is located?</h4>
                  <div className="utr-form-inputs">
                    <div className="utr-form-input-cover">
                      {(
                        userType === "Builder"
                          ? builderDetails.builderAddress
                          : userType === "Agent"
                          ? agentDetails.agentAddress
                          : userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          ? employeerDetails.userType === "Agent"
                            ? employeerDetails.agentDetails.agentOfficeAddress
                            : employeerDetails.userType === "Builder"
                            ? employeerDetails.builderDetails
                                .builderOfficeAddress
                            : ""
                          : salespersonDetails.employeeCompanyAddress
                      ) ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          {userType} Firm Address
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select red">
                          * Required
                        </h6>
                      )}
                      <div className="utr-form-input utr-form-input-flex">
                        <textarea
                          type="text"
                          placeholder={
                            userType === "Builder"
                              ? "Builder Firm Address"
                              : userType === "Agent"
                              ? "Agent Firm Address"
                              : "Firm Address"
                          }
                          value={
                            userType === "Builder"
                              ? builderDetails.builderAddress
                              : userType === "Agent"
                              ? agentDetails.agentAddress
                              : userType === "Salesperson" &&
                                userProfileData?.userAccessId
                              ? employeerDetails.userType === "Agent"
                                ? employeerDetails.agentDetails
                                    .agentOfficeAddress
                                : employeerDetails.userType === "Builder"
                                ? employeerDetails.builderDetails
                                    .builderOfficeAddress
                                : ""
                              : salespersonDetails.employeeCompanyAddress
                          }
                          disabled={
                            userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          }
                          onChange={
                            userType === "Builder"
                              ? handleBuilderAddressChange
                              : userType === "Agent"
                              ? handleagentAddressChange
                              : userType === "Salesperson" &&
                                !userProfileData?.userAccessId
                              ? handleSalespersonCompanyAddressChange ||
                                undefined
                              : null
                          }
                          className={
                            userType === "Builder"
                              ? builderAddressCharacterCount ||
                                builderDetails?.builderAddress?.length === 100
                                ? "utr-form-input-max-limit-reached"
                                : builderDetails.builderAddress?.trim() !== ""
                                ? "utr-form-input-selected"
                                : ""
                              : userType === "Agent"
                              ? agentAddressCharacterCount ||
                                agentDetails?.agentAddress?.length === 100
                                ? "utr-form-input-max-limit-reached"
                                : agentDetails.agentAddress.trim() !== ""
                                ? "utr-form-input-selected"
                                : ""
                              : ""
                          }
                        />
                        <div
                          className={
                            userType === "Builder"
                              ? builderAddressCharacterCount ||
                                builderDetails?.builderAddress?.length === 100
                                ? "utr-form-input-sub-max-limit-reached"
                                : "utr-form-input-sub"
                              : userType === "Agent"
                              ? agentAddressCharacterCount ||
                                agentDetails?.agentAddress?.length === 100
                                ? "utr-form-input-sub-max-limit-reached"
                                : "utr-form-input-sub"
                              : userType === "Salesperson" &&
                                !userProfileData?.userAccessId
                              ? salespersonCompanyAddressCharacterCount ||
                                salespersonDetails?.employeeCompanyAddress
                                  ?.length === 100
                                ? "utr-form-input-sub-max-limit-reached"
                                : "utr-form-input-sub"
                              : userType === "Salesperson" &&
                                userProfileData?.userAccessId &&
                                (employeerDetails.userType === "Agent"
                                  ? employeerDetails.agentDetails
                                      ?.agentOfficeAddress?.length >= 100
                                  : employeerDetails.userType === "Builder"
                                  ? employeerDetails.builderDetails
                                      ?.builderOfficeAddress?.length >= 100
                                  : false)
                              ? "utr-form-input-sub-max-limit-reached"
                              : "utr-form-input-sub"
                          }
                        >
                          {
                            <span>
                              {userType && userType === "Builder"
                                ? builderAddressSpanContent
                                : userType === "Agent"
                                ? agentAddressSpanContent
                                : userType === "Salesperson" &&
                                  !userProfileData?.userAccessId
                                ? salespersonCompanyAddressSpanContent
                                : null}
                            </span>
                          }
                          {
                            <span>
                              {userType && userType === "Builder"
                                ? builderAddressCharacterCount ||
                                  builderDetails?.builderAddress?.length
                                : userType === "Agent"
                                ? agentAddressCharacterCount ||
                                  agentDetails?.agentAddress?.length
                                : userType === "Salesperson" &&
                                  !userProfileData?.userAccessId
                                ? salespersonCompanyAddressCharacterCount ||
                                  salespersonDetails?.employeeCompanyAddress
                                    ?.length
                                : userType === "Salesperson" &&
                                  userProfileData?.userAccessId
                                ? employeerDetails.userType === "Agent"
                                  ? employeerDetails.agentDetails
                                      .agentOfficeAddress?.length
                                  : employeerDetails.userType === "Builder"
                                  ? employeerDetails.builderDetails
                                      .builderOfficeAddress?.length
                                  : null
                                : 0}
                              / 100
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>
                    {!userProfileData?.userAccessId
                      ? "Company"
                      : employeerDetails?.userType || "Employeer"}{" "}
                    Photos
                  </h4>
                  <div className="utr-form-images">
                    <ImageUpload
                      image={
                        userType === "Builder"
                          ? builderDetails.builderFirmLogo
                          : userType === "Agent"
                          ? agentDetails.agentFirmLogo
                          : userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          ? employeerDetails.userType === "Agent"
                            ? employeerDetails.agentDetails.agentFirmLogo
                            : employeerDetails.userType === "Builder"
                            ? employeerDetails.builderDetails.builderFirmLogo
                            : null
                          : salespersonDetails?.employeeCompanyFirmLogo
                      }
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                      onImageChange={(e) => {
                        if (userType === "Builder") {
                          setBuilderDetails((prev) => ({
                            ...prev,
                            builderFirmLogo: e.target.files[0],
                          }));
                        } else if (userType === "Agent") {
                          setAgentDetails((prev) => ({
                            ...prev,
                            agentFirmLogo: e.target.files[0],
                          }));
                        } else if (
                          userType === "Salesperson" &&
                          !userProfileData?.userAccessId
                        ) {
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeeCompanyFirmLogo: e.target.files[0],
                          }));
                        }
                      }}
                      onRemove={() => {
                        if (userType === "Builder") {
                          setBuilderDetails((prev) => ({
                            ...prev,
                            builderFirmLogo: null,
                          }));
                        } else if (userType === "Agent") {
                          setAgentDetails((prev) => ({
                            ...prev,
                            agentFirmLogo: null,
                          }));
                        } else if (
                          userType === "Salesperson" &&
                          !userProfileData?.userAccessId
                        ) {
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeeCompanyFirmLogo: null,
                          }));
                        }
                      }}
                      label="Firm Logo"
                    />
                  </div>
                </div>
              </div>
            )}
            {selectedSection === "Office Details" && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>{userType} Office Details</h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      required={false}
                      icon={TbBuildingEstate}
                      placeholder="Office Name"
                      value={
                        userType === "Builder"
                          ? builderDetails.builderOfficeName
                          : userType === "Agent"
                          ? agentDetails.agentOfficeName
                          : userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          ? employeerDetails.userType === "Agent"
                            ? employeerDetails.agentDetails.agentOfficeName
                            : employeerDetails.userType === "Builder"
                            ? employeerDetails.builderDetails.builderOfficeName
                            : "Office name not provided"
                          : salespersonDetails?.employeeCompanyOfficeName
                      }
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                      onChange={(e) =>
                        userType === "Builder"
                          ? setBuilderDetails((prev) => ({
                              ...prev,
                              builderOfficeName: e.target.value,
                            }))
                          : userType === "Agent"
                          ? setAgentDetails((prev) => ({
                              ...prev,
                              agentOfficeName: e.target.value,
                            }))
                          : userType === "Salesperson" &&
                            !userProfileData?.userAccessId
                          ? setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeCompanyOfficeName: e.target.value,
                            }))
                          : null
                      }
                    />
                    <div className="utr-form-input-cover">
                      {(
                        userType === "Builder"
                          ? builderDetails.builderOfficeCity || undefined
                          : userType === "Agent"
                          ? agentDetails.agentOfficeCity || undefined
                          : userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          ? employeerDetails.userType === "Agent"
                            ? employeerDetails.agentDetails?.agentOfficeCity ||
                              undefined
                            : employeerDetails.userType === "Builder"
                            ? employeerDetails.builderDetails
                                ?.builderOfficeCity || undefined
                            : "Not provided"
                          : salespersonDetails?.employeeCompanyOfficeCity
                      ) ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          Office City
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select red">
                          * Required
                        </h6>
                      )}
                      <div className="utr-form-input">
                        <PiCity
                          className={`utr-form-input-icon ${
                            (
                              userType === "Builder"
                                ? builderDetails.builderOfficeCity
                                : userType === "Agent"
                                ? agentDetails.agentOfficeCity
                                : userType === "Salesperson" &&
                                  userProfileData?.userAccessId
                                ? employeerDetails.userType === "Agent"
                                  ? employeerDetails.agentDetails
                                      ?.agentOfficeCity
                                  : employeerDetails.userType === "Builder"
                                  ? employeerDetails.builderDetails
                                      ?.builderOfficeCity
                                  : "Not provided"
                                : salespersonDetails?.employeeCompanyOfficeCity
                            )
                              ? "selected"
                              : ""
                          }`}
                        />
                        <Select
                          showSearch
                          className="utr-form-input-select"
                          placeholder="Select a city"
                          value={
                            userType === "Builder"
                              ? builderDetails.builderOfficeCity || undefined
                              : userType === "Agent"
                              ? agentDetails.agentOfficeCity || undefined
                              : userType === "Salesperson" &&
                                userProfileData?.userAccessId
                              ? employeerDetails.userType === "Agent"
                                ? employeerDetails.agentDetails
                                    .agentOfficeCity || undefined
                                : employeerDetails.userType === "Builder"
                                ? employeerDetails.builderDetails
                                    .builderOfficeCity || undefined
                                : "Not provided"
                              : salespersonDetails?.employeeCompanyOfficeCity ||
                                undefined
                          }
                          optionFilterProp="label"
                          onChange={(value) =>
                            userType === "Builder"
                              ? setBuilderDetails((prev) => ({
                                  ...prev,
                                  builderOfficeCity: value,
                                }))
                              : userType === "Agent"
                              ? setAgentDetails((prev) => ({
                                  ...prev,
                                  agentOfficeCity: value,
                                }))
                              : userType === "Salesperson" &&
                                !userProfileData?.userAccessId
                              ? setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeCompanyOfficeCity: value,
                                }))
                              : null
                          }
                          options={cityOptions.map((option) => ({
                            value: option.cityName,
                            label: option.cityName,
                          }))}
                          disabled={
                            userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          }
                        />
                      </div>
                    </div>
                    <FormInput
                      // userProfileData?.profileData?.hasOwnProperty("officeWebsite") &&
                      //         userProfileData?.profileData?.officeWebsite !== "undefined"
                      required={false}
                      icon={PiBracketsCurlyBold}
                      placeholder="Website Url (ex. https://proptzo.com/)"
                      value={
                        userType === "Builder"
                          ? builderDetails?.builderOfficeWebsite
                          : userType === "Agent"
                          ? agentDetails?.agentOfficeWebsite
                          : userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          ? employeerDetails.userType === "Agent" &&
                            employeerDetails.agentDetails?.hasOwnProperty(
                              "agentOfficeWebsite"
                            ) &&
                            employeerDetails.agentDetails.agentOfficeWebsite !==
                              "undefined"
                            ? employeerDetails.agentDetails.agentOfficeWebsite
                            : employeerDetails.userType === "Builder" &&
                              employeerDetails.builderDetails?.hasOwnProperty(
                                "builderOfficeWebsite"
                              ) &&
                              employeerDetails.builderDetails
                                .builderOfficeWebsite !== "undefined"
                            ? employeerDetails.builderDetails
                                .builderOfficeWebsite
                            : ""
                          : salespersonDetails?.employeeCompanyOfficeWebsite !==
                            "undefined"
                          ? salespersonDetails?.employeeCompanyOfficeWebsite
                          : ""
                      }
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                      onChange={(e) =>
                        userType === "Builder"
                          ? setBuilderDetails((prev) => ({
                              ...prev,
                              builderOfficeWebsite: e.target.value,
                            }))
                          : userType === "Agent"
                          ? setAgentDetails((prev) => ({
                              ...prev,
                              agentOfficeWebsite: e.target.value,
                            }))
                          : userType === "Salesperson" &&
                            !userProfileData?.userAccessId
                          ? setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeCompanyOfficeWebsite: e.target.value,
                            }))
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {selectedSection === "Employees" && (
              <>
                {!isBuilderEditing && (
                  <>
                    {userType !== "Agent" && (
                      <div className="utr-form-sub">
                        {builderAddedEmployeesList.length === 0 && (
                          <div className="utr-form-category">
                            <h4>0 Employees added.</h4>
                          </div>
                        )}
                        {builderAddedEmployeesList.length > 0 && (
                          <>
                            <div className="utr-form-category">
                              <h4>
                                {builderAddedEmployeesList.length === 1
                                  ? "Added Employee"
                                  : "Added Employees"}{" "}
                                ({builderAddedEmployeesList.length})
                              </h4>
                              <List
                                itemLayout="horizontal"
                                dataSource={builderAddedEmployeesList}
                                renderItem={(item, index) => (
                                  <List.Item
                                    key={index}
                                    actions={[
                                      <h5
                                        className="utr-employees-remove blue"
                                        onClick={() =>
                                          handleEditBuilderEmployee(index)
                                        }
                                      >
                                        edit
                                      </h5>,
                                      <h5
                                        className="utr-employees-remove red"
                                        onClick={() =>
                                          removeBuilderEmployee(index)
                                        }
                                      >
                                        remove
                                      </h5>,
                                    ]}
                                  >
                                    <List.Item.Meta
                                      avatar={
                                        <img
                                          src={renderBuilderEmployeeImage(
                                            item.employeeImage,
                                            item.employeeName?.charAt(0),
                                            item
                                          )}
                                          alt="Profile"
                                          className="utr-employees-img"
                                        />
                                      }
                                      title={
                                        <div className="utr-employees-name">
                                          {item.employeeName}
                                        </div>
                                      }
                                      description={
                                        <div>
                                          <div className="utr-employees-description">
                                            {item.employeeDesignation} /{" "}
                                            {item.employeeEmail} /{" "}
                                            {item.employeeMobileNo} /{" "}
                                            {item.employeeID}
                                          </div>
                                          {item.employeementLetterImage ? (
                                            <img
                                              src={renderBuilderEmployeeImage(
                                                item.employeementLetterImage,
                                                "EL"
                                              )}
                                              alt="Employment Letter"
                                              className="utr-employees-img-docs"
                                            />
                                          ) : (
                                            <img
                                              src={`https://placehold.co/30x30?text=EL`}
                                              alt="Employment Letter Placeholder"
                                              className="utr-employees-img-docs"
                                            />
                                          )}
                                          {item.employeeIdCardImage ? (
                                            <img
                                              src={renderBuilderEmployeeImage(
                                                item.employeeIdCardImage,
                                                "ID"
                                              )}
                                              alt="ID Card"
                                              className="utr-employees-img-docs"
                                            />
                                          ) : (
                                            <img
                                              src={`https://placehold.co/30x30?text=ID`}
                                              alt="ID Card Placeholder"
                                              className="utr-employees-img-docs"
                                            />
                                          )}
                                        </div>
                                      }
                                    />
                                  </List.Item>
                                )}
                              />
                            </div>
                            <div className="utr-form-category">
                              <h4>Note</h4>
                              <h6>
                                The added employee profiles are automatically
                                created with these credentials. Review
                                carefully, as employees will see these details
                                upon login and will not be able to change them.
                              </h6>
                            </div>
                          </>
                        )}
                        {builderTempAddedEmployeesList.length === 0 && (
                          <div className="utr-form-category">
                            <h4>0 Pending invitations.</h4>
                          </div>
                        )}
                        {builderTempAddedEmployeesList.length > 0 && (
                          <>
                            <div className="utr-form-category">
                              <h4>
                                {builderTempAddedEmployeesList.length === 1
                                  ? "Pending invitation"
                                  : "Pending invitations"}{" "}
                                ({builderTempAddedEmployeesList.length})
                              </h4>
                              <List
                                itemLayout="horizontal"
                                dataSource={builderTempAddedEmployeesList}
                                renderItem={(item, index) => (
                                  <List.Item
                                    key={index}
                                    actions={[
                                      <h5
                                        className="utr-employees-remove red"
                                        onClick={() =>
                                          removeBuilderEmployee(index)
                                        }
                                      >
                                        remove
                                      </h5>,
                                    ]}
                                  >
                                    <List.Item.Meta
                                      avatar={
                                        <img
                                          src={renderBuilderEmployeeImage(
                                            item.employeeImage,
                                            item.employeeName?.charAt(0),
                                            item
                                          )}
                                          alt="Profile"
                                          className="utr-employees-img"
                                        />
                                      }
                                      title={
                                        <div className="utr-employees-name">
                                          {item.employeeName}
                                        </div>
                                      }
                                      description={
                                        <div>
                                          <div className="utr-employees-description">
                                            {item.employeeDesignation} /{" "}
                                            {item.employeeEmail} /{" "}
                                            {item.employeeMobileNo} /{" "}
                                            {item.employeeID}
                                          </div>
                                          {item.employeementLetterImage ? (
                                            <img
                                              src={renderBuilderEmployeeImage(
                                                item.employeementLetterImage,
                                                "EL"
                                              )}
                                              alt="Employment Letter"
                                              className="utr-employees-img-docs"
                                            />
                                          ) : (
                                            <img
                                              src={`https://placehold.co/30x30?text=EL`}
                                              alt="Employment Letter Placeholder"
                                              className="utr-employees-img-docs"
                                            />
                                          )}
                                          {item.employeeIdCardImage ? (
                                            <img
                                              src={renderBuilderEmployeeImage(
                                                item.employeeIdCardImage,
                                                "ID"
                                              )}
                                              alt="ID Card"
                                              className="utr-employees-img-docs"
                                            />
                                          ) : (
                                            <img
                                              src={`https://placehold.co/30x30?text=ID`}
                                              alt="ID Card Placeholder"
                                              className="utr-employees-img-docs"
                                            />
                                          )}
                                        </div>
                                      }
                                    />
                                  </List.Item>
                                )}
                              />
                            </div>
                            <div className="utr-form-category">
                              <h4>Note</h4>
                              <h6>
                                The added employee profiles are automatically
                                created with these credentials. Review
                                carefully, as employees will see these details
                                upon login and will not be able to change them.
                              </h6>
                            </div>
                          </>
                        )}
                        <div className="utr-form-category utr-form-category-last">
                          <h4>Add New Employee</h4>
                          <Collapse
                            // ghost
                            items={builderEmployeesCollapse}
                            bordered={true}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {isBuilderEditing && (
                  <>
                    <div className="utr-form-sub">
                      <div className="utr-form-category">
                        <h4>Update Your Employee Details</h4>
                        <div className="utr-form-inputs">
                          <FormInput
                            icon={MdDriveFileRenameOutline}
                            placeholder="Employee Full Name"
                            value={builderEmployeeUpdateDetails.employeeName}
                            onChange={(e) =>
                              setBuilderEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeName: e.target.value,
                              }))
                            }
                          />
                          <FormInput
                            icon={LiaUserTagSolid}
                            placeholder="Employee Designation"
                            value={
                              builderEmployeeUpdateDetails.employeeDesignation
                            }
                            onChange={(e) =>
                              setBuilderEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeDesignation: e.target.value,
                              }))
                            }
                          />
                          <FormInput
                            icon={MdOutlineEmail}
                            placeholder="Employee Email"
                            value={builderEmployeeUpdateDetails.employeeEmail}
                            onChange={(e) =>
                              setBuilderEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeEmail: e.target.value,
                              }))
                            }
                          />
                          <FormInput
                            icon={MdOutlinePhoneEnabled}
                            placeholder="Employee Mobile No."
                            value={formatBuilderEmployeeUpdateMobileDisplay(
                              builderEmployeeUpdateDetails.employeeMobileNo
                            )}
                            onChange={handleBuilderEmployeeUpdateMobileChange}
                            maxLength="14"
                          />
                          <FormInput
                            icon={LiaAddressCardSolid}
                            placeholder="Employee ID card"
                            value={builderEmployeeUpdateDetails.employeeID}
                            onChange={(e) =>
                              setBuilderEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeID: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                      <div className="utr-form-category utr-form-category-last">
                        <h4>Employee Photos</h4>
                        <div className="utr-form-images">
                          <ImageUpload
                            image={builderEmployeeUpdateDetails.employeeImage}
                            onImageChange={(e) =>
                              setBuilderEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeImage: e.target.files[0],
                              }))
                            }
                            onRemove={() =>
                              setBuilderEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeImage: null,
                              }))
                            }
                            label="Profile"
                          />
                          <ImageUpload
                            required={false}
                            image={
                              builderEmployeeUpdateDetails.employeementLetterImage
                            }
                            onImageChange={(e) =>
                              setBuilderEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeementLetterImage: e.target.files[0],
                              }))
                            }
                            onRemove={() =>
                              setBuilderEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeementLetterImage: null,
                              }))
                            }
                            label="Employment Letter"
                          />
                          <ImageUpload
                            image={
                              builderEmployeeUpdateDetails.employeeIdCardImage
                            }
                            onImageChange={(e) =>
                              setBuilderEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeIdCardImage: e.target.files[0],
                              }))
                            }
                            onRemove={() =>
                              setBuilderEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeIdCardImage: null,
                              }))
                            }
                            label="ID Card"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!isAgentEditing && (
                  <>
                    {userType !== "Builder" && (
                      <div className="utr-form-sub">
                        {agentAddedEmployeesList.length === 0 && (
                          <div className="utr-form-category">
                            <h4>0 Employees added.</h4>
                          </div>
                        )}
                        {agentAddedEmployeesList.length > 0 && (
                          <>
                            <div className="utr-form-category">
                              <h4>
                                {agentAddedEmployeesList.length === 1
                                  ? "Added Employee"
                                  : "Added Employees"}{" "}
                                ({agentAddedEmployeesList.length})
                              </h4>
                              <List
                                itemLayout="horizontal"
                                dataSource={agentAddedEmployeesList}
                                renderItem={(item, index) => (
                                  <List.Item
                                    key={index}
                                    actions={[
                                      <h5
                                        className="utr-employees-remove blue"
                                        onClick={() =>
                                          handleEditAgentEmployee(index)
                                        }
                                      >
                                        edit
                                      </h5>,
                                      <h5
                                        className="utr-employees-remove red"
                                        onClick={() =>
                                          removeAgentEmployee(index)
                                        }
                                      >
                                        remove
                                      </h5>,
                                    ]}
                                  >
                                    <List.Item.Meta
                                      avatar={
                                        <img
                                          src={renderAgentEmployeeImage(
                                            item.employeeImage,
                                            item.employeeName?.charAt(0),
                                            item
                                          )}
                                          alt="Profile"
                                          className="utr-employees-img"
                                        />
                                      }
                                      title={
                                        <div className="utr-employees-name">
                                          {item.employeeName}
                                        </div>
                                      }
                                      description={
                                        <div>
                                          <div className="utr-employees-description">
                                            {item.employeeDesignation} /{" "}
                                            {item.employeeEmail} /{" "}
                                            {item.employeeMobileNo} /{" "}
                                            {item.employeeID}
                                          </div>
                                          {item.employeeEmployeementLetter ? (
                                            <img
                                              src={renderAgentEmployeeImage(
                                                item.employeeEmployeementLetter,
                                                "EL"
                                              )}
                                              alt="Employment Letter"
                                              className="utr-employees-img-docs"
                                            />
                                          ) : (
                                            <img
                                              src={`https://placehold.co/30x30?text=EL`}
                                              alt="Employment Letter Placeholder"
                                              className="utr-employees-img-docs"
                                            />
                                          )}
                                          {item.selectedEmployeeIdType ===
                                            "Employee ID Card" &&
                                            (item.employeeIdCardImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeIdCardImage,
                                                  "ID"
                                                )}
                                                alt="ID Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=ID`}
                                                alt="ID Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}
                                          {item.selectedEmployeeIdType ===
                                            "Aadhaar Card" &&
                                            (item.employeeAadhaarcardFrontImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeAadhaarcardFrontImage,
                                                  "AC"
                                                )}
                                                alt="Aadhaar Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=AC`}
                                                alt="Aadhaar Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Aadhaar Card" &&
                                            (item.employeeAadhaarcardBackImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeAadhaarcardBackImage,
                                                  "AC"
                                                )}
                                                alt="Aadhaar Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=AC`}
                                                alt="Aadhaar Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Pan Card" &&
                                            (item.employeePancardImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeePancardImage,
                                                  "PC"
                                                )}
                                                alt="Pan Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=PC`}
                                                alt="pan Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Voter Card" &&
                                            (item.employeeVoterIdFrontImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeVoterIdFrontImage,
                                                  "VC"
                                                )}
                                                alt="Voter Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=VC`}
                                                alt="Voter Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Voter Card" &&
                                            (item.employeeVoterIdBackImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeVoterIdBackImage,
                                                  "VC"
                                                )}
                                                alt="Voter Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=VC`}
                                                alt="Voter Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}
                                          {item.selectedEmployeeIdType ===
                                            "Passport" &&
                                            (item.employeePassportImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeePassportImage,
                                                  "P"
                                                )}
                                                alt="Passport"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=P`}
                                                alt="Passport Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Driving License" &&
                                            (item.employeeDrivinglicenseFrontImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeDrivinglicenseFrontImage,
                                                  "DL"
                                                )}
                                                alt="Driving License"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=DL`}
                                                alt="Driving license Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Driving License" &&
                                            (item.employeeDrivinglicenseBackImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeDrivinglicenseBackImage,
                                                  "DL"
                                                )}
                                                alt="Driving license"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=DL`}
                                                alt="Driving license Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}
                                        </div>
                                      }
                                    />
                                  </List.Item>
                                )}
                              />
                            </div>
                            <div className="utr-form-category">
                              <h4>Note</h4>
                              <h6>
                                The added employee profiles are automatically
                                created with these credentials. Review
                                carefully, as employees will see these details
                                upon login and will not be able to change them.
                              </h6>
                            </div>
                          </>
                        )}
                        {agentTempAddedEmployeesList.length === 0 && (
                          <div className="utr-form-category">
                            <h4>0 Pending invitations.</h4>
                          </div>
                        )}
                        {agentTempAddedEmployeesList.length > 0 && (
                          <>
                            <div className="utr-form-category">
                              <h4>
                                {agentTempAddedEmployeesList.length === 1
                                  ? "Pending invitation"
                                  : "Pending invitations"}{" "}
                                ({agentTempAddedEmployeesList.length})
                              </h4>
                              <List
                                itemLayout="horizontal"
                                dataSource={agentTempAddedEmployeesList}
                                renderItem={(item, index) => (
                                  <List.Item
                                    key={index}
                                    actions={[
                                      <h5
                                        className="utr-employees-remove red"
                                        onClick={() =>
                                          removeAgentEmployee(index)
                                        }
                                      >
                                        remove
                                      </h5>,
                                    ]}
                                  >
                                    <List.Item.Meta
                                      avatar={
                                        <img
                                          src={renderAgentEmployeeImage(
                                            item.employeeImage,
                                            item.employeeName?.charAt(0),
                                            item
                                          )}
                                          alt="Profile"
                                          className="utr-employees-img"
                                        />
                                      }
                                      title={
                                        <div className="utr-employees-name">
                                          {item.employeeName}
                                        </div>
                                      }
                                      description={
                                        <div>
                                          <div className="utr-employees-description">
                                            {item.employeeDesignation} /{" "}
                                            {item.employeeEmail} /{" "}
                                            {item.employeeMobileNo} /{" "}
                                            {item.employeeID}
                                          </div>
                                          {item.employeeEmployeementLetter ? (
                                            <img
                                              src={renderAgentEmployeeImage(
                                                item.employeeEmployeementLetter,
                                                "EL"
                                              )}
                                              alt="Employment Letter"
                                              className="utr-employees-img-docs"
                                            />
                                          ) : (
                                            <img
                                              src={`https://placehold.co/30x30?text=EL`}
                                              alt="Employment Letter Placeholder"
                                              className="utr-employees-img-docs"
                                            />
                                          )}
                                          {item.selectedEmployeeIdType ===
                                            "Employee ID Card" &&
                                            (item.employeeIdCardImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeIdCardImage,
                                                  "ID"
                                                )}
                                                alt="ID Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=ID`}
                                                alt="ID Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}
                                          {item.selectedEmployeeIdType ===
                                            "Aadhaar Card" &&
                                            (item.employeeAadhaarcardFrontImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeAadhaarcardFrontImage,
                                                  "AC"
                                                )}
                                                alt="Aadhaar Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=AC`}
                                                alt="Aadhaar Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Aadhaar Card" &&
                                            (item.employeeAadhaarcardBackImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeAadhaarcardBackImage,
                                                  "AC"
                                                )}
                                                alt="Aadhaar Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=AC`}
                                                alt="Aadhaar Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Pan Card" &&
                                            (item.employeePancardImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeePancardImage,
                                                  "PC"
                                                )}
                                                alt="Pan Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=PC`}
                                                alt="pan Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Voter Card" &&
                                            (item.employeeVoterIdFrontImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeVoterIdFrontImage,
                                                  "VC"
                                                )}
                                                alt="Voter Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=VC`}
                                                alt="Voter Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Voter Card" &&
                                            (item.employeeVoterIdBackImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeVoterIdBackImage,
                                                  "VC"
                                                )}
                                                alt="Voter Card"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=VC`}
                                                alt="Voter Card Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}
                                          {item.selectedEmployeeIdType ===
                                            "Passport" &&
                                            (item.employeePassportImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeePassportImage,
                                                  "P"
                                                )}
                                                alt="Passport"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=P`}
                                                alt="Passport Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Driving License" &&
                                            (item.employeeDrivinglicenseFrontImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeDrivinglicenseFrontImage,
                                                  "DL"
                                                )}
                                                alt="Driving License"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=DL`}
                                                alt="Driving license Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}

                                          {item.selectedEmployeeIdType ===
                                            "Driving License" &&
                                            (item.employeeDrivinglicenseBackImage ? (
                                              <img
                                                src={renderAgentEmployeeImage(
                                                  item.employeeDrivinglicenseBackImage,
                                                  "DL"
                                                )}
                                                alt="Driving license"
                                                className="utr-employees-img-docs"
                                              />
                                            ) : (
                                              <img
                                                src={`https://placehold.co/30x30?text=DL`}
                                                alt="Driving license Placeholder"
                                                className="utr-employees-img-docs"
                                              />
                                            ))}
                                        </div>
                                      }
                                    />
                                  </List.Item>
                                )}
                              />
                            </div>
                            <div className="utr-form-category">
                              <h4>Note</h4>
                              <h6>
                                The added employee profiles are automatically
                                created with these credentials. Review
                                carefully, as employees will see these details
                                upon login and will not be able to change them.
                              </h6>
                            </div>
                          </>
                        )}
                        <div className="utr-form-category utr-form-category-last">
                          <h4>Add New Employee</h4>
                          <Collapse
                            // ghost
                            items={agentEmployeesCollapse}
                            bordered={true}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {isAgentEditing && (
                  <>
                    <div className="utr-form-sub">
                      <div className="utr-form-category">
                        <h4>Update Your Employee Details</h4>
                        <div className="utr-form-inputs">
                          <FormInput
                            icon={MdDriveFileRenameOutline}
                            placeholder="Employee Full Name"
                            value={agentEmployeeUpdateDetails.employeeName}
                            onChange={(e) =>
                              setAgentEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeName: e.target.value,
                              }))
                            }
                          />
                          <FormInput
                            icon={LiaUserTagSolid}
                            placeholder="Employee Designation"
                            value={
                              agentEmployeeUpdateDetails.employeeDesignation
                            }
                            onChange={(e) =>
                              setAgentEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeDesignation: e.target.value,
                              }))
                            }
                          />
                          <FormInput
                            icon={MdOutlineEmail}
                            placeholder="Employee Email"
                            value={agentEmployeeUpdateDetails.employeeEmail}
                            onChange={(e) =>
                              setAgentEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeEmail: e.target.value,
                              }))
                            }
                          />
                          <FormInput
                            icon={MdOutlinePhoneEnabled}
                            placeholder="Employee Mobile No."
                            value={formatAgentEmployeeUpdateMobileDisplay(
                              agentEmployeeUpdateDetails.employeeMobileNo
                            )}
                            onChange={handleAgentEmployeeUpdateMobileChange}
                            maxLength="14"
                          />
                          <div className="utr-form-input-cover">
                            {agentEmployeeUpdateDetails.employeeIdType ? (
                              <h6 className="utr-form-input-cover-h6-select">
                                Select ID Type
                              </h6>
                            ) : (
                              <h6 className="utr-form-input-cover-h6-select red">
                                * Required
                              </h6>
                            )}
                            <div className="utr-form-input">
                              <HiOutlineDocumentReport
                                className={`utr-form-input-icon ${
                                  agentEmployeeUpdateDetails.employeeIdType
                                    ? "selected"
                                    : ""
                                }`}
                              />
                              <Select
                                showSearch
                                name="agentEmployeeIdType"
                                className="utr-form-input-select"
                                placeholder="Select ID Type"
                                value={
                                  agentEmployeeUpdateDetails.employeeIdType ||
                                  undefined
                                }
                                optionFilterProp="label"
                                onChange={handleAgentEmployeeUpdateIdTypeChange}
                                options={[
                                  {
                                    value: "Employee ID Card",
                                    label: "Employee ID Card",
                                  },
                                  {
                                    value: "Aadhaar Card",
                                    label: "Employee Aadhaar Card",
                                  },
                                  {
                                    value: "Pan Card",
                                    label: "Employee Pan Card",
                                  },
                                  {
                                    value: "Voter Card",
                                    label: "Employee Voter Card",
                                  },
                                  {
                                    value: "Passport",
                                    label: "Employee Passport",
                                  },
                                  {
                                    value: "Driving License",
                                    label: "Employee Driving License",
                                  },
                                ]}
                              />
                            </div>
                          </div>
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Employee ID Card" && (
                            <FormInput
                              icon={LiaAddressCardSolid}
                              placeholder="Employee ID"
                              value={agentEmployeeUpdateDetails.employeeID}
                              onChange={(e) =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeeID: e.target.value,
                                }))
                              }
                            />
                          )}
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Aadhaar Card" && (
                            <FormInput
                              icon={LiaAddressCardSolid}
                              placeholder="Aadhaar Card No. (last 4 digits)"
                              value={
                                agentEmployeeUpdateDetails.employeeAadhaarCardNo
                              }
                              onChange={handleAgentEmployeeUpdateAadhaarCardNo}
                              maxLength="4"
                            />
                          )}
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Pan Card" && (
                            <FormInput
                              icon={LiaAddressCardSolid}
                              placeholder="Pan Card No."
                              value={
                                agentEmployeeUpdateDetails.employeePanCardNo
                              }
                              onChange={(e) =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeePanCardNo: e.target.value,
                                }))
                              }
                            />
                          )}
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Voter Card" && (
                            <FormInput
                              icon={LiaAddressCardSolid}
                              placeholder="Voter Card No."
                              value={
                                agentEmployeeUpdateDetails.employeeVoterCardNo
                              }
                              onChange={(e) =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeeVoterCardNo: e.target.value,
                                }))
                              }
                            />
                          )}
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Passport" && (
                            <FormInput
                              icon={LiaAddressCardSolid}
                              placeholder="Passport No."
                              value={
                                agentEmployeeUpdateDetails.employeePassportNo
                              }
                              onChange={(e) =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeePassportNo: e.target.value,
                                }))
                              }
                            />
                          )}
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Driving License" && (
                            <FormInput
                              icon={LiaAddressCardSolid}
                              placeholder="Driving License No."
                              value={
                                agentEmployeeUpdateDetails.employeeDrivingLicenseNo
                              }
                              onChange={(e) =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeeDrivingLicenseNo: e.target.value,
                                }))
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="utr-form-category">
                        <h4>Employee Photos</h4>
                        <div className="utr-form-images">
                          <ImageUpload
                            image={agentEmployeeUpdateDetails.employeeImage}
                            onImageChange={(e) =>
                              setAgentEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeImage: e.target.files[0],
                              }))
                            }
                            onRemove={() =>
                              setAgentEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeeImage: null,
                              }))
                            }
                            label="Employee Photo"
                          />
                          <ImageUpload
                            required={false}
                            image={
                              agentEmployeeUpdateDetails.employeementLetterImage
                            }
                            onImageChange={(e) =>
                              setAgentEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeementLetterImage: e.target.files[0],
                              }))
                            }
                            onRemove={() =>
                              setAgentEmployeeUpdateDetails((prev) => ({
                                ...prev,
                                employeementLetterImage: null,
                              }))
                            }
                            label="Employeement Letter"
                          />
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Employee ID Card" && (
                            <ImageUpload
                              image={
                                agentEmployeeUpdateDetails.employeeIdCardImage
                              }
                              onImageChange={(e) =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeeIdCardImage: e.target.files[0],
                                }))
                              }
                              onRemove={() =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeeIdCardImage: null,
                                }))
                              }
                              label="ID Card"
                            />
                          )}
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Aadhaar Card" && (
                            <>
                              <ImageUpload
                                image={
                                  agentEmployeeUpdateDetails.employeeAadhaarcardFrontImage
                                }
                                onImageChange={(e) =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeAadhaarcardFrontImage:
                                      e.target.files[0],
                                  }))
                                }
                                onRemove={() =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeAadhaarcardFrontImage: null,
                                  }))
                                }
                                label="Aadhaar Front"
                              />
                              <ImageUpload
                                image={
                                  agentEmployeeUpdateDetails.employeeAadhaarcardBackImage
                                }
                                onImageChange={(e) =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeAadhaarcardBackImage:
                                      e.target.files[0],
                                  }))
                                }
                                onRemove={() =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeAadhaarcardBackImage: null,
                                  }))
                                }
                                label="Aadhaar Back"
                              />
                            </>
                          )}
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Pan Card" && (
                            <ImageUpload
                              image={
                                agentEmployeeUpdateDetails.employeePancardImage
                              }
                              onImageChange={(e) =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeePancardImage: e.target.files[0],
                                }))
                              }
                              onRemove={() =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeePancardImage: null,
                                }))
                              }
                              label="Pan Card"
                            />
                          )}
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Voter Card" && (
                            <>
                              <ImageUpload
                                image={
                                  agentEmployeeUpdateDetails.employeeVoterIdFrontImage
                                }
                                onImageChange={(e) =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeVoterIdFrontImage:
                                      e.target.files[0],
                                  }))
                                }
                                onRemove={() =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeVoterIdFrontImage: null,
                                  }))
                                }
                                label="Voter Front"
                              />
                              <ImageUpload
                                image={
                                  agentEmployeeUpdateDetails.employeeVoterIdBackImage
                                }
                                onImageChange={(e) =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeVoterIdBackImage: e.target.files[0],
                                  }))
                                }
                                onRemove={() =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeVoterIdBackImage: null,
                                  }))
                                }
                                label="Voter Back"
                              />
                            </>
                          )}
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Passport" && (
                            <ImageUpload
                              image={
                                agentEmployeeUpdateDetails.employeePassportImage
                              }
                              onImageChange={(e) =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeePassportImage: e.target.files[0],
                                }))
                              }
                              onRemove={() =>
                                setAgentEmployeeUpdateDetails((prev) => ({
                                  ...prev,
                                  employeePassportImage: null,
                                }))
                              }
                              label="Passport"
                            />
                          )}
                          {agentEmployeeUpdateDetails.employeeIdType ===
                            "Driving License" && (
                            <>
                              <ImageUpload
                                image={
                                  agentEmployeeUpdateDetails.employeeDrivinglicenseFrontImage
                                }
                                onImageChange={(e) =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeDrivinglicenseFrontImage:
                                      e.target.files[0],
                                  }))
                                }
                                onRemove={() =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeDrivinglicenseFrontImage: null,
                                  }))
                                }
                                label="Driving License Front"
                              />
                              <ImageUpload
                                image={
                                  agentEmployeeUpdateDetails.employeeDrivinglicenseBackImage
                                }
                                onImageChange={(e) =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeDrivinglicenseBackImage:
                                      e.target.files[0],
                                  }))
                                }
                                onRemove={() =>
                                  setAgentEmployeeUpdateDetails((prev) => ({
                                    ...prev,
                                    employeeDrivinglicenseBackImage: null,
                                  }))
                                }
                                label="Driving License Back"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {selectedSection === "Documents" && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>Your Documents/ID Proofs</h4>
                  <div className="utr-form-inputs">
                    <div className="utr-form-input-cover">
                      {salespersonDetails.employeeIdType ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          Select ID Type
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select red">
                          * Required
                        </h6>
                      )}
                      <div className="utr-form-input">
                        <HiOutlineDocumentReport
                          className={`utr-form-input-icon ${
                            salespersonDetails.employeeIdType ? "selected" : ""
                          }`}
                        />
                        <Select
                          showSearch
                          name="salespersonEmployeeIdType"
                          className="utr-form-input-select"
                          placeholder="Select ID Type"
                          value={salespersonDetails.employeeIdType || undefined}
                          disabled={
                            userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          }
                          optionFilterProp="label"
                          onChange={handleSalespersonIdTypeChange}
                          options={[
                            {
                              value: "Employee ID Card",
                              label: "Employee ID Card",
                            },
                            {
                              value: "Aadhaar Card",
                              label: "Aadhaar Card",
                            },
                            {
                              value: "Pan Card",
                              label: "Pan Card",
                            },
                            {
                              value: "Voter Card",
                              label: "Voter Card",
                            },
                            {
                              value: "Passport",
                              label: "Passport",
                            },
                            {
                              value: "Driving License",
                              label: "Driving License",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    {salespersonDetails.employeeIdType ===
                      "Employee ID Card" && (
                      <FormInput
                        icon={LiaAddressCardSolid}
                        placeholder="Employee ID"
                        value={salespersonDetails.employeeID}
                        onChange={(e) =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeeID: e.target.value,
                          }))
                        }
                        disabled={userProfileData?.userAccessId}
                      />
                    )}
                    {salespersonDetails.employeeIdType === "Aadhaar Card" && (
                      <FormInput
                        icon={LiaAddressCardSolid}
                        placeholder="Aadhaar Card No. (last 4 digits)"
                        value={salespersonDetails.employeeAadhaarCardNo}
                        onChange={handleSalespersonAadhaarCardNo}
                        maxLength="4"
                        disabled={userProfileData?.userAccessId}
                      />
                    )}
                    {salespersonDetails.employeeIdType === "Pan Card" && (
                      <FormInput
                        icon={LiaAddressCardSolid}
                        placeholder="Pan Card No."
                        value={salespersonDetails.employeePanCardNo}
                        onChange={(e) =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeePanCardNo: e.target.value,
                          }))
                        }
                        disabled={userProfileData?.userAccessId}
                      />
                    )}
                    {salespersonDetails.employeeIdType === "Voter Card" && (
                      <FormInput
                        icon={LiaAddressCardSolid}
                        placeholder="Voter Card No."
                        value={salespersonDetails.employeeVoterCardNo}
                        onChange={(e) =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeeVoterCardNo: e.target.value,
                          }))
                        }
                        disabled={userProfileData?.userAccessId}
                      />
                    )}
                    {salespersonDetails.employeeIdType === "Passport" && (
                      <FormInput
                        icon={LiaAddressCardSolid}
                        placeholder="Passport No."
                        value={salespersonDetails.employeePassportNo}
                        onChange={(e) =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeePassportNo: e.target.value,
                          }))
                        }
                        disabled={userProfileData?.userAccessId}
                      />
                    )}
                    {salespersonDetails.employeeIdType ===
                      "Driving License" && (
                      <FormInput
                        icon={LiaAddressCardSolid}
                        placeholder="Driving License No."
                        value={salespersonDetails.employeeDrivingLicenseNo}
                        onChange={(e) =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeeDrivingLicenseNo: e.target.value,
                          }))
                        }
                        disabled={userProfileData?.userAccessId}
                      />
                    )}
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Salesperson Photos</h4>
                  <div className="utr-form-images">
                    <ImageUpload
                      required={false}
                      image={salespersonDetails.employeementLetterImage}
                      onImageChange={(e) =>
                        setSalespersonDetails((prev) => ({
                          ...prev,
                          employeementLetterImage: e.target.files[0],
                        }))
                      }
                      onRemove={() =>
                        setSalespersonDetails((prev) => ({
                          ...prev,
                          employeementLetterImage: null,
                        }))
                      }
                      label="Employee Letter"
                      disabled={
                        userType === "Salesperson" &&
                        userProfileData?.userAccessId
                      }
                    />
                    {salespersonDetails.employeeIdType ===
                      "Employee ID Card" && (
                      <ImageUpload
                        image={salespersonDetails.employeeIdCardImage}
                        onImageChange={(e) =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeeIdCardImage: e.target.files[0],
                          }))
                        }
                        onRemove={() =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeeIdCardImage: null,
                          }))
                        }
                        label="ID Card"
                        disabled={
                          userType === "Salesperson" &&
                          userProfileData?.userAccessId
                        }
                      />
                    )}
                    {salespersonDetails.employeeIdType === "Aadhaar Card" && (
                      <>
                        <ImageUpload
                          image={
                            salespersonDetails.employeeAadhaarcardFrontImage
                          }
                          onImageChange={(e) =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeAadhaarcardFrontImage: e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeAadhaarcardFrontImage: null,
                            }))
                          }
                          label="Aadhaar Front"
                          disabled={
                            userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          }
                        />
                        <ImageUpload
                          image={
                            salespersonDetails.employeeAadhaarcardBackImage
                          }
                          onImageChange={(e) =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeAadhaarcardBackImage: e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeAadhaarcardBackImage: null,
                            }))
                          }
                          label="Aadhaar Back"
                          disabled={
                            userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          }
                        />
                      </>
                    )}
                    {salespersonDetails.employeeIdType === "Pan Card" && (
                      <ImageUpload
                        image={salespersonDetails.employeePancardImage}
                        onImageChange={(e) =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeePancardImage: e.target.files[0],
                          }))
                        }
                        onRemove={() =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeePancardImage: null,
                          }))
                        }
                        label="Pan Card"
                        disabled={
                          userType === "Salesperson" &&
                          userProfileData?.userAccessId
                        }
                      />
                    )}
                    {salespersonDetails.employeeIdType === "Voter Card" && (
                      <>
                        <ImageUpload
                          image={salespersonDetails.employeeVoterIdFrontImage}
                          onImageChange={(e) =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeVoterIdFrontImage: e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeVoterIdFrontImage: null,
                            }))
                          }
                          label="Voter Front"
                          disabled={
                            userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          }
                        />
                        <ImageUpload
                          image={salespersonDetails.employeeVoterIdBackImage}
                          onImageChange={(e) =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeVoterIdBackImage: e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeVoterIdBackImage: null,
                            }))
                          }
                          label="Voter Back"
                          disabled={
                            userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          }
                        />
                      </>
                    )}
                    {salespersonDetails.employeeIdType === "Passport" && (
                      <ImageUpload
                        image={salespersonDetails.employeePassportImage}
                        onImageChange={(e) =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeePassportImage: e.target.files[0],
                          }))
                        }
                        onRemove={() =>
                          setSalespersonDetails((prev) => ({
                            ...prev,
                            employeePassportImage: null,
                          }))
                        }
                        label="Passport"
                        disabled={
                          userType === "Salesperson" &&
                          userProfileData?.userAccessId
                        }
                      />
                    )}
                    {salespersonDetails.employeeIdType ===
                      "Driving License" && (
                      <>
                        <ImageUpload
                          image={
                            salespersonDetails.employeeDrivinglicenseFrontImage
                          }
                          onImageChange={(e) =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeDrivinglicenseFrontImage:
                                e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeDrivinglicenseFrontImage: null,
                            }))
                          }
                          label="Driving License Front"
                          disabled={
                            userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          }
                        />
                        <ImageUpload
                          image={
                            salespersonDetails.employeeDrivinglicenseBackImage
                          }
                          onImageChange={(e) =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeDrivinglicenseBackImage:
                                e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeDrivinglicenseBackImage: null,
                            }))
                          }
                          label="Driving License Back"
                          disabled={
                            userType === "Salesperson" &&
                            userProfileData?.userAccessId
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {selectedSection === "Preferences" && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h6>
                    Would you like to share these details publicly?
                    <Switch
                      checked={personalDetails.showPersonalDetails}
                      onChange={handleSwitchChange}
                      style={{
                        backgroundColor: switchBackgroundColorPublicDetails,
                      }}
                      className="utr-form-category-h6-switch"
                    />
                  </h6>
                </div>
                {(userType === "Owner" || userType === "Buyer") && (
                  <div className="utr-form-category">
                    <h4>Your Annual Income</h4>
                    <div className="utr-form-inputs">
                      <FormInput
                        required={false}
                        icon={
                          currencySymbol === "₹"
                            ? BsCurrencyRupee
                            : BsCurrencyDollar
                        }
                        placeholder="Annual Income"
                        value={annualIncomeValue}
                        readOnly
                      />
                      <div className="utr-form-input-cover">
                        {personalDetails.annualIncome ? (
                          <h6 className="utr-form-input-cover-h6-select">
                            Select Annual Income (Range)
                          </h6>
                        ) : (
                          <h6 className="utr-form-input-cover-h6-select">
                            Optional
                          </h6>
                        )}
                        <div className="utr-form-input">
                          <Slider
                            className="utr-form-range-slider"
                            range
                            value={personalDetails.annualIncome}
                            onChange={handleSliderChange}
                            min={0}
                            max={2000000}
                            step={10000}
                            marks={marks}
                          />
                        </div>
                      </div>
                      <div className="utr-form-input-cover">
                        {personalDetails.annualIncomeCurrencyType ? (
                          <h6 className="utr-form-input-cover-h6-select">
                            Annual Income Currency Type
                          </h6>
                        ) : (
                          <h6 className="utr-form-input-cover-h6-select">
                            Optional
                          </h6>
                        )}
                        <div className="utr-form-input">
                          <GrCurrency
                            className={`utr-form-input-icon ${
                              personalDetails.annualIncomeCurrencyType
                                ? "selected"
                                : ""
                            }`}
                          />
                          <Select
                            showSearch
                            name="annualIncomeCurrencyType"
                            className="utr-form-input-select"
                            placeholder="Select Annual Income Currency Type"
                            value={
                              personalDetails.annualIncomeCurrencyType ||
                              undefined
                            }
                            defaultValue="₹"
                            optionFilterProp="label"
                            onChange={(value) =>
                              setPersonalDetails((prev) => ({
                                ...prev,
                                annualIncomeCurrencyType: value,
                              }))
                            }
                            options={[
                              {
                                value: "₹",
                                label: "INR - Rupees (₹)",
                              },
                              {
                                value: "$",
                                label: "USD - Dollars ($)",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="utr-form-category">
                  <h4>Additional Details</h4>
                  <div className="utr-form-inputs">
                    {(userType === "Owner" || userType === "Buyer") && (
                      <div className="utr-form-input-cover">
                        {personalDetails.profession ? (
                          <h6 className="utr-form-input-cover-h6-select">
                            Your Profession
                          </h6>
                        ) : (
                          <h6 className="utr-form-input-cover-h6-select">
                            Optional
                          </h6>
                        )}
                        <div className="utr-form-input">
                          <PiShoppingBag
                            className={`utr-form-input-icon ${
                              personalDetails.profession ? "selected" : ""
                            }`}
                          />
                          <Select
                            showSearch
                            name="profession"
                            className="utr-form-input-select"
                            placeholder="Select Your Profession"
                            value={personalDetails.profession || undefined}
                            optionFilterProp="label"
                            onChange={(value) =>
                              setPersonalDetails((prev) => ({
                                ...prev,
                                profession: value,
                              }))
                            }
                            options={[
                              {
                                value: "",
                                label: "Select Your Profession",
                                disabled: true,
                              },
                              { value: "Accountant", label: "Accountant" },
                              { value: "Actor", label: "Actor" },
                              {
                                value: "Air traffic controller",
                                label: "Air traffic controller",
                              },
                              { value: "Architect", label: "Architect" },
                              { value: "Artist", label: "Artist" },
                              {
                                value:
                                  "Aerospace Engineering and Operations Technicians",
                                label:
                                  "Aerospace Engineering and Operations Technicians",
                              },
                              { value: "Aviator", label: "Aviator" },
                              { value: "Barber", label: "Barber" },
                              { value: "Bartender", label: "Bartender" },
                              { value: "Book Keeper", label: "Book Keeper" },
                              {
                                value:
                                  "Bookkeeping, Accounting, and Auditing Clerks",
                                label:
                                  "Bookkeeping, Accounting, and Auditing Clerks",
                              },
                              { value: "Bus driver", label: "Bus driver" },
                              { value: "Butcher", label: "Butcher" },
                              {
                                value: "Businessperson",
                                label: "Businessperson",
                              },
                              { value: "Cashier", label: "Cashier" },
                              { value: "Chef", label: "Chef" },
                              {
                                value: "Construction Worker",
                                label: "Construction Worker",
                              },
                              {
                                value:
                                  "Computer and Information Research Scientists",
                                label:
                                  "Computer and Information Research Scientists",
                              },
                              {
                                value: "Dental hygienist",
                                label: "Dental hygienist",
                              },
                              { value: "Dentist", label: "Dentist" },
                              { value: "Designer", label: "Designer" },
                              {
                                value: "Dietitians and Nutritionists",
                                label: "Dietitians and Nutritionists",
                              },
                              { value: "Economist", label: "Economist" },
                              { value: "Electrician", label: "Electrician" },
                              { value: "Engineer", label: "Engineer" },
                              { value: "Estate agent", label: "Estate agent" },
                              { value: "Farmer", label: "Farmer" },
                              { value: "Fire Fighter", label: "Fire Fighter" },
                              {
                                value: "Flight attendant",
                                label: "Flight attendant",
                              },
                              { value: "Hairdresser", label: "Hairdresser" },
                              { value: "Journalist", label: "Journalist" },
                              { value: "Lawyer", label: "Lawyer" },
                              { value: "Librarian", label: "Librarian" },
                              { value: "Lifeguard", label: "Lifeguard" },
                              {
                                value: "Mechanical Engineering Technicians",
                                label: "Mechanical Engineering Technicians",
                              },
                              { value: "Mechanic", label: "Mechanic" },
                              { value: "Nurse", label: "Nurse" },
                              { value: "Paramedic", label: "Paramedic" },
                              { value: "Photographer", label: "Photographer" },
                              { value: "Physician", label: "Physician" },
                              { value: "Plumber", label: "Plumber" },
                              {
                                value: "Police officer",
                                label: "Police officer",
                              },
                              {
                                value: "Registered nurse",
                                label: "Registered nurse",
                              },
                              { value: "Scientist", label: "Scientist" },
                              { value: "Secretary", label: "Secretary" },
                              {
                                value: "Software Developer",
                                label: "Software Developer",
                              },
                              { value: "Teacher", label: "Teacher" },
                              { value: "Veterinarian", label: "Veterinarian" },
                              {
                                value: "Waiting staff",
                                label: "Waiting staff",
                              },
                              { value: "Others", label: "Others" },
                            ]}
                          />
                        </div>
                      </div>
                    )}
                    <div className="utr-form-input-cover">
                      {personalDetails.dob ? (
                        <h6 className="utr-form-input-cover-h6-select">DOB</h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select">
                          Optional
                        </h6>
                      )}
                      <div className="utr-form-input">
                        <TbCalendarStar
                          className={`utr-form-input-icon ${
                            personalDetails.dob ? "selected" : ""
                          }`}
                        />
                        <DatePicker
                          className="utr-form-input-select"
                          placeholder="Select DOB"
                          format="YYYY-MM-DD"
                          disabledDate={(current) =>
                            current && current > dayjs()
                          }
                          onChange={(date) =>
                            setPersonalDetails((prev) => ({
                              ...prev,
                              dob: date ? date.format("YYYY-MM-DD") : "",
                            }))
                          }
                          value={
                            personalDetails.dob
                              ? dayjs(personalDetails.dob)
                              : null
                          }
                        />
                      </div>
                    </div>
                    <div className="utr-form-input-cover">
                      {personalDetails.gender ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          Gender
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select">
                          Optional
                        </h6>
                      )}
                      <div className="utr-form-input">
                        <MdOutlineNaturePeople
                          className={`utr-form-input-icon ${
                            personalDetails.gender ? "selected" : ""
                          }`}
                        />
                        <Select
                          showSearch
                          name="gender"
                          className="utr-form-input-select"
                          placeholder="Select Your Gender"
                          value={personalDetails.gender || undefined}
                          optionFilterProp="label"
                          onChange={(value) =>
                            setPersonalDetails((prev) => ({
                              ...prev,
                              gender: value,
                            }))
                          }
                          options={[
                            {
                              value: "",
                              label: "Select Your Gender",
                              disabled: true,
                            },
                            { value: "Male", label: "Male" },
                            { value: "Female", label: "Female" },
                            { value: "Other", label: "Other" },
                            {
                              value: "Prefer Not to Say",
                              label: "Prefer Not to Say",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="utr-form-input-cover">
                      {personalDetails.maritalStatus ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          Marital Status
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select">
                          Optional
                        </h6>
                      )}
                      <div className="utr-form-input">
                        <GiMeepleCircle
                          className={`utr-form-input-icon ${
                            personalDetails.maritalStatus ? "selected" : ""
                          }`}
                        />
                        <Select
                          showSearch
                          name="marriedStatus"
                          className="utr-form-input-select"
                          placeholder="Select Marital Status"
                          value={personalDetails.maritalStatus || undefined}
                          optionFilterProp="label"
                          onChange={(value) =>
                            setPersonalDetails((prev) => ({
                              ...prev,
                              maritalStatus: value,
                            }))
                          }
                          options={[
                            {
                              value: "",
                              label: "Select Marital Status",
                              disabled: true,
                            },
                            { value: "Married", label: "Married" },
                            { value: "Un-Married", label: "Un-Married" },
                          ]}
                        />
                      </div>
                    </div>
                    {personalDetails.maritalStatus === "Married" && (
                      <div className="utr-form-input-cover">
                        {personalDetails.marriageAnniversary ? (
                          <h6 className="utr-form-input-cover-h6-select">
                            Anniversary Date
                          </h6>
                        ) : (
                          <h6 className="utr-form-input-cover-h6-select">
                            Optional
                          </h6>
                        )}
                        <div className="utr-form-input">
                          <BiParty
                            className={`utr-form-input-icon ${
                              personalDetails.marriageAnniversary
                                ? "selected"
                                : ""
                            }`}
                          />
                          <DatePicker
                            className="utr-form-input-select"
                            placeholder="Select Anniversary Date"
                            format="YYYY-MM-DD"
                            disabledDate={(current) =>
                              current && current > dayjs()
                            }
                            onChange={(date) =>
                              setPersonalDetails((prev) => ({
                                ...prev,
                                marriageAnniversary: date
                                  ? date.format("YYYY-MM-DD")
                                  : "",
                              }))
                            }
                            value={
                              personalDetails.marriageAnniversary
                                ? dayjs(personalDetails.marriageAnniversary)
                                : null
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Note</h4>
                  <h6>
                    (More preferences and customization options are coming soon!
                    Stay tuned for updates to further personalize your
                    experience.)
                  </h6>
                </div>
                <div className="utr-form-category utr-form-category-last">
                  <Button type="text" danger icon={<AiOutlineDelete />}>
                    Permanently Remove Account
                  </Button>
                </div>
                <div className="utr-form-category utr-form-category-last"></div>
              </div>
            )}
          </div>
          {!showBuilderUpdateEmployeeBtn && !showUpdateAgentEmployeeBtn && (
            <>
              {showSaveUserDetailsBtn && (
                <div className="utr-footer utr-footer-save">
                  <button
                    className="button"
                    onClick={updateProfile}
                    disabled={buttonLoading || loading}
                  >
                    {buttonLoading ? <LoadingOutlined /> : "Save"}
                  </button>
                </div>
              )}
            </>
          )}
          {showBuilderUpdateEmployeeBtn && (
            <div className="utr-footer utr-footer-save">
              <RiArrowLeftSLine
                className="utr-form-footer-icon"
                onClick={handleCancelBuilderUpdate}
              />
              <button
                className="button"
                onClick={handleUpdateBuilderEmployee}
                disabled={buttonLoading || loading}
              >
                {buttonLoading ? <LoadingOutlined /> : "Update Employee"}
              </button>
            </div>
          )}
          {showUpdateAgentEmployeeBtn && (
            <div className="utr-footer utr-footer-save">
              <RiArrowLeftSLine
                className="utr-form-footer-icon"
                onClick={handleCancelAgentUpdate}
              />
              <button
                className="button"
                onClick={handleUpdateAgentEmployee}
                disabled={buttonLoading || loading}
              >
                {buttonLoading ? <LoadingOutlined /> : "Update Employee"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
