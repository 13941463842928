import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";

import "./AdminProfiles.css";

// Ant Design Import
import { Space, Table, Tag, Modal, Input, Select, message } from "antd";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../../store/atoms/userDataAtom";

const { TextArea } = Input;
const { Column } = Table;

function AdminProfiles() {
  const baseURL = process.env.REACT_APP_base_URL;

  const userDetails = useRecoilValue(userDataAtom);
  const [userId, setUserId] = useState(null);

  const [isAdminPanelNotModified, setIsAdminPanelNotModified] = useState(true);

  useMemo(() => {
    if (userDetails) {
      setUserId(userDetails.user._id);
    }
  }, [userDetails]);

  const [unverifiedProfiles, setUnverifiedProfiles] = useState([]);
  const [assignedProfiles, setAssignedProfiles] = useState([]);
  const [rejectedProfiles, setRejectedProfiles] = useState([]);

  const getUnVerifiedProfiles = useCallback(async () => {
    try {
      const res = await axios.get(
        `${baseURL}/api/admin/getUnVerifiedProfiles`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching unverified profiles:", error);
    }
  }, [baseURL]);

  useMemo(() => {
    const fetchData = async () => {
      const profiles = await getUnVerifiedProfiles();
      if (profiles) {
        setUnverifiedProfiles(profiles);
      }
    };
    if (isAdminPanelNotModified) {
      fetchData();
    }
    return () => setIsAdminPanelNotModified(false);
  }, [getUnVerifiedProfiles, isAdminPanelNotModified]);

  const [data, setData] = useState([]);
  const [assignedUnverifiedProfilesData, setAssignedUnverifiedProfilesData] =
    useState([]);

  const getAllAdmins = useCallback(async () => {
    try {
      const res = await axios.get(`${baseURL}/api/admin/getAllAdminUsers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (
        res.data.message === "Admins found successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching all admins:", error);
    }
  }, [baseURL]);

  const getAllAssignedUnverifiedProfiles = useCallback(async () => {
    try {
      const res = await axios.get(
        `${baseURL}/api/admin/getAllAssignedUnverifiedProfiles`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: { userId: userId },
        }
      );
      if (
        res.data.message ===
          "Assigned unverified profiles fetched successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching all admins:", error);
    }
  }, [baseURL, userId]);

  useEffect(() => {
    const fetchAllAdmins = async () => {
      const AllAdmins = await getAllAdmins();
      const AllAssignedUnverifiedProfiles =
        await getAllAssignedUnverifiedProfiles();
      if (AllAdmins) {
        setData(AllAdmins.adminList);
      }
      if (AllAssignedUnverifiedProfiles) {
        setAssignedUnverifiedProfilesData(
          AllAssignedUnverifiedProfiles.unverifiedProfiles
        );
      }
    };
    if (isAdminPanelNotModified) {
      fetchAllAdmins();
    }
    return () => setIsAdminPanelNotModified(false);
  }, [getAllAdmins, getAllAssignedUnverifiedProfiles, isAdminPanelNotModified]);

  // const unverifiedData = unverifiedProfiles.map((profile, index) => ({
  //   id: profile.id,
  //   key: index,
  //   fullName: profile.name,
  //   email: profile.email,
  //   mobile: profile.mobileNumber,
  //   userType: profile.userType,
  //   exists: [profile.userExists.toString()],
  //   profilePhoto: profile.profilePhoto,
  // }));
  // const assignedData = assignedProfiles.map((profile, index) => ({
  //   key: index,
  //   fullName: profile.name,
  //   email: profile.email,
  //   mobile: profile.mobileNumber,
  //   userType: profile.userType,
  //   exists: [profile.userExists.toString()],
  //   profilePhoto: profile.profilePhoto,
  // }));
  // const rejectedData = rejectedProfiles.map((profile, index) => ({
  //   key: index,
  //   fullName: profile.name,
  //   email: profile.email,
  //   mobile: profile.mobileNumber,
  //   userType: profile.userType,
  //   exists: [profile.userExists.toString()],
  //   profilePhoto: profile.profilePhoto,
  // }));

  const unverifiedData = [
    {
      key: "1",
      fullName: "John",
      email: "john@example.com",
      mobile: "1234567890",
      userType: "Builder",
      exists: ["true"],
      profilePhoto: "https://placehold.co/600x400?text=U",
    },
  ];
  const assignedData = assignedUnverifiedProfilesData.map((profile, index) => ({
    id: profile.id,
    key: index,
    fullName: profile.name,
    email: profile.email,
    mobile: profile.mobileNumber,
    userType: profile.userType,
    exists: [profile.userExists.toString()],
    profilePhoto: profile.profilePhoto,
  }));

  const rejectedData = [
    {
      key: "1",
      fullName: "John",
      email: "john@example.com",
      mobile: "1234567890",
      userType: "Builder",
      exists: ["false"],
      profilePhoto: "https://placehold.co/600x400?text=U",
    },
  ];

  // Profile Reject Modal & Reject Reason
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const showRejectModal = () => {
    setIsRejectModalVisible(true);
  };
  const handleRejectOk = () => {
    console.log("Reject reason:", rejectReason);
    setIsRejectModalVisible(false);
    setRejectReason("");
  };
  const handleRejectCancel = () => {
    setIsRejectModalVisible(false);
    setRejectReason("");
  };

  // Profile Reject Reason Modal
  const [isRejectReasonModalVisible, setIsRejectReasonModalVisible] =
    useState(false);
  const showRejectReasonModal = () => {
    setIsRejectReasonModalVisible(true);
  };
  const handleRejectReasonOk = () => {
    setIsRejectReasonModalVisible(false);
  };
  const handleRejectReasonCancel = () => {
    setIsRejectReasonModalVisible(false);
  };

  // Profile Assign Modal
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [selectedAssignedAdmin, setSelectedAssignedAdmin] = useState("");
  const [selectedUnverfiedProfileId, setSelectedUnverfiedProfileId] =
    useState("");

  const showAssignModal = (id) => {
    setIsAssignModalVisible(true);
    setSelectedUnverfiedProfileId(id);
  };
  const handleAssignOk = async () => {
    if (selectedAssignedAdmin === "") {
      return message.error("please select admin to assign this task");
    }
    if (selectedUnverfiedProfileId === "") {
      return message.error("please select a task to assign");
    }
    try {
      const formData = new FormData();
      formData.append("assignedBy", userId);
      formData.append("assignedTo", selectedAssignedAdmin);
      formData.append("unverifiedProfileId", selectedUnverfiedProfileId);
      const res = await axios.post(
        `${baseURL}/api/admin/assignTask`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        res.data.message === "Task assigned successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        message.success(res.data.message);
        setIsAssignModalVisible(false);
        setIsAdminPanelNotModified(true);
        setSelectedAssignedAdmin("");
        setSelectedUnverfiedProfileId("");
      }
    } catch (error) {
      message.error(error.response.data.message);
      setIsAssignModalVisible(false);
      console.error("Error assigning task:", error);
    }
  };
  const handleAssignCancel = () => {
    setIsAssignModalVisible(false);
    setSelectedAssignedAdmin("");
    setSelectedUnverfiedProfileId("");
  };

  return (
    <div className="adashboard">
      <div className="adashboard-sub">
        <div className="adashboard-header">
          <h2>Profile Verifications</h2>
        </div>
        <div className="ausers">
          <div className="ausers-sub">
            <h5>Pending for verifications ({unverifiedData.length})</h5>
          </div>
          <div className="ausers-sub">
            <div className="ausers-sub-table">
              <Table
                dataSource={unverifiedData}
                className="ausers-sub-table-ant-design"
              >
                <Column title="Full Name" dataIndex="fullName" key="fullName" />
                <Column title="Email" dataIndex="email" key="email" />
                <Column title="Mobile" dataIndex="mobile" key="mobile" />
                <Column title="User Type" dataIndex="userType" key="userType" />
                <Column
                  title="Profile Photo"
                  dataIndex="profilePhoto"
                  key="profilePhoto"
                  render={(profilePhoto) => (
                    <img
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "50%",
                      }}
                      src={`data:image/jpeg;base64,${profilePhoto}`}
                      alt=""
                    />
                  )}
                />
                <Column
                  title="Exists"
                  dataIndex="exists"
                  key="exists"
                  render={(exists) => (
                    <>
                      {exists.map((exist) => {
                        let color;
                        if (exist === "true") {
                          color = "green";
                        } else if (exist === "false") {
                          color = "volcano";
                        }
                        return (
                          <Tag color={color} key={exist}>
                            {exist.toUpperCase()}
                          </Tag>
                        );
                      })}
                    </>
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  render={(action) => (
                    <Space size="middle">
                      <a>Start Verification</a>
                      <a
                        onClick={() => {
                          showAssignModal(action.id);
                        }}
                      >
                        Assign task
                      </a>
                      <a onClick={showRejectModal}>Reject</a>
                    </Space>
                  )}
                />
              </Table>
            </div>
          </div>
        </div>
        {assignedData.length > 0 && (
          <div className="ausers">
            <div className="ausers-sub">
              <h5>Assigned Profiles ({assignedData.length})</h5>
            </div>
            <div className="ausers-sub">
              <div className="ausers-sub-table">
                <Table
                  dataSource={assignedData}
                  className="ausers-sub-table-ant-design"
                >
                  <Column
                    title="Full Name"
                    dataIndex="fullName"
                    key="fullName"
                  />
                  <Column title="Email" dataIndex="email" key="email" />
                  <Column title="Mobile" dataIndex="mobile" key="mobile" />
                  <Column
                    title="User Type"
                    dataIndex="userType"
                    key="userType"
                  />
                  <Column
                    title="Profile Photo"
                    dataIndex="profilePhoto"
                    key="profilePhoto"
                    render={(profilePhoto) => (
                      <img
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "50%",
                        }}
                        src={`data:image/jpeg;base64,${profilePhoto}`}
                        alt=""
                      />
                    )}
                  />
                  <Column
                    title="Exists"
                    dataIndex="exists"
                    key="exists"
                    render={(exists) => (
                      <>
                        {exists.map((exist) => {
                          let color;
                          if (exist === "true") {
                            color = "green";
                          } else if (exist === "false") {
                            color = "volcano";
                          }
                          return (
                            <Tag color={color} key={exist}>
                              {exist.toUpperCase()}
                            </Tag>
                          );
                        })}
                      </>
                    )}
                  />
                  <Column
                    title="Action"
                    key="action"
                    render={() => (
                      <Space size="middle">
                        <a>Continue Verification</a>
                        <a onClick={showRejectModal}>Reject</a>
                      </Space>
                    )}
                  />
                </Table>
              </div>
            </div>
          </div>
        )}
        <div className="ausers">
          <div className="ausers-sub">
            <h5>Rejected Profiles (3)</h5>
          </div>
          <div className="ausers-sub">
            <div className="ausers-sub-table">
              <Table
                dataSource={rejectedData}
                className="ausers-sub-table-ant-design"
              >
                <Column title="Full Name" dataIndex="fullName" key="fullName" />
                <Column title="Email" dataIndex="email" key="email" />
                <Column title="Mobile" dataIndex="mobile" key="mobile" />
                <Column title="User Type" dataIndex="userType" key="userType" />
                <Column
                  title="Profile Photo"
                  dataIndex="profilePhoto"
                  key="profilePhoto"
                  render={(profilePhoto) => (
                    <img
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "50%",
                      }}
                      src={`data:image/jpeg;base64,${profilePhoto}`}
                      alt=""
                    />
                  )}
                />
                <Column
                  title="Exists"
                  dataIndex="exists"
                  key="exists"
                  render={(exists) => (
                    <>
                      {exists.map((exist) => {
                        let color;
                        if (exist === "true") {
                          color = "green";
                        } else if (exist === "false") {
                          color = "volcano";
                        }
                        return (
                          <Tag color={color} key={exist}>
                            {exist.toUpperCase()}
                          </Tag>
                        );
                      })}
                    </>
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  render={() => (
                    <Space size="middle">
                      <a onClick={showRejectReasonModal}>
                        View Rejected Reason
                      </a>
                    </Space>
                  )}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Are you sure to reject this profile verification?"
        visible={isRejectModalVisible}
        onOk={handleRejectOk}
        onCancel={handleRejectCancel}
        okText="Reject"
        okType="danger"
        cancelText="Cancel"
      >
        <div className="aprofile-modal-reject-reason">
          <p>This action is permanent and cannot be undone.</p>
          <TextArea
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            placeholder="Enter reject reason"
            autoSize={{
              minRows: 4,
              maxRows: 8,
            }}
          />
        </div>
      </Modal>
      <Modal
        title="Profile Verification Rejected Reason"
        visible={isRejectReasonModalVisible}
        footer={null}
        onOk={handleRejectReasonOk}
        onCancel={handleRejectReasonCancel}
      >
        <div className="aprofile-modal-reject-reason">
          <p>The reson of rejection will come here.</p>
          <p>
            (Note: The user will also see the reason provided for rejection of
            his/her profile.)
          </p>
        </div>
      </Modal>
      <Modal
        title="Assign this profile verification"
        visible={isAssignModalVisible}
        onClose={() => setSelectedUnverfiedProfileId("")}
        onOk={handleAssignOk}
        onCancel={handleAssignCancel}
        okText="Assign"
        cancelText="Cancel"
      >
        <div className="aprofile-modal-reject-reason">
          <p>
            You can assign to any admin (Sub Admin or Reviewer), but only assign
            one admin at a time.
          </p>
          <Select
            showSearch
            name="adminsLists"
            value={
              selectedAssignedAdmin && selectedAssignedAdmin !== ""
                ? selectedAssignedAdmin
                : "Select Admin or Reviewer"
            }
            className="aprofile-modal-reject-reason-select"
            onChange={(value) => setSelectedAssignedAdmin(value)}
            optionFilterProp="label"
            options={[
              {
                label: <span>Sub Admin</span>,
                title: "manager",
                options: data
                  .filter(
                    (admin) => admin.roles && admin.roles[0] === "subAdmin"
                  )
                  .map((admin) => ({
                    label: admin.fullName,
                    value: admin.id,
                  })),
              },
              {
                label: <span>Reviewer</span>,
                title: "engineer",
                options: data
                  .filter(
                    (admin) => admin.roles && admin.roles[0] === "reviewer"
                  )
                  .map((admin) => ({
                    label: admin.fullName,
                    value: admin.id,
                  })),
              },
            ]}
          />
          <p>
            (Note: The selected admin will be allowed to verify the profile of
            the user only.)
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default AdminProfiles;
