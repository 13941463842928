import React, { useState } from "react";
import LoadingOverlay from "../../LoadingOverlay/LoadingOverlay";

// React Icons
import { IoClose } from "react-icons/io5";
import { LuFileImage } from "react-icons/lu";

// Ant Design Import
import { message } from "antd";

// Browser Image Compressor
import imageCompression from "browser-image-compression";

const ImageUpload = ({
  image,
  onImageChange,
  onRemove,
  label,
  disabled,
  required = true,
  type,
  ishd = false,
}) => {
  const [isCompressing, setIsCompressing] = useState(false); // State to track compression status

  // Size limits for different image types and resolutions
  const sizeLimits = {
    profile: {
      sd: 150 * 1024, // SD: 150KB for profile
      hd: 300 * 1024, // HD: 300KB for profile
    },
    firm: {
      sd: 100 * 1024, // SD: 100KB for firm logo
      hd: 250 * 1024, // HD: 250KB for firm logo
    },
    property: {
      sd: 300 * 1024, // SD: 300KB for property images
      hd: 1000 * 1024, // HD: 1MB for property images
    },
    floorPlan: {
      sd: 400 * 1024, // SD: 400KB for floor plans
      hd: 800 * 1024, // HD: 800KB for floor plans
    },
    default: {
      sd: 150 * 1024, // Default SD size 150KB
      hd: 300 * 1024, // Default HD size 300KB
    },
  };

  // Determine max size based on `type` and `ishd` status, using default as fallback
  const maxSize = ishd
    ? sizeLimits[type]?.hd || sizeLimits.default.hd
    : sizeLimits[type]?.sd || sizeLimits.default.sd;

  // Handle file input and potential compression
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > maxSize) {
        message.warning("File is too large, attempting to compress...");
        setIsCompressing(true);

        try {
          const options = {
            maxSizeMB: maxSize / 1024 / 1024,
            maxWidthOrHeight: 1920,
          };
          const compressedFile = await imageCompression(file, options);

          if (compressedFile.size > maxSize) {
            message.error(
              `Unable to compress within limit. Max size allowed is ${Math.round(
                maxSize / 1024
              )}KB`
            );
          } else {
            onImageChange({ target: { files: [compressedFile] } });
            message.success("Image ready to upload.");
          }
        } catch (error) {
          message.error("Failed to compress the image.");
        } finally {
          setIsCompressing(false);
        }
      } else {
        onImageChange(e);
        message.success("Image ready to upload.");
      }
    }
  };

  return (
    <div className="utr-form-images-sub-cover">
      {image ? (
        <h6>{label}</h6>
      ) : required ? (
        <h6 className="red">
          * Required (Max size: {Math.round(maxSize / 1024)}KB)
        </h6>
      ) : (
        <h6>Optional (Max size: {Math.round(maxSize / 1024)}KB)</h6>
      )}

      {isCompressing && <LoadingOverlay />}

      {image ? (
        <div className="utr-form-images-sub">
          {typeof image === "string" ? (
            <img src={image} alt={label} />
          ) : (
            <img src={URL.createObjectURL(image)} alt={label} />
          )}
          {!disabled && (
            <div className="utr-form-images-remove" onClick={onRemove}>
              <IoClose className="utr-form-images-remove-icon" />
            </div>
          )}
        </div>
      ) : (
        <div className="utr-form-images-sub">
          <label htmlFor={`utr-image-upload-${label}`}>
            <LuFileImage className="utr-form-images-sub-icon" />
            <h5>{label}</h5>
          </label>
          {!disabled && (
            <input
              id={`utr-image-upload-${label}`}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
