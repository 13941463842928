import React from "react";

// React Icons
import { IoClose } from "react-icons/io5";
import { BsFiletypePdf, BsFileEarmarkPdf } from "react-icons/bs";

// Ant Design
import { message } from "antd";

const FileUpload = ({
  file,
  onFileChange,
  onRemove,
  label,
  required = true,
  ishd = false,
}) => {
  // PDF size limits (you can adjust these as per your requirement)
  const sizeLimits = {
    sd: 5120 * 1024, // 5MB for SD PDFs
    hd: 10240 * 1024, // 10MB for HD PDFs
  };

  // Determine max size based on `ishd` status
  const maxSize = ishd ? sizeLimits.hd : sizeLimits.sd;

  // Function to format file size to KB or MB
  const formatSize = (size) => {
    return size >= 1024 * 1024
      ? `${(size / (1024 * 1024)).toFixed(0)} MB`
      : `${Math.round(size / 1024)} KB`;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > maxSize) {
        message.error(
          `File size too large. Max allowed size is ${formatSize(maxSize)}`
        );
      } else {
        onFileChange(e);
        message.success("File ready to upload.");
      }
    }
  };

  return (
    <>
      {file ? (
        <div className="utr-form-images-sub-cover">
          <h6>{label}</h6>
          <div className="utr-form-images-sub utr-form-images-sub-pdf">
            {file.type === "application/pdf" ? (
              <div className="utr-form-files-preview-doc">
                <BsFileEarmarkPdf className="utr-form-images-sub-icon" />
                <h5>{file.name}</h5>
              </div>
            ) : (
              <div className="utr-form-files-preview-doc">
                <BsFileEarmarkPdf className="utr-form-images-sub-icon" />
                <h5>{file.name}</h5>
              </div>
            )}
            <div className="utr-form-images-remove" onClick={onRemove}>
              <IoClose className="utr-form-images-remove-icon" />
            </div>
          </div>
        </div>
      ) : (
        <div className="utr-form-images-sub-cover">
          {required ? (
            <h6 className="red">
              * Required (Max size: {formatSize(maxSize)})
            </h6>
          ) : (
            <h6>Optional (Max size: {formatSize(maxSize)})</h6>
          )}
          <div className="utr-form-images-sub">
            <label htmlFor={`utr-file-upload-${label}`}>
              <BsFiletypePdf className="utr-form-images-sub-icon" />
              <h5>{label}</h5>
            </label>
            <input
              id={`utr-file-upload-${label}`}
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FileUpload;
