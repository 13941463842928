import React, { useEffect, useRef } from "react";
import "./Home.css";

// COMPONENTS IMPORT
import Hero from "../../components/Hero/Hero";
import UnderConstruction from "../../components/UnderConstruction/UnderConstruction";
import Searches from "../../components/Searches/Searches";
import RecentlyViewed from "../../components/RecentlyViewed/RecentlyViewed";
import Features from "../../components/Features/Features";
import TrendingProperties from "../../components/TrendingProperties/TrendingProperties";
import TrendingProjects from "../../components/TrendingProjects/TrendingProjects";
import LatestNews from "../../components/LatestNews/LatestNews";
import Map from "../../components/Map/Map";
import TopCities from "../../components/TopCities/TopCities";

function Home() {
  useEffect(() => {
    document.title = "Proptzo - Sustainable Real Estate Solutions";
  }, []);

  return (
    <div className="home">
      <Hero />
      {/* <UnderConstruction /> */}
      <RecentlyViewed />
      <Searches />
      <Map />
      <TrendingProperties />
      <Features />
      <LatestNews />
      <TrendingProjects />
      <TopCities />
    </div>
  );
}

export default Home;
