import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Blogs.css";

// React Icons
import {
  MdArchitecture,
  MdOutlineRealEstateAgent,
  MdOutlineConstruction,
  MdOutlineComputer,
} from "react-icons/md";
import { SiAltiumdesigner } from "react-icons/si";
import { GiTurban, GiSuspensionBridge, GiLifeSupport } from "react-icons/gi";
import { TbMoneybag } from "react-icons/tb";
import { IoSchoolOutline } from "react-icons/io5";

function Blogs() {
  useEffect(() => {
    document.title = "Proptzo - Blog & Articles";
  }, []);
  const navigate = useNavigate();

  return (
    <div className="blogs">
      <div className="blogs-sub">
        <h1>Latest Blogs</h1>
        <h5>Stay updated with our newest posts and articles</h5>
      </div>
      <div className="blogs-container">
        <div className="blogs-pricing-duration">
          <div className="blogs-pricing-duration-sub selected">
            <h5>All</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <MdArchitecture className="blogs-pricing-duration-sub-icon" />
            <h5>Architecture</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <SiAltiumdesigner className="blogs-pricing-duration-sub-icon" />
            <h5>Interior Design</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <MdOutlineRealEstateAgent className="blogs-pricing-duration-sub-icon" />
            <h5>Real Estate</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <MdOutlineConstruction className="blogs-pricing-duration-sub-icon" />
            <h5>Construction</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <GiTurban className="blogs-pricing-duration-sub-icon" />
            <h5>Urban Planning</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <GiSuspensionBridge className="blogs-pricing-duration-sub-icon" />
            <h5>Sustainability</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <MdOutlineComputer className="blogs-pricing-duration-sub-icon" />
            <h5>Technology</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <GiLifeSupport className="blogs-pricing-duration-sub-icon" />
            <h5>Lifestyle</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <TbMoneybag className="blogs-pricing-duration-sub-icon" />
            <h5>Finance</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <IoSchoolOutline className="blogs-pricing-duration-sub-icon" />
            <h5>Education</h5>
          </div>
        </div>
        <div className="blogs-container-sub">
          {[...Array(12)].map((_, index) => (
            <div key={index} className="latestnews">
              <div className="trending-prop-img trending-prop-img-latestnews">
                <img
                  src="https://placehold.co/800x450?text=proptzo"
                  alt="property"
                />
                <div className="trending-type trending-type-latestnews">
                  <h5>JANUARY 26, 2024</h5>
                </div>
              </div>
              <div className="latestnews-sub">
                <h5>Esther</h5>
                <h4>
                  Building Gains Into Housing Stocks And How To Trade The Sector
                </h4>
                <p>
                  In today's dynamic real estate market, understanding the
                  nuances of housing stocks is crucial for maximizing investment
                  returns. Esther delves into the latest trends and strategies,
                  highlighting key factors that drive gains in housing stocks.
                  From market analysis to actionable trading tips, discover how
                  to navigate the sector effectively and make informed decisions
                  that can lead to significant financial growth. Whether you're
                  a seasoned investor or just starting out, this comprehensive
                  guide provides valuable insights to help you succeed in
                  trading housing stocks.
                </p>
              </div>
            </div>
          ))}
        </div>
        <button className="button" onClick={() => navigate("/viewlatestnews")}>
          Load more
        </button>
      </div>
    </div>
  );
}

export default Blogs;
