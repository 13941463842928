import React from "react";
import "./HomeMobile.css";

// Components Import
import HeroMobile from "../../Components/HeroMobile/HeroMobile";
import LiveUsersMobile from "../../Components/LiveUsersMobile/LiveUsersMobile";
import RecentlyViewed from "../../../components/RecentlyViewed/RecentlyViewed";
import Searches from "../../../components/Searches/Searches";
import Map from "../../../components/Map/Map";
import TrendingProperties from "../../../components/TrendingProperties/TrendingProperties";
import Features from "../../../components/Features/Features";
import LatestNews from "../../../components/LatestNews/LatestNews";
import TrendingProjects from "../../../components/TrendingProjects/TrendingProjects";
import TopCities from "../../../components/TopCities/TopCities";

function HomeMobile() {
  return (
    <div className="homem">
      <div className="homem-sub">
        <HeroMobile />
        <LiveUsersMobile />
        <RecentlyViewed />
        <Searches />
        <Map />
        <TrendingProperties />
        <Features />
        <LatestNews />
        <TrendingProjects />
        <TopCities />
      </div>
    </div>
  );
}

export default HomeMobile;
