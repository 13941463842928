import React, { useEffect, useState } from "react";
import "./AdminHeader.css";

// Recoil
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../../store/atoms/userDataAtom";

// Ant Design Imports
import { Dropdown } from "antd";

// React Icons
import { TbSquareRoundedArrowDown, TbLogout } from "react-icons/tb";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineContactPage,
} from "react-icons/md";
import { GoTasklist } from "react-icons/go";

function AdminHeader({ onTasklistClick }) {
  const userdetails = useRecoilValue(userDataAtom);
  const [userName, setuserName] = useState(null);
  const [userAdminType, setuserAdminType] = useState(null);

  useEffect(() => {
    if (userdetails) {
      setuserName(userdetails.user.name);
      setuserAdminType(userdetails.user.admintype);
    }
  }, [userdetails]);

  const items = [
    {
      key: "0",
      icon: <MdOutlineAdminPanelSettings className="aheader-dropdown-icon" />,
      label: "Request Role Change",
    },
    {
      key: "1",
      icon: <MdOutlineContactPage className="aheader-dropdown-icon" />,
      label: "Contact Admin",
    },
    {
      type: "divider",
    },
    {
      key: "3",
      icon: <TbLogout className="aheader-dropdown-icon" />,
      label: "Log out",
      danger: true,
    },
  ];

  const handleMenuClick = ({ key }) => {
    if (key === "3") {
      // Log out the user
      console.log("Logging out...");
      // Add your logout logic here, such as clearing user data or redirecting
      // Example:
      // setUser(null);
      // navigate("/login");
    }
  };

  return (
    <div className="aheader">
      <div className="aheader-sub">
        <div className="aheader-sub-sub">
          <h2>Proptzo Dashboard (Admin)</h2>
        </div>
        <div className="aheader-sub-sub">
          <Dropdown
            menu={{
              items,
              onClick: handleMenuClick,
            }}
            trigger={["click"]}
          >
            <div className="aheader-userInfo">
              <img src="https://placehold.co/40x40?text=U" alt="" />
              <div className="aheader-userInfo-sub">
                <h3>{userName ? userName : "Welcome admin"}</h3>
                <h5>
                  {userAdminType
                    ? userAdminType.toUpperCase()
                    : "Role not assigned!"}
                </h5>
              </div>
              <TbSquareRoundedArrowDown className="aheader-userInfo-icon" />
            </div>
          </Dropdown>
          <GoTasklist
            className="aheader-sub-sub-icon"
            onClick={onTasklistClick}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminHeader;
