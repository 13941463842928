import React from "react";

const FormInput = ({
  required = true,
  icon: Icon,
  placeholder,
  value = "",
  onChange,
  type = "text",
  disabled = false,
}) => {
  let label;
  if (required) {
    label =
      value.trim() === "" ? (
        <h6 className="red">* Required</h6>
      ) : (
        <h6>{placeholder}</h6>
      );
  } else {
    label = value.trim() === "" ? <h6>Optional</h6> : <h6>{placeholder}</h6>;
  }
  return (
    <div className="utr-form-input-cover">
      {label}
      <div className="utr-form-input">
        <Icon
          className={`utr-form-input-icon ${
            value.trim() !== "" ? "selected" : ""
          }`}
        />
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          className={value.trim() !== "" ? "utr-form-input-selected" : ""}
        />
      </div>
    </div>
  );
};

export default FormInput;
