import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// STYLING IMPORT
import "./AddProject.css";
import "../SignUp/InitialProfile.css";

// LOADER IMPORT
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

// REACT ICONS
import { GoArrowLeft } from "react-icons/go";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { GrPowerReset } from "react-icons/gr";
import { IoLocationOutline } from "react-icons/io5";
import {
  MdDriveFileRenameOutline,
  MdOutlineCompareArrows,
  MdOutlineSpeakerNotes,
} from "react-icons/md";
import { TbInputCheck } from "react-icons/tb";
import { LuCalendarClock } from "react-icons/lu";
import { PiCity } from "react-icons/pi";
import { FiKey } from "react-icons/fi";
import {
  BsBuildingLock,
  BsCurrencyDollar,
  BsCurrencyRupee,
} from "react-icons/bs";
import {
  AiOutlineAlert,
  AiOutlineExpandAlt,
  AiOutlineFileImage,
  AiOutlineProject,
} from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CgTag } from "react-icons/cg";
import { GrCurrency } from "react-icons/gr";

// ANT DESIGN IMPORT
import { Checkbox, message, Popconfirm, Select } from "antd";
import { AimOutlined, LoadingOutlined } from "@ant-design/icons";

// CITIES IMPORT
import citiesData from "../../utils/CitiesData";

// LEAFLET MAP INTEGRATION
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import locationPin from "../../assets/svgs/locpin.svg";
import L from "leaflet";
import { addProjectSchema } from "../../schemas/addProjectSchema";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../store/atoms/userDataAtom";

// REUSABLE FORM INPUT COMPONENT
import FormInput from "../../components/Reusable/FormInput/FormInput";
// REUSABLE IMAGE UPLOAD COMPONENT
import ImageUpload from "../../components/Reusable/ImageUpload/ImageUpload";
// REUSABLE FILE UPLOAD COMPONENT
import FileUpload from "../../components/Reusable/FileUpload/FileUpload";

function AddProject() {
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;
  const userType = userDetails?.userProfile?.userType;
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Proptzo - Add New Project";
  }, []);

  // Steps
  const [currentStep, setCurrentStep] = useState(1);
  const nextStep = () => {
    if (
      currentStep === 1 &&
      addProjectSchema.safeParse({
        projectName: builderProjectDetails.projectName,
      }).success === false
    ) {
      const errorMessage = addProjectSchema.safeParse({
        projectName: builderProjectDetails.projectName,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Project name");
      message.error(customMessage);
      return;
    }

    if (
      currentStep === 1 &&
      addProjectSchema.safeParse({
        projectReraNo: builderProjectDetails.projectReraNo,
      }).success === false
    ) {
      const errorMessage = addProjectSchema.safeParse({
        projectReraNo: builderProjectDetails.projectReraNo,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Project rera no");
      message.error(customMessage);
      return;
    }

    if (
      currentStep === 1 &&
      addProjectSchema.safeParse({
        projectDescription: builderProjectDetails.projectDescription,
      }).success === false
    ) {
      const errorMessage = addProjectSchema.safeParse({
        projectDescription: builderProjectDetails.projectDescription,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Project description"
      );
      message.error(customMessage);
      return;
    }

    if (
      currentStep === 1 &&
      addProjectSchema.safeParse({
        projectStatus: builderProjectDetails.projectStatus,
      }).success === false
    ) {
      message.error("Project status required!");
      return;
    }

    if (
      currentStep === 1 &&
      builderProjectDetails.projectStatus === "Under Construction" &&
      addProjectSchema.safeParse({
        projectCompletionYear: builderProjectDetails.projectCompletionYear,
      }).success === false
    ) {
      message.error("Project estimated completion year required!");
      return;
    }

    if (
      currentStep === 2 &&
      addProjectSchema.safeParse({
        projectOwnershipType: builderProjectDetails.projectOwnershipType,
      }).success === false
    ) {
      message.error("Project ownership type required!");
      return;
    }
    if (
      (currentStep === 2) &
      (builderProjectDetails.projectFloorPlans?.length <= 0)
    ) {
      message.error("Project floor plans must contain atleast 1 plan");
      return;
    }

    if (
      currentStep === 2 &&
      addProjectSchema.safeParse({
        projectArea: builderProjectDetails.projectArea,
      }).success === false
    ) {
      const errorMessage = addProjectSchema.safeParse({
        projectArea: builderProjectDetails.projectArea,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Project area");
      message.error(customMessage);
      return;
    }
    if (
      currentStep === 2 &&
      addProjectSchema.safeParse({
        projectAreaUnit: builderProjectDetails.projectAreaUnit,
      }).success === false
    ) {
      message.error("project area unit required!");
      return;
    }

    if (
      currentStep === 2 &&
      addProjectSchema.safeParse({
        projectPriceRangePriceFrom:
          builderProjectDetails.projectPriceRange.priceFrom,
      }).success === false
    ) {
      message.error("Price range required");
      return;
    }

    if (
      currentStep === 2 &&
      addProjectSchema.safeParse({
        projectPriceRangePriceTo:
          builderProjectDetails.projectPriceRange.priceTo,
      }).success === false
    ) {
      message.error("Price range required");
      return;
    }

    if (
      currentStep === 2 &&
      addProjectSchema.safeParse({
        projectCurrencyType: builderProjectDetails.projectCurrencyType,
      }).success === false
    ) {
      const errorMessage = addProjectSchema.safeParse({
        projectCurrencyType: builderProjectDetails.projectCurrencyType,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Project currency type"
      );
      message.error(customMessage);
      return;
    }

    if (
      currentStep === 3 &&
      addProjectSchema.safeParse({
        projectCity: builderProjectDetails.projectCity,
      }).success === false
    ) {
      message.error("Select project city.");
      return;
    }

    if (
      currentStep === 3 &&
      (builderProjectDetails.projectLocation.latitude === 0 ||
        builderProjectDetails.projectLocation.longitude === 0)
    ) {
      message.error("Please provide estimate nearby project location on map");
      return;
    }

    if (
      currentStep === 3 &&
      addProjectSchema.safeParse({
        projectLocality: builderProjectDetails.projectLocality,
      }).success === false
    ) {
      const errorMessage = addProjectSchema.safeParse({
        projectLocality: builderProjectDetails.projectLocality,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Project locality");
      message.error(customMessage);
      return;
    }

    setLoading(true);
    setTimeout(() => {
      setCurrentStep((prevStep) => prevStep + 1);
      setLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 300);
  };
  const prevStep = () => {
    setLoading(true);
    setTimeout(() => {
      setCurrentStep((prevStep) => prevStep - 1);
      setLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 300);
  };

  // Form Reset
  const confirmFormReset = (e) => {
    message.success("Profile Data Reset success!");
    setBuilderProjectDetails({
      projectName: "",
      projectReraNo: "",
      projectDescription: "",
      projectStatus: "",
      projectCompletionYear: "",
      projectOwnershipType: "",
      projectFloorPlans: [],
      projectArea: "",
      projectAreaUnit: "",
      projectPriceRange: {
        priceFrom: "",
        priceTo: "",
      },
      projectCurrencyType: "₹",
      projectCity: "",
      projectLocation: {
        latitude: 0,
        longitude: 0,
      },
      projectLocality: "",
      projectLogo: null,
      projectLayout: null,
      projectReraCertificate: null,
      projectBrochure: null,
    });
    setBuilderProjectDescriptionCharacterCount(0);
    setBuilderProjectLocalityCharacterCount(0);
    // Reset Map States
    setClickedPosition(null);
    setPlaceName("");
    setPrevPlaceName("");
    setZoomLevel(12);
    setSelectedCityCoordinates({
      longitude: 0,
      latitude: 0,
    });
    setCurrentStep(1);
  };
  const cancelFormReset = (e) => {
    message.error("Reset cancelled!");
  };

  // Progress Bar
  const totalSteps = 4;
  const calculateProgressWidth = (currentStep, totalSteps) => {
    if (currentStep <= 1) {
      return 0;
    } else if (currentStep === 2) {
      return 35;
    } else {
      return 35 + ((currentStep - 2) / (totalSteps - 2)) * 65;
    }
  };
  const progressWidth = calculateProgressWidth(currentStep, totalSteps);

  // Cities with their GeoCodes
  const cityOptions = citiesData.map((city) => ({
    cityName: city.city,
    geocode: city.geoCode,
  }));
  const filterCityData = (selectedCity) =>
    citiesData.filter((city) => city.city === selectedCity)[0];

  // Builder Projects
  const [builderProjectDetails, setBuilderProjectDetails] = useState({
    projectName: "",
    projectReraNo: "",
    projectDescription: "",
    projectStatus: "",
    projectCompletionYear: "",
    projectOwnershipType: "",
    projectFloorPlans: [],
    projectArea: "",
    projectAreaUnit: "",
    projectPriceRange: {
      priceFrom: "",
      priceTo: "",
    },
    projectCurrencyType: "₹",
    projectCity: "",
    projectLocation: {
      latitude: 0,
      longitude: 0,
    },
    projectLocality: "",
    projectLogo: null,
    projectLayout: null,
    projectReraCertificate: null,
    projectBrochure: null,
  });
  // Builder Project Description
  const [
    builderProjectDescriptionCharacterCount,
    setBuilderProjectDescriptionCharacterCount,
  ] = useState(0);
  const handleBuilderProjectDescriptionChange = (e) => {
    const newBuilderProjectDescription = e.target.value;
    if (newBuilderProjectDescription.length <= 2500) {
      setBuilderProjectDetails((prev) => ({
        ...prev,
        projectDescription: newBuilderProjectDescription,
      }));
      setBuilderProjectDescriptionCharacterCount(
        newBuilderProjectDescription.length
      );
    } else {
      setBuilderProjectDetails((prev) => ({
        ...prev,
        projectDescription: newBuilderProjectDescription.slice(0, 2500),
      }));
      setBuilderProjectDescriptionCharacterCount(2500);
    }
  };
  const builderProjectDescriptionSpanContent =
    builderProjectDescriptionCharacterCount === 2500
      ? "Max character limit reached"
      : "Provide project description";
  // Builder Project Status
  const handleBuilderProjectStatusChange = (status) => {
    setBuilderProjectDetails((prev) => ({
      ...prev,
      projectStatus: status,
      projectCompletionYear: "",
    }));
  };
  const isReadyToMoveSelected =
    builderProjectDetails.projectStatus === "Ready to Move";
  const isUnderConstructionSelected =
    builderProjectDetails.projectStatus === "Under Construction";
  // Builder Project Completion Year
  const handleBuilderProjectCompletionYearYearChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 4);
    setBuilderProjectDetails((prev) => ({
      ...prev,
      projectCompletionYear: numericValue,
    }));
  };
  // Builder Ownership Type
  const handleBuilderProjectOwnershipTypeChange = (status) => {
    setBuilderProjectDetails((prev) => ({
      ...prev,
      projectOwnershipType: status,
    }));
  };
  const isFreeholdSelected =
    builderProjectDetails.projectOwnershipType === "Freehold";
  const isLeaseholdSelected =
    builderProjectDetails.projectOwnershipType === "Leasehold";
  // Builder Project Floor Plans
  const handleBuilderProjectFloorPlansChange = (floorPlan) => {
    setBuilderProjectDetails((prev) => {
      const isSelected = prev.projectFloorPlans.includes(floorPlan);
      const newFloorPlans = isSelected
        ? prev.projectFloorPlans.filter((plan) => plan !== floorPlan)
        : [...prev.projectFloorPlans, floorPlan];
      return {
        ...prev,
        projectFloorPlans: newFloorPlans,
      };
    });
  };
  const handleClearBuilderProjectFloorPlansSelection = () => {
    setBuilderProjectDetails((prev) => ({
      ...prev,
      projectFloorPlans: [],
    }));
  };
  const isBuilderFloorPlanSelected = (floorPlan) =>
    builderProjectDetails.projectFloorPlans.includes(floorPlan);
  // Builder Price Range
  const formatPrice = (price, currencyType) => {
    const numericPrice = parseFloat(price);
    if (!isNaN(numericPrice)) {
      let formattedPrice = "";
      if (currencyType === "₹") {
        if (numericPrice >= 10000000) {
          formattedPrice = `₹ ${numericPrice / 10000000} Cr`;
        } else if (numericPrice >= 100000) {
          formattedPrice = `₹ ${numericPrice / 100000} Lakh`;
        } else if (numericPrice >= 1000) {
          formattedPrice = `₹ ${numericPrice / 1000} K`;
        } else {
          formattedPrice = `₹ ${numericPrice}`;
        }
      } else if (currencyType === "$") {
        if (numericPrice >= 1000000) {
          formattedPrice = `$ ${numericPrice / 1000000} M`;
        } else if (numericPrice >= 1000) {
          formattedPrice = `$ ${numericPrice / 1000} K`;
        } else {
          formattedPrice = `$ ${numericPrice}`;
        }
      }
      return formattedPrice;
    } else {
      return "";
    }
  };
  const [isPriceRangeTyping, setIsPriceRangeTyping] = useState(false);
  const handleProjectPriceRangeChange = (field, value) => {
    setIsPriceRangeTyping(!!value);
    setBuilderProjectDetails((prev) => ({
      ...prev,
      projectPriceRange: {
        ...prev.projectPriceRange,
        [field]: value,
      },
    }));
  };
  // Builder Project Locality
  const [
    builderProjectLocalityCharacterCount,
    setBuilderProjectLocalityCharacterCount,
  ] = useState(0);
  const handleBuilderProjectLocalityChange = (e) => {
    const newBuilderProjectLocality = e.target.value;
    if (newBuilderProjectLocality.length <= 500) {
      setBuilderProjectDetails((prev) => ({
        ...prev,
        projectLocality: newBuilderProjectLocality,
      }));
      setBuilderProjectLocalityCharacterCount(newBuilderProjectLocality.length);
    } else {
      setBuilderProjectDetails((prev) => ({
        ...prev,
        projectLocality: newBuilderProjectLocality.slice(0, 500),
      }));
      setBuilderProjectLocalityCharacterCount(500);
    }
  };
  const builderProjectLocalitySpanContent =
    builderProjectLocalityCharacterCount === 500
      ? "Max character limit reached"
      : "Provide project locality";
  // Builder Project location
  const [clickedPosition, setClickedPosition] = useState(null);
  const [placeName, setPlaceName] = useState("");
  const [prevPlaceName, setPrevPlaceName] = useState("");
  const [zoomLevel, setZoomLevel] = useState(12);
  const [selectedCityCoordinates, setSelectedCityCoordinates] = useState({
    longitude: 0,
    latitude: 0,
  });
  function ClickHandler() {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setClickedPosition([lat, lng]);
        setBuilderProjectDetails((prev) => ({
          ...prev,
          projectLocation: { latitude: lat, longitude: lng },
        }));
        const fetchLocality = async () =>
          await axios
            .get(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=${1}`
            )
            .then((response) => {
              const { display_name } = response.data;
              setPlaceName(display_name);

              setBuilderProjectDetails((prev) => ({
                ...prev,
                projectLocality: display_name,
              }));
            })
            .catch((error) => {
              console.error("Error fetching place name:", error);
              setPlaceName(null);
            });
        fetchLocality();
      },
    });
    useEffect(() => {
      if (placeName !== "" && placeName !== prevPlaceName) {
        setBuilderProjectDetails((prev) => ({
          ...prev,
          projectLocality: placeName,
        }));
        setPrevPlaceName(placeName);
      }
    }, []);
    useEffect(() => {
      if (
        selectedCityCoordinates.latitude !== 0 &&
        selectedCityCoordinates.longitude !== 0 &&
        map
      ) {
        map.setView(
          [selectedCityCoordinates.latitude, selectedCityCoordinates.longitude],
          zoomLevel
        );
      }
    }, [map]);
    useEffect(() => {
      const handleZoomEnd = () => {
        if (map) {
          setZoomLevel(map.getZoom());
        }
      };
      const handleMoveEnd = () => {
        if (map) {
          const { lng, lat } = map.getCenter();
          setSelectedCityCoordinates((prevCityCoordinates) => ({
            ...prevCityCoordinates,
            latitude: lat,
            longitude: lng,
          }));
        }
      };
      if (map) {
        map.on("zoomend", handleZoomEnd);
        map.on("dragend", handleMoveEnd);
      }
      return () => {
        if (map) {
          map.off("zoomend", handleZoomEnd);
          map.off("dragend", handleMoveEnd);
        }
      };
    }, [map]);
    return null;
  }
  const customIcon = L.icon({
    iconUrl: locationPin,
    iconSize: [40, 40],
    iconAnchor: [16, 32],
  });
  const getLiveLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setSelectedCityCoordinates({
            latitude: latitude,
            longitude: longitude,
          });
          setClickedPosition([latitude, longitude]);
          setBuilderProjectDetails((prev) => ({
            ...prev,
            projectLocation: { latitude, longitude },
          }));
          setZoomLevel(18);
          (async function fetchLocationAddress() {
            await axios
              .get(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=${1}`
              )
              .then((response) => {
                const { display_name } = response.data;
                setPlaceName(display_name);

                setBuilderProjectDetails((prev) => ({
                  ...prev,
                  projectLocality: display_name,
                }));
              })
              .catch((error) => {
                console.error("Error fetching place name:", error);
                setPlaceName(null);
              });
          })();
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
    }
  };

  // Terms & Conditions
  const [isCheckedOne, setIsCheckedOne] = useState(false);
  const [isCheckedTwo, setIsCheckedTwo] = useState(false);
  const onTermAgreedOne = (e) => {
    setIsCheckedOne(e.target.checked);
  };
  const onTermAgreedTwo = (e) => {
    setIsCheckedTwo(e.target.checked);
  };

  // const handleSubmit = async (e) => {
  //   if (!builderProjectDetails.projectLogo) {
  //     message.error("Project logo required.");
  //     return;
  //   }

  //   if (!builderProjectDetails.projectLayout) {
  //     message.error("Project layout required.");
  //     return;
  //   }

  //   if (!builderProjectDetails.projectBrochure) {
  //     message.error("Project brochure required.");
  //     return;
  //   }

  //   if (!isCheckedOne || !isCheckedTwo) {
  //     message.error("Please agree to terms and conditions");
  //     return;
  //   }

  //   e.preventDefault();

  //   // Validations
  //   if (!userType) {
  //     message.error("User type not found. Please log in again!");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("userId", userId);
  //   formData.append("userType", userType);
  //   formData.append("projectName", builderProjectDetails.projectName);
  //   formData.append("projectReraNo", builderProjectDetails.projectReraNo);
  //   formData.append(
  //     "projectDescription",
  //     builderProjectDetails.projectDescription
  //   );
  //   formData.append("projectStatus", builderProjectDetails.projectStatus);
  //   formData.append(
  //     "projectCompletionYear",
  //     builderProjectDetails.projectCompletionYear
  //   );
  //   formData.append(
  //     "projectOwnershipType",
  //     builderProjectDetails.projectOwnershipType
  //   );
  //   formData.append(
  //     "projectFloorPlans",
  //     JSON.stringify(builderProjectDetails.projectFloorPlans)
  //   );
  //   formData.append("projectArea", builderProjectDetails.projectArea);
  //   formData.append("projectAreaUnit", builderProjectDetails.projectAreaUnit);
  //   formData.append(
  //     "projectPriceRange",
  //     JSON.stringify(builderProjectDetails.projectPriceRange)
  //   );
  //   formData.append(
  //     "projectCurrencyType",
  //     builderProjectDetails.projectCurrencyType
  //   );
  //   formData.append("projectCity", builderProjectDetails.projectCity);
  //   formData.append(
  //     "projectLocation",
  //     JSON.stringify(builderProjectDetails.projectLocation)
  //   );
  //   formData.append("projectLocality", builderProjectDetails.projectLocality);
  //   formData.append("projectLogo", builderProjectDetails.projectLogo);
  //   formData.append("projectLayout", builderProjectDetails.projectLayout);
  //   formData.append("projectBrochure", builderProjectDetails.projectBrochure);

  //   if (buttonLoading || loading) {
  //     return;
  //   }
  //   setButtonLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${baseURL}/api/project/saveProjectsById/${userId}`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     if (
  //       response.status === 201 ||
  //       response.statusText === "Created" ||
  //       response.data.message ===
  //         "Project added successfully & under verification!"
  //     ) {
  //       setTimeout(() => {
  //         setButtonLoading(false);
  //         setLoading(true);
  //         setTimeout(() => {
  //           setLoading(false);
  //         }, 500);
  //         message.info(
  //           response?.data?.message ||
  //             "Project added successfully & under verification!"
  //         );
  //         navigate("/dashboard");
  //       }, 2000);
  //     } else {
  //       message.error("Failed to add project. Please try again later.");
  //       setButtonLoading(false);
  //     }
  //   } catch (error) {
  //     setButtonLoading(false);
  //     message.error(error.response.data.message);
  //   }
  // };

  const handleSubmitByS3 = async (e) => {
    if (!builderProjectDetails.projectLogo) {
      message.error("Project logo required.");
      return;
    }

    if (!builderProjectDetails.projectLayout) {
      message.error("Project layout required.");
      return;
    }

    if (!builderProjectDetails.projectBrochure) {
      message.error("Project brochure required.");
      return;
    }

    if (!isCheckedOne || !isCheckedTwo) {
      message.error("Please agree to terms and conditions");
      return;
    }

    e.preventDefault();

    // Validations
    if (!userType) {
      message.error("User type not found. Please log in again!");
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("userType", userType);
    formData.append("projectName", builderProjectDetails.projectName);
    formData.append("projectReraNo", builderProjectDetails.projectReraNo);
    formData.append(
      "projectDescription",
      builderProjectDetails.projectDescription
    );
    formData.append("projectStatus", builderProjectDetails.projectStatus);
    formData.append(
      "projectCompletionYear",
      builderProjectDetails.projectCompletionYear
    );
    formData.append(
      "projectOwnershipType",
      builderProjectDetails.projectOwnershipType
    );
    formData.append(
      "projectFloorPlans",
      JSON.stringify(builderProjectDetails.projectFloorPlans)
    );
    formData.append("projectArea", builderProjectDetails.projectArea);
    formData.append("projectAreaUnit", builderProjectDetails.projectAreaUnit);
    formData.append(
      "projectPriceRange",
      JSON.stringify(builderProjectDetails.projectPriceRange)
    );
    formData.append(
      "projectCurrencyType",
      builderProjectDetails.projectCurrencyType
    );
    formData.append("projectCity", builderProjectDetails.projectCity);
    formData.append(
      "projectLocation",
      JSON.stringify(builderProjectDetails.projectLocation)
    );
    formData.append("projectLocality", builderProjectDetails.projectLocality);

    // will be sending directly to s3
    // formData.append("projectLogo", builderProjectDetails.projectLogo);
    // formData.append("projectLayout", builderProjectDetails.projectLayout);
    // formData.append("projectBrochure", builderProjectDetails.projectBrochure);

    if (buttonLoading || loading) {
      return;
    }
    setButtonLoading(true);
    try {
      const response = await axios.post(
        `${baseURL}/api/project/saveProjectInS3`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (
        response.status === 201 ||
        response.statusText === "Created" ||
        (response.data.message ===
          "Project added successfully & under verification!" &&
          response.data.projectLogoUrl &&
          response.data.projectLayoutUrl &&
          response.data.projectBrochureUrl)
      ) {
        // s3 image uploading starts here---------------

        const logoUploadPromise = axios.put(
          response.data.projectLogoUrl,
          builderProjectDetails.projectLogo
        );

        const layoutUploadPromise = axios.put(
          response.data.projectLayoutUrl, // Likely different URL
          builderProjectDetails.projectLayout
        );

        const brochureUploadPromise = axios.put(
          response.data.projectBrochureUrl, // Likely different URL
          builderProjectDetails.projectBrochure
        );

        try {
          // Wait for all uploads to finish
          const [
            projectLogoResponse,
            projectLayoutResponse,
            projectBrochureResponse,
          ] = await Promise.all([
            logoUploadPromise,
            layoutUploadPromise,
            brochureUploadPromise,
          ]);

          if (
            projectLogoResponse &&
            projectLayoutResponse &&
            projectBrochureResponse
          ) {
            const projectLogoUrl = response.data.projectLogoUrl;
            const projectLayoutUrl = response.data.projectLayoutUrl;
            const projectBrochureUrl = response.data.projectBrochureUrl;

            const parsedProjectLogoUrl = new URL(projectLogoUrl);
            const parsedProjectLayoutUrl = new URL(projectLayoutUrl);
            const parsedProjectBrochureUrl = new URL(projectBrochureUrl);

            const projectID = response.data.projectID;

            const projectLogoKey = parsedProjectLogoUrl.pathname.slice(1);
            const projectLayoutKey = parsedProjectLayoutUrl.pathname.slice(1);
            const projectBrochureKey =
              parsedProjectBrochureUrl.pathname.slice(1);

            const saveProjectFileKeys = await axios.patch(
              `${baseURL}/api/project/saveProjectFileKeys`,
              {
                projectID,
                projectLogoKey,
                projectLayoutKey,
                projectBrochureKey,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (saveProjectFileKeys) {
              message.success(saveProjectFileKeys.data.message);
            }
          }
        } catch (err) {
          message.error("Error occured while uploading images");
        } finally {
          setTimeout(() => {
            setButtonLoading(false);
            setLoading(true);
            message.info(
              response?.data?.message ||
                "Project added successfully & under verification!"
            );
            setTimeout(() => {
              setLoading(false);
            }, 500);

            navigate("/dashboard");
          }, 2000);
        }
      } else {
        message.error("Failed to add project. Please try again later.");
        setButtonLoading(false);
      }
    } catch (error) {
      console.log("error:", error);
      setButtonLoading(false);
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="utr">
      {loading && <LoadingOverlay />}
      <div className="utr-sub">
        <div className="utr-container">
          <div className="utr-header">
            <div className="utr-header-sub">
              <GoArrowLeft
                className="utr-header-sub-icon"
                onClick={() => (currentStep === 1 ? navigate(-1) : prevStep())}
              />
              <h3>Add New Project</h3>
              <Popconfirm
                title="Reset the data"
                description="Are you sure to rest this form?"
                onConfirm={confirmFormReset}
                onCancel={cancelFormReset}
                okText="Reset"
                cancelText="Cancel"
              >
                <GrPowerReset className="utr-header-sub-icon-reset" />
              </Popconfirm>
            </div>
          </div>
          <div className="utr-progress-bar">
            <div className="utr-progress-bar-background">
              <div
                className="utr-progress-bar-sub"
                style={{ width: `${progressWidth}%` }}
              ></div>
            </div>
            <div className="utr-progress-bar-icons">
              <div
                className={`utr-progress-bar-category ${
                  currentStep >= 1 ? "selected" : ""
                }`}
              >
                <AiOutlineProject className="utr-progress-bar-category-icon" />
              </div>
              <div
                className={`utr-progress-bar-category ${
                  currentStep >= 2 ? "selected" : ""
                }`}
              >
                <MdOutlineSpeakerNotes className="utr-progress-bar-category-icon" />
              </div>
              <div
                className={`utr-progress-bar-category ${
                  currentStep >= 3 ? "selected" : ""
                }`}
              >
                <IoLocationOutline className="utr-progress-bar-category-icon" />
              </div>
              <div
                className={`utr-progress-bar-category ${
                  currentStep >= 4 ? "selected" : ""
                }`}
              >
                <AiOutlineFileImage className="utr-progress-bar-category-icon" />
              </div>
            </div>
          </div>
          <div className="utr-form">
            {currentStep === 1 && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>Project Details</h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      icon={MdDriveFileRenameOutline}
                      placeholder="Project Title / Name"
                      value={builderProjectDetails.projectName}
                      onChange={(e) =>
                        setBuilderProjectDetails((prev) => ({
                          ...prev,
                          projectName: e.target.value,
                        }))
                      }
                    />
                    <FormInput
                      icon={TbInputCheck}
                      placeholder="Project Rera No."
                      value={builderProjectDetails.projectReraNo.toUpperCase()}
                      onChange={(e) =>
                        setBuilderProjectDetails((prev) => ({
                          ...prev,
                          projectReraNo: e.target.value,
                        }))
                      }
                    />
                    <div className="utr-form-input-cover">
                      {builderProjectDetails.projectDescription ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          Project Description
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select red">
                          * Required
                        </h6>
                      )}
                      <div className="utr-form-input utr-form-input-flex">
                        <textarea
                          placeholder="Project Description"
                          value={builderProjectDetails.projectDescription}
                          onChange={handleBuilderProjectDescriptionChange}
                          className={
                            builderProjectDescriptionCharacterCount === 2500
                              ? "utr-form-input-max-limit-reached"
                              : builderProjectDetails.projectDescription.trim() !==
                                ""
                              ? "utr-form-input-selected"
                              : ""
                          }
                          rows={5}
                        />
                        <div
                          className={
                            builderProjectDescriptionCharacterCount === 2500
                              ? "utr-form-input-sub-max-limit-reached"
                              : "utr-form-input-sub"
                          }
                        >
                          <span>{builderProjectDescriptionSpanContent}</span>
                          <span>
                            {builderProjectDescriptionCharacterCount} / 2500
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="utr-form-category utr-form-category-last">
                  <h4>Project Status</h4>
                  <div className="utr-form-types">
                    <div
                      className={`utr-form-types-sub ${
                        isReadyToMoveSelected ? "selected" : ""
                      }`}
                      onClick={() =>
                        handleBuilderProjectStatusChange("Ready to Move")
                      }
                    >
                      <FaRegCircleCheck className="utr-form-types-sub-icon" />
                      <h5>Ready to Move</h5>
                    </div>
                    <div
                      className={`utr-form-types-sub ${
                        isUnderConstructionSelected ? "selected" : ""
                      }`}
                      onClick={() =>
                        handleBuilderProjectStatusChange("Under Construction")
                      }
                    >
                      <AiOutlineAlert className="utr-form-types-sub-icon" />
                      <h5>Under Construction</h5>
                    </div>
                  </div>
                </div>
                {builderProjectDetails.projectStatus ===
                  "Under Construction" && (
                  <div className="utr-form-category utr-form-category-last">
                    <h4>Estimated Year of Completion</h4>
                    <div className="utr-form-inputs">
                      <FormInput
                        icon={LuCalendarClock}
                        placeholder="Year"
                        value={builderProjectDetails.projectCompletionYear}
                        onChange={handleBuilderProjectCompletionYearYearChange}
                        maxLength="4"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {currentStep === 2 && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>Project Ownership Type</h4>
                  <div className="utr-form-types">
                    <div
                      className={`utr-form-types-sub ${
                        isFreeholdSelected ? "selected" : ""
                      }`}
                      onClick={() =>
                        handleBuilderProjectOwnershipTypeChange("Freehold")
                      }
                    >
                      <BsBuildingLock className="utr-form-types-sub-icon" />
                      <h5>Freehold</h5>
                    </div>
                    <div
                      className={`utr-form-types-sub ${
                        isLeaseholdSelected ? "selected" : ""
                      }`}
                      onClick={() =>
                        handleBuilderProjectOwnershipTypeChange("Leasehold")
                      }
                    >
                      <FiKey className="utr-form-types-sub-icon" />
                      <h5>Leasehold</h5>
                    </div>
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Project Floor Plans</h4>
                  <div className="utr-form-types">
                    {[
                      "1 BHK",
                      "2 BHK",
                      "3 BHK",
                      "4 BHK",
                      "5 BHK",
                      "6 BHK",
                      "SOHO",
                      "Studio Apartment",
                    ].map((floorPlan) => (
                      <div
                        key={floorPlan}
                        className={`utr-form-types-sub ${
                          isBuilderFloorPlanSelected(floorPlan)
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          handleBuilderProjectFloorPlansChange(floorPlan)
                        }
                      >
                        <CgTag className="utr-form-types-sub-icon" />
                        <h5>{floorPlan}</h5>
                      </div>
                    ))}
                    {builderProjectDetails.projectFloorPlans.length > 0 && (
                      <div className="utr-form-types-clearselection">
                        <span
                          onClick={handleClearBuilderProjectFloorPlansSelection}
                        >
                          - Clear Selection
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Project Area</h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      type="number"
                      icon={AiOutlineExpandAlt}
                      placeholder="Project Area"
                      value={builderProjectDetails.projectArea}
                      onChange={(e) =>
                        setBuilderProjectDetails((prev) => ({
                          ...prev,
                          projectArea: e.target.value,
                        }))
                      }
                    />
                    <div className="utr-form-input-cover">
                      {builderProjectDetails.projectAreaUnit ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          Select Area Unit
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select red">
                          * Required
                        </h6>
                      )}
                      <div className="utr-form-input">
                        <MdOutlineCompareArrows
                          className={`utr-form-input-icon ${
                            builderProjectDetails.projectAreaUnit
                              ? "selected"
                              : ""
                          }`}
                        />
                        <Select
                          showSearch
                          name="builderProjectAreaUnit"
                          className="utr-form-input-select"
                          placeholder="Select Area unit"
                          value={
                            builderProjectDetails.projectAreaUnit || undefined
                          }
                          optionFilterProp="label"
                          onChange={(value) =>
                            setBuilderProjectDetails((prev) => ({
                              ...prev,
                              projectAreaUnit: value,
                            }))
                          }
                          options={[
                            {
                              value: "acres",
                              label: "Acres",
                            },
                            {
                              value: "sq.meters",
                              label: "Sq Meters",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>
                    Project Price Range{" "}
                    {isPriceRangeTyping && (
                      <>
                        - (
                        {formatPrice(
                          builderProjectDetails.projectPriceRange.priceFrom,
                          builderProjectDetails.projectCurrencyType
                        )}{" "}
                        -{" "}
                        {formatPrice(
                          builderProjectDetails.projectPriceRange.priceTo,
                          builderProjectDetails.projectCurrencyType
                        )}
                        )
                      </>
                    )}
                  </h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      type="number"
                      icon={
                        builderProjectDetails.projectCurrencyType === "₹"
                          ? BsCurrencyRupee
                          : BsCurrencyDollar
                      }
                      placeholder="Price From"
                      value={builderProjectDetails.projectPriceRange.priceFrom}
                      onChange={(e) =>
                        handleProjectPriceRangeChange(
                          "priceFrom",
                          e.target.value
                        )
                      }
                    />
                    <FormInput
                      type="number"
                      icon={
                        builderProjectDetails.projectCurrencyType === "₹"
                          ? BsCurrencyRupee
                          : BsCurrencyDollar
                      }
                      placeholder="Price To"
                      value={builderProjectDetails.projectPriceRange.priceTo}
                      onChange={(e) =>
                        handleProjectPriceRangeChange("priceTo", e.target.value)
                      }
                    />
                    <div className="utr-form-input-cover">
                      {builderProjectDetails.projectCurrencyType ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          Select Currency Type
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select red">
                          * Required
                        </h6>
                      )}
                      <div className="utr-form-input">
                        <GrCurrency
                          className={`utr-form-input-icon ${
                            builderProjectDetails.projectCurrencyType
                              ? "selected"
                              : ""
                          }`}
                        />
                        <Select
                          showSearch
                          name="builderProjectCurrencyType"
                          className="utr-form-input-select"
                          placeholder="Select Currency Type"
                          value={
                            builderProjectDetails.projectCurrencyType ||
                            undefined
                          }
                          defaultValue="₹"
                          optionFilterProp="label"
                          onChange={(value) =>
                            setBuilderProjectDetails((prev) => ({
                              ...prev,
                              projectCurrencyType: value,
                            }))
                          }
                          options={[
                            {
                              value: "₹",
                              label: "INR - Rupees (₹)",
                            },
                            {
                              value: "$",
                              label: "USD - Dollars ($)",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>Project Location Details</h4>
                  <div className="utr-form-inputs">
                    <div className="utr-form-input-cover">
                      {builderProjectDetails.projectCity ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          Select City
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select red">
                          * Required
                        </h6>
                      )}
                      <div className="utr-form-input">
                        <PiCity
                          className={`utr-form-input-icon ${
                            builderProjectDetails.projectCity ? "selected" : ""
                          }`}
                        />
                        <Select
                          showSearch
                          className="utr-form-input-select"
                          placeholder="Select a city"
                          value={builderProjectDetails.projectCity || undefined}
                          optionFilterProp="label"
                          onChange={(value) => {
                            setBuilderProjectDetails((prev) => ({
                              ...prev,
                              projectCity: value,
                            }));
                            const cityData = filterCityData(value);
                            setSelectedCityCoordinates({
                              latitude: cityData.geoCode[0],
                              longitude: cityData.geoCode[1],
                            });
                          }}
                          options={cityOptions.map((option) => ({
                            value: option.cityName,
                            label: option.cityName,
                          }))}
                        />
                      </div>
                    </div>
                    <div className="utr-form-input"></div>
                    <div className="utr-form-input-cover">
                      {builderProjectDetails.projectLocality ? (
                        <h6 className="utr-form-input-cover-h6-select">
                          Project Locality
                        </h6>
                      ) : (
                        <h6 className="utr-form-input-cover-h6-select red">
                          * Required
                        </h6>
                      )}
                      <div className="utr-form-input utr-form-input-flex utr-form-input-mtmap">
                        <textarea
                          placeholder="Project Locality"
                          value={builderProjectDetails.projectLocality}
                          onChange={handleBuilderProjectLocalityChange}
                          className={
                            builderProjectLocalityCharacterCount === 500
                              ? "utr-form-input-max-limit-reached"
                              : builderProjectDetails?.projectLocality?.trim() !==
                                ""
                              ? "utr-form-input-selected"
                              : ""
                          }
                          rows={5}
                        />
                        <div
                          className={
                            builderProjectLocalityCharacterCount === 500
                              ? "utr-form-input-sub-max-limit-reached"
                              : "utr-form-input-sub"
                          }
                        >
                          <span>{builderProjectLocalitySpanContent}</span>
                          <span>
                            {builderProjectLocalityCharacterCount ||
                              builderProjectDetails?.projectLocality
                                ?.length}{" "}
                            / 500
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="utr-form-input-cover">
                      {/* <h6 className="utr-form-input-cover-h6-select">Project Description</h6> */}
                      <div className="utr-form-input">
                        <div className="utr-form-input-map">
                          <AimOutlined
                            className="utr-form-map-live-Loc"
                            onClick={getLiveLocation}
                          />
                          <MapContainer
                            center={[20.5937, 78.9629]}
                            zoom={3}
                            className="pp-current-location-onMap"
                          >
                            <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <ClickHandler />
                            {clickedPosition && (
                              <Marker
                                position={[...clickedPosition]}
                                icon={customIcon}
                              >
                                {/* <Popup>{projectLocality}</Popup> */}
                              </Marker>
                            )}
                          </MapContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 4 && (
              <div className="utr-form-sub">
                {" "}
                <div className="utr-form-category">
                  <h4>Project Photos</h4>
                  <div className="utr-form-images">
                    <ImageUpload
                      image={builderProjectDetails.projectLogo}
                      onImageChange={(e) =>
                        setBuilderProjectDetails((prev) => ({
                          ...prev,
                          projectLogo: e.target.files[0],
                        }))
                      }
                      onRemove={() =>
                        setBuilderProjectDetails((prev) => ({
                          ...prev,
                          projectLogo: null,
                        }))
                      }
                      label="Project Logo"
                    />
                    <ImageUpload
                      image={builderProjectDetails.projectLayout}
                      onImageChange={(e) =>
                        setBuilderProjectDetails((prev) => ({
                          ...prev,
                          projectLayout: e.target.files[0],
                        }))
                      }
                      onRemove={() =>
                        setBuilderProjectDetails((prev) => ({
                          ...prev,
                          projectLayout: null,
                        }))
                      }
                      label="Project Layout"
                    />
                    <ImageUpload
                      image={builderProjectDetails.projectReraCertificate}
                      onImageChange={(e) =>
                        setBuilderProjectDetails((prev) => ({
                          ...prev,
                          projectReraCertificate: e.target.files[0],
                        }))
                      }
                      onRemove={() =>
                        setBuilderProjectDetails((prev) => ({
                          ...prev,
                          projectReraCertificate: null,
                        }))
                      }
                      label="Rera Certificate"
                    />
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Project Brcohure (Accepted Format: .pdf)</h4>
                  <FileUpload
                    file={builderProjectDetails.projectBrochure}
                    onFileChange={(e) =>
                      setBuilderProjectDetails((prev) => ({
                        ...prev,
                        projectBrochure: e.target.files[0],
                      }))
                    }
                    onRemove={() =>
                      setBuilderProjectDetails((prev) => ({
                        ...prev,
                        projectBrochure: null,
                      }))
                    }
                    label="Project Brochure"
                  />
                </div>
                <div className="utr-form-category utr-form-category-last">
                  <h4>Terms & Conditions</h4>
                  <div className="utr-form-inputs">
                    <Checkbox
                      onChange={onTermAgreedOne}
                      className={`utr-form-input-checkbox ${
                        isCheckedOne ? "checked" : ""
                      }`}
                    >
                      I agree to exclusively list this project on Proptzo.
                    </Checkbox>
                  </div>
                  <div className="utr-form-inputs">
                    <Checkbox
                      onChange={onTermAgreedTwo}
                      className={`utr-form-input-checkbox ${
                        isCheckedTwo ? "checked" : ""
                      }`}
                    >
                      I agree to Proptzo{" "}
                      <span className="utr-form-input-checkbox-span">T&C</span>,{" "}
                      <span className="utr-form-input-checkbox-span">
                        Privacy Policy
                      </span>
                      , &{" "}
                      <span className="utr-form-input-checkbox-span">
                        Cookie Policy
                      </span>
                    </Checkbox>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="utr-footer">
            {currentStep > 1 && (
              <RiArrowLeftSLine
                className="utr-form-footer-icon"
                onClick={prevStep}
              />
            )}
            {currentStep < 4 ? (
              <RiArrowRightSLine
                className="utr-form-footer-icon"
                onClick={nextStep}
              />
            ) : (
              <button
                className="button"
                // onClick={handleSubmit}
                onClick={handleSubmitByS3}
                disabled={buttonLoading || loading}
              >
                {buttonLoading ? <LoadingOutlined /> : "Add Project"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProject;
