import React from "react";

// React Icons
import { IoClose } from "react-icons/io5";
import { LuFileImage } from "react-icons/lu";

// Function to get ordinal suffix
import getOrdinalSuffix from "../../../utils/getOrdinalSuffix";

const MultipleImageUpload = ({
  images,
  onImageChange,
  onRemove,
  label,
  maxImages,
  required = true,
}) => {
  // const showRequiredMessage = images.length < 2;
  const showOptionalMessage = images.length >= 2 && images.length < maxImages;
  return (
    <div className="utr-form-images">
      {images.map((image, index) => (
        <div key={index} className="utr-form-images-sub-cover">
          <h6>{image ? `${getOrdinalSuffix(index + 1)} IMG` : ""}</h6>
          <div className="utr-form-images-sub">
            <img src={URL.createObjectURL(image)} alt={`Image-${index}`} />
            <div
              className="utr-form-images-remove"
              onClick={() => onRemove(index)}
            >
              <IoClose className="utr-form-images-remove-icon" />
            </div>
          </div>
        </div>
      ))}
      {images.length < maxImages && (
        <div className="utr-form-images-sub-cover">
          {images.length < 2 ? (
            <h6 className="red">* Required</h6>
          ) : showOptionalMessage ? (
            <h6>Optional</h6>
          ) : required && images.length === 0 ? (
            <h6 className="red">* Required</h6>
          ) : (
            <h6>Optional</h6>
          )}
          <div className="utr-form-images-sub">
            <label htmlFor={`utr-image-upload-${label}`}>
              <LuFileImage className="utr-form-images-sub-icon" />
              <h5>{label}</h5>
            </label>
            <input
              id={`utr-image-upload-${label}`}
              type="file"
              accept="image/*"
              onChange={onImageChange}
              style={{ display: "none" }}
              multiple
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleImageUpload;
