import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import axios from "axios";

// STYLING IMPORT
import "./ViewProjects.css";
import "../ViewProperty/ViewProperty.css";

// SVGS ASSETS IMPORT
import logo from "../../assets/svgs/main-logo.svg";
import WhatsappSvg from "../../assets/svgs/socials/whatsapp.svg";
import FacebookSvg from "../../assets/svgs/socials/facebook.svg";
import InstagramSvg from "../../assets/svgs/socials/instagram.svg";
import LinkedinSvg from "../../assets/svgs/socials/linkedin.svg";

// ANT DESIGN IMPORTS
import { Flex, Rate, Modal, Radio, Checkbox, message } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  CloseOutlined,
  EnvironmentOutlined,
  ShareAltOutlined,
  HeartOutlined,
  PrinterOutlined,
  MailOutlined,
  ExpandAltOutlined,
  FilePdfOutlined,
  VerticalAlignBottomOutlined,
  SafetyCertificateOutlined,
  EyeOutlined,
  PushpinOutlined,
  HeartFilled,
  LinkOutlined,
  ClockCircleOutlined,
  TrademarkOutlined,
} from "@ant-design/icons";

// REACT ICONS
import { PiBedDuotone, PiBathtubDuotone, PiHandTapLight } from "react-icons/pi";
import { IoLockClosedOutline } from "react-icons/io5";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { AiOutlineCustomerService } from "react-icons/ai";
import { CiCalendarDate } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import {
  FaCar,
  FaBatteryFull,
  FaShieldAlt,
  FaSwimmingPool,
  FaLeaf,
  FaDumbbell,
  FaChild,
  FaBuilding,
  FaCoffee,
  FaGolfBall,
  FaUsers,
  FaFutbol,
  FaTint,
  FaPhoneAlt,
  FaRunning,
  FaChess,
  FaTools,
  FaHome,
  FaCompass,
  FaShoppingCart,
  FaSchool,
  FaHospital,
  FaMoneyCheckAlt,
  FaPray,
  FaBook,
  FaWarehouse,
  FaBroom,
} from "react-icons/fa";

// PRICE FORMATTER
import PriceFormatter from "../../components/PriceFormatter/PriceFormatter";

// LEAFLET MAP INTEGRATION
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import locationPin from "../../assets/svgs/locpin.svg";
import L from "leaflet";

// RECOIL INTEGRATION
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  filteredPropertiesCount,
  selectedPropertyFilters,
} from "../../store/atoms/filterProperties";
import { fetchWishlistAtom } from "../../store/atoms/updateWIshlist";

function ViewProjects() {
  useEffect(() => {
    document.title = "Proptzo - View Project Details";
  }, []);

  const baseURL = process.env.REACT_APP_base_URL;
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const { projectId } = useParams();

  // const [isLoading, setIsLoading] = useState(true);

  // Image Modals
  const [currentGroup, setCurrentGroup] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [modalGroup, setModalGroup] = useState(0);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  // Data Fetching States & Api's
  const [fetchedProject, setFetchedProject] = useState(null);
  const [fetchedUserData, setFetchedUserData] = useState(null);
  const [fetchedProperties, setFetchedProperties] = useState(null);
  const [fetchedPropertiesImages, setFetchedPropertiesImages] = useState([]);
  const [fetchedPropertiesAmenities, setFetchedPropertiesAmenities] = useState(
    []
  );
  const [
    fetchedPropertiesAdditionalRooms,
    setFetchedPropertiesAdditionalRooms,
  ] = useState([]);
  const [fetchedPropertiesStatus, setFetchedPropertiesStatus] = useState([]);

  const [fetchedPropertiesFloorPlans, setFetchedPropertiesFloorPlans] =
    useState([]);
  const [isProjectdataFethced, setIsProjectdataFethced] = useState(false);

  useEffect(() => {
    if (fetchedProperties && fetchedProperties.length > 0) {
      // Unique Property Images
      const images = fetchedProperties.flatMap(
        (property) => property.propertyImages
      );
      setFetchedPropertiesImages(images);
      // Unique Property Amenities
      const amenities = fetchedProperties.flatMap(
        (property) => property.propertyAmenities
      );
      const uniqueAmenities = Array.from(new Set(amenities));
      setFetchedPropertiesAmenities(uniqueAmenities);
      // Unique Property Additional Rooms
      const additionalRooms = fetchedProperties.flatMap(
        (property) => property.propertyAdditionalRooms
      );
      const uniqueAdditionalRooms = Array.from(new Set(additionalRooms));
      setFetchedPropertiesAdditionalRooms(uniqueAdditionalRooms);
      // Unique Property Status
      const propertyStatus = fetchedProperties.flatMap(
        (property) => property.propertyStatus
      );
      const uniquePropertyStatus = Array.from(new Set(propertyStatus));
      setFetchedPropertiesStatus(uniquePropertyStatus);
      // Unique Property Floor Plans
      const propertyFloorPlans = fetchedProperties.flatMap(
        (property) => property.propertyFloorPlan
      );
      const uniquePropertyFloorPlans = Array.from(new Set(propertyFloorPlans));
      setFetchedPropertiesFloorPlans(uniquePropertyFloorPlans);
    }
  }, [fetchedProperties]);

  const fetchProject = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/project/getproject/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setFetchedProject(response?.data);
      setIsProjectdataFethced(true);
    } catch (error) {
      if (
        error.response.statusText === "Bad Request" ||
        error.response.status === 400
      ) {
        navigate("/signup");
      }

      if (
        error.response.status === 401 ||
        error.response.statusText === "Unauthorized" ||
        error.response.data.message ===
          "user is not authorized for this operation"
      ) {
        navigate("/signup");
      }

      if (
        error.response.status === 404 ||
        error.response.statusText === "Not Found" ||
        error.response.data.message === "Project not  found!"
      ) {
        message.error(error.response.data.message);
      }
    }
  }, [
    baseURL,
    projectId,
    setFetchedProject,
    navigate,
    setIsProjectdataFethced,
  ]);

  useMemo(async () => {
    if (projectId && baseURL) {
      await fetchProject();
    }
  }, [fetchProject, projectId, baseURL]);

  useEffect(() => {
    const fetchUserData = async (_userId) => {
      try {
        const response = await axios.get(
          `${baseURL}/api/profile/getUserDetailsById/${_userId}`
        );
        setFetchedUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    const fetchProperties = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/post/getProducts/${projectId}`
        );
        setFetchedProperties(response.data);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };
    fetchProperties();
    if (isProjectdataFethced && fetchedProject?.userId) {
      fetchUserData(fetchedProject?.userId);
    }
  }, [projectId, baseURL, fetchedProject?.userId, isProjectdataFethced]);

  const [base64PropertyImages, setBase64PropertyImages] = useState(null);
  // Function to distribute image paths into sub-arrays of 3
  function distributeImages(imagePaths, subArraySize = 3) {
    const distributedImages = [];
    // Handle edge case where there are fewer than subArraySize elements
    if (imagePaths?.length < subArraySize) {
      distributedImages.push(imagePaths); // Add the remaining images as a single sub-array
    } else {
      for (let i = 0; i < imagePaths?.length; i += subArraySize) {
        const subArray = imagePaths?.slice(i, i + subArraySize);
        distributedImages.push(subArray);
      }
    }
    return setBase64PropertyImages(distributedImages);
  }
  useMemo(() => {
    distributeImages(fetchedPropertiesImages);
  }, [fetchedPropertiesImages]);
  const totalImages = base64PropertyImages?.flat()?.length;
  const handlePrevClick = () => {
    setCurrentGroup((prevGroup) =>
      prevGroup === 0 ? base64PropertyImages.length - 1 : prevGroup - 1
    );
  };
  const handleNextClick = () => {
    setCurrentGroup((prevGroup) =>
      prevGroup === base64PropertyImages.length - 1 ? 0 : prevGroup + 1
    );
  };
  const openModal = (src, group, index) => {
    setModalImageSrc(src);
    setModalGroup(group);
    setModalImageIndex(index);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageSrc("");
    setModalImageIndex(0);
  };
  // const handleModalImageChange = (direction) => {
  //   if (direction === "prev") {
  //     setModalGroup((prevGroup) =>
  //       prevGroup === 0 ? base64PropertyImages.length - 1 : prevGroup - 1
  //     );
  //   } else if (direction === "next") {
  //     setModalGroup((prevGroup) =>
  //       prevGroup === base64PropertyImages.length - 1 ? 0 : prevGroup + 1
  //     );
  //   }
  // };
  const renderImages = () => {
    const currentImages = base64PropertyImages?.[currentGroup];
    if (currentImages?.length === 1) {
      return (
        <div className="vp-images-sub vp-images-sub-onlyOne">
          <img
            src={`data:image/jpeg;base64,${currentImages[0]}`}
            alt="onlyoneimg"
            className="vp-image-center"
            onClick={() => openModal(currentImages[0], currentGroup, 0)}
          />
        </div>
      );
    } else if (currentImages?.length === 2) {
      return (
        <div className="vp-images-sub">
          <img
            src={`data:image/jpeg;base64,${currentImages[0]}`}
            alt="onlyoneimg"
            className="vp-image-center"
            onClick={() => openModal(currentImages[0], currentGroup, 0)}
          />
          <img
            src={`data:image/jpeg;base64,${currentImages[1]}`}
            alt="onlyoneimg"
            className="vp-image-center"
            onClick={() => openModal(currentImages[1], currentGroup, 1)}
          />
        </div>
      );
    } else {
      return (
        <div className="vp-images-sub">
          {currentImages?.map((src, index) => (
            <img
              key={index}
              src={`data:image/jpeg;base64,${src}`}
              alt={`propertyImg${currentGroup * 3 + index + 1}`}
              className={`vp-image-${
                index === 1 ? "center" : index === 0 ? "left" : "right"
              }`}
              onClick={() => openModal(src, currentGroup, index)}
            />
          ))}
        </div>
      );
    }
  };

  // ADDITIONAL ROOMS
  function conditionalAdditionalRooms(additionalRoom) {
    let icon;
    switch (additionalRoom) {
      case "Prayer Room":
        icon = <FaPray className="vp-pd-sub-icon" />;
        break;
      case "Study Room":
        icon = <FaBook className="vp-pd-sub-icon" />;
        break;
      case "Store Room":
        icon = <FaWarehouse className="vp-pd-sub-icon" />;
        break;
      case "Servant Room":
        icon = <FaBroom className="vp-pd-sub-icon" />;
        break;
      default:
        icon = null;
        break;
    }
    return (
      <div className="vp-pd-sub">
        {icon}
        <h5 className="vp-pd-sub-amenity">{additionalRoom}</h5>
      </div>
    );
  }

  // Amenities
  function conditionalAmenities(amenity) {
    let icon;
    switch (amenity) {
      case "Car Parking":
        icon = <FaCar className="vp-pd-sub-icon" />;
        break;
      case "Power Backup":
        icon = <FaBatteryFull className="vp-pd-sub-icon" />;
        break;
      case "24*7 Security":
        icon = <FaShieldAlt className="vp-pd-sub-icon" />;
        break;
      case "Swimming Pool":
        icon = <FaSwimmingPool className="vp-pd-sub-icon" />;
        break;
      case "Landscaped Gardens":
        icon = <FaLeaf className="vp-pd-sub-icon" />;
        break;
      case "Gym":
        icon = <FaDumbbell className="vp-pd-sub-icon" />;
        break;
      case "Children's Playarea":
        icon = <FaChild className="vp-pd-sub-icon" />;
        break;
      case "Club House":
        icon = <FaBuilding className="vp-pd-sub-icon" />;
        break;
      case "Cafeteria":
        icon = <FaCoffee className="vp-pd-sub-icon" />;
        break;
      case "Golf Course":
        icon = <FaGolfBall className="vp-pd-sub-icon" />;
        break;
      case "Multipurpose Room":
        icon = <FaUsers className="vp-pd-sub-icon" />;
        break;
      case "Sports Facility":
        icon = <FaFutbol className="vp-pd-sub-icon" />;
        break;
      case "Rainwater Harvesting":
        icon = <FaTint className="vp-pd-sub-icon" />;
        break;
      case "Intercom":
        icon = <FaPhoneAlt className="vp-pd-sub-icon" />;
        break;
      case "Jogging Track":
        icon = <FaRunning className="vp-pd-sub-icon" />;
        break;
      case "Indoor Games":
        icon = <FaChess className="vp-pd-sub-icon" />;
        break;
      case "Maintenance Staff":
        icon = <FaTools className="vp-pd-sub-icon" />;
        break;
      case "Staff Quarter":
        icon = <FaHome className="vp-pd-sub-icon" />;
        break;
      case "Vastu Compliant":
        icon = <FaCompass className="vp-pd-sub-icon" />;
        break;
      case "Shopping Mall":
        icon = <FaShoppingCart className="vp-pd-sub-icon" />;
        break;
      case "School":
        icon = <FaSchool className="vp-pd-sub-icon" />;
        break;
      case "Hospital":
        icon = <FaHospital className="vp-pd-sub-icon" />;
        break;
      case "Atm":
        icon = <FaMoneyCheckAlt className="vp-pd-sub-icon" />;
        break;
      default:
        icon = <PushpinOutlined className="vp-pd-sub-icon" />;
        break;
    }
    return (
      <div className="vp-pd-sub">
        {icon}
        <h5 className="vp-pd-sub-amenity">{amenity}</h5>
      </div>
    );
  }

  // Last Updated Date - Format - Month Date, Year
  const [updatedDate, setUpdatedDate] = useState("");
  useEffect(() => {
    const formattedDate = new Date(
      fetchedProject?.updatedAt
    ).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setUpdatedDate(formattedDate);
  }, [fetchedProject?.updatedAt]);

  //Map Function & States
  // States for navigating the map to the selected city/state
  const [selectedCityCoordinates, setSelectedCityCoordinates] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [zoomLevel, setZoomLevel] = useState(16);
  const customIcon = L.icon({
    iconUrl: locationPin,
    iconSize: [40, 40], // Size of the icon
    iconAnchor: [16, 32], // Anchor point of the icon
  });
  // Base 64 Image Function
  const isBase64Image = (str) => {
    if (!str) {
      return false;
    }
    const base64Regex = /^([A-Za-z0-9+/])+={0,2}$/;
    return base64Regex.test(str);
  };
  const projectImage =
    base64PropertyImages &&
    base64PropertyImages?.length > 0 &&
    base64PropertyImages?.[0]?.length > 0
      ? base64PropertyImages?.[0]?.[0]
      : "https://placehold.co/250x250?text=No Images ...";
  const projectTitle = fetchedProject?.projectName;
  // useEffect to update the map view when selectedCityCoordinates changes
  useEffect(() => {
    if (
      selectedCityCoordinates.latitude === 0 &&
      selectedCityCoordinates.longitude === 0 &&
      fetchedProject?.projectLocation
    ) {
      const location = fetchedProject?.projectLocation;
      if (location && fetchedProject) {
        setSelectedCityCoordinates({
          latitude: location?.latitude,
          longitude: location?.longitude,
        });
        // setIsLoading(false);
      }
    }
  }, [
    selectedCityCoordinates,
    fetchedProject,
    fetchedProject?.projectLocation,
  ]);
  function ClickHandler() {
    const map = useMapEvents({});
    // Add a new useEffect to update zoom level when map zoom changes
    useEffect(() => {
      const handleZoomEnd = () => {
        if (map) {
          setZoomLevel(map.getZoom());
        }
      };
      if (map) {
        map.on("zoomend", handleZoomEnd);
      }
      // Cleanup function to remove the event listeners when component unmounts
      return () => {
        if (map) {
          map.off("zoomend", handleZoomEnd);
        }
      };
    }, [map]);

    return null;
  }
  // Reference for Leaflet Map Functionality
  const mapRef = useRef(null);
  useEffect(() => {
    // Access the map instance from the MapContainer component (assuming it's a parent)
    // Function to center the map on user's location (if available)
    const centerOnUserLocation = () => {
      if (mapRef.current) {
        // Check if mapInstance exists
        if (fetchedProject?.projectLocation) {
          mapRef.current.setView(
            [
              fetchedProject?.projectLocation.latitude,
              fetchedProject?.projectLocation.longitude,
            ],
            13
          );
        }
      } else {
        console.error("Map instance not available yet"); // Handle the case where map is not ready
      }
    };
    // Call the centering function on component mount and when userCurrentLocation changes
    centerOnUserLocation();
  }, [fetchedProject?.projectLocation, selectedCityCoordinates, mapRef]);

  // Render properties according to Filter - Property Status & Floor Plans
  const [propertyFilter, setPropertyFilter] = useRecoilState(
    selectedPropertyFilters
  );
  const [propertiesCount, setPropertiesCount] = useRecoilState(
    filteredPropertiesCount
  );
  const filter = propertyFilter?.floorPlan || propertyFilter?.status;
  const renderProperties = () => {
    const properties =
      fetchedProperties &&
      fetchedProperties?.length > 0 &&
      propertyFilter.default === "All"
        ? fetchedProperties
        : propertyFilter.status
        ? fetchedProperties?.filter(
            (property) => property.propertyStatus === propertyFilter.status
          )
        : propertyFilter.floorPlan
        ? fetchedProperties?.filter(
            (property) =>
              property.propertyFloorPlan === propertyFilter.floorPlan
          )
        : [];
    setPropertiesCount(properties?.length);
    return properties?.slice(0, 3).map((property, index) => (
      <div key={index} className="trending-prop-sub">
        <div className="trending-prop-img">
          <img
            src={`data:image/jpeg;base64,${property.propertyImages[0]}`}
            onerror="this.src='https://placehold.co/100x100?text=no+image'"
            alt="project"
          />
          <div className="trending-for">
            <h5>FOR {property.propertyAction}</h5>
          </div>
          <div className="trending-type">
            <h5>{property.propertySubType}</h5>
          </div>
          <div className="trending-rera">
            <SafetyCertificateOutlined className="trending-rera-icon" />
            <h5>RERA CERTIFIED</h5>
          </div>
          <div className="trending-prop-img-icons">
            <ShareAltOutlined
              className="trending-prop-img-icons-icon"
              onClick={() => handleShare(property._id)}
            />
            {property.userId === userId ? (
              <MdEdit
                className="trending-prop-img-icons-icon"
                onClick={() => navigate(`/postupdate/${property._id}`)}
              />
            ) : wishlist.some((item) => item.postId === property._id) ? (
              <HeartFilled
                className="trending-prop-img-icons-icon red"
                onClick={() => {
                  handleWishlistToggle(property._id);
                  setCheckLatestWishlistCount(true);
                }}
              />
            ) : (
              <HeartOutlined
                className="trending-prop-img-icons-icon"
                onClick={() => {
                  handleWishlistToggle(property._id);
                  setCheckLatestWishlistCount(true);
                }}
              />
            )}
            <EyeOutlined
              className="trending-prop-img-icons-icon"
              onClick={() => navigate(`/viewproperty/${property._id}`)}
            />
          </div>
        </div>
        <h5>{property.propertyTitle}</h5>
        <div className="trending-prop-loc">
          <EnvironmentOutlined className="trending-prop-loc-icon" />
          <p>
            {property.propertyLocality}, {property.propertyCity}
          </p>
        </div>
        <div className="trending-prop-data">
          <PiBedDuotone className="trending-prop-data-icon" />
          <h5>{property.propertyBedroomCount}</h5>
          <PiBathtubDuotone className="trending-prop-data-icon" />
          <h5>{property.propertyBathroomCount}</h5>
          <ExpandAltOutlined className="trending-prop-data-icon" />
          <h5>
            {property.propertyArea}, {property.propertyAreaUnit}
          </h5>
        </div>
        <div className="trending-prop-footer">
          <h5 className="trending-prop-footer-name">{property.sellerName}</h5>
          <h5 className="trending-prop-footer-price">
            <PriceFormatter
              amount={property.propertyPrice}
              currencyType={property.propertyCurrencyType}
            />
          </h5>
        </div>
      </div>
    ));
  };

  // Wishlist Toggle & States
  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/wishlist/${userId}`);
        setWishlist(response.data.wishlist);
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };
    fetchWishlist();
  }, [baseURL, userId]);
  const [wishlist, setWishlist] = useState([]);
  const setCheckLatestWishlistCount = useSetRecoilState(fetchWishlistAtom);
  const handleWishlistToggle = async (postId) => {
    try {
      const response = await axios.post(`${baseURL}/api/wishlist/toggle`, {
        userId,
        postId,
      });
      if (response.data.isAdded) {
        setWishlist([...wishlist, { userId, postId }]);
      } else {
        setWishlist(wishlist.filter((item) => item.postId !== postId));
      }
    } catch (error) {
      console.error("Error toggling wishlist:", error);
    }
  };

  // Contact Info
  const [contactInfo, setContactInfo] = useState({
    fullName: "",
    mobile: "",
    email: "",
    senderMessage: "",
  });
  const [validationMessage, setValidationMessage] = useState("");

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    const { fullName, mobile, email, senderMessage } = contactInfo;
    if (!fullName) {
      setValidationMessage("Please enter your full name.");
      return;
    }
    if (!mobile || mobile.length !== 12) {
      setValidationMessage("Please enter a valid 10-digit mobile number.");
      return;
    }
    if (!email || !validateContactInfoEmail(email)) {
      setValidationMessage("Please enter a valid email address.");
      return;
    }
    if (!senderMessage) {
      setValidationMessage("Please enter your message.");
      return;
    }

    const formData = new FormData();

    const notificationType = "Requested Contact Info";
    formData.append("senderUserId", userId);
    formData.append("recieverUserId", fetchedProject?.userId);
    formData.append("name", fullName);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("senderMessage", senderMessage);
    formData.append("notificationType", notificationType);
    formData.append("projectId", projectId);
    try {
      const response = await axios.post(
        `${baseURL}/api/notification/sendNotification`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json", // Set content type for file upload
          },
        }
      );

      if (
        response.data.message === "notification sent" ||
        response.status === 200 ||
        response.statusText === "OK" ||
        response.data.notification
      ) {
        message.success("Message sent successfully!");
        setIsContactNotificationAlreadySent(true);
        setValidationMessage("");
        // Clear form fields
        setContactInfo({
          fullName: "",
          mobile: "",
          email: "",
          senderMessage: "",
        });
      }
    } catch (err) {
      console.log(err);
      message.error(`${err.response.data.message} ...`);
      setValidationMessage("");
      // Clear form fields
      setContactInfo({
        fullName: "",
        mobile: "",
        email: "",
        senderMessage: "",
      });
    }
  };
  const handleContactInfoMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setContactInfo((prevValues) => ({
        ...prevValues,
        mobile: value,
      }));
    }
  };
  const formatContactInfoMobileDisplay = (mobile) => {
    const formatted = mobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const validateContactInfoEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Render Seller Info according to UserType
  const renderSellerInfo = () => {
    const userType =
      fetchedUserData &&
      fetchedUserData?.userDetails &&
      fetchedUserData?.userDetails?.userType;
    const userDetails = fetchedUserData && fetchedUserData?.userDetails;
    const firmName =
      userType === "Builder"
        ? userDetails?.builderDetails?.builderFirmName
        : userType === "Agent"
        ? userDetails?.agentDetails?.agentFirmName
        : "firmname";
    const initialLetter = firmName?.charAt(0);
    const projectLogo = fetchedProject?.projectLogo && fetchedProject?.projectLogo!==""
      ? fetchedProject?.projectLogo
      : `https://placehold.co/100x100?text=${initialLetter}`;
    return (
      <div className="vp-content-right-container">
        <h5>Developed By</h5>
        <div className="vp-seller-details">
          <div className="vp-seller-details-image">
            <img
              src={projectLogo}
              onerror="this.src='https://placehold.co/100x100?text=no+image'"
              alt="projectLogo"
            />
          </div>
          <div className="vp-seller-details-data">
            <h5>
              {firmName} {fetchedProject?.userId === userId && "(You)"}{" "}
              <TrademarkOutlined />
            </h5>
            <span>Listing {userType}</span>
          </div>
        </div>
        {fetchedProject?.userId !== userId &&
          !isContactNotificationAlreadySent && (
            <>
              <h4>Full Name:</h4>
              <input
                type="text"
                name="fullName"
                value={contactInfo.fullName}
                onChange={handleInputChange}
              />
              <h4>Mobile:</h4>
              <input
                type="text"
                name="mobile"
                value={formatContactInfoMobileDisplay(contactInfo.mobile)}
                onChange={handleContactInfoMobileChange}
                maxLength="14"
              />
              <h4>Email:</h4>
              <input
                type="text"
                name="email"
                value={contactInfo.email}
                onChange={handleInputChange}
              />
              <h4>Your Message:</h4>
              <input
                type="text"
                name="senderMessage"
                value={contactInfo.senderMessage}
                onChange={handleInputChange}
              />
              <button onClick={handleSubmit}>Send Message</button>
              {validationMessage && <p className="red">{validationMessage}</p>}
            </>
          )}
        {isContactNotificationAlreadySent && (
          <p className="red">contact notification already sent</p>
        )}
      </div>
    );
  };

  // Download Brochure States & Modal
  const [isBrochureModalVisible, setIsBrochureModalVisible] = useState(false);
  const [reasonToBuy, setReasonToBuy] = useState("");
  const [isPropertyDealer, setIsPropertyDealer] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("91");
  const [email, setEmail] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [interestedInHomeLoan, setInterestedInHomeLoan] = useState(false);
  const [interestedInSiteVisits, setInterestedInSiteVisits] = useState(false);
  const [planningToBuy, setPlanningToBuy] = useState("");

  // IS NOTIFICATION SENT ?

  const [isBrochureAlreadyDownloaded, setIsBrochureAlreadyDownloaded] =
    useState(null);

  const [
    isContactNotificationAlreadySent,
    setIsContactNotificationAlreadySent,
  ] = useState(null);

  const isBrochureNotificationPresent = useCallback(async () => {
    if (userId && fetchedProject?.userId && projectId) {
      const resp = await axios.get(
        `${baseURL}/api/notification/isNotificationPresent`,
        {
          params: {
            senderUserId: userId,
            recieverUserId: fetchedProject?.userId,
            projectId: projectId,
            notificationType: "Brochure Download",
          },
        }
      );
      return await resp.data.exists;
    } else {
      return console.log("missing notification parameters");
    }
  }, [baseURL, projectId, userId, fetchedProject?.userId]);

  const isContactNotificationPresent = useCallback(async () => {
    if (userId && fetchedProject?.userId && projectId) {
      const resp = await axios.get(
        `${baseURL}/api/notification/isNotificationPresent`,
        {
          params: {
            senderUserId: userId,
            recieverUserId: fetchedProject?.userId,
            projectId: projectId,
            notificationType: "Requested Contact Info",
          },
        }
      );
      return await resp.data.exists;
    } else {
      return console.log("missing notification parameters");
    }
  }, [baseURL, projectId, userId, fetchedProject?.userId]);

  useMemo(async () => {
    if (userId && fetchedProject?.userId && projectId) {
      setIsBrochureAlreadyDownloaded(await isBrochureNotificationPresent());
    }
  }, [
    userId,
    fetchedProject?.userId,
    projectId,
    isBrochureNotificationPresent,
  ]);

  useMemo(async () => {
    if (userId && fetchedProject?.userId && projectId) {
      setIsContactNotificationAlreadySent(await isContactNotificationPresent());
    }
  }, [userId, fetchedProject?.userId, projectId, isContactNotificationPresent]);

  const showBrochureModal = () => {
    setIsBrochureModalVisible(true);
  };
  const handleBrochureModalOk = () => {
    setIsBrochureModalVisible(false);
  };
  const handleBrochureModalCancel = () => {
    setIsBrochureModalVisible(false);
  };
  const onChangeReasonToBuy = (e) => {
    setReasonToBuy(e.target.value);
  };
  const onChangeIsPropertyDealer = (e) => {
    setIsPropertyDealer(e.target.value);
  };
  const onChangeAgreedToTerms = (e) => {
    setAgreedToTerms(e.target.checked);
  };
  const onChangePlanningToBuy = (e) => {
    setPlanningToBuy(e.target.value);
  };
  const onChangeInterestedInHomeLoan = (e) => {
    setInterestedInHomeLoan(e.target.checked);
  };
  const onChangeInterestedInSiteVisits = (e) => {
    setInterestedInSiteVisits(e.target.checked);
  };
  const handleDownloadBrochure = async () => {
    if (!reasonToBuy) {
      message.error("Please select your reason to buy.");
      return;
    }
    if (!isPropertyDealer) {
      message.error("Please indicate if you are a property dealer.");
      return;
    }
    if (!name) {
      message.error("Please enter your name.");
      return;
    }
    if (!mobile || mobile.length !== 12) {
      message.error("Please enter a valid 10-digit mobile number.");
      return;
    }
    if (!email || !validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }
    if (!agreedToTerms) {
      message.error("You must agree to the terms and conditions.");
      return;
    }
    if (!fetchedProject?.projectBrochure) {
      message.error("Brochure information is missing.");
      return;
    }

    const formData = new FormData();
    const notificationType = "Brochure Download";

    const _isPropertyDealer = isPropertyDealer === "Yes" ? true : false;
    formData.append("senderUserId", userId);
    formData.append("recieverUserId", fetchedProject?.userId);
    formData.append("notificationType", notificationType);
    formData.append("projectId", projectId);

    formData.append("reasonToBuy", reasonToBuy);
    formData.append("isPropertyDealer", _isPropertyDealer);
    formData.append("name", name);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("interestedInHomeLoan", interestedInHomeLoan);
    formData.append("interestedInSiteVisits", interestedInSiteVisits);
    formData.append("planningToBuy", planningToBuy);
    try {
      const response = await axios.post(
        `${baseURL}/api/notification/sendNotification`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json", // Set content type for file upload
          },
        }
      );
      if (
        response.data.message === "notification sent" ||
        response.status === 200 ||
        response.statusText === "OK" ||
        response.data.notification
      ) {
        setIsBrochureAlreadyDownloaded(true);
      }
      downloadBrochure(fetchedProject?.projectBrochure);
      message.success("Brochure download started ...");
      setIsBrochureModalVisible(false);
      resetBrochureForm();
    } catch (err) {
      message.error(`${err.response.data.message} ...`);
      setIsBrochureModalVisible(false);
      resetBrochureForm();
    }
  };
  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setMobile(value);
    }
  };
  const formatMobileDisplay = (mobile) => {
    const formatted = mobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const downloadBrochure = (brochureUrl) => {
    const link = document.createElement("a");
    link.href = brochureUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 100);
  };

  const downloadBrochureAgain = (brochureUrl) => {
    if (!brochureUrl) {
      message.error("Brochure information is missing.");
      return;
    }
    const link = document.createElement("a");
    link.href = brochureUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 100);
    message.success("Brochure download started ...");
  };
  const resetBrochureForm = () => {
    setReasonToBuy("");
    setIsPropertyDealer("");
    setName("");
    setMobile("91");
    setEmail("");
    setAgreedToTerms(false);
    setInterestedInHomeLoan(false);
    setInterestedInSiteVisits(false);
    setPlanningToBuy("");
  };

  // Share Project Functionality
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const showShareModal = () => {
    setIsShareModalVisible(true);
  };
  const handleShareModalOk = () => {
    setIsShareModalVisible(false);
  };
  const handleShareModalCancel = () => {
    setIsShareModalVisible(false);
    setCopyButtonText("Copy");
  };
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const currentUrl = window.location.href;
  const handleCopyUrl = () => {
    copy(currentUrl);
    setCopyButtonText("Copied!");
    setTimeout(() => {
      setCopyButtonText("Copy");
    }, 10000);
  };
  const copyButtonClass =
    copyButtonText === "Copied!"
      ? "vp-share-url-copybtn vp-share-url-copybtn-green"
      : "vp-share-url-copybtn";
  const shareMessage =
    `${fetchedProject?.projectName || "Project Name Unavailable"}\n\n` +
    `Checkout this project in ${
      fetchedProject?.projectLocality || "Location Unavailable"
    }, ${fetchedProject?.projectCity || ""}.\n\n` +
    `👉 Price: ${fetchedProject?.projectCurrencyType} ${
      fetchedProject?.projectPriceRange.priceFrom
    } - ${fetchedProject?.projectPriceRange.priceTo || "Price Unavailable"}\n` +
    `👉 Area: ${fetchedProject?.projectArea || "Project Area not mentioned"} ${
      fetchedProject?.projectAreaUnit
    }\n` +
    `👉 Availability: ${
      fetchedProject?.projectStatus || "Status not updated"
    }\n\n` +
    `👉 More Details: ${currentUrl}`;
  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareMessage)}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}&quote=${encodeURIComponent(shareMessage)}`;
  const instagramUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
    currentUrl
  )}&title=${encodeURIComponent(shareMessage)}`;
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  // Property Card Share Functionality - Individual Property Card
  const handleShare = async (propertyId) => {
    const propertyUrl = `https://proptzo.com/viewproperty/${propertyId}`;
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Check out this property!",
          url: propertyUrl,
        });
      } else {
        // Fallback for browsers that do not support navigator.share
        alert(`Copy this link to share: ${propertyUrl}`);
      }
    } catch (error) {
      console.error("Error sharing the property:", error);
    }
  };

  // // Print Functionality
  // const printRef = useRef();
  // const handlePrint = () => {
  //   const printContents = printRef.current.innerHTML;
  //   const originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // };

  // Ratings Functionality
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];
  const [rating, setRating] = useState(3);

  return (
    <div className="vp">
      <div className="vp-sub">
        {totalImages > 0 ? (
          <div className="vp-images">
            {totalImages > 3 && (
              <LeftOutlined
                className="vp-images-icon vp-images-icon-left-icon"
                onClick={handlePrevClick}
              />
            )}
            {renderImages()}
            {totalImages > 3 && (
              <RightOutlined
                className="vp-images-icon vp-images-icon-right-icon"
                onClick={handleNextClick}
              />
            )}
            {isModalOpen && (
              <div className="vp-modal-overlay" onClick={closeModal}>
                <CloseOutlined
                  className="vp-modal-close-icon"
                  onClick={closeModal}
                />
                <div
                  className="vp-modal-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={`data:image/jpeg;base64,${modalImageSrc}`}
                    alt="modalImg"
                    className="vp-modal-image"
                  />
                  <div className="vp-modal-allimages">
                    {base64PropertyImages?.map((group, groupIndex) => (
                      <div
                        key={groupIndex}
                        className={`vp-modal-allimages-group ${
                          groupIndex === modalGroup ? "active" : ""
                        }`}
                      >
                        {group.map((src, index) => (
                          <div
                            key={index}
                            className={`vp-modal-allimages-sub ${
                              groupIndex === modalGroup &&
                              index === modalImageIndex
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => openModal(src, groupIndex, index)}
                          >
                            <img
                              src={`data:image/jpeg;base64,${src}`}
                              alt={`propertyImg${groupIndex * 3 + index + 1}`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="vp-images">
            <div className="vp-images-sub vp-images-sub-onlyOne">
              <img
                src="https://placehold.co/1920x1080?text=proptzo"
                alt="proptzo"
                // className="vp-image-center"
              />
            </div>
          </div>
        )}
        <div className="vp-container">
          <div className="vp-header">
            <div className="vp-header-left">
              <span>RERA CERTIFIED</span>
              <h1>{fetchedProject?.projectName}</h1>
            </div>
            <div className="vp-header-right">
              <h1>
                {fetchedProject?.projectPriceRange?.priceFrom &&
                fetchedProject?.projectPriceRange?.priceTo ? (
                  <>
                    <PriceFormatter
                      amount={fetchedProject.projectPriceRange.priceFrom}
                      currencyType={fetchedProject.projectCurrencyType}
                    />
                    {" - "}
                    <PriceFormatter
                      amount={fetchedProject.projectPriceRange.priceTo}
                      currencyType={fetchedProject.projectCurrencyType}
                    />
                  </>
                ) : (
                  "Loading..."
                )}
              </h1>
            </div>
          </div>
          <div className="vp-hero">
            <div className="vp-hero-sub">
              <h5>LOCATION:</h5>
              <div className="vp-hero-options">
                <div className="vp-hero-options-sub">
                  <EnvironmentOutlined className="vp-hero-options-sub-icon" />
                  <span>
                    {fetchedProject?.projectLocality},{" "}
                    {fetchedProject?.projectCity}
                  </span>
                </div>
              </div>
            </div>
            <div className="vp-hero-sub">
              <div className="vp-hero-icons">
                {/* <MailOutlined className="vp-hero-icons-sub" />
                <PrinterOutlined
                  className="vp-hero-icons-sub"
                  onClick={handlePrint}
                />
                <div ref={printRef} className="vp-print-page">
                  <div className="watermark">
                    <img src={logo} alt="proptzo" />
                  </div>
                  <div className="vp-print-page-sub">
                    <div className="vp-print-page-category">
                      <h1>{fetchedProject?.projectName}</h1>
                      <span>
                        {fetchedProject?.projectLocality},{" "}
                        {fetchedProject?.projectCity}
                      </span>
                    </div>
                    <div className="vp-print-page-category">
                      <h2>Description:</h2>
                      <span>{fetchedProject?.projectDescription}</span>
                    </div>
                  </div>
                </div> */}
                <ShareAltOutlined
                  className="vp-hero-icons-sub"
                  onClick={showShareModal}
                />
                {fetchedProject?.userId === userId ? (
                  <MdEdit
                    className="vp-hero-icons-sub"
                    onClick={() =>
                      navigate(`/projectupdate/${fetchedProject?._id}`)
                    }
                  />
                ) : null}
                <Modal
                  title="Share Property Details"
                  visible={isShareModalVisible}
                  onOk={handleShareModalOk}
                  onCancel={handleShareModalCancel}
                  footer={null}
                  centered
                  className="vp-share-modal"
                >
                  <div className="vp-share-url">
                    <div className="vp-share-url-element">
                      <LinkOutlined className="vp-share-url-element-icon" />
                    </div>
                    <div className="vp-share-url-link">
                      <span onClick={handleCopyUrl}>{currentUrl}</span>
                    </div>
                    <div className={copyButtonClass} onClick={handleCopyUrl}>
                      <span>{copyButtonText}</span>
                    </div>
                  </div>
                  <div className="vp-share-modal-sub">
                    <div className="vp-share-category">
                      <a
                        href={whatsappUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={WhatsappSvg} alt="WhatsApp" />
                      </a>
                    </div>
                    <div className="vp-share-category">
                      <a
                        href={facebookUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={FacebookSvg} alt="Facebook" />
                      </a>
                    </div>
                    <div className="vp-share-category">
                      <a
                        href={instagramUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={InstagramSvg} alt="Facebook" />
                      </a>
                    </div>
                    <div className="vp-share-category">
                      <a
                        href={linkedinUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={LinkedinSvg} alt="Facebook" />
                      </a>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          <div className="vp-content">
            <div className="vp-content-left">
              <div className="vp-content-left-container vp-content-left-container-one">
                <h5>Description</h5>
                <p>{fetchedProject?.projectDescription}</p>
                <span>View More</span>
              </div>
              <div className="vp-content-left-container">
                <h5>Overview</h5>
                <div className="vp-left-overview">
                  <div className="vp-left-overview-sub">
                    <CiCalendarDate className="vp-left-overview-sub-icon" />
                    <div className="vp-left-overview-sub-data">
                      <h5>Last Updated:</h5>
                      <span>{updatedDate}</span>
                    </div>
                  </div>
                  <div className="vp-left-overview-sub">
                    <ExpandAltOutlined className="vp-left-overview-sub-icon" />
                    <div className="vp-left-overview-sub-data">
                      <h5>Area:</h5>
                      <span>
                        {fetchedProject?.projectArea}{" "}
                        {fetchedProject?.projectAreaUnit}
                      </span>
                    </div>
                  </div>
                  {fetchedProject?.projectCompletionYear && (
                    <div className="vp-left-overview-sub">
                      <ClockCircleOutlined className="vp-left-overview-sub-icon" />
                      <div className="vp-left-overview-sub-data">
                        <h5>Estimated Completion Year:</h5>
                        <span>{fetchedProject?.projectCompletionYear}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="vp-content-left-container">
                <h5>Project Details</h5>
                <div className="vp-pd">
                  <div className="vp-pd-sub">
                    <h5>Price Range:</h5>
                    <span>
                      {fetchedProject?.projectPriceRange?.priceFrom &&
                      fetchedProject?.projectPriceRange?.priceTo ? (
                        <>
                          <PriceFormatter
                            amount={fetchedProject.projectPriceRange.priceFrom}
                            currencyType={fetchedProject.projectCurrencyType}
                          />
                          {" - "}
                          <PriceFormatter
                            amount={fetchedProject.projectPriceRange.priceTo}
                            currencyType={fetchedProject.projectCurrencyType}
                          />
                        </>
                      ) : (
                        "Loading..."
                      )}
                    </span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Floor Plans:</h5>
                    <span>
                      {fetchedProject?.projectFloorPlans &&
                        fetchedProject?.projectFloorPlans
                          .slice()
                          .sort((a, b) => {
                            const numericA = parseInt(a);
                            const numericB = parseInt(b);
                            return numericA - numericB;
                          })
                          .map((floorPlan, index) => (
                            <span key={index}>
                              {floorPlan}
                              {fetchedProject?.projectFloorPlans?.length >
                                index + 1 && ","}{" "}
                            </span>
                          ))}
                    </span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Project Area:</h5>
                    <span>
                      {fetchedProject?.projectArea}{" "}
                      {fetchedProject?.projectAreaUnit}
                    </span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Status:</h5>
                    <span>{fetchedProject?.projectStatus}</span>
                  </div>
                  {fetchedProject?.projectOwnershipType && (
                    <div className="vp-pd-sub">
                      <h5>Ownership Type:</h5>
                      <span>{fetchedProject?.projectOwnershipType}</span>
                    </div>
                  )}
                </div>
              </div>
              {fetchedPropertiesAdditionalRooms &&
                fetchedPropertiesAdditionalRooms?.length > 0 && (
                  <div className="vp-content-left-container">
                    <h5>Additional Rooms</h5>

                    <div className="vp-pd">
                      {fetchedPropertiesAdditionalRooms?.map((additionalRoom) =>
                        conditionalAdditionalRooms(additionalRoom)
                      )}
                    </div>
                  </div>
                )}
              {fetchedPropertiesAmenities &&
                fetchedPropertiesAmenities?.length > 0 && (
                  <div className="vp-content-left-container">
                    <h5>Amenities & Features</h5>

                    <div className="vp-pd">
                      {fetchedPropertiesAmenities?.map((amenity) =>
                        conditionalAmenities(amenity)
                      )}
                    </div>
                  </div>
                )}
              {fetchedProject?.projectLocation && (
                <div className="vp-content-left-container">
                  <h5>Map</h5>
                  <div className="vp-map">
                    <MapContainer
                      center={[
                        selectedCityCoordinates.latitude,
                        selectedCityCoordinates.longitude,
                      ]}
                      zoom={zoomLevel}
                      className="vp-map-sub"
                      ref={mapRef}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />

                      <Marker
                        position={[
                          selectedCityCoordinates.latitude,
                          selectedCityCoordinates.longitude,
                        ]}
                        icon={customIcon}
                      >
                        <Popup
                          position={[
                            selectedCityCoordinates.latitude,
                            selectedCityCoordinates.longitude,
                          ]}
                        >
                          <div className="custom-marker-popup">
                            <div className="custom-popup-image">
                              {isBase64Image(projectImage) ? (
                                <img
                                  src={`data:image/jpeg;base64,${projectImage}`}
                                  alt="propertyimage"
                                />
                              ) : (
                                <img src={projectImage} alt="propertyimage" />
                              )}
                            </div>
                            <div className="custom-popup-content">
                              <h5>{projectTitle}</h5>
                              <h4>
                                {fetchedProject?.projectPriceRange?.priceFrom &&
                                fetchedProject?.projectPriceRange?.priceTo ? (
                                  <>
                                    <PriceFormatter
                                      amount={
                                        fetchedProject.projectPriceRange
                                          .priceFrom
                                      }
                                      currencyType={
                                        fetchedProject.projectCurrencyType
                                      }
                                    />
                                    {" - "}
                                    <PriceFormatter
                                      amount={
                                        fetchedProject.projectPriceRange.priceTo
                                      }
                                      currencyType={
                                        fetchedProject.projectCurrencyType
                                      }
                                    />
                                  </>
                                ) : (
                                  "Loading..."
                                )}
                              </h4>
                            </div>
                          </div>
                        </Popup>
                      </Marker>

                      <ClickHandler />
                    </MapContainer>
                  </div>
                  <div className="vp-map-address">
                    <h5>Address</h5>
                    <p>
                      {fetchedProject?.projectLocality},{" "}
                      {fetchedProject?.projectCity}
                    </p>
                  </div>
                </div>
              )}
              {fetchedProject?.projectLayout && fetchedProject?.projectLayout!=="" && (
                <div className="vp-content-left-container">
                  <h5>Project Layout</h5>
                  <div className="vp-map">
                    <img
                      src={fetchedProject?.projectLayout}
                      className="vp-property-layout-img"
                      alt="projectLayout"
                    />
                  </div>
                  <div className="vp-map-address">
                    <h5>Note:</h5>
                    <p>
                      This project layout is for illustrative purposes only.
                      Please do not rely on this as an exact representation.
                    </p>
                  </div>
                </div>
              )}
              {fetchedProperties && fetchedProperties?.length > 0 && (
                <div className="vp-content-left-container">
                  <h5>Properties offered in this project</h5>
                  <div className="vp-pip-category">
                    <div
                      className={`vp-pip-category-sub ${
                        propertyFilter.default === "All" ? `selected` : ""
                      }`}
                      onClick={() =>
                        setPropertyFilter(() => ({ default: "All" }))
                      }
                    >
                      <span>All</span>
                    </div>
                    {/* Property Status */}
                    {fetchedPropertiesStatus &&
                      fetchedPropertiesStatus?.length > 0 &&
                      fetchedPropertiesStatus?.map((status) => (
                        <div
                          className={`vp-pip-category-sub ${
                            propertyFilter.status === status ? `selected` : ""
                          }`}
                          onClick={() =>
                            setPropertyFilter(() => ({ status: status }))
                          }
                        >
                          <span>{status}</span>
                        </div>
                      ))}
                    {/* Property Floor Plans */}
                    {fetchedPropertiesFloorPlans &&
                      fetchedPropertiesFloorPlans?.length > 0 &&
                      fetchedPropertiesFloorPlans?.map((floorPlan) => (
                        <div
                          className={`vp-pip-category-sub ${
                            propertyFilter.floorPlan === floorPlan
                              ? `selected`
                              : ""
                          }`}
                          onClick={() =>
                            setPropertyFilter(() => ({ floorPlan: floorPlan }))
                          }
                        >
                          <span>{floorPlan}</span>
                        </div>
                      ))}
                  </div>
                  <div className="vp-pip-category vp-pip-category-ml">
                    {renderProperties()}
                  </div>
                  <span>
                    See all {!propertyFilter.default && filter.toLowerCase()}{" "}
                    properties in this project ({propertiesCount})
                  </span>
                </div>
              )}
              {fetchedProject?.projectBrochure &&
                fetchedProject?.userId !== userId && (
                  <div className="vp-content-left-container">
                    <h5>File Attachments</h5>
                    <div className="vp-documents">
                      {isBrochureAlreadyDownloaded ? (
                        <>
                          <div className="vp-documents-sub">
                            <FilePdfOutlined
                              className="vp-documents-sub-icon"
                              onClick={() =>
                                downloadBrochureAgain(
                                  fetchedProject?.projectBrochure
                                )
                              }
                            />
                            <span
                              onClick={() =>
                                downloadBrochureAgain(
                                  fetchedProject?.projectBrochure
                                )
                              }
                            >
                              Download Brochure Again
                            </span>
                            <VerticalAlignBottomOutlined
                              className="vp-documents-sub-icon-download"
                              onClick={() =>
                                downloadBrochureAgain(
                                  fetchedProject?.projectBrochure
                                )
                              }
                            />
                          </div>
                        </>
                      ) : (
                        <div className="vp-documents-sub">
                          <FilePdfOutlined
                            className="vp-documents-sub-icon"
                            onClick={showBrochureModal}
                          />
                          <span onClick={showBrochureModal}>
                            Download Brochure
                          </span>
                          <VerticalAlignBottomOutlined
                            className="vp-documents-sub-icon-download"
                            onClick={showBrochureModal}
                          />
                        </div>
                      )}
                      <Modal
                        title="File Attachment (Brochure)"
                        visible={isBrochureModalVisible}
                        onOk={handleBrochureModalOk}
                        onCancel={handleBrochureModalCancel}
                        footer={null}
                        centered
                        className="vp-brochure-modal"
                      >
                        <div className="vp-brochure-modal-sub">
                          <h5>BASIC INFORMATION</h5>
                          <p>Your reason to buy is</p>
                          <div className="vp-brochure-modal-category">
                            <Radio.Group
                              onChange={onChangeReasonToBuy}
                              value={reasonToBuy}
                              className="vp-brochure-modal-category-radio"
                            >
                              <Radio value="Investment">Investment</Radio>
                              <Radio value="Self Use">Self Use</Radio>
                            </Radio.Group>
                            <p>Are you a property Dealer?</p>
                            <div className="vp-brochure-modal-category">
                              <Radio.Group
                                onChange={onChangeIsPropertyDealer}
                                value={isPropertyDealer}
                                className="vp-brochure-modal-category-radio"
                              >
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Radio.Group>
                            </div>
                          </div>
                        </div>
                        <div className="vp-brochure-modal-info">
                          <input
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <input
                            type="text"
                            placeholder="Mobile"
                            value={formatMobileDisplay(mobile)}
                            onChange={handleMobileChange}
                            maxLength="14"
                          />
                          <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="vp-brochure-modal-sub">
                          <h5>OPTIONAL INFORMATION</h5>
                          <p>By when you are planning to buy the property?</p>
                          <div className="vp-brochure-modal-category">
                            <Radio.Group
                              onChange={onChangePlanningToBuy}
                              value={planningToBuy}
                            >
                              <Radio value="3 months">3 months</Radio>
                              <Radio value="6 months">6 months</Radio>
                              <Radio value="More than 6 months">
                                More than 6 months
                              </Radio>
                            </Radio.Group>
                          </div>
                          <Checkbox
                            className="vp-brochure-modal-sub-checkbox"
                            checked={interestedInHomeLoan}
                            onChange={onChangeInterestedInHomeLoan}
                          >
                            I am interested in home loan.
                          </Checkbox>
                          <Checkbox
                            className="vp-brochure-modal-sub-checkbox"
                            checked={interestedInSiteVisits}
                            onChange={onChangeInterestedInSiteVisits}
                          >
                            I am interested in site visits.
                          </Checkbox>
                          <Checkbox
                            className="vp-brochure-modal-sub-checkbox"
                            checked={agreedToTerms}
                            onChange={onChangeAgreedToTerms}
                          >
                            I agree to the{" "}
                            <span className="vp-brochure-modal-sub-checkbox-span">
                              Terms & Conditions
                            </span>{" "}
                            and{" "}
                            <span className="vp-brochure-modal-sub-checkbox-span">
                              Privacy Policy
                            </span>
                            .
                          </Checkbox>
                        </div>
                        <button
                          className="vp-brochure-modal-button"
                          onClick={handleDownloadBrochure}
                        >
                          Download Brochure
                        </button>
                      </Modal>
                    </div>
                  </div>
                )}
              {/* <div className="vp-content-left-container">
            <h5>Loan Calculator</h5>
          </div> */}
              <div className="vp-content-left-container">
                <h5>What's Nearby</h5>
                <p className="vp-nearby-p">
                  Discover the convenience of local amenities and attractions
                  just moments away from your new home.
                </p>
                <div className="vp-pd">
                  <div className="vp-pd-sub">
                    <h5>School:</h5>
                    <span>0.7 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Hospital:</h5>
                    <span>0.4 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>University:</h5>
                    <span>0.8 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Metro Station:</h5>
                    <span>0.1 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Grocery Center:</h5>
                    <span>0.3 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Gym, Wellness:</h5>
                    <span>0.2 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Market:</h5>
                    <span>0.9 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>River:</h5>
                    <span>0.5 Km</span>
                  </div>
                </div>
              </div>
              <div className="vp-content-left-container">
                <h5>Project Reviews</h5>
                <div className="vp-reviews">
                  {[...Array(3)].map((_, index) => (
                    <div className="vp-reviews-sub">
                      <img src="https://placehold.co/100x100?text=A" alt="" />
                      <div className="vp-review-main">
                        <h5>Anil Kumar</h5>
                        <span>August 13, 2024</span>
                        <Flex
                          gap="middle"
                          vertical
                          className="vp-reviews-rating"
                        >
                          <Rate
                            tooltips={desc}
                            onChange={setRating}
                            value={rating}
                          />
                        </Flex>
                        <p>
                          I recently moved into this property and I couldn't be
                          happier with my decision. The amenities are top-notch,
                          from the beautifully maintained landscaped gardens to
                          the fully-equipped gym. The location is also
                          incredibly convenient, with great cafes, parks, and
                          public transport options just a stone's throw away.
                          The staff is friendly and always willing to help.
                          Overall, it's a fantastic place to live and I highly
                          recommend it to anyone looking for a new home.
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <span>See all reviews (19)</span>
              </div>
              <div className="vp-content-left-container vp-content-left-container-postreview">
                <h5>Leave a review</h5>
                <Flex gap="middle" vertical className="vp-reviews-rating">
                  <Rate tooltips={desc} onChange={setRating} />
                </Flex>
                <div className="vp-postreview">
                  <div className="vp-postreview-sub">
                    <h5>Review</h5>
                    <input type="text"></input>
                    <button>Post Review</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="vp-content-right">
              {renderSellerInfo()}
              <div className="vp-content-right-container">
                <h5>Why Choose Us?</h5>
                <div className="vp-content-right-wcu">
                  <IoLockClosedOutline className="vp-content-right-wcu-icon" />
                  <span>Secure Booking</span>
                </div>
                <div className="vp-content-right-wcu">
                  <IoIosCheckmarkCircleOutline className="vp-content-right-wcu-icon" />
                  <span>Best Price Guarantee</span>
                </div>
                <div className="vp-content-right-wcu">
                  <PiHandTapLight className="vp-content-right-wcu-icon" />
                  <span>Easy Booking Process</span>
                </div>
                <div className="vp-content-right-wcu">
                  <AiOutlineCustomerService className="vp-content-right-wcu-icon" />
                  <span>Customer Support Ease</span>
                </div>
              </div>
            </div>
          </div>
          <div className="vp-featured-properties">
            <div className="searches-header">
              <h5>RELATED PROJECTS</h5>
              <div className="searches-header-sub">
                <h1>Explore Similar Projects</h1>
              </div>
            </div>
            <div className="vp-featured-properties-sub">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="trending-prop-sub">
                  <div className="trending-prop-img">
                    <img
                      src="https://placehold.co/800x450?text=proptzo"
                      alt="property"
                    />
                    <div className="trending-for">
                      <h5>READY TO MOVE</h5>
                    </div>
                    <div className="trending-type">
                      <h5>FREEHOLD</h5>
                    </div>
                    <div className="trending-rera">
                      <SafetyCertificateOutlined className="trending-rera-icon" />
                      <h5>RERA CERTIFIED</h5>
                    </div>
                    <div className="trending-prop-img-icons">
                      <ShareAltOutlined className="trending-prop-img-icons-icon" />
                      <EyeOutlined className="trending-prop-img-icons-icon" />
                    </div>
                  </div>
                  <h5>Sushma Grande NXT</h5>
                  <div className="trending-prop-loc">
                    <EnvironmentOutlined className="trending-prop-loc-icon" />
                    <p>
                      15 Willow Street, Seattle, WA 15 Willow Street, Seattle,
                      WA
                    </p>
                  </div>
                  <div className="trending-prop-data">
                    <ExpandAltOutlined className="trending-prop-data-icon" />
                    <h5>2250 sqft</h5>
                  </div>
                  <div className="trending-prop-footer">
                    <h5 className="trending-prop-footer-name">Anil Kumar</h5>
                    <h5 className="trending-prop-footer-price">
                      ₹ 2.5Cr - 10.2Cr
                    </h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="vp-featured-properties">
            <div className="searches-header">
              <h5>FEATURED PROJECTS</h5>
              <div className="searches-header-sub">
                <h1>Discover More Innovative Projects</h1>
              </div>
            </div>
            <div className="vp-featured-properties-sub">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="trending-prop-sub">
                  <div className="trending-prop-img">
                    <img
                      src="https://placehold.co/800x450?text=proptzo"
                      alt="property"
                    />
                    <div className="trending-for">
                      <h5>READY TO MOVE</h5>
                    </div>
                    <div className="trending-type">
                      <h5>FREEHOLD</h5>
                    </div>
                    <div className="trending-rera">
                      <SafetyCertificateOutlined className="trending-rera-icon" />
                      <h5>RERA CERTIFIED</h5>
                    </div>
                    <div className="trending-prop-img-icons">
                      <ShareAltOutlined className="trending-prop-img-icons-icon" />
                      <EyeOutlined className="trending-prop-img-icons-icon" />
                    </div>
                  </div>
                  <h5>Sushma Grande NXT</h5>
                  <div className="trending-prop-loc">
                    <EnvironmentOutlined className="trending-prop-loc-icon" />
                    <p>
                      15 Willow Street, Seattle, WA 15 Willow Street, Seattle,
                      WA
                    </p>
                  </div>
                  <div className="trending-prop-data">
                    <ExpandAltOutlined className="trending-prop-data-icon" />
                    <h5>2250 sqft</h5>
                  </div>
                  <div className="trending-prop-footer">
                    <h5 className="trending-prop-footer-name">Anil Kumar</h5>
                    <h5 className="trending-prop-footer-price">
                      ₹ 2.5Cr - 10.2Cr
                    </h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProjects;
