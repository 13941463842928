import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./ViewAgentProfile.css";

// Ant Design
import { Flex, Rate } from "antd";
import {
  EnvironmentOutlined,
  ExpandAltOutlined,
  EyeOutlined,
  SafetyCertificateOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

// React Icons
import { MdEdit } from "react-icons/md";
import { RiHomeOfficeLine } from "react-icons/ri";
import { TbSettings2 } from "react-icons/tb";
import { LiaUserTagSolid } from "react-icons/lia";
import { CiShoppingTag } from "react-icons/ci";
import { useRecoilState, useRecoilValue } from "recoil";
import { userDataAtom } from "../../store/atoms/userDataAtom";
import axios from "axios";
import { fetchLatestUserDetails } from "../../store/atoms/fetchLatestUserDetails";
import { useNavigate, useParams } from "react-router-dom";
import { formatPrice } from "../../utils/priceFormatter";
import { PiBathtubDuotone, PiBedDuotone } from "react-icons/pi";
import PriceFormatter from "../../components/PriceFormatter/PriceFormatter";

function ViewAgentProfile() {
  useEffect(() => {
    document.title = "Proptzo - View Agent Details";
  }, []);

  // Ratings Functionality
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];
  const [rating, setRating] = useState(3);
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const navigate = useNavigate();
  const [fetchLatestUserData, setFetchLatestUserData] = useRecoilState(
    fetchLatestUserDetails
  );
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userProfileData, setUserProfileData] = useState(null);
  const [allUserData, setAllUserData] = useState(null);

  const [employeerDetails, setEmployeerDetails] = useState(null);
  const [isProfileVerified, setIsProfileVerified] = useState(null);

  const [allProperties, setAllProperties] = useState(null);
  const [allProjects, setAllProjects] = useState(null);

  const { profileId } = useParams();

  useMemo(() => {
    if (userDetails) {
      setUserId(userDetails?.user?._id);
    }
  }, [userDetails]);

  const fetchUserData = useCallback(async () => {
    try {
      const alldetails = true;
      const _userProfileData = await axios.get(
        `${baseURL}/api/profile/getUserDetailsById/${profileId}?alldetails=${alldetails}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setUserProfileData(_userProfileData.data?.profileData);
      setUserType(_userProfileData.data?.profileData?.userType);
      setAllUserData(_userProfileData.data);
      setEmployeerDetails(_userProfileData?.data?.employeer);
      setIsProfileVerified(_userProfileData?.data?.isProfileVerified);
    } catch (error) {
      if (
        error.response.data ||
        error.response.data.message === "userId parameter is required" ||
        error.response.data.message === "profile not found"
      ) {
        navigate("/profile-not-found");
      }
      console.error("Error fetching user data:", error);
    }
  }, [baseURL, userId]);

  const fetchAllPosts = useCallback(async () => {
    try {
      const allPosts = await axios.get(
        `${baseURL}/api/profile/getAllPostsById/${profileId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (
        allPosts.status === 200 ||
        allPosts.statusText === "OK" ||
        allPosts?.data?.message === "posts fetched successfully"
      ) {
        setAllProperties(allPosts?.data?.allProperties);
        setAllProjects(allPosts?.data?.allProjects);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [baseURL, userId]);

  useMemo(() => {
    if (userId && baseURL && !fetchLatestUserData) {
      fetchUserData();
    }
    fetchAllPosts();
    return () => setFetchLatestUserData(false);
  }, [userId, baseURL, fetchUserData, fetchAllPosts, fetchLatestUserData]);

  const annualIncome =
    userProfileData?.annualIncome &&
    userProfileData?.annualIncome !== "undefined" &&
    userProfileData?.annualIncome !== "[]"
      ? JSON.parse(userProfileData?.annualIncome)
      : [];

  return (
    <div className="blogs">
      <div className="blogs-sub">
        <h5>{userType}</h5>
      </div>
      <div className="vap">
        <div className="vap-sub">
          <img
            src={
              userProfileData?.profilePhoto &&
              userProfileData?.profilePhoto !== ""
                ? userProfileData?.profilePhoto
                : `https://placehold.co/150x150?text=${userProfileData?.userName?.charAt(
                    0
                  )}`
            }
            alt=""
          />
        </div>
        <h5>{userProfileData?.userName}</h5>
      </div>
      <div className="vap-container">
        {userType !== "Buyer" && userType !== "Owner" && (
          <div className="vap-container-sub">
            <h4>Business Info</h4>
            {userType === "Salesperson" && (
              <div className="vap-category">
                <div className="vap-dealsIn">
                  <LiaUserTagSolid className="vap-dealsIn-icon" />
                  <div className="vap-office">
                    <div className="vap-office-sub">
                      <h5>Employee Designation</h5>
                      <h6>Web Developer (Mern Stack)</h6>
                    </div>
                  </div>
                </div>
                <div className="vap-share">
                  <ShareAltOutlined
                    className="vp-hero-icons-sub"
                    // onClick={showShareModal}
                  />
                  <MdEdit className="vp-hero-icons-sub" />
                </div>
              </div>
            )}
            {userType === "Agent" && (
              <div className="vap-category">
                <div className="vap-dealsIn">
                  <CiShoppingTag className="vap-dealsIn-icon" />
                  <div className="vap-office">
                    <div className="vap-office-sub">
                      <h5>Agent Deals In</h5>
                      <div className="vap-office-agent-dealsIn">
                        <div className="vap-office-agent-dealsIn-sub selected">
                          Sale
                        </div>
                        <div className="vap-office-agent-dealsIn-sub">
                          Rent / Lease
                        </div>
                        <div className="vap-office-agent-dealsIn-sub">
                          PG / Hostel
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vap-share">
                  <ShareAltOutlined
                    className="vp-hero-icons-sub"
                    // onClick={showShareModal}
                  />
                  <MdEdit className="vp-hero-icons-sub" />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="vap-container-sub">
          {userType !== "Buyer" &&
            userType !== "Owner" &&
            (userProfileData?.firmName ||
              userProfileData?.firmLogo ||
              userProfileData?.firmAddress ||
              userProfileData?.reraNo ||
              userProfileData?.reraNoType) && (
              <>
                <h4>Firm Details</h4>
                <div className="vap-category">
                  <div className="vap-dealsIn">
                    <img
                      src={
                        userProfileData?.firmLogo &&
                        userProfileData?.firmLogo !== ""
                          ? userProfileData?.firmLogo
                          : `https://placehold.co/150x150?text=${userProfileData?.firmName?.charAt(
                              0
                            )}`
                      }
                      alt=""
                    />
                    <div className="vap-dealsIn-sub">
                      {userProfileData?.firmName && (
                        <h3>Firm Name: {userProfileData?.firmName}</h3>
                      )}
                      {userProfileData?.reraNo && (
                        <h5>Rera No.: {userProfileData?.reraNo}</h5>
                      )}
                      {userProfileData?.reraNoType && (
                        <h5>Rera No Type: {userProfileData?.reraNoType}</h5>
                      )}
                      {userProfileData?.firmAddress && (
                        <h5>Firm Address: {userProfileData?.firmAddress}</h5>
                      )}
                    </div>
                  </div>
                  <div className="vap-share">
                    <ShareAltOutlined
                      className="vp-hero-icons-sub"
                      // onClick={showShareModal}
                    />
                    <MdEdit className="vp-hero-icons-sub" />
                  </div>
                </div>
              </>
            )}
        </div>
        {userType !== "Buyer" &&
          userType !== "Owner" &&
          (userProfileData?.officeName ||
            userProfileData?.officeCity ||
            userProfileData?.officeWebsite) && (
            <div className="vap-container-sub">
              <h4>Office Details</h4>
              <div className="vap-category">
                <div className="vap-dealsIn">
                  <RiHomeOfficeLine className="vap-dealsIn-icon" />
                  <div className="vap-office">
                    {userProfileData?.officeName && (
                      <div className="vap-office-sub">
                        <h5>Office Name</h5>
                        <h6>{userProfileData?.officeName}</h6>
                      </div>
                    )}
                    {userProfileData?.officeCity && (
                      <div className="vap-office-sub">
                        <h5>Office City</h5>
                        <h6>{userProfileData?.officeCity}</h6>
                      </div>
                    )}
                    {userProfileData?.officeWebsite && (
                      <div className="vap-office-sub">
                        <h5>Office Website URL</h5>
                        <h6
                          className="vap-office-sub-h6-url"
                          onClick={() =>
                            window.open(
                              userProfileData?.officeWebsite,
                              "_blank"
                            )
                          }
                        >
                          See Website
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        {userProfileData?.showPersonalDetails && (
          <div className="vap-container-sub">
            <h4>Personal Details</h4>
            <div className="vap-category">
              <div className="vap-dealsIn">
                <TbSettings2 className="vap-dealsIn-icon" />
                <div className="vap-office">
                  {annualIncome.length !== 0 && (
                    <div className="vap-office-sub">
                      <h5>Annual Income</h5>
                      <h6>{`${
                        userProfileData?.annualIncomeCurrencyType
                      } ${formatPrice(
                        annualIncome[0],
                        userProfileData?.annualIncomeCurrencyType
                      )} - ${
                        userProfileData?.annualIncomeCurrencyType
                      } ${formatPrice(
                        annualIncome[1],
                        userProfileData?.annualIncomeCurrencyType
                      )}`}</h6>
                    </div>
                  )}
                  {userProfileData?.profession && (
                    <div className="vap-office-sub">
                      <h5>Profession</h5>
                      <h6>{userProfileData?.profession}</h6>
                    </div>
                  )}
                  {userProfileData?.dob && (
                    <div className="vap-office-sub">
                      <h5>DOB</h5>
                      <h6>{userProfileData?.dob}</h6>
                    </div>
                  )}
                  {userProfileData?.gender && (
                    <div className="vap-office-sub">
                      <h5>Gender</h5>
                      <h6>{userProfileData?.gender}</h6>
                    </div>
                  )}
                  {userProfileData?.maritalStatus && (
                    <div className="vap-office-sub">
                      <h5>Martial Status</h5>
                      <h6>{userProfileData?.maritalStatus}</h6>
                    </div>
                  )}
                  {userProfileData?.maritalStatus === "Married" &&
                    userProfileData?.marriageAnniversary && (
                      <div className="vap-office-sub">
                        <h5>Anniversary Date</h5>
                        <h6>{userProfileData?.marriageAnniversary}</h6>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
        {allProperties?.length > 0 && (
          <div className="vap-container-sub">
            <h4>{userType} Properties</h4>
            <div className="vap-properties">
              {allProperties?.map((property, index) => (
                <div key={index} className="trending-prop-sub">
                  <div className="trending-prop-img">
                    <img
                      src={
                        property?.picture && property?.picture !== ""
                          ? property?.picture
                          : "https://placehold.co/800x450?text=proptzo"
                      }
                      alt="property"
                    />
                    <div className="trending-for">
                      <h5>FOR {property?.action?.toUpperCase()}</h5>
                    </div>
                    <div className="trending-type">
                      <h5>{property?.subType?.toUpperCase()}</h5>
                    </div>
                    <div className="trending-rera">
                      <SafetyCertificateOutlined className="trending-rera-icon" />
                      <h5>RERA CERTIFIED</h5>
                    </div>
                    <div className="trending-prop-img-icons">
                      <ShareAltOutlined className="trending-prop-img-icons-icon" />
                      <EyeOutlined className="trending-prop-img-icons-icon" onClick={()=> navigate(`/viewproperty/${property.propertyId}`)}/>
                        {profileId === userId ? (
                  <MdEdit
                    className="trending-prop-img-icons-icon"
                    onClick={() =>
                      navigate(`/postupdate/${property.propertyId}`)
                    }
                  />
                ) : null}
                    </div>
                  </div>
                  <h5>{property?.title}</h5>
                  <div className="trending-prop-loc">
                    <EnvironmentOutlined className="trending-prop-loc-icon" />
                    <p>{`${property?.locality}, ${property?.city}`}</p>
                  </div>
                  <div className="trending-prop-data">
                    <PiBedDuotone className="trending-prop-data-icon" />
                    <h5>{property?.bedroomCount}</h5>
                    <PiBathtubDuotone className="trending-prop-data-icon" />
                    <h5>{property?.bathroomCount}</h5>
                    <ExpandAltOutlined className="trending-prop-data-icon" />
                    <h5>{`${property?.area} ${property?.areaUnit}`}</h5>
                  </div>
                  <div className="trending-prop-footer">
                    <h5 className="trending-prop-footer-name">
                      {userProfileData?.userName}
                    </h5>
                    <h5 className="trending-prop-footer-price">
                      <PriceFormatter
                        amount={property?.price}
                        currencyType={property?.currencyType}
                      />
                    </h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {(userType === "Builder" || userType === "Agent") &&
          allProjects?.length > 0 && (
            <div className="vap-container-sub">
              <h4>{userType} Projects</h4>
              <div className="vap-properties">
                {allProjects.map((project, index) => (
                  <div key={index} className="trending-prop-sub">
                    <div className="trending-prop-img">
                      <img
                        src={
                          project?.logo && project?.logo !== ""
                            ? project?.logo
                            : "https://placehold.co/800x450?text=proptzo"
                        }
                        alt="property"
                      />
                      <div className="trending-for">
                        <h5>{project?.status?.toUpperCase()}</h5>
                      </div>
                      <div className="trending-type">
                        <h5>{project?.ownershipType?.toUpperCase()}</h5>
                      </div>
                      <div className="trending-rera">
                        <SafetyCertificateOutlined className="trending-rera-icon" />
                        <h5>RERA CERTIFIED</h5>
                      </div>
                      <div className="trending-prop-img-icons">
                        <ShareAltOutlined className="trending-prop-img-icons-icon" />
                        <EyeOutlined className="trending-prop-img-icons-icon" onClick={()=> navigate(`/viewprojects/${project.projectId}`)}/>
                          {profileId === userId ? (
                  <MdEdit
                    className="trending-prop-img-icons-icon"
                    onClick={() =>
                      navigate(`/projectupdate/${project.projectId}`)
                    }
                  />
                ) : null}
                      </div>
                    </div>
                    <h5>{project?.title}</h5>
                    <div className="trending-prop-loc">
                      <EnvironmentOutlined className="trending-prop-loc-icon" />
                      <p>{`${project.locality}, ${project.city}`}</p>
                    </div>
                    <div className="trending-prop-data">
                      <ExpandAltOutlined className="trending-prop-data-icon" />
                      <h5>{`${project?.area} ${project?.areaUnit}`}</h5>
                    </div>
                    <div className="trending-prop-footer">
                      <h5 className="trending-prop-footer-name">
                        {userProfileData?.userName}
                      </h5>
                      <h5 className="trending-prop-footer-price">
                        {project?.priceRange?.priceFrom &&
                        project?.priceRange?.priceTo ? (
                          <>
                            <PriceFormatter
                              amount={project?.priceRange?.priceFrom}
                              currencyType={project?.currencyType}
                            />
                            {" - "}
                            <PriceFormatter
                              amount={project?.priceRange?.priceTo}
                              currencyType={project?.currencyType}
                            />
                          </>
                        ) : (
                          "Loading..."
                        )}
                      </h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        <div className="vap-container-sub">
          <h4>Agent Reviews</h4>
          <div className="vap-properties">
            {[...Array(3)].map((_, index) => (
              <div className="vp-reviews-sub">
                <img src="https://placehold.co/100x100?text=A" alt="" />
                <div className="vp-review-main">
                  <h5>Anil Kumar</h5>
                  <span>August 13, 2024</span>
                  <Flex gap="middle" vertical className="vp-reviews-rating">
                    <Rate tooltips={desc} onChange={setRating} value={rating} />
                  </Flex>
                  <p>
                    I recently moved into this property and I couldn't be
                    happier with my decision. The amenities are top-notch, from
                    the beautifully maintained landscaped gardens to the
                    fully-equipped gym. The location is also incredibly
                    convenient, with great cafes, parks, and public transport
                    options just a stone's throw away. The staff is friendly and
                    always willing to help. Overall, it's a fantastic place to
                    live and I highly recommend it to anyone looking for a new
                    home.
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAgentProfile;
