import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import "./UserManagement.css";

// Loader
import AdminLoader from "../../components/Loader/AdminLoader";

// Recoil Integration
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../../store/atoms/userDataAtom";

// Ant Design Imports
import {
  Table,
  Modal,
  Button,
  Input,
  Select,
  notification,
  message,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// React Icons
import { TbUserShield } from "react-icons/tb";
import { CgShapeCircle } from "react-icons/cg";
import { GoArrowRight } from "react-icons/go";

// schemas
import { newAdminUserSchema } from "../../../schemas/adminPanelSchema";

function AdminUsers() {
  const [loading, setLoading] = useState(true);
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;
  const userType = userDetails?.userProfile?.userType;

  const [isAdminRoleNotModified, setIsAdminRoleNotModified] = useState(true);

  // Add New Admin Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [newAdmin, setNewAdmin] = useState({
    userEmail: "",
    userMobileNo: "",
    selectedAdminRole: "",
  });

  const [fetchedAdminDetails, setFetchedAdminDetails] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setNewAdmin(()=>({
      userEmail: "",
      userMobileNo: "",
      selectedAdminRole: "",
    }));
    setFetchedAdminDetails(null);
    setIsModalOpen(false);
  };
  // Modify Admin Modal
  const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
  const [selectedAdminData, setSelectedAdminData] = useState({
    adminId: "",
    fullName: "",
    profileImage: "",
    location: "",
    currentAdminrole: "",
    createdAt: "",
    newAdminRole: "",
  });
  const showModifyModal = (selectedAdmin) => {
    setSelectedAdminData((prev) => ({
      ...prev,
      adminId: selectedAdmin?.id,
      fullName: selectedAdmin?.fullName,
      profileImage: selectedAdmin?.profileImage,
      currentAdminrole: selectedAdmin?.roles[0],
      createdAt: selectedAdmin?.createdAt,
      location: selectedAdmin?.currentLocation,
    }));

    setIsModifyModalOpen(true);
  };
  const handleModifyOk = () => {
    setIsModifyModalOpen(false);
  };
  const handleModifyCancel = () => {
    setSelectedAdminData((prev) => ({
      ...prev,
      adminId: "",
      fullName: "",
      profileImage: "",
      location: "",
      currentAdminrole: "",
      createdAt: "",
      newAdminRole: "",
    }));
    setIsModifyModalOpen(false);
  };
  // Delete Admin Modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const showDeleteModal = (selectedAdmin) => {
    setSelectedAdminData((prev) => ({
      ...prev,
      adminId: selectedAdmin?.id,
      fullName: selectedAdmin?.fullName,
      profileImage: selectedAdmin?.profileImage,
      currentAdminrole: selectedAdmin?.roles[0],
      createdAt: selectedAdmin?.createdAt,
      location: selectedAdmin?.currentLocation,
    }));
    setIsDeleteModalOpen(true);
  };
  
  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteCancel = () => {
    setSelectedAdminData((prev) => ({
      ...prev,
      adminId: "",
      fullName: "",
      profileImage: "",
      location: "",
      currentAdminrole: "",
      createdAt: "",
      newAdminRole: "",
    }));
    setIsDeleteModalOpen(false);
  };

  const formatMobileNumber = (mobile) => {
    if (typeof mobile === "string" && mobile.length >= 12) {
      const countryCode = mobile.slice(0, 2);
      const number = mobile.slice(2);
      return `+${countryCode} ${number}`;
    }
    return mobile;
  };

  const [data, setData] = useState([]);
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/api/admin/getAllAdminUsers`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (!response.data || !Array.isArray(response.data.adminList)) {
        throw new Error("Unexpected data format");
      }

      const formattedData = response.data.adminList.map((user, index) => {
        const roles = user.roles || [];
        const role = roles.length > 0 ? roles[0] : "not assigned";

        return {
          key: index.toString(),
          userId: user.id,
          name: <h5 className="auser-table-data">{user.fullName}</h5>,
          photo: (
            <img
              src={
                user.profileImage ||
                `https://placehold.co/40x40?text=${user.fullName
                  ?.charAt(0)
                  ?.toUpperCase()}`
              }
              alt=""
              className="auser-table-data-profileImg"
            />
          ),
          email: <h5 className="auser-table-data">{user.email || "N/A"}</h5>,
          mobile: (
            <h5 className="auser-table-data">
              {formatMobileNumber(user.mobile) || "N/A"}
            </h5>
          ),
          role: role,
          loginAt: (
            <h5 className="auser-table-data">
              {new Date(user.updatedAt).toLocaleString()}
            </h5>
          ),
          createdAt: (
            <h5 className="auser-table-data">
              {new Date(user.createdAt).toLocaleString()}
            </h5>
          ),
          action: (
            <div className="auser-table-data-action">
              <span onClick={() => showModifyModal(user)}>Modify Role</span>
              <span className="red" onClick={()=>showDeleteModal(user)}>
                Delete Admin
              </span>
            </div>
          ),
        };
      });

      // Sort data to show the logged-in user on top
      const sortedData = formattedData.sort((a, b) => {
        if (a.userId === userId) return -1;
        if (b.userId === userId) return 1;
        return 0;
      });

      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      notification.error({
        message: "Error",
        description: "Failed to load user data",
      });
    } finally {
      setLoading(false);
    }
  }, [baseURL, userId]);

  useEffect(() => {
    if (isAdminRoleNotModified) {
      fetchData();
    }
    return () => setIsAdminRoleNotModified(false);
  }, [fetchData, isAdminRoleNotModified]);

  const handleNewAdminDetails = (e) => {
    const { name, value } = e.target;

    if (name === "userEmail") {
      setNewAdmin((prev) => ({
        ...prev,
        userEmail: value,
      }));
      return;
    } else if (name === "userMobileNo") {
      setNewAdmin((prev) => ({
        ...prev,
        userMobileNo: value,
      }));
      return;
    }
  };

  const searchAdminDetails = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append("userEmail", newAdmin.userEmail);
      formData.append("userMobileNo", newAdmin.userMobileNo);

      const res = await axios.post(
        `${baseURL}/api/admin/searchAdminDetails`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (
        res.data.message === "Admin details fetched successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        message.success(res.data.message);
        setFetchedAdminDetails(res.data.adminInfo);
      }
    } catch (error) {
      message.error(error.response.data.message);
      console.error("Error updating admin role:", error);
    }
  }, [newAdmin, baseURL]);

  useEffect(() => {
    let searchAdminTimeout;

    if (newAdmin.userEmail !== "" || newAdmin.userMobileNo !== "") {
      clearTimeout(searchAdminTimeout); // Clear previous timeout if any
      searchAdminTimeout = setTimeout(() => {
        searchAdminDetails();
      }, 2000);
    }

    return () => clearTimeout(searchAdminTimeout);
  }, [newAdmin, searchAdminDetails]);

  const handleAddAdmin = async () => {
    if (
      newAdmin.userEmail !== "" &&
      newAdminUserSchema.safeParse({ adminEmail: newAdmin.userEmail })
        .success === false
    ) {
      const errorMessage = newAdminUserSchema.safeParse({
        adminEmail: newAdmin.userEmail,
      }).error.issues[0].message;

      return message.error(errorMessage);
    }

    if (
      newAdmin.userMobileNo !== "" &&
      newAdminUserSchema.safeParse({ adminMobile: newAdmin.userMobileNo })
        .success === false
    ) {
      const errorMessage = newAdminUserSchema.safeParse({
        adminMobile: newAdmin.userMobileNo,
      }).error.issues[0].message;
      return message.error(errorMessage);
    }

    if (
      newAdminUserSchema.safeParse({
        selectedAdminRole: newAdmin.selectedAdminRole,
      }).success === false
    ) {
      const errorMessage = newAdminUserSchema.safeParse({
        selectedAdminRole: newAdmin.selectedAdminRole,
      }).error.issues[0].message;
      return message.error(errorMessage);
    }

    try {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("userEmail", newAdmin.userEmail);
      formData.append("userMobileNo", newAdmin.userMobileNo);
      formData.append("selectedAdminRole", newAdmin.selectedAdminRole);
      const res = await axios.post(
        ` ${baseURL}/api/admin/addNewAdmin`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        res.data.message === "Admin role updated successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        message.success(res.data.message);
        setIsAdminRoleNotModified(true);
      }
    } catch (error) {
      message.error(error.response.data.message);
      console.error("Error updating admin role:", error);
      setIsAdminRoleNotModified(false);
    } finally {
      setNewAdmin(() => ({
        userEmail: "",
        userMobileNo: "",
        selectedAdminRole: "",
      }));
      setFetchedAdminDetails(null);
      setIsModalOpen(false);
    }
  };

  const handleModifyAdmin = async () => {
    if (selectedAdminData.newAdminRole === "") {
      return message.error("Please select a admin role");
    }
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("selectedAdminUserId", selectedAdminData.adminId);
      formData.append("selectedAdminRole", selectedAdminData.newAdminRole);
      const res = await axios.post(
        `${baseURL}/api/admin/updateAdminRole`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        res.data.message === "Admin role updated successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        message.success(res.data.message);
        setIsAdminRoleNotModified(true);
      }
    } catch (error) {
      setIsAdminRoleNotModified(false);
      message.error(error.response.data.message);
      console.error("Error updating admin role:", error);
    } finally {
      setIsModifyModalOpen(false);
      setSelectedAdminData((prev) => ({
        ...prev,
        adminId: "",
        fullName: "",
        profileImage: "",
        location: "",
        currentAdminrole: "",
        createdAt: "",
        newAdminRole: "",
      }));
    }
  };

  const handleDeleteAdmin = async () => {
    if (selectedAdminData.adminId === "") {
      return message.error("Please select an admin to remove");
    }
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("selectedAdminUserId", selectedAdminData.adminId);
      const res = await axios.patch(
        `${baseURL}/api/admin/removeAdmin`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        res.data.message === "Admin removed successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        message.success(res.data.message);
        setIsAdminRoleNotModified(true);
      }
    } catch (error) {
      setIsAdminRoleNotModified(false);
      message.error(error.response.data.message);
      console.error("Error removing admin:", error);
    } finally {
      setIsDeleteModalOpen(false);
      setSelectedAdminData((prev) => ({
        ...prev,
        adminId: "",
        fullName: "",
        profileImage: "",
        location: "",
        currentAdminrole: "",
        createdAt: "",
        newAdminRole: "",
      }));
    }
    
  };

  const columns = [
    {
      title: "PHOTO",
      dataIndex: "photo",
      align: "center",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "NAME",
      dataIndex: "name",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "MOBILE NO.",
      dataIndex: "mobile",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "USER ID",
      dataIndex: "userId",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "ROLE",
      dataIndex: "role",
      responsive: ["xs", "sm"],
      ellipsis: true,
      filters: [
        {
          text: "SUPER ADMIN",
          value: "superAdmin",
        },
        {
          text: "SUB ADMIN",
          value: "subAdmin",
        },
        {
          text: "REVIEWER",
          value: "reviewer",
        },
      ],
      onFilter: (value, record) => record.role.indexOf(value) === 0,
      render: (role) => {
        let roleClass = "";
        switch (role) {
          case "superAdmin":
            roleClass = "superAdmin";
            break;
          case "subAdmin":
            roleClass = "subAdmin";
            break;
          case "reviewer":
            roleClass = "reviewer";
            break;
          default:
            roleClass = "default";
        }
        return (
          <div className={`auser-table-data-role ${roleClass}`}>
            <CgShapeCircle className="auser-table-data-role-icon" />{" "}
            {role.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: "LAST LOGIN AT",
      dataIndex: "loginAt",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "ACTION",
      dataIndex: "action",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
  ];

  return (
    <div className="ausers">
      {loading && <AdminLoader />}
      <div className="ausers-sub">
        <div className="auser-container">
          <h1>Admin Users</h1>
        </div>
        <div className="auser-container">
          <h4>Add new admin user</h4>
          <button onClick={showModal}>
            <TbUserShield className="auser-container-btn-icon" />
            New Administrator
          </button>
          <Modal
            title="Add New Admin to System"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={handleAddAdmin}>
                Add
              </Button>,
            ]}
            centered
            className="auser-modal"
          >
            <div className="auser-modal-sub">
              <p>
                (Note: You can update the selected role later if needed.
                However, please ensure the email and mobile number are accurate
                before proceeding.)
              </p>
            </div>
            <div className="auser-modal-sub">
              <label>Email</label>
              <Input
                placeholder="Enter Email"
                className="auser-modal-sub-input"
                name="userEmail"
                value={newAdmin.userEmail}
                onChange={(e) => handleNewAdminDetails(e)}
              />
            </div>
            <div className="auser-modal-sub">
              <label>Mobile</label>
              <Input
                placeholder="Enter Mobile No."
                className="auser-modal-sub-input"
                name="userMobileNo"
                value={newAdmin.userMobileNo}
                onChange={(e) => handleNewAdminDetails(e)}
              />
            </div>
            <div className="auser-modal-sub">
              <label>Admin Type</label>
              <Select
                options={[
                  {
                    value: "superAdmin",
                    label: "SUPER ADMIN",
                  },
                  {
                    value: "subAdmin",
                    label: "SUB ADMIN",
                  },
                  {
                    value: "reviewer",
                    label: "REVIEWER",
                  },
                  {
                    value: "comingSoon",
                    label: "More Coming Soon!",
                    disabled: true,
                  },
                ]}
                className="auser-modal-sub-input"
                value={
                  newAdmin.selectedAdminRole
                    ? newAdmin.selectedAdminRole
                    : "Select Admin Type"
                }
                onChange={(value) =>
                  setNewAdmin((prev) => ({
                    ...prev,
                    selectedAdminRole: value,
                  }))
                }
              />
            </div>
            {fetchedAdminDetails && (
              <>
                <div className="auser-modal-sub">
                  <div className="auser-modal-sub-profileCheck">
                    <img
                      src={
                        fetchedAdminDetails.profileImage
                          ? fetchedAdminDetails.profileImage
                          : "https://placehold.co/40x40?text=U"
                      }
                      alt=""
                      className="auser-modal-sub-profileImg"
                    />
                    <h4>
                      {fetchedAdminDetails.fullName
                        ? fetchedAdminDetails.fullName
                        : "Username"}
                    </h4>
                  </div>
                  <div className="auser-modal-sub-profileCheck ml">
                    {fetchedAdminDetails.currentLocation &&
                      fetchedAdminDetails.createdAt && (
                        <h5>
                          {`${fetchedAdminDetails.currentLocation} (User Created At: ${fetchedAdminDetails.createdAt})`}
                        </h5>
                      )}
                  </div>
                </div>
                <div className="auser-modal-sub">
                  <p>Selected Admin (Position/Role)</p>
                  <div className="auser-modal-sub-rolefromto">
                    <GoArrowRight className="auser-modal-sub-rolefromto-icon" />
                    {newAdmin.selectedAdminRole ? (
                      <div
                        className={`auser-table-data-role ${newAdmin.selectedAdminRole}`}
                      >
                        <CgShapeCircle className="auser-table-data-role-icon" />{" "}
                        {newAdmin.selectedAdminRole.toUpperCase()}
                      </div>
                    ) : (
                      <LoadingOutlined className="auser-modal-sub-rolefromto-icon" />
                    )}
                  </div>
                </div>
                <div className="auser-modal-sub">
                  <p>
                    Please confirm that the email and mobile number you’ve
                    entered are correct for this user. Double-check to avoid any
                    inconvenience.
                  </p>
                </div>
                <div className="auser-modal-sub">
                  <p>
                    If the details are incorrect, please re-enter the email and
                    mobile number for the account you want to add.
                  </p>
                </div>
              </>
            )}
          </Modal>
        </div>
        <div className="auser-container">
          <h4>
            Admin Users (<span>{data.length}</span>/10)
          </h4>
          <div className="auser-table">
            <Table
              loading={loading}
              className="auser-table-ant-design"
              bordered
              columns={columns}
              dataSource={data}
              showSorterTooltip={{
                target: "sorter-icon",
              }}
              pagination={{ position: ["bottomLeft"] }}
              scroll={{ x: true }}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Modify Admin Role to System"
        open={isModifyModalOpen}
        onOk={handleModifyOk}
        onCancel={handleModifyCancel}
        footer={[
          <Button key="back" onClick={handleModifyCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleModifyAdmin}>
            Modify
          </Button>,
        ]}
        centered
        className="auser-modal"
      >
        <div className="auser-modal-sub">
          <p>
            (Note: You can update the selected role. However, please ensure the
            email and mobile number are accurate before proceeding.)
          </p>
        </div>
        <div className="auser-modal-sub">
          <label>Admin Type</label>
          <Select
            options={[
              selectedAdminData.currentAdminrole !== "superAdmin" && {
                value: "superAdmin",
                label: "SUPER ADMIN",
              },
              selectedAdminData.currentAdminrole !== "subAdmin" && {
                value: "subAdmin",
                label: "SUB ADMIN",
              },
              selectedAdminData.currentAdminrole !== "reviewer" && {
                value: "reviewer",
                label: "REVIEWER",
              },
              {
                value: "comingSoon",
                label: "More Coming Soon!",
                disabled: true,
              },
            ].filter(Boolean)}
            className="auser-modal-sub-input"
            value={
              selectedAdminData.newAdminRole
                ? selectedAdminData.newAdminRole
                : "Select Admin Type"
            }
            onChange={(value) =>
              setSelectedAdminData((prev) => ({
                ...prev,
                newAdminRole: value,
              }))
            }
          />
        </div>
        <div className="auser-modal-sub">
          <div className="auser-modal-sub-profileCheck">
            <img
              src={
                selectedAdminData.profileImage ||
                `https://placehold.co/40x40?text=${selectedAdminData.fullName
                  ?.charAt(0)
                  ?.toUpperCase()}`
              }
              alt=""
              className="auser-modal-sub-profileImg"
            />
            <h4>
              {selectedAdminData.fullName
                ? selectedAdminData.fullName
                : "Username"}
            </h4>
          </div>
          <div className="auser-modal-sub-profileCheck ml">
            <h5>
              {`(User Created At: ${
                selectedAdminData?.createdAt
                  ? `${new Date(
                      selectedAdminData.createdAt
                    ).toLocaleDateString()}, ${new Date(
                      selectedAdminData.createdAt
                    ).toLocaleTimeString()}`
                  : "N/A"
              })`}
            </h5>
          </div>
        </div>
        <div className="auser-modal-sub">
          <p>From & To (Position/Role)</p>
          <div className="auser-modal-sub-rolefromto">
            <div
              className={`auser-table-data-role ${selectedAdminData.currentAdminrole}`}
            >
              <CgShapeCircle className="auser-table-data-role-icon" />{" "}
              {selectedAdminData.currentAdminrole.toUpperCase()}
            </div>
            <GoArrowRight className="auser-modal-sub-rolefromto-icon" />
            {selectedAdminData.newAdminRole ? (
              <div
                className={`auser-table-data-role ${selectedAdminData.newAdminRole}`}
              >
                <CgShapeCircle className="auser-table-data-role-icon" />{" "}
                {selectedAdminData.newAdminRole.toUpperCase()}
              </div>
            ) : (
              <LoadingOutlined className="auser-modal-sub-rolefromto-icon" />
            )}
          </div>
        </div>
        <div className="auser-modal-sub">
          <p>
            Please confirm that you are modifying the role for the selected
            user. Double-check the details to ensure accuracy and avoid any
            potential issues.
          </p>
        </div>
        <div className="auser-modal-sub">
          <p>
            Making changes to a user’s role can affect their access and
            permissions, so proceed with caution and verify all information
            before applying any updates.
          </p>
        </div>
      </Modal>
      <Modal
        title="Delete Admin from System"
        open={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        footer={[
          <Button key="back" onClick={handleDeleteCancel}>
            Cancel
          </Button>,
          <Button
            danger
            key="submit"
            type="primary"
            onClick={handleDeleteAdmin}
          >
            Delete
          </Button>,
        ]}
        centered
        className="auser-modal"
      >
        <div className="auser-modal-sub">
          <p>
            (Note: You can delete this admin user. Please double-check that you
            have selected the correct user, as this action cannot be undone.)
          </p>
        </div>
        <div className="auser-modal-sub">
          <div className="auser-modal-sub-profileCheck">
          <img
              src={
                selectedAdminData.profileImage ||
                `https://placehold.co/40x40?text=${selectedAdminData.fullName
                  ?.charAt(0)
                  ?.toUpperCase()}`
              }
              alt=""
              className="auser-modal-sub-profileImg"
            />
             <h4>
              {selectedAdminData.fullName
                ? selectedAdminData.fullName
                : "Username"}
            </h4>
          </div>
          <div className="auser-modal-sub-profileCheck ml">
          <h5>
              {`(User Created At: ${
                selectedAdminData?.createdAt
                  ? `${new Date(
                      selectedAdminData.createdAt
                    ).toLocaleDateString()}, ${new Date(
                      selectedAdminData.createdAt
                    ).toLocaleTimeString()}`
                  : "N/A"
              })`}
            </h5>
          </div>
        </div>
        <div className="auser-modal-sub">
          <p>
            (Note: Once the user is deleted, their access to the administrator
            role will be revoked and will remain so until you add them back
            again.)
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default AdminUsers;
