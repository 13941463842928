import React, { useEffect } from "react";

import "./Pricing.css";
import "../Blogs/Blogs.css";

// React Icons
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa6";

// Ant Design
import { Collapse } from "antd";

function Pricing() {
  useEffect(() => {
    document.title = "Proptzo - Pricing Details";
  }, []);

  const faqs = [
    {
      key: "1",
      label: "Why Should I Use Your Services?",
      children: (
        <p>
          Our services are designed to provide you with top-notch solutions that
          meet your needs efficiently and effectively. With a focus on quality,
          reliability, and customer satisfaction, we ensure that you receive the
          best possible service.
        </p>
      ),
    },
    {
      key: "2",
      label: "How Do I Get Started With Your Services?",
      children: (
        <p>
          Getting started with our services is easy. Simply sign up on our
          website, fill out the necessary information, and you'll be on your way
          to enjoying the benefits of our offerings. If you need any assistance,
          our support team is always here to help.
        </p>
      ),
    },
    {
      key: "3",
      label: "How Secure Are Your Services?",
      children: (
        <p>
          Security is our top priority. We use advanced encryption and security
          protocols to protect your data and ensure that your information is
          safe with us. Our team continuously monitors and updates our systems
          to guard against any potential threats.
        </p>
      ),
    },
    {
      key: "4",
      label: "Is There Customer Support Available?",
      children: (
        <p>
          Yes, we offer comprehensive customer support to assist you with any
          questions or issues you may have. Our support team is available 24/7
          through various channels, including phone, email, and live chat, to
          ensure you receive the help you need.
        </p>
      ),
    },
    {
      key: "5",
      label: "How Can I Update My Account Information?",
      children: (
        <p>
          Updating your account information is straightforward. Log in to your
          account, navigate to the account settings section, and make the
          necessary changes. If you encounter any difficulties, our support team
          is ready to assist you.
        </p>
      ),
    },
  ];

  return (
    <div className="blogs">
      <div className="blogs-sub">
        <h1>Pricing Plans</h1>
        <h5>Choose the best plan that fits your needs</h5>
      </div>
      <div className="blogs-container">
        <div className="trending-sub">
          <h5>PRICING</h5>
          <h1>Our Subscription</h1>
        </div>
        <div className="blogs-pricing-duration">
          <div className="blogs-pricing-duration-sub">
            <h5>Monthly</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <h5>Quarterly</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <h5>Semi-Annually</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <h5>Yearly</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <h5>Lifetime</h5>
          </div>
          <div className="blogs-pricing-duration-sub">
            <h5>Custom</h5>
          </div>
        </div>
        <div className="blogs-container-sub">
          {/* Owner Plans */}
          <div className="pricing">
            <div className="pricing-sub">
              <h1>₹ 0 / (30 days)</h1>
            </div>
            <div className="pricing-sub">
              <h2>Free Plan (Starter)</h2>
            </div>
            <div className="pricing-sub">
              <p>
                Perfect for beginners or those just getting started with limited
                requirements.
              </p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>List 1 property for 30 days</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>SD Image Quality (Standard Definition)</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Basic Search Visibility</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Limited Lead Management Tools</h5>
              </span>
            </div>
            <div className="pricing-sub pricing-sub-last">
              <button>Get Started</button>
            </div>
          </div>
          <div className="pricing selected">
            <div className="pricing-sub">
              <div className="pricing-popular-tag">Most Popular</div>
            </div>
            <div className="pricing-sub">
              <h1>₹ 599 / (30 days)</h1>
            </div>
            <div className="pricing-sub">
              <h2>Essentials Plan (Value Driven)</h2>
            </div>
            <div className="pricing-sub">
              <p>
                Unlock growth-driven tools at unbeatable value, ideal for
                expanding your market presence.
              </p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>List 3 properties for 30 days (fixed duration)</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Featured Listings for 5 days</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Targeted Email Promotions (250 emails)</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Advanced Lead Management & Communication Tools</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>SD Image Quality (Standard Definition)</h5>
              </span>
            </div>
            <div className="pricing-sub pricing-sub-last">
              <button>Unlock Essentials</button>
            </div>
          </div>
          <div className="pricing">
            <div className="pricing-sub">
              <h1>₹ 1,199 / (30 days)</h1>
            </div>
            <div className="pricing-sub">
              <h2>Pro Plan (Maximum Exposure)</h2>
            </div>
            <div className="pricing-sub">
              <p>
                Maximize visibility with our comprehensive premium features at a
                competitive price.
              </p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>
                  List a total of 15 projects for 30 days across all employees
                </h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Up to 5 employee profiles</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Each employee can list up to 3 projects for 30 days</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>HD Image Quality (High Definition)</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Top placement in search results for 14 days</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Comprehensive Email Marketing (1,500 emails)</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Advanced Lead Management & Analytics</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>24/7 Support</h5>
              </span>
            </div>
            <div className="pricing-sub pricing-sub-last">
              <button>Maximize Exposure</button>
            </div>
          </div>
        </div>
        <div className="blogs-container-sub">
          {/* Builder/Agent Plans */}
          <div className="pricing">
            <div className="pricing-sub">
              <h1>₹ 0 / (30 days)</h1>
            </div>
            <div className="pricing-sub">
              <h2>Free Plan (Starter)</h2>
            </div>
            <div className="pricing-sub">
              <p>
                Perfect for beginners or those just getting started with limited
                requirements.
              </p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>List 1 project</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Add 1 employee profile</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>SD Image Quality (Standard Definition)</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Basic Search Visibility</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Limited Lead Management Tools</h5>
              </span>
            </div>
            <div className="pricing-sub pricing-sub-last">
              <button>Get Started</button>
            </div>
          </div>
          <div className="pricing selected">
            <div className="pricing-sub">
              <div className="pricing-popular-tag">Most Popular</div>
            </div>
            <div className="pricing-sub">
              <h1>₹ 699 / (30 days)</h1>
            </div>
            <div className="pricing-sub">
              <h2>Essentials Plan (Value Driven)</h2>
            </div>
            <div className="pricing-sub">
              <p>
                Empower your team with essential tools to drive growth and boost
                collaboration.
              </p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>List up to 5 projects for 30 days</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Manage & collaborate with up to 5 team members</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Featured Listings for 5 days</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Targeted Email Promotions (500 emails)</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Advanced Lead Management & Communication Tools</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>SD Image Quality (Standard Definition)</h5>
              </span>
            </div>
            <div className="pricing-sub pricing-sub-last">
              <button>Unlock Essentials</button>
            </div>
          </div>
          <div className="pricing">
            <div className="pricing-sub">
              <h1>₹ 1,499 / (30 days)</h1>
            </div>
            <div className="pricing-sub">
              <h2>Pro Plan (Maximum Exposure)</h2>
            </div>
            <div className="pricing-sub">
              <p>
                Get premium features and maximum visibility to stay ahead in a
                competitive market.
              </p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>
                  List a total of 15 projects for 30 days across all employees
                </h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Up to 5 employee profiles</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>HD Image Quality (High Definition)</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Top placement in search results for 14 days</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Comprehensive Email Marketing (1,500 emails)</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Advanced Lead Management & Analytics</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>24/7 Support</h5>
              </span>
            </div>
            <div className="pricing-sub pricing-sub-last">
              <button>Maximize Exposure</button>
            </div>
          </div>
        </div>
        <div className="blogs-container-sub">
          {/* Salesperson Plans */}
          <div className="pricing">
            <div className="pricing-sub">
              <h1>₹ 0 / (30 days)</h1>
            </div>
            <div className="pricing-sub">
              <h2>Free Plan (Starter)</h2>
            </div>
            <div className="pricing-sub">
              <p>
                Ideal for first-time users or those exploring basic options with
                minimal requirements.
              </p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Create and manage a basic profile</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Showcase expertise and areas of operation</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>SD Image Quality (Standard Definition)</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Basic Lead Management Tools</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Receive inquiries from potential clients</h5>
              </span>
            </div>
            <div className="pricing-sub pricing-sub-last">
              <button>Get Started</button>
            </div>
          </div>
          <div className="pricing selected">
            <div className="pricing-sub">
              <div className="pricing-popular-tag">Most Popular</div>
            </div>
            <div className="pricing-sub">
              <h1>₹ 699 / (30 days)</h1>
            </div>
            <div className="pricing-sub">
              <h2>Essentials Plan (Build Your Network)</h2>
            </div>
            <div className="pricing-sub">
              <p>
                Equip with the right tools for scaling growth, expanding your
                network, and improving collaboration.
              </p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Enhanced profile visibility for better reach</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Showcase successful listings and client testimonials</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Priority lead notifications to stay ahead</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Access Lead Management & Communication Tools</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Basic Analytics to track performance</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>SD Image Quality (Standard Definition)</h5>
              </span>
            </div>
            <div className="pricing-sub pricing-sub-last">
              <button>Unlock Essentials</button>
            </div>
          </div>
          <div className="pricing">
            <div className="pricing-sub">
              <h1>₹ 1,499 / (30 days)</h1>
            </div>
            <div className="pricing-sub">
              <h2>Pro Plan (Maximum Exposure)</h2>
            </div>
            <div className="pricing-sub">
              <p>
                Unlock premium features and achieve unmatched visibility in a
                competitive market.
              </p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Prominently featured agent/salesperson profile</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>HD Image Quality (High Definition)</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Comprehensive Lead Management & Analytics Suite</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>In-depth Analytics for strategic decision-making</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>24/7 Support</h5>
              </span>
            </div>
            <div className="pricing-sub pricing-sub-last">
              <button>Maximize Exposure</button>
            </div>
          </div>
        </div>
      </div>
      <div className="blogs-container">
        <div className="trending-sub">
          <h5>FAQS</h5>
          <h1>Quick Answers To Questions</h1>
        </div>
        <div className="pricing-container-faqs">
          <Collapse
            items={faqs}
            defaultActiveKey={["1"]}
            expandIconPosition="end"
          />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
