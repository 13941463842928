import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./UserProfiles.css";

// Loader
import AdminLoader from "../../../components/Loader/AdminLoader";

// Ant Design Imports
import { Table, Modal, Button, Input, Select, notification } from "antd";

// React Icons
import { CgShapeCircle } from "react-icons/cg";
import { userDataAtom } from "../../../../store/atoms/userDataAtom";
import { useRecoilValue } from "recoil";

function RejectedProfiles() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;

  return (
    <div className="ausers">
      <div className="ausers-sub">
        <div className="auser-container">
          <h1>Rejected Profile Verifications</h1>
        </div>
        <div className="auser-container">
          <h4></h4>
          <div className="auser-table">
            <Table
              // loading={loading}
              className="auser-table-ant-design"
              bordered
              //   columns={columns}
              //   dataSource={data}
              showSorterTooltip={{
                target: "sorter-icon",
              }}
              pagination={{ position: ["bottomLeft"] }}
              scroll={{ x: true }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RejectedProfiles;
