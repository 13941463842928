import React from "react";
import "./LiveUsersMobile.css";

function LiveUsersMobile() {
  return (
    <div className="lum">
      <div className="lum-sub">
        <div className="lum-category">
          <h3>140K</h3>
          <h5>Registered Users</h5>
        </div>
        <div className="lum-category">
          <h3>90K</h3>
          <h5>Active Users</h5>
        </div>
        <div className="lum-category">
          <h3>250K</h3>
          <h5>Transactions Today</h5>
        </div>
        <div className="lum-category">
          <h3>25K</h3>
          <h5>Verified Profiles</h5>
        </div>
      </div>
    </div>
  );
}

export default LiveUsersMobile;
