import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import REACTGA from "react-ga";

// STYLES IMPORT
import "./App.css";

// ANT DESIGN IMPORT
import { FloatButton } from "antd";

// RECOIIL
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { fetchUserData, userDataAtom } from "./store/atoms/userDataAtom";
import { fetchLatestUserDetails } from "./store/atoms/fetchLatestUserDetails";
import { isAuthenticatedAtom } from "./store/atoms/IsAuthenticatedAtom";

// COMPONENTS IMPORT
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

// PAGES IMPORT
import Home from "./pages/Home/Home";
import SignUp from "./pages/SignUp/SignUp";
import Profile from "./pages/Profile/Profile";
import MyDashboard from "./pages/MyDashboard/MyDashboard";
import Properties from "./pages/Properties/Properties";
import ViewProperty from "./pages/ViewProperty/ViewProperty";
import WishList from "./pages/WishList/WishList";
import ViewProjects from "./pages/ViewProjects/ViewProjects";
import Projects from "./pages/Projects/Projects";
import Notifications from "./pages/Notifications/Notifications";
import InitialProfile from "./pages/SignUp/InitialProfile";
import AddProject from "./pages/PostProject/AddProject";
import AddProperty from "./pages/PostProperty/AddProperty";
import UpdateProject from "./pages/MyDashboard/UpdateProject";
import UserProfile from "./pages/Profile/UserProfile";
import UpdateProperty from "./pages/MyDashboard/UpdateProperty";
import Blogs from "./pages/Blogs/Blogs";
import Dashboard from "./pages/MyDashboard/Dashboard";
import AllProperties from "./pages/Properties/AllProperties";
import PropertyNotFound from "./pages/NotFounds/PropertyNotFound";
import Pricing from "./pages/Pricing/Pricing";
import ViewLatestBlogs from "./pages/ViewLatestNews/ViewLatestBlogs";
import ViewAgentProfile from "./pages/ViewAgentProfile/ViewAgentProfile";

// LAYOUT EFFECTORS
import ScrollToTop from "./ScrollToTop";

// PROTECTED ROUTE IMPORTS
import ProfileGaurd from "./ProtectedRoutes/ProfileGaurd";
import PostPropertyGaurd from "./ProtectedRoutes/PostPropertyGaurd";
import PostProjectGaurd from "./ProtectedRoutes/PostProjectGaurd";
import UserTypeRedirectGaurd from "./ProtectedRoutes/UserTypeRedirectGaurd";
import AdminRoute from "./ProtectedRoutesAdmin/adminRoute";

// MOBILE COMPONENTS
import HeaderMobile from "./mobileUi/Components/HeaderMobile/HeaderMobile";
import FooterMobile from "./mobileUi/Components/FooterMobile/FooterMobile";

// MOBILE PAGES
import HomeMobile from "./mobileUi/Pages/HomeMobile/HomeMobile";

// ADMIN PANEL DASHBOARD IMPORTS
import AdminDashboard from "./admin/pages/Dashboard/AdminDashboard";
import AdminHeader from "./admin/components/Header/AdminHeader";
import AdminSidebar from "./admin/components/Sidebar/AdminSidebar";
import AdminFeedbacks from "./admin/pages/Feedbacks/AdminFeedbacks";
import AdminProjects from "./admin/pages/Projects/AdminProjects";
import AdminProperties from "./admin/pages/Properties/AdminProperties";
import AdminSettings from "./admin/pages/Settings/AdminSettings";
import AdminUsers from "./admin/pages/UserManagement/AdminUsers";
import AdminProfiles from "./admin/pages/Profiles/AdminProfiles";
import Password from "./pages/Password/Password";
import AdminCs from "./admin/pages/CustomerCare/AdminCs";
import AdminBlogs from "./admin/pages/Blogs/AdminBlogs";
import AdminAddBlog from "./admin/pages/PostBlog/AdminAddBlog";
import TextEditor from "./utils/textEditor/TextEditor";
import PendingProfiles from "./admin/pages/UserManagement/UserProfiles/PendingProfiles";
import AssignedProfiles from "./admin/pages/UserManagement/UserProfiles/AssignedProfiles";
import ReviewProfiles from "./admin/pages/UserManagement/UserProfiles/ReviewProfiles";
import ListingsMobile from "./mobileUi/Pages/ListingsMobile/ListingsMobile";
import BlogAdd from "./admin/pages/ContentManagement/Blogs/BlogAdd";
import RejectedProfiles from "./admin/pages/UserManagement/UserProfiles/RejectedProfiles";
import ProfileNotFound from "./pages/NotFounds/ProfileNotFound";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const setUserData = useSetRecoilState(userDataAtom);
  const [fetchLatestUserData, setFetchLatestUserData] = useRecoilState(
    fetchLatestUserDetails
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const initialFetchData = async () => {
      try {
        const res = await fetchUserData();
        setUserData({
          user: res.user,
          userProfile: res.userProfile,
          isLoading: false,
          isError: false,
        });
      } catch (err) {
        console.error("Error fetching user data:", err);
      }
    };

    const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
    if (typeof window !== "undefined") {
      REACTGA.initialize(TRACKING_ID);
      REACTGA.pageview(window.location.pathname + window.location.search);
    }
    initialFetchData();
    return () => setFetchLatestUserData(false);
  }, [fetchLatestUserData, setFetchLatestUserData, setUserData]);

  const isAuthenticatedValue = useRecoilValue(isAuthenticatedAtom);
  const isAuthenticated =
    isAuthenticatedValue === true
      ? true
      : sessionStorage.getItem("authenticated") === "true"
      ? true
      : false;

  // Admin Panel Sidebar
  const [isSidebarToggled, setIsSidebarToggled] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarToggled(!isSidebarToggled);
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route
            path="/password"
            element={!isAuthenticated ? <Password /> : <Navigate to="/" />}
          />
          {isAuthenticated ? (
            <>
              {/* Unprotected Routes */}
              <Route
                path="/"
                element={
                  <>
                    {isMobile ? (
                      <>
                        <HeaderMobile />
                        <HomeMobile />
                        <FooterMobile />
                      </>
                    ) : (
                      <>
                        <Header />
                        <Home />
                        <Footer />
                      </>
                    )}
                  </>
                }
              />
              <Route
                path="/compare"
                element={
                  <>
                    {isMobile ? (
                      <>
                        <HeaderMobile />
                        <FooterMobile />
                      </>
                    ) : (
                      <>
                        <Header />
                        <Footer />
                      </>
                    )}
                  </>
                }
              />
              <Route
                path="/latestnews"
                element={
                  <>
                    {isMobile ? (
                      <>
                        <HeaderMobile />
                        <Blogs />
                        <FooterMobile />
                      </>
                    ) : (
                      <>
                        <Header />
                        <Blogs />
                        <Footer />
                      </>
                    )}
                  </>
                }
              />
              <Route
                path="/texteditor"
                element={
                  <>
                    {isMobile ? (
                      <>
                        <HeaderMobile />
                        <TextEditor />
                        <FooterMobile />
                      </>
                    ) : (
                      <>
                        <Header />
                        <TextEditor
                          placeholder={"Start typing..."}
                          width="50vw"
                          role="blog Title"
                        />
                        <Footer />
                      </>
                    )}
                  </>
                }
              />
              <Route
                path="/viewlatestnews"
                element={
                  <>
                    {isMobile ? (
                      <>
                        <HeaderMobile />
                        <ViewLatestBlogs />
                        <FooterMobile />
                      </>
                    ) : (
                      <>
                        <Header />
                        <ViewLatestBlogs />
                        <Footer />
                      </>
                    )}
                  </>
                }
              />
              <Route
                path="/viewagentprofile/:profileId"
                element={
                  <>
                    {isMobile ? (
                      <>
                        <HeaderMobile />
                        <ViewAgentProfile />
                        <FooterMobile />
                      </>
                    ) : (
                      <>
                        <Header />
                        <ViewAgentProfile />
                        <Footer />
                      </>
                    )}
                  </>
                }
              />
              <Route
                path="/pricing"
                element={
                  <>
                    {isMobile ? (
                      <>
                        <HeaderMobile />
                        <Pricing />
                        <FooterMobile />
                      </>
                    ) : (
                      <>
                        <Header />
                        <Pricing />
                        <Footer />
                      </>
                    )}
                  </>
                }
              />
              <Route
                path="/properties"
                element={
                  <>
                    {isMobile ? (
                      <>
                        <HeaderMobile />
                        <ListingsMobile />
                        <FooterMobile />
                      </>
                    ) : (
                      <>
                        <Header />
                        <AllProperties />
                        <Footer />
                      </>
                    )}
                  </>
                }
              />
              <Route path="/signup" element={<SignUp />} />
              <Route
                path="/dashboardpage"
                element={
                  <>
                    {isMobile ? (
                      <>
                        <HeaderMobile />
                        <Dashboard />
                      </>
                    ) : (
                      <>
                        <Header />
                        <Dashboard />
                      </>
                    )}
                  </>
                }
              />
              {/* Protected Routes */}
              <Route element={<UserTypeRedirectGaurd />}>
                <Route element={<ProfileGaurd />}>
                  <Route
                    path="/usertyperedirect"
                    element={<InitialProfile />}
                  />
                  <Route path="/profile" element={<UserProfile />} />
                </Route>
                <Route
                  path="/viewproperty/:postId"
                  element={
                    <>
                      {isMobile ? (
                        <>
                          <HeaderMobile />
                          <ViewProperty />
                          <FooterMobile />
                        </>
                      ) : (
                        <>
                          <Header />
                          <ViewProperty />
                          <Footer />
                        </>
                      )}
                    </>
                  }
                />
                <Route
                  path="/viewprojects/:projectId"
                  element={
                    <>
                      {isMobile ? (
                        <>
                          <HeaderMobile />
                          <ViewProjects />
                          <FooterMobile />
                        </>
                      ) : (
                        <>
                          <Header />
                          <ViewProjects />
                          <Footer />
                        </>
                      )}
                    </>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <>
                      {isMobile ? (
                        <>
                          <HeaderMobile />
                          {/* <MyDashboard /> */}
                          <Dashboard />
                          {/* <FooterMobile /> */}
                        </>
                      ) : (
                        <>
                          <Header />
                          {/* <MyDashboard /> */}
                          <Dashboard />
                          {/* <Footer /> */}
                        </>
                      )}
                    </>
                  }
                />
                <Route
                  path="/postupdate/:dashboardPropertyId"
                  element={<UpdateProperty />}
                />
                <Route
                  path="/projectupdate/:dashboardProjectId"
                  element={<UpdateProject />}
                />
                <Route element={<PostPropertyGaurd />}>
                  <Route path="/postproperty" element={<AddProperty />} />
                </Route>
                <Route element={<PostProjectGaurd />}>
                  <Route path="/postproject" element={<AddProject />} />
                </Route>
                <Route
                  path="/wishlist"
                  element={
                    <>
                      {isMobile ? (
                        <>
                          <HeaderMobile />
                          <WishList />
                          <FooterMobile />
                        </>
                      ) : (
                        <>
                          <Header />
                          <WishList />
                          <Footer />
                        </>
                      )}
                    </>
                  }
                />
                <Route
                  path="/notifications"
                  element={
                    <>
                      {isMobile ? (
                        <>
                          <HeaderMobile />
                          <Notifications />
                          <FooterMobile />
                        </>
                      ) : (
                        <>
                          <Header />
                          <Notifications />
                          <Footer />
                        </>
                      )}
                    </>
                  }
                />
              </Route>
            </>
          ) : (
            <Route path="*" element={<Navigate to="/password" />} />
          )}

          {/* Routes for admin panel dashboard */}
          <Route element={<AdminRoute />}>
            <Route
              path="/admin"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <AdminDashboard />
                </>
              }
            />
            <Route
              path="admin/user-management/admin-users"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <AdminUsers />
                </>
              }
            />
            <Route
              path="admin/user-management/user-profiles/pending-profiles"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <PendingProfiles />
                </>
              }
            />
            <Route
              path="admin/user-management/user-profiles/assigned-profiles"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <AssignedProfiles />
                </>
              }
            />
            <Route
              path="admin/user-management/user-profiles/assigned-profiles/:profileId"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <ReviewProfiles />
                </>
              }
            />
            <Route
              path="admin/user-management/user-profiles/rejected-profiles"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <RejectedProfiles />
                </>
              }
            />
            <Route
              path="/admin/feedbacks"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <AdminFeedbacks />
                </>
              }
            />
            <Route
              path="/admin/projects"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <AdminProjects />
                </>
              }
            />
            <Route
              path="/admin/properties"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <AdminProperties />
                </>
              }
            />
            <Route
              path="/admin/profiles"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <AdminProfiles />
                </>
              }
            />
            <Route
              path="/admin/settings"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <AdminSettings />
                </>
              }
            />
            <Route
              path="/admin/customer-care"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <AdminCs />
                </>
              }
            />
            <Route
              path="/admin/blogs"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <AdminBlogs />
                </>
              }
            />
            <Route
              path="admin/content-management/blogs/add-blog"
              element={
                <>
                  <AdminHeader onTasklistClick={toggleSidebar} />
                  <AdminSidebar
                    isToggled={isSidebarToggled}
                    toggleSidebar={toggleSidebar}
                  />
                  <BlogAdd />
                </>
              }
            />
          </Route>

          {/* Redirect to homepage for invalid routes */}
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/property-not-found" element={<PropertyNotFound />} />
          <Route path="/profile-not-found" element={<ProfileNotFound />} />
        </Routes>
      </div>
      <FloatButton.BackTop
        duration={600}
        target={() => window}
        visibilityHeight={800}
        style={{ bottom: "100px" }}
      />
    </Router>
  );
}

export default App;
