import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import "../UserManagement.css";

// Loader
import AdminLoader from "../../../components/Loader/AdminLoader";

// Ant Design Imports
import {
  Table,
  Modal,
  Button,
  Input,
  Select,
  notification,
  message,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// React Icons
import { CgShapeCircle } from "react-icons/cg";
import { GoArrowRight } from "react-icons/go";

import { userDataAtom } from "../../../../store/atoms/userDataAtom";
import { useRecoilValue } from "recoil";

function PendingProfiles() {
  const [loading, setLoading] = useState(true);
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedAdminData, setSelectedAdminData] = useState({
    adminId: "",
    fullName: "",
    profileImage: "",
    location: "",
    currentAdminrole: "",
    createdAt: "",
    updatedAt: "",
  });

  const [assignedProfileId, setAssignedProfileId] = useState(null);

  const showModal = (selectedProfileId) => {
    setAssignedProfileId(selectedProfileId.id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setSelectedAdminData(() => ({
      adminId: "",
      fullName: "",
      profileImage: "",
      location: "",
      currentAdminrole: "",
      createdAt: "",
      updatedAt: "",
    }));
    setAssignedProfileId(null);
    setIsModalOpen(false);
  };

  const formatMobileNumber = (mobile) => {
    if (typeof mobile === "string" && mobile.length >= 12) {
      const countryCode = mobile.slice(0, 2);
      const number = mobile.slice(2);
      return `+${countryCode} ${number}`;
    }
    return mobile;
  };

  const [data, setData] = useState([]);
  const [isProfileTaskNotAssigned, setIsProfileTaskNotAssigned] =
    useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/api/admin/getUnVerifiedProfiles`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (
        !response.data ||
        (response.data.pendingProfileVerifications &&
          !Array.isArray(response.data.pendingProfileVerifications))
      ) {
        throw new Error("Unexpected data format");
      }
      const formattedData =
        response.data.pendingProfileVerifications &&
        response.data.pendingProfileVerifications.map((user, index) => {
          return {
            key: index.toString(),
            userType: `${user.userType?.toUpperCase()}`,
            name: <h5 className="auser-table-data">{user.fullName}</h5>,
            photo: (
              <img
                src={
                  user.profilePhoto ||
                  `https://placehold.co/40x40?text=${user.fullName
                    ?.charAt(0)
                    ?.toUpperCase()}`
                }
                alt=""
                className="auser-table-data-profileImg"
              />
            ),
            email: <h5 className="auser-table-data">{user.email || "N/A"}</h5>,
            mobile: (
              <h5 className="auser-table-data">
                {formatMobileNumber(user.mobileNumber) || "N/A"}
              </h5>
            ),
            status: (
              <div className={`auser-table-data-role reviewer`}>
                <CgShapeCircle className="auser-table-data-role-icon" /> PENDING
              </div>
            ),
            exists: "true",
            updatedAt: (
              <h5 className="auser-table-data">
                {new Date(user.updatedAt).toLocaleString()}
              </h5>
            ),
            createdAt: (
              <h5 className="auser-table-data">
                {new Date(user.createdAt).toLocaleString()}
              </h5>
            ),
            action: (
              <div className="auser-table-data-action">
                <span onClick={() => showModal(user)}>Assign Task</span>
              </div>
            ),
          };
        });
      setData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      notification.error({
        message: "Error",
        description: "Failed to load user data",
      });
    } finally {
      setLoading(false);
    }
  }, [baseURL]);

  useEffect(() => {
    if (isProfileTaskNotAssigned) {
      fetchData();
    }
    return () => setIsProfileTaskNotAssigned(false);
  }, [fetchData, isProfileTaskNotAssigned]);

  const [adminList, setAdminList] = useState(null);

  const getAllAdmins = useCallback(async () => {
    try {
      const res = await axios.get(`${baseURL}/api/admin/getAllAdminUsers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (
        res.data.message === "Admins found successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        return res.data?.adminList;
      }
    } catch (error) {
      console.error("Error fetching all admins:", error);
    }
  }, [baseURL]);

  useMemo(() => {
    const getAdminList = async () => {
      const adminList = await getAllAdmins();
      if (adminList?.length > 0) {
        setAdminList(adminList);
      }
    };

    getAdminList();
  }, [getAllAdmins]);

  const handleAssignProfileTask = async () => {
    if (assignedProfileId === "") {
      return message.error("Please select a profile to assign");
    }

    if (selectedAdminData.adminId === "") {
      return message.error("Please select an admin to assign this task");
    }

    try {
      const formData = new FormData();

      formData.append("assignedBy", userId);
      formData.append("assignedTo", selectedAdminData.adminId);
      formData.append("unverifiedProfileId", assignedProfileId);
      const res = await axios.post(
        `${baseURL}/api/admin/assignTask`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        res.data.message === "Task assigned successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        message.success(res.data.message);
        setIsProfileTaskNotAssigned(true);
      }
    } catch (error) {
      setIsProfileTaskNotAssigned(false);
      message.error(error.response.data.message);
      console.error("Error assigning profile task:", error);
    } finally {
      setIsModalOpen(false);
      setSelectedAdminData((prev) => ({
        ...prev,
        adminId: "",
        fullName: "",
        profileImage: "",
        location: "",
        currentAdminrole: "",
        createdAt: "",
        newAdminRole: "",
      }));
    }
  };

  const columns = [
    {
      title: "PHOTO",
      dataIndex: "photo",
      align: "center",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "NAME",
      dataIndex: "name",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "MOBILE NO.",
      dataIndex: "mobile",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "USER TYPE",
      dataIndex: "userType",
      responsive: ["xs", "sm"],
      ellipsis: true,
      filters: [
        {
          text: "BUYER",
          value: "BUYER",
        },
        {
          text: "OWNER",
          value: "OWNER",
        },
        {
          text: "BUILDER",
          value: "BUILDER",
        },
        {
          text: "AGENT",
          value: "AGENT",
        },
        {
          text: "SALESPERSON",
          value: "SALESPERSON",
        },
      ],
      onFilter: (value, record) => record.userType.indexOf(value) === 0,
      render: (userType) => (
        <div className={`auser-table-data-role userType`}>
          <CgShapeCircle className="auser-table-data-role-icon" /> {userType}
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "EXISTS",
      dataIndex: "exists",
      responsive: ["xs", "sm"],
      ellipsis: true,
      filters: [
        {
          text: "TRUE",
          value: "true",
        },
        {
          text: "FALSE",
          value: "false",
        },
      ],
      onFilter: (value, record) => record.exists.indexOf(value) === 0,
      render: (exists) => {
        let existsClass = "";
        switch (exists) {
          case "true":
            existsClass = "superAdmin";
            break;
          case "false":
            existsClass = "danger";
            break;
          default:
            existsClass = "default";
        }
        const displayText = exists ? exists.toUpperCase() : "UNKNOWN";
        return (
          <div className={`auser-table-data-role ${existsClass}`}>
            <CgShapeCircle className="auser-table-data-role-icon" />{" "}
            {displayText}
          </div>
        );
      },
    },
    {
      title: "LAST LOGIN AT",
      dataIndex: "updatedAt",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
    {
      title: "ACTION",
      dataIndex: "action",
      responsive: ["xs", "sm"],
      ellipsis: true,
    },
  ];

  return (
    <div className="ausers">
      {loading && <AdminLoader />}
      <div className="ausers-sub">
        <div className="auser-container">
          <h1>Pending Profile Verifications</h1>
        </div>
        <div className="auser-container">
          <h4>
            <span>{data && data?.length > 0 ? data?.length : "~"}</span> Pending
            Profiles
          </h4>
          <div className="auser-table">
            <Table
              loading={loading}
              className="auser-table-ant-design"
              bordered
              columns={columns}
              dataSource={data}
              showSorterTooltip={{
                target: "sorter-icon",
              }}
              pagination={{ position: ["bottomLeft"] }}
              scroll={{ x: true }}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Assign this profile verification"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleAssignProfileTask}>
            Assign
          </Button>,
        ]}
        centered
        className="auser-modal"
      >
        <div className="auser-modal-sub">
          <p>
            (Note: You can assign to any admin (Sub Admin or Reviewer), but only
            assign one admin at a time.)
          </p>
        </div>
        <div className="auser-modal-sub">
          <label>Select an admin to assign a task</label>
          <Select
            className="auser-modal-sub-input"
            options={[
              {
                label: <span>Super Admin</span>,
                title: "Super Admin",
                options: adminList
                  ?.filter(
                    (admin) => admin.roles && admin.roles[0] === "superAdmin"
                  )
                  .map((admin) => ({
                    label: admin.fullName,
                    value: admin.id,
                  })),
              },
              {
                label: <span>Sub Admin</span>,
                title: "Sub Admin",
                options: adminList
                  ?.filter(
                    (admin) => admin.roles && admin.roles[0] === "subAdmin"
                  )
                  .map((admin) => ({
                    label: admin.fullName,
                    value: admin.id,
                  })),
              },
              {
                label: <span>Reviewer</span>,
                title: "Reviewer",
                options: adminList
                  ?.filter(
                    (admin) => admin.roles && admin.roles[0] === "reviewer"
                  )
                  .map((admin) => ({
                    label: admin.fullName,
                    value: admin.id,
                  })),
              },
            ]}
            value={
              selectedAdminData.adminId
                ? selectedAdminData.adminId
                : "Select Admin Type"
            }
            onChange={(value) => {
              const selectedAdmin = adminList?.find(
                (admin) => admin.id === value
              );

              setSelectedAdminData((prev) => ({
                ...prev,
                adminId: value,
                fullName: selectedAdmin?.fullName,
                profileImage: selectedAdmin?.profileImage,
                location: selectedAdmin?.currentLocation,
                currentAdminrole: selectedAdmin?.roles[0],
                createdAt: selectedAdmin?.createdAt,
                updatedAt: selectedAdmin?.updatedAt,
              }));
            }}
          />
        </div>
        {selectedAdminData.adminId !== "" && (
          <>
            <div className="auser-modal-sub">
              <div className="auser-modal-sub-profileCheck">
                <img
                  src={
                    selectedAdminData?.profileImage ||
                    `https://placehold.co/40x40?text=${selectedAdminData?.fullName
                      ?.charAt(0)
                      ?.toUpperCase()}`
                  }
                  alt=""
                  className="auser-modal-sub-profileImg"
                />
                <h4>{selectedAdminData.fullName}</h4>
              </div>
              <div className="auser-modal-sub-profileCheck ml">
                <h5>
                  {new Date(selectedAdminData?.updatedAt).toLocaleString()}
                </h5>
              </div>
            </div>

            <div className="auser-modal-sub">
              <p>Selected Admin (Position/Role)</p>
              <div className="auser-modal-sub-rolefromto">
                <div
                  className={`auser-table-data-role ${selectedAdminData.currentAdminrole}`}
                >
                  <CgShapeCircle className="auser-table-data-role-icon" />{" "}
                  {selectedAdminData.currentAdminrole}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default PendingProfiles;
