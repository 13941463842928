import z from "zod";

export const updateProfileSchema = z.object({
  email: z.string().email().min(1).optional(),
  mobile: z.string().min(12, 'Provide mobile number in (+91 1234567890) format').max(12).optional(),
  whatsappMobile: z.string().min(12, 'Provide whatsapp number in (+91 1234567890) format').max(12).optional(),
  officeWebsite : z.string().url('Please provide correct website url').min(1).optional()
});

export const updateSalespersonSchema = z.object({
  employeeID: z.string().min(4, "Please provide your employee id number").optional(),
  employeeAadhaarcard: z.string().min(4, "Please provide last 4 digits of your aadhaar number").optional(),
  employeePancard: z.string().min(10, "Please provide your pancard number").optional(),
  employeeVotercard: z.string().min(10, "Please provide your voter card (EPIC) number").optional(),
  employeePassport: z.string().min(8, "Please provide your passport number").optional(),
  employeeDrivinglicense: z.string().min(16, "Please provide your driving license number").optional(),
})