import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// STYLING IMPORT
import "./SignUp.css";

// ASSETS IMPORT
import logo from "../../assets/svgs/main-logo.svg";

// REACT ICONS
import { BsBuildings } from "react-icons/bs";
import { RiBuilding3Line } from "react-icons/ri";
import { FaPersonArrowDownToLine } from "react-icons/fa6";
import { useSetRecoilState } from "recoil";
import { fetchLatestUserDetails } from "../../store/atoms/fetchLatestUserDetails";

function SignUp() {
  const baseURL = process.env.REACT_APP_base_URL;
  const userId = localStorage.getItem("userId");
  const setFetchLatestUserData = useSetRecoilState(fetchLatestUserDetails);

  const navigate = useNavigate();

  const [userProfileData, setUserProfileData] = useState(null);
  const [apiRequestCompleted, setApiRequestCompleted] = useState(false);

  useEffect(() => {
    document.title = "Proptzo - Sign Up / Log In";
  }, []);

  useEffect(() => {
    const initializeOtpless = () => {
      // Initialize otpless component
      window.otpless = (otplessUser) => {
        // Call the function to store user data in MongoDB and fetch access token
        storeUserData(otplessUser);
        console.log(otplessUser);
      };
    };

    // Load the script dynamically
    const script = document.createElement("script");
    script.src = "https://otpless.com/v2/auth.js";
    script.type = "text/javascript";
    script.id = "otpless-sdk";
    script.async = true;
    script.onload = initializeOtpless;

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup function
    return () => {
      // Remove the otpless event handler
      delete window.otpless;
      // Remove the script tag when the component unmounts
      document.body.removeChild(script);
    };
  }, []); // Add userId to the dependency array

  const storeUserData = async (otplessUser) => {
    try {
      // Send a request to your backend to store user data in MongoDB
      const response = await axios.post(
        `${baseURL}/api/auth/storeUserData`,
        otplessUser
      );

      // Retrieve the user's ID from the response
      const userId = await response.data.user._id;
      // Store the userId in local storage
      localStorage.setItem("userId", userId);

      // Fetch access token related to the user's ID
      const tokenResponse = await axios.get(
        `${baseURL}/api/auth/getAccessToken/${userId}`
      );

      const token = tokenResponse.data.access_token;

      // Check if token is null or undefined
      if (!token) {
        console.error("Access token is null or undefined");
        return;
      }

      // Store the token in local storage
      localStorage.setItem("access_token", token);
      localStorage.setItem("userData", JSON.stringify(otplessUser));

      // Navigate to the '/' path

      try {
        const _userProfileData = await axios.get(
          `${baseURL}/api/profile/getUserDetailsById/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        setUserProfileData(_userProfileData?.data?.profileData);
        localStorage.setItem(
          "userType",
          _userProfileData.data.profileData.userType
        );
        setApiRequestCompleted(true);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setApiRequestCompleted(true);
      }
    } catch (error) {
      console.error("Error storing user data:", error);
    }
  };

  useEffect(() => {
    // If userProfileData exists and has userDetails, navigate to the home page
    if (apiRequestCompleted) {
      if (userProfileData) {
        console.log("redirecting to home page");
        navigate("/");
        setFetchLatestUserData(true);
      } else {
        // If userProfileData doesn't exist or doesn't have userDetails, navigate to the userredirect page
        console.log("redirecting to userredirect page");
        navigate("/usertyperedirect");
        setFetchLatestUserData(true);
      }
    }
  }, [userProfileData, navigate, apiRequestCompleted]); // Run this effect whenever userProfileData changes

  return (
    <div className="signup">
      <div className="signup-sub">
        <div className="signup-one">
          <div className="signup-one-sub">
            <div className="signup-one-container">
              <img src={logo} alt="proptzo" onClick={() => navigate("/")} />
            </div>
            <div className="signup-one-container">
              <h1>
                Join Proptzo: Effortless Sign-Up for Your Real Estate Journey
              </h1>
            </div>
            <div className="signup-one-container"></div>
            <div className="signup-one-container">
              <div className="signup-feature">
                <h5>Secure and Fast Sign-Up</h5>
              </div>
              <div className="signup-feature">
                <h5>Personalized Property Recommendations</h5>
              </div>
              <div className="signup-feature">
                <h5>User-Friendly Interface</h5>
              </div>
              <div className="signup-feature">
                <h5>Favorite Listings</h5>
              </div>
              <div className="signup-feature">
                <h5>Interactive Maps</h5>
              </div>
              <div className="signup-feature">
                <h5>24/7 Customer Support</h5>
              </div>
            </div>
          </div>
          <div className="signup-one-sub">
            <div id="otpless-login-page"></div>
            <script
              id="otpless-sdk"
              type="text/javascript"
              src="https://otpless.com/v2/auth.js"
              data-appid="IUZ3B7DF8ACEQTKMLUKF"
            ></script>
          </div>
        </div>
      </div>
      <div className="signup-sub-container">
        <div className="signup-sub-container-sub">
          <h1>How to post to get the most?</h1>
          <p>
            Selling/renting property is no more a challenge with Square Yards.
            Renting / Selling a house has become easy with the internet and
            easier with us! We will handle the process of selling your property
            from start to finish. Follow these steps to begin your home selling
            journey.
          </p>
        </div>
        <div className="signup-sub-container-flex">
          <div className="signup-sub-container-flex-sub">
            <BsBuildings className="signup-sub-container-flex-sub-icon" />
            <h5>Show off your property</h5>
            <p>
              Sign up or log in to Square Yards and create a free property ad
              post with good pictures, detailed video, and other important
              information like the type, price, size, location, etc.
            </p>
          </div>
          <div className="signup-sub-container-flex-sub">
            <RiBuilding3Line className="signup-sub-container-flex-sub-icon" />
            <h5>Property Proposal</h5>
            <p>
              To attract buyers or renters, you have to put your best info
              forward. Jot down all the advantages and benefits of your property
              that the renter or buyer may want to avail.
            </p>
          </div>
          <div className="signup-sub-container-flex-sub">
            <FaPersonArrowDownToLine className="signup-sub-container-flex-sub-icon" />
            <h5>Needful Negotiations</h5>
            <p>
              No one can sell your property better than you. Price is the most
              important factor. We suggest you inspect, negotiate, and seal the
              deal. Do Not Under-Price!
            </p>
          </div>
        </div>
      </div>
      <div className="signup-sub-gray-bg"></div>
    </div>
  );
}

export default SignUp;
