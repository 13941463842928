import React from "react";
import "./AdminCs.css";

function AdminCs() {
  return (
    <div className="adashboard">
      <div className="adashboard-sub">
        <div className="adashboard-header">
          <h2>Customer Care</h2>
        </div>
        <div className="ausers-add">
          <div className="ausers-add-sub">
            <h5>Coming Soon, Check Back later for more updates!</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminCs;
