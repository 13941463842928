import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./AdminSidebar.css";

// Loader
import AdminLoader from "../Loader/AdminLoader";

// React Icons
import {
  RiDashboardLine,
  RiCustomerServiceLine,
  RiFeedbackLine,
  RiSettings4Line,
  RiFileListLine,
  RiBarChartBoxLine,
  RiTeamLine,
  RiMailLine,
  RiBuildingLine,
} from "react-icons/ri";
import { LiaUsersCogSolid } from "react-icons/lia";
import { TbUserSquareRounded } from "react-icons/tb";
import { BsHouse } from "react-icons/bs";
import { GoProject, GoChecklist } from "react-icons/go";
import { RxReset } from "react-icons/rx";
import { MdOutlineCircle } from "react-icons/md";

// Ant Design Imports
import { Menu } from "antd";

const menuItems = [
  {
    key: "goToProptzo",
    icon: <RxReset className="asidebar-ant-design-icon" />,
    label: "Go to Proptzo",
  },
  {
    type: "divider",
  },
  {
    type: "divider",
  },
  {
    type: "divider",
  },
  {
    type: "divider",
  },
  {
    type: "divider",
  },
  {
    key: "dashboard",
    icon: <RiDashboardLine className="asidebar-ant-design-icon" />,
    label: "Dashboard",
  },
  {
    key: "userManagement",
    label: "User Management",
    type: "group",
    children: [
      {
        key: "adminUsers",
        icon: <LiaUsersCogSolid className="asidebar-ant-design-icon" />,
        label: "Admin Users",
      },
      {
        key: "agents",
        icon: <RiTeamLine className="asidebar-ant-design-icon" />,
        label: "Agents",
      },
      {
        key: "builders",
        icon: <RiBuildingLine className="asidebar-ant-design-icon" />,
        label: "Builders",
      },
      {
        key: "userProfiles",
        icon: <TbUserSquareRounded className="asidebar-ant-design-icon" />,
        label: "User Profiles",
        children: [
          {
            key: "allProfiles",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "All Profiles",
          },
          {
            key: "pendingProfiles",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "Pending Profiles",
          },
          {
            key: "assignedProfiles",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "Assigned Profiles",
          },
          {
            key: "rejectedProfiles",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "Rejected Profiles",
          },
        ],
      },
    ],
  },
  {
    key: "contentManagement",
    label: "Content Management",
    type: "group",
    children: [
      {
        key: "projects",
        icon: <GoProject className="asidebar-ant-design-icon" />,
        label: "Projects",
        children: [
          {
            key: "allProjects",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "All Projects",
          },
          {
            key: "pendingProjects",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "Pending Projects",
          },
          {
            key: "rejectedProjects",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "Rejected Projects",
          },
        ],
      },
      {
        key: "properties",
        icon: <BsHouse className="asidebar-ant-design-icon" />,
        label: "Properties",
        children: [
          {
            key: "allProperties",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "All Properties",
          },
          {
            key: "pendingProperties",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "Pending Properties",
          },
          {
            key: "rejectedProperties",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "Rejected Properties",
          },
          // {
          //   key: "featuredProperties",
          //   label: "Featured Properties",
          // },
        ],
      },
      {
        key: "blogs",
        icon: <GoChecklist className="asidebar-ant-design-icon" />,
        label: "Blogs",
        children: [
          {
            key: "blogOverview",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "Overview",
          },
          {
            key: "blogPosts",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "Posts",
          },
          {
            key: "blogComments",
            icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
            label: "Comments",
          },
        ],
      },
    ],
  },
  {
    key: "clientInquiries",
    label: "Client Inquiries",
    icon: <RiMailLine className="asidebar-ant-design-icon" />,
    children: [
      {
        key: "allInquiries",
        icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
        label: "All Inquiries",
      },
      {
        key: "newInquiries",
        icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
        label: "New Inquiries",
      },
      {
        key: "resolvedInquiries",
        icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
        label: "Resolved Inquiries",
      },
    ],
  },
  {
    key: "analytics",
    label: "Analytics",
    icon: <RiBarChartBoxLine className="asidebar-ant-design-icon" />,
    children: [
      {
        key: "kpiOverview",
        icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
        label: "KPI Overview",
      },
      {
        key: "marketTrends",
        icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
        label: "Market Trends",
      },
      {
        key: "reporting",
        icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
        label: "Reporting",
      },
    ],
  },
  {
    key: "integrations",
    label: "Integrations",
    icon: <RiFileListLine className="asidebar-ant-design-icon" />,
    children: [
      {
        key: "mlsIntegration",
        icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
        label: "MLS Integration",
      },
      {
        key: "crmIntegration",
        icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
        label: "CRM Integration",
      },
      {
        key: "marketingIntegration",
        icon: <MdOutlineCircle className="asidebar-ant-design-icon-sm" />,
        label: "Marketing Tools",
      },
    ],
  },
  {
    key: "settings",
    label: "Settings",
    type: "group",
    children: [
      {
        key: "accountSettings",
        icon: <RiSettings4Line className="asidebar-ant-design-icon" />,
        label: "Account Settings",
      },
      {
        key: "customerSupport",
        icon: <RiCustomerServiceLine className="asidebar-ant-design-icon" />,
        label: "Customer Support",
      },
      {
        key: "feedback",
        icon: <RiFeedbackLine className="asidebar-ant-design-icon" />,
        label: "Feedback",
      },
    ],
  },
];

const AdminSidebar = ({ isToggled, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const handleMenuClick = (e) => {
    setLoading(true);
    switch (e.key) {
      case "goToProptzo":
        navigate("/");
        break;
      case "dashboard":
        navigate("/admin");
        break;
      case "adminUsers":
        navigate("/admin/user-management/admin-users");
        break;
      case "agents":
        navigate("/admin/user-management/agents");
        break;
      case "builders":
        navigate("/admin/user-management/builders");
        break;
      case "allProfiles":
        navigate("/admin/user-management/user-profiles/all-profiles");
        break;
      case "pendingProfiles":
        navigate("/admin/user-management/user-profiles/pending-profiles");
        break;
      case "assignedProfiles":
        navigate("/admin/user-management/user-profiles/assigned-profiles");
        break;
      case "rejectedProfiles":
        navigate("/admin/user-management/user-profiles/rejected-profiles");
        break;
      case "allProjects":
        navigate("/admin/content-management/projects/all-projects");
        break;
      case "pendingProjects":
        navigate("/admin/content-management/projects/pending-projects");
        break;
      case "rejectedProjects":
        navigate("/admin/content-management/projects/rejected-projects");
        break;
      case "allProperties":
        navigate("/admin/content-management/properties/all-properties");
        break;
      case "pendingProperties":
        navigate("/admin/content-management/properties/pending-properties");
        break;
      case "rejectedProperties":
        navigate("/admin/content-management/properties/rejected-properties");
        break;
      case "blogOverview":
        navigate("/admin/content-management/blogs/overview");
        break;
      case "blogPosts":
        navigate("/admin/content-management/blogs/posts");
        break;
      case "blogComments":
        navigate("/admin/content-management/blogs/comments");
        break;
      case "allInquiries":
        navigate("/admin/client-inquiries/all-inquiries");
        break;
      case "newInquiries":
        navigate("/admin/client-inquiries/new-inquiries");
        break;
      case "resolvedInquiries":
        navigate("/admin/client-inquiries/resolved-inquiries");
        break;
      case "kpiOverview":
        navigate("/admin/analytics/kpi-overview");
        break;
      case "marketTrends":
        navigate("/admin/analytics/market-trends");
        break;
      case "reporting":
        navigate("/admin/analytics/reporting");
        break;
      case "mlsIntegration":
        navigate("/admin/integrations/mls-integration");
        break;
      case "crmIntegration":
        navigate("/admin/integrations/crm-integration");
        break;
      case "marketingIntegration":
        navigate("/admin/integrations/marketing-tools");
        break;
      case "accountSettings":
        navigate("/admin/settings/account-settings");
        break;
      case "customerSupport":
        navigate("/admin/settings/customer-support");
        break;
      case "feedback":
        navigate("/admin/settings/feedback");
        break;
      default:
        console.warn(`Unhandled key: ${e.key}`);
    }
    toggleSidebar();
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const getSelectedKey = (pathname) => {
    if (pathname.startsWith("/admin/user-management/admin-users"))
      return "adminUsers";
    if (pathname.startsWith("/admin/user-management/agents")) return "agents";
    if (pathname.startsWith("/admin/user-management/builders"))
      return "builders";
    if (
      pathname.startsWith("/admin/user-management/user-profiles/all-profiles")
    )
      return "allProfiles";
    if (
      pathname.startsWith(
        "/admin/user-management/user-profiles/pending-profiles"
      )
    )
      return "pendingProfiles";
    if (
      pathname.startsWith(
        "/admin/user-management/user-profiles/assigned-profiles"
      )
    )
      return "assignedProfiles";
    if (
      pathname.startsWith(
        "/admin/user-management/user-profiles/rejected-profiles"
      )
    )
      return "rejectedProfiles";
    if (pathname.startsWith("/admin/content-management/projects/all-projects"))
      return "allProjects";
    if (
      pathname.startsWith("/admin/content-management/projects/pending-projects")
    )
      return "pendingProjects";
    if (
      pathname.startsWith(
        "/admin/content-management/projects/rejected-projects"
      )
    )
      return "rejectedProjects";
    if (
      pathname.startsWith("/admin/content-management/properties/all-properties")
    )
      return "allProperties";
    if (
      pathname.startsWith(
        "/admin/content-management/properties/pending-properties"
      )
    )
      return "pendingProperties";
    if (
      pathname.startsWith(
        "/admin/content-management/properties/rejected-properties"
      )
    )
      return "rejectedProperties";
    if (pathname.startsWith("/admin/content-management/blogs/overview"))
      return "blogOverview";
    if (pathname.startsWith("/admin/content-management/blogs/posts"))
      return "blogPosts";
    if (pathname.startsWith("/admin/content-management/blogs/comments"))
      return "blogComments";
    if (pathname.startsWith("/admin/client-inquiries/all-inquiries"))
      return "allInquiries";
    if (pathname.startsWith("/admin/client-inquiries/new-inquiries"))
      return "newInquiries";
    if (pathname.startsWith("/admin/client-inquiries/resolved-inquiries"))
      return "resolvedInquiries";
    if (pathname.startsWith("/admin/analytics/kpi-overview"))
      return "kpiOverview";
    if (pathname.startsWith("/admin/analytics/market-trends"))
      return "marketTrends";
    if (pathname.startsWith("/admin/analytics/reporting")) return "reporting";
    if (pathname.startsWith("/admin/integrations/mls-integration"))
      return "mlsIntegration";
    if (pathname.startsWith("/admin/integrations/crm-integration"))
      return "crmIntegration";
    if (pathname.startsWith("/admin/integrations/marketing-tools"))
      return "marketingIntegration";
    if (pathname.startsWith("/admin/settings/account-settings"))
      return "accountSettings";
    if (pathname.startsWith("/admin/settings/customer-support"))
      return "customerSupport";
    if (pathname.startsWith("/admin/settings/feedback")) return "feedback";
    return "dashboard";
  };

  return (
    <>
      {loading && <AdminLoader />}
      <div className={`asidebar ${isToggled ? "toggle" : ""}`}>
        <Menu
          selectedKeys={[getSelectedKey(location.pathname)]}
          mode="inline"
          theme="dark"
          items={menuItems}
          className="asidebar-ant-design"
          aria-label="Admin Sidebar Navigation"
          onClick={handleMenuClick}
          // inlineCollapsed
        />
      </div>
    </>
  );
};

export default AdminSidebar;
