import React, { useState, useEffect, Suspense, lazy, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";
import { userDataAtom } from "../store/atoms/userDataAtom";
import { useRecoilValue } from "recoil";

const AddProperty = lazy(() => import("../pages/PostProperty/AddProperty"));
const InitialProfile = lazy(() => import("../pages/SignUp/InitialProfile"));

function PostPropertyGaurd() {
  const navigate = useNavigate();
  const [userTypePresent, setUserTypePresent] = useState(null);

  const userDetails = useRecoilValue(userDataAtom);

  useMemo(() => {
    if (userDetails?.userProfile && userDetails?.isLoading === false && userDetails?.userProfile?.hasOwnProperty("userType")) {
      setUserTypePresent(true);
    } else if((!userDetails?.userProfile || !userDetails?.userProfile?.hasOwnProperty("userType")) && userDetails?.isLoading === false) {
      setUserTypePresent(false);
    }
  }, [userDetails]);

  useEffect(() => {
    if (userTypePresent === false) {
      navigate("/usertyperedirect");
    }
  }, [userTypePresent, navigate]);

  if (userTypePresent === null) {
    return <LoadingOverlay />;
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      {userTypePresent ? <AddProperty /> : <InitialProfile />}
    </Suspense>
  );
}

export default PostPropertyGaurd;
