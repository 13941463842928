const getOrdinalSuffix = (number) => {
  const j = number % 10;
  const k = number % 100;
  if (j === 1 && k !== 11) {
    return `${number}ST`;
  }
  if (j === 2 && k !== 12) {
    return `${number}ND`;
  }
  if (j === 3 && k !== 13) {
    return `${number}RD`;
  }
  return `${number}TH`;
};

export default getOrdinalSuffix;
