import React, { useState, useEffect, Suspense, lazy, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";
import { userDataAtom } from "../store/atoms/userDataAtom";
import { useRecoilValue } from "recoil";
// import UserProfile from "../pages/Profile/UserProfile";

const UserProfile = lazy(() => import("../pages/Profile/UserProfile"));
const InitialProfile = lazy(() => import("../pages/SignUp/InitialProfile"));

function ProfileGaurd() {
  const navigate = useNavigate();
  const [userTypePresent, setUserTypePresent] = useState(null); 
  const [userTypeCheckComplete, setUserTypeCheckComplete] =
    useState(false);
  const userDetails = useRecoilValue(userDataAtom);

  useMemo(() => {
    if (userDetails && userDetails.isLoading === false) {
      setUserTypePresent(userDetails?.userProfile?.hasOwnProperty("userType"));
      setUserTypeCheckComplete(true)
    }
  }, [userDetails]);

  useEffect(() => {
    if (userTypePresent === false && userTypeCheckComplete) {
      navigate("/usertyperedirect");
    }
  }, [userTypePresent,userTypeCheckComplete, navigate]);

  if (userTypePresent === null && userDetails?.isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      {userTypePresent === true ? <UserProfile /> : <InitialProfile />}
    </Suspense>
  );
}

export default ProfileGaurd;
