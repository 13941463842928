import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";

import "./AdminProjects.css";

// Ant Design Import
import { Space, Table, Modal, Input, Select, message } from "antd";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../../store/atoms/userDataAtom";

const { TextArea } = Input;
const { Column } = Table;

function AdminProjects() {
  const baseURL = process.env.REACT_APP_base_URL;

  const userDetails = useRecoilValue(userDataAtom);
  const [userId, setUserId] = useState(null);

  useMemo(() => {
    if (userDetails) {
      setUserId(userDetails.user._id);
    }
  }, [userDetails]);

  const [unverifiedProjects, setUnverifiedProjects] = useState([]);
  const [isAdminPanelNotModified, setIsAdminPanelNotModified] = useState(true);

  const getUnverifiedProjects = useCallback(async () => {
    try {
      const res = await axios.get(
        `${baseURL}/api/admin/getUnVerifiedProjects`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching unverified projects:", error);
    }
  }, [baseURL]);

  useMemo(() => {
    const fetchData = async () => {
      const projects = await getUnverifiedProjects();
      if (projects) {
        setUnverifiedProjects(projects);
      }
    };
    if (isAdminPanelNotModified) {
      fetchData();
    }
    return () => setIsAdminPanelNotModified(false);
  }, [getUnverifiedProjects, isAdminPanelNotModified]);

  // s3 testing

  const [unverifiedProjectsFromS3, setUnverifiedProjectsFromS3] = useState([]);

  const getUnverifiedProjectsFroms3 = useCallback(async () => {
    try {
      const res = await axios.get(
        `${baseURL}/api/admin/getUnVerifiedProjectsFromS3`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching unverified projects:", error);
    }
  }, [baseURL]);

  useMemo(() => {
    const fetchData = async () => {
      const s3Projects = await getUnverifiedProjectsFroms3();
      if (s3Projects) {
        setUnverifiedProjectsFromS3(s3Projects);
      }
    };
    if (isAdminPanelNotModified) {
      fetchData();
    }
    return () => setIsAdminPanelNotModified(false);
  }, [getUnverifiedProjectsFroms3, isAdminPanelNotModified]);

  const [data, setData] = useState([]);
  const [assignedUnverifiedProjectsData, setAssignedUnverifiedProjectsData] =
    useState([]);

  const getAllAdmins = useCallback(async () => {
    try {
      const res = await axios.get(`${baseURL}/api/admin/getAllAdminUsers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (
        res.data.message === "Admins found successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching all admins:", error);
    }
  }, [baseURL]);

  const getAllAssignedUnverifiedProjects = useCallback(async () => {
    try {
      const res = await axios.get(
        `${baseURL}/api/admin/getAllAssignedUnverifiedProjects`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: { userId: userId },
        }
      );
      if (
        res.data.message ===
          "Assigned unverified projects fetched successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching all admins:", error);
    }
  }, [baseURL, userId]);

  useEffect(() => {
    const fetchAllAdmins = async () => {
      const AllAdmins = await getAllAdmins();
      const AllAssignedUnverifiedProjects =
        await getAllAssignedUnverifiedProjects();
      if (AllAdmins) {
        setData(AllAdmins.adminList);
      }
      if (AllAssignedUnverifiedProjects) {
        setAssignedUnverifiedProjectsData(
          AllAssignedUnverifiedProjects.unverifiedProjects
        );
      }
    };
    if (isAdminPanelNotModified) {
      fetchAllAdmins();
    }
    return () => setIsAdminPanelNotModified(false);
  }, [getAllAdmins, getAllAssignedUnverifiedProjects, isAdminPanelNotModified]);

  const unverifiedData = unverifiedProjects.map((project, index) => ({
    id: project.id,
    key: index,
    name: project.projectName,
    area: `${project.projectArea} ${project.projectAreaUnit}`,
    price: `${project.projectCurrencyType} ${project.projectPriceRange.priceFrom} - ${project.projectPriceRange.priceTo}`,
    projectLogo: project.projectLogo,
  }));

  const unverifiedDataFromS3 = unverifiedProjectsFromS3.map((project, index) => ({
    id: project.id,
    key: index,
    name: project.projectName,
    area: `${project.projectArea} ${project.projectAreaUnit}`,
    price: `${project.projectCurrencyType} ${project.projectPriceRange.priceFrom} - ${project.projectPriceRange.priceTo}`,
    projectLogo: project.projectLogo,
  }));

  const assignedData = assignedUnverifiedProjectsData.map((project, index) => ({
    key: index,
    name: project.projectName,
    area: `${project.projectArea} ${project.projectAreaUnit}`,
    price: `${project.projectCurrencyType} ${project.projectPriceRange.priceFrom} - ${project.projectPriceRange.priceTo}`,
    projectLogo: project.projectLogo,
  }));
  // const rejectedData = unverifiedProperties.map((property, index) => ({
  //   key: index,
  //   name: project.projectName,
  //   area: `${project.projectArea} ${project.projectAreaUnit}`,
  //   price: `${project.projectCurrencyType} ${project.projectPriceRange.priceFrom} - ${project.projectPriceRange.priceTo}`,
  //   projectLogo: project.projectLogo,
  // }));

  // const unverifiedData = [
  //   {
  //     key: "1",
  //     name: "This is project Name ...",
  //     area: "2250 sqft",
  //     price: "₹ 15Cr - 25Cr",
  //     projectLogo: "https://placehold.co/600x400?text=U",
  //   },
  // ];

  const rejectedData = [
    {
      key: "1",
      name: "This is project Name ...",
      area: "2250 sqft",
      price: "₹ 15Cr - 25Cr",
      projectLogo: "https://placehold.co/600x400?text=U",
    },
  ];

  // Project Reject Modal & Reject Reason
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const showRejectModal = () => {
    setIsRejectModalVisible(true);
  };
  const handleRejectOk = () => {
    console.log("Reject reason:", rejectReason);
    setIsRejectModalVisible(false);
    setRejectReason("");
  };
  const handleRejectCancel = () => {
    setIsRejectModalVisible(false);
    setRejectReason("");
  };

  // Project Reject Reason Modal
  const [isRejectReasonModalVisible, setIsRejectReasonModalVisible] =
    useState(false);
  const showRejectReasonModal = () => {
    setIsRejectReasonModalVisible(true);
  };
  const handleRejectReasonOk = () => {
    setIsRejectReasonModalVisible(false);
  };
  const handleRejectReasonCancel = () => {
    setIsRejectReasonModalVisible(false);
  };

  // Project Assign Modal
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [selectedAssignedAdmin, setSelectedAssignedAdmin] = useState("");
  const [selectedUnverfiedProjectId, setSelectedUnverfiedProjectId] =
    useState("");
  const showAssignModal = (id) => {
    setIsAssignModalVisible(true);
    setSelectedUnverfiedProjectId(id);
  };
  const handleAssignOk = async () => {
    if (selectedAssignedAdmin === "") {
      return message.error("please select admin to assign this task");
    }
    if (selectedUnverfiedProjectId === "") {
      return message.error("please select a task to assign");
    }
    try {
      const formData = new FormData();
      formData.append("assignedBy", userId);
      formData.append("assignedTo", selectedAssignedAdmin);
      formData.append("unverifiedProjectId", selectedUnverfiedProjectId);
      const res = await axios.post(
        `${baseURL}/api/admin/assignTask`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        res.data.message === "Task assigned successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        message.success(res.data.message);
        setIsAssignModalVisible(false);
        setIsAdminPanelNotModified(true);
        setSelectedAssignedAdmin("");
        setSelectedUnverfiedProjectId("");
      }
    } catch (error) {
      message.error(error.response.data.message);
      setIsAssignModalVisible(false);
      console.error("Error assigning task:", error);
    }
  };
  const handleAssignCancel = () => {
    setIsAssignModalVisible(false);
    setSelectedAssignedAdmin("");
  };

  return (
    <div className="adashboard">
      <div className="adashboard-sub">
        <div className="adashboard-header">
          <h2>Project Verifications</h2>
        </div>

        <div className="ausers">
          <div className="ausers-sub">
            <h5>Pending for verifications from S3 ({unverifiedDataFromS3.length})</h5>
          </div>
          <div className="ausers-sub">
            <div className="ausers-sub-table">
              <Table
                dataSource={unverifiedDataFromS3}
                className="ausers-sub-table-ant-design"
              >
                <Column title="Name" dataIndex="name" key="name" />
                <Column title="Area" dataIndex="area" key="area" />
                <Column title="Price" dataIndex="price" key="price" />
                <Column
                  title="Project Logo"
                  dataIndex="projectLogo"
                  key="projectLogo"
                  render={(projectLogo) => (
                    <img
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "50%",
                      }}
                      src={projectLogo}
                      alt=""
                    />
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  render={(action) => (
                    <Space size="middle">
                      <a>Start Verification</a>
                      <a
                        onClick={() => {
                          showAssignModal(action.id);
                        }}
                      >
                        Assign task
                      </a>
                      <a onClick={showRejectModal}>Reject</a>
                    </Space>
                  )}
                />
              </Table>
            </div>
          </div>
        </div>

        <div className="ausers">
          <div className="ausers-sub">
            <h5>Pending for verifications ({unverifiedData.length})</h5>
          </div>
          <div className="ausers-sub">
            <div className="ausers-sub-table">
              <Table
                dataSource={unverifiedData}
                className="ausers-sub-table-ant-design"
              >
                <Column title="Name" dataIndex="name" key="name" />
                <Column title="Area" dataIndex="area" key="area" />
                <Column title="Price" dataIndex="price" key="price" />
                <Column
                  title="Project Logo"
                  dataIndex="projectLogo"
                  key="projectLogo"
                  render={(projectLogo) => (
                    <img
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "50%",
                      }}
                      src={`data:image/jpeg;base64,${projectLogo}`}
                      alt=""
                    />
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  render={(action) => (
                    <Space size="middle">
                      <a>Start Verification</a>
                      <a
                        onClick={() => {
                          showAssignModal(action.id);
                        }}
                      >
                        Assign task
                      </a>
                      <a onClick={showRejectModal}>Reject</a>
                    </Space>
                  )}
                />
              </Table>
            </div>
          </div>
        </div>
        {assignedData.length > 0 && (
          <div className="ausers">
            <div className="ausers-sub">
              <h5>Assigned Projects ({assignedData.length})</h5>
            </div>
            <div className="ausers-sub">
              <div className="ausers-sub-table">
                <Table
                  dataSource={assignedData}
                  className="ausers-sub-table-ant-design"
                >
                  <Column title="Name" dataIndex="name" key="name" />
                  <Column title="Area" dataIndex="area" key="area" />
                  <Column title="Price" dataIndex="price" key="price" />
                  <Column
                    title="Project Logo"
                    dataIndex="projectLogo"
                    key="projectLogo"
                    render={(projectLogo) => (
                      <img
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "50%",
                        }}
                        src={`data:image/jpeg;base64,${projectLogo}`}
                        alt=""
                      />
                    )}
                  />
                  <Column
                    title="Action"
                    key="action"
                    render={() => (
                      <Space size="middle">
                        <a>Continue Verification</a>
                        <a onClick={showRejectModal}>Reject</a>
                      </Space>
                    )}
                  />
                </Table>
              </div>
            </div>
          </div>
        )}
        <div className="ausers">
          <div className="ausers-sub">
            <h5>Rejected Projects (3)</h5>
          </div>
          <div className="ausers-sub">
            <div className="ausers-sub-table">
              <Table
                dataSource={rejectedData}
                className="ausers-sub-table-ant-design"
              >
                <Column title="Name" dataIndex="name" key="name" />
                <Column title="Area" dataIndex="area" key="area" />
                <Column title="Price" dataIndex="price" key="price" />
                <Column
                  title="Project Logo"
                  dataIndex="projectLogo"
                  key="projectLogo"
                  render={(projectLogo) => (
                    <img
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "50%",
                      }}
                      src={`data:image/jpeg;base64,${projectLogo}`}
                      alt=""
                    />
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  render={() => (
                    <Space size="middle">
                      <a onClick={showRejectReasonModal}>
                        View Rejected Reason
                      </a>
                    </Space>
                  )}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Are you sure to reject this project verification?"
        visible={isRejectModalVisible}
        onOk={handleRejectOk}
        onCancel={handleRejectCancel}
        okText="Reject"
        okType="danger"
        cancelText="Cancel"
      >
        <div className="aprofile-modal-reject-reason">
          <p>This action is permanent and cannot be undone.</p>
          <TextArea
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            placeholder="Enter reject reason"
            autoSize={{
              minRows: 4,
              maxRows: 8,
            }}
          />
        </div>
      </Modal>
      <Modal
        title="Project Verification Rejected Reason"
        visible={isRejectReasonModalVisible}
        footer={null}
        onOk={handleRejectReasonOk}
        onCancel={handleRejectReasonCancel}
      >
        <div className="aprofile-modal-reject-reason">
          <p>The reson of rejection will come here.</p>
          <p>
            (Note: The user will also see the reason provided for rejection of
            his/her project.)
          </p>
        </div>
      </Modal>
      <Modal
        title="Assign this project verification"
        visible={isAssignModalVisible}
        onOk={handleAssignOk}
        onCancel={handleAssignCancel}
        okText="Assign"
        cancelText="Cancel"
      >
        <div className="aprofile-modal-reject-reason">
          <p>
            You can assign to any admin (Sub Admin or Reviewer), but only assign
            one admin at a time.
          </p>
          <Select
            showSearch
            name="adminsLists"
            value={
              selectedAssignedAdmin && selectedAssignedAdmin !== ""
                ? selectedAssignedAdmin
                : "Select Admin or Reviewer"
            }
            className="aprofile-modal-reject-reason-select"
            onChange={(value) => setSelectedAssignedAdmin(value)}
            optionFilterProp="label"
            options={[
              {
                label: <span>Sub Admin</span>,
                title: "manager",
                options: data
                  .filter((admin) => admin.roles[0] === "subAdmin")
                  .map((admin) => ({
                    label: admin.fullName,
                    value: admin.id,
                  })),
              },
              {
                label: <span>Reviewer</span>,
                title: "engineer",
                options: data
                  .filter((admin) => admin.roles[0] === "reviewer")
                  .map((admin) => ({
                    label: admin.fullName,
                    value: admin.id,
                  })),
              },
            ]}
          />
          <p>
            (Note: The selected admin will be allowed to verify the project of
            the user only.)
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default AdminProjects;
