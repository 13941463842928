import React from "react";
import { useNavigate } from "react-router-dom";
import "./TrendingProperties.css";

// ANT DESIGN ICONS
import {
  SafetyCertificateOutlined,
  HeartOutlined,
  EyeOutlined,
  ShareAltOutlined,
  EnvironmentOutlined,
  ExpandAltOutlined,
} from "@ant-design/icons";

// REACT ICONS
import { PiBedDuotone, PiBathtubDuotone } from "react-icons/pi";

function TrendingProperties() {
  const navigate = useNavigate();

  return (
    <div className="trending">
      <div className="trending-sub">
        <h5>TRENDING PROPERTIES</h5>
        <h1>Discover Proptzo's Finest Properties</h1>
        <h1>For Your Dream Home</h1>
        <div className="trending-prop">
          {[...Array(8)].map((_, index) => (
            <div key={index} className="trending-prop-sub">
              <div className="trending-prop-img">
                <img
                  src="https://placehold.co/800x450?text=proptzo"
                  alt="property"
                />
                <div className="trending-for">
                  <h5>FOR SALE</h5>
                </div>
                <div className="trending-type">
                  <h5>APARTMENT</h5>
                </div>
                <div className="trending-rera">
                  <SafetyCertificateOutlined className="trending-rera-icon" />
                  <h5>RERA CERTIFIED</h5>
                </div>
                <div className="trending-prop-img-icons">
                  <ShareAltOutlined className="trending-prop-img-icons-icon" />
                  <HeartOutlined className="trending-prop-img-icons-icon" />
                  <EyeOutlined className="trending-prop-img-icons-icon" />
                </div>
              </div>
              <h5>Casa Loma De Machali Casa Loma De Machali</h5>
              <div className="trending-prop-loc">
                <EnvironmentOutlined className="trending-prop-loc-icon" />
                <p>
                  15 Willow Street, Seattle, WA 15 Willow Street, Seattle, WA
                </p>
              </div>
              <div className="trending-prop-data">
                <PiBedDuotone className="trending-prop-data-icon" />
                <h5>2</h5>
                <PiBathtubDuotone className="trending-prop-data-icon" />
                <h5>6</h5>
                <ExpandAltOutlined className="trending-prop-data-icon" />
                <h5>2250 sqft</h5>
              </div>
              <div className="trending-prop-footer">
                <h5 className="trending-prop-footer-name">Anil Kumar</h5>
                <h5 className="trending-prop-footer-price">₹ 2.5Cr</h5>
              </div>
            </div>
          ))}
        </div>
        <button onClick={() => navigate("/properties")}>
          View All Properties
        </button>
      </div>
    </div>
  );
}

export default TrendingProperties;
