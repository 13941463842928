import { useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import parse from "html-react-parser";

function TextEditor({ placeholder, width, role }) {
  const editorRef = useRef(null);
  const [content, setContent] = useState("");

  const config = useMemo(
    () => ({
      placeholder: placeholder,
      speechRecognize: false,
      autofocus: true,
      spellcheck: true,
      width: width,
    }),
    [placeholder, width]
  );

  return (
    <div style={{width: "100vw", paddingTop: "65px", display:'flex', justifyContent:'center', flexDirection:'column'}}>
      <JoditEditor
        ref={editorRef}
        value={content}
        config={config}
        onChange={(newContent) => setContent(newContent)}
      />
      <div style={{width: "100vw", marginTop: "20px", display:'flex', alignItems:'flex-start', justifyContent:'flex-start', border: '2px solid #000'}}>
          {parse(content)}
      </div>
    </div>
  );
}

export default TextEditor;
